import './Left.css';
import HeAd from './Head/Main';
import LeFTBodY from './Body/Main';

// Left Main
export default function RetuRnLEFt() {
 return (
  <div className='flex-litige-left flex-border-rigth'>
   <HeAd />
   <LeFTBodY />
  </div>
 );
};