import './Head.css';
import RetuRnTitle from './Title';
import secureLocalStorage from "react-secure-storage";


// Head Form
export default function RetuRnHEAd() {
 return (
  <div style={{ backgroundColor: secureLocalStorage.getItem("##$$!!color#$$#") }} className='flex-head-form-acces-litige'>
   <RetuRnTitle />
  </div>
 );
};