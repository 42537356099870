import './Pret.css';
import ReturnBalance from './Balance';

// Pret Component
export default function RetuRnPrET() {
 return (
  <div className='between'>
   <div></div>
   <ReturnBalance />
  </div>
 );
};