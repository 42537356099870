
import './Title.css';

// Title View 
export default function ReturnTitle() {
 return (
  <div className='litige-title-transf-two'>
   <h1>Annulation d'achat de crédit</h1>
  </div>
 );
};