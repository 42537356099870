import './Home.css';
import HeAd from './Head/Main';
import TotAl from './Total/Main';
import ViEw from './View/Main';
import Media from 'react-media';


// InFo view Component 
export default function ReturnHmInFo() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='wrp-hm-info'>
  <HeAd />
  <TotAl />
  <ViEw />
  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-hm-info'>
  <HeAd />
  <TotAl />
  <div></div>
  <div></div>
 </div>
);