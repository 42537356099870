import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";


// Identity View
export default function RetuRnNameTransf() {

 let [value, setValue] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setValue(doc.data().user);
  });

 }, []);


 let result = value.charAt(0).toUpperCase() + value.slice(1);

 return (
  <div className='list-sub-exchange-info-detail color-purple-info'>
   <p>Nom du dernier transfert : </p>
   <p>{result}</p>
  </div>
 );
};