import React from 'react';
import { db } from '../../../../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import './List.css';
import moment from 'moment';
import currency from 'currency.js';
import Media from 'react-media';


// List 
export default function RetuRnFrAnLisT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenSmall = () => {
 return (
  <ViEW />
 );
};
export const ScreenLarge = () => {
 return (
  <ViEW />
 );
};


export const ViEW = () => {

 let pushDoc = new Array();
 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const q = query(collection(db, "swap" + secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), orderBy('dateofcreate'));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {

   if (doc.data().devise === 'CDF') {
    pushDoc.push(doc.data())
   };

  })

  setList([...new Set(pushDoc.reverse())]);

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 return (
  <div className='list-exchange-buy-detail-view'>
   {[...Array(list.length).keys()].map((item) => {

    let str = list[item].type === undefined ? '' : list[item].type;
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    return (

     <div style={{ backgroundColor: `${list[item].color}` }} className='list-top-exchange-buy-detail-view'>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Action  : </p>
       <p>{list[item].action}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Montant transféré  : </p>
       <p>{euro(list[item].money).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Montant avant  : </p>
       <p>{euro(list[item].before).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Montant après  : </p>
       <p>{euro(list[item].after).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Type de transfert  : </p>
       <p>{list[item].type}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Date du transfert  : </p>
       <p>{moment(list[item].date).format('LLL')}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Délai consommer  : </p>
       <p>{moment().to(list[item].date)}</p>
      </div>

      {list[item].statetrans &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Numéro du bénéficiaire  : </p>
        <p>{list[item].phone}</p>
       </div>
      }

      {list[item].statetrans &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Nom du bénéficiaire  : </p>
        <p>{(list[item].user + '').toLowerCase()}</p>
       </div>
      }



      {list[item].statetransed &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Numéro de l'envoyeur  : </p>
        <p>{list[item].phone}</p>
       </div>
      }

      {list[item].statetransed &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Nom de l'envoyeur  : </p>
        <p>{(list[item].user + '').toLowerCase()}</p>
       </div>
      }



      {list[item].statecrediter &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Opérateur utilisé  : </p>
        <p>{list[item].operator}</p>
       </div>
      }

      {list[item].statetrans &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Soldes du bénéficiaire avant  : </p>
        <p>{euro(list[item].soldebefore).format()} {list[item].devise}</p>
       </div>
      }

      {list[item].statetrans &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Soldes du bénéficiaire après  : </p>
        <p>{euro(list[item].soldeafter).format()} {list[item].devise}</p>
       </div>

      }

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Frais  : </p>
       <p>{euro(list[item].frais).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Commission  : </p>
       <p>{euro(list[item].commission).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Coffre avant  : </p>
       <p>{euro(list[item].beforethrift).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Coffre après  : </p>
       <p>{euro(list[item].afterthrift).format()} {list[item].devise}</p>
      </div>


      {list[item].statecrediter
       &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Référence  : </p>
        <p>{list[item].ref}</p>
       </div>
      }

      {list[item].statepret
       &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>prêt initiale  : </p>
        <p>{euro(list[item].pretbefore).format()} {list[item].devise}</p>
       </div>
      }
      {list[item].statepret
       &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>prêt actuel  : </p>
        <p>{euro(list[item].pretafter).format()} {list[item].devise}</p>
       </div>
      }




      {list[item].stateunit &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Montant d'achat  : </p>
        <p>{euro(list[item].count).format()} {list[item].devise}</p>
       </div>
      }

      {list[item].stateunit &&
       <div className='list-sub-exchange-buy-detail-view'>
        <p>Nombre d'unités acheté  : </p>
        <p>{list[item].unit} U</p>
       </div>
      }


      {parseInt(list[item].countvoda) != 0 &&
       <>
        {
         list[item].stateunit &&
         <div className='list-sub-exchange-buy-detail-view'>
          <p>Achat sur {list[item].numvoda}  : </p>
          <p>{euro(list[item].countvoda).format()} {list[item].devise}  :   {list[item].unitvoda} U</p>
         </div>
        }
       </>
      }

      {parseInt(list[item].countorange) != 0 &&
       <>
        {list[item].stateunit &&
         <div className='list-sub-exchange-buy-detail-view'>
          <p>Achat sur {list[item].numorange}  : </p>
          <p>{euro(list[item].countorange).format()} {list[item].devise}  :   {list[item].unitorange} U</p>
         </div>
        }

       </>
      }

      {parseInt(list[item].countafricell) != 0 &&
       <>
        {list[item].stateunit &&
         <div className='list-sub-exchange-buy-detail-view'>
          <p>Achat sur {list[item].numafricell}  : </p>
          <p>{euro(list[item].countafricell).format()} {list[item].devise}  :   {list[item].unitafricell} U</p>
         </div>
        }
       </>
      }

      {parseInt(list[item].countairtel) != 0 &&
       <>
        {
         list[item].stateunit &&
         <div className='list-sub-exchange-buy-detail-view'>
          <p>Achat sur {list[item].numairtel}  : </p>
          <p>{euro(list[item].countairtel).format()} {list[item].devise}  :  {list[item].unitairtel} U</p>
         </div>
        }
       </>

      }



     </div>

    );
   })}

  </div>
 );
};
