import React from 'react';
import './Prix.css';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';
import RetuRnDrawer from './Drawer';

// Valid Head Two
export default function RetuRnPriX() {

 const [counter, setCounter] = React.useState(0);
 React.useEffect(() => {

  const intervalId = setInterval(() => {
   setCounter(prevCounter => prevCounter + 1)
  }, 1000);

  return () => clearInterval(intervalId);

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });


 return (
  <>
   <div className='wrp-prix-valid-litige'>
    {counter != 0 && <p style={{ color: '#fff' }}>{euro(secureLocalStorage.getItem("#$$!!money%%$$$")).format()} {secureLocalStorage.getItem("#$$!!devise%%$$$")}</p>}
   </div>
   <RetuRnDrawer />
  </>
 );
};