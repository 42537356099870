import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import secureLocalStorage from "react-secure-storage";




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};


// Firebase auth 
export let phoneX = '';




// Input Field Component 
export default function ReturnInput() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};
export const SreenLarge = () => (
 <div className='wrp-input-phone-personal'>
  <FormInput />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-input-phone-personal'>
  <FormInput />
 </div>
);

export const FormInput = () => {

 let clientDocs = new Array();
 let agentDocs = new Array();


 const navigation = useNavigate();
 const [load, setLoad] = React.useState(false);
 const [arrClient, setArrClient] = React.useState([]);
 const [arrAgent, setArrAgent] = React.useState([]);

 const { handleSubmit, reset, control } = useForm({});

 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');

 const [open, setOpen] = React.useState(false);
 const [error, setError] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });

 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handleError = () => {
  setError(false);
 };

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   clientDocs.push(doc.id);
  });

  setArrClient(clientDocs);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   agentDocs.push(doc.id);
  });

  setArrAgent(agentDocs);

 }, []);


 const onSubmit = async (data) => {

  setLoad(true);

  if (false) {

   setError(true);
   setLoad(false);
   reset();

  } else {


   if (secureLocalStorage.getItem("##$$!!#$$#") === 'two') {

    let num = (data.send).match(/\d+/g);
    let numPhone = '';
    num.map(index => {
     numPhone += index;

    });

    let numed = (data.getted).match(/\d+/g);
    let numgetted = '';
    numed.map(index => {
     numgetted += index;
    });


    if (data.send === undefined || data.getted === undefined) {

     setError(true);
     setLoad(false);
     reset();

    } else {


     if (numPhone.length != 10 || numPhone.charAt(0) != 0 || numgetted.length != 10 || numgetted.charAt(0) != 0) {
      setError(true);
      setLoad(false);
      reset();

     } else {




      if (arrAgent.includes(numPhone) && arrAgent.includes(numgetted)) {

       secureLocalStorage.setItem("##**$$cols#", false);
       secureLocalStorage.setItem("##**$$cols#$$", false);

       secureLocalStorage.setItem("##**", 'agent');

       secureLocalStorage.setItem("#@$$$phone##$", numPhone);
       secureLocalStorage.setItem("#@$$$phone##$%%", numgetted);

       window.setTimeout(() => {
        window.location.href = '/litige/dash/two';
       }, 1000);


      } else if (arrClient.includes(numPhone) && arrClient.includes(numgetted)) {

       secureLocalStorage.setItem("##**$$cols#", true);
       secureLocalStorage.setItem("##**$$cols#$$", true);

       secureLocalStorage.setItem("##**", 'client');

       secureLocalStorage.setItem("#@$$$phone##$", numPhone);
       secureLocalStorage.setItem("#@$$$phone##$%%", numgetted);

       window.setTimeout(() => {
        window.location.href = '/litige/dash/two';
       }, 1000);

      } else if (arrAgent.includes(numPhone) && arrClient.includes(numgetted)) {

       secureLocalStorage.setItem("##**$$cols#", false);
       secureLocalStorage.setItem("##**$$cols#$$", true);

       secureLocalStorage.setItem("##**", 'agentclient');

       secureLocalStorage.setItem("#@$$$phone##$", numPhone);
       secureLocalStorage.setItem("#@$$$phone##$%%", numgetted);

       window.setTimeout(() => {
        window.location.href = '/litige/dash/two';
       }, 1000);


      } else if (arrClient.includes(numPhone) && arrAgent.includes(numgetted)) {

       secureLocalStorage.setItem("##**$$cols#", true);
       secureLocalStorage.setItem("##**$$cols#$$", false);

       secureLocalStorage.setItem("##**", 'clientagent');

       secureLocalStorage.setItem("#@$$$phone##$", numPhone);
       secureLocalStorage.setItem("#@$$$phone##$%%", numgetted);


       window.setTimeout(() => {
        window.location.href = '/litige/dash/two';
       }, 1000);

      } else {
       window.console.log('Hello world');
      }




     };

    }


   } else if (secureLocalStorage.getItem("##$$!!#$$#") === 'personal') {

    if (data.personal === undefined) {

     setError(true);
     setLoad(false);
     reset();

    } else {

     let numpers = (data.personal).match(/\d+/g);
     let numPersonal = '';
     numpers.map(index => {
      numPersonal += index;
     });

     if (numPersonal.length != 10 || numPersonal.charAt(0) != 0) {

      setError(true);
      setLoad(false);
      reset();

     } else {

      if (arrAgent.includes(numPersonal)) {

       secureLocalStorage.setItem("##**$$cols#", false);
       secureLocalStorage.setItem("##**$$cols#$$", false);
       secureLocalStorage.setItem("##**", 'agent');

       secureLocalStorage.setItem("#@$$$phone##$", numPersonal);

       window.setTimeout(() => {
        window.location.href = '/litige/dash/personal';
       }, 1000);

       window.console.log(numPersonal);

      } else if (arrClient.includes(numPersonal)) {

       secureLocalStorage.setItem("##**$$cols#", true);
       secureLocalStorage.setItem("##**$$cols#$$", true);
       secureLocalStorage.setItem("##**", 'client');
       secureLocalStorage.setItem("#@$$$phone##$", numPersonal);

       window.console.log(numPersonal);

       window.setTimeout(() => {
        window.location.href = '/litige/dash/personal';
       }, 1000);

      } else {
       window.console.log('Hello world');
      }



     };




    }

   } else if (secureLocalStorage.getItem("##$$!!#$$#") === 'cancel') {

    if (data.cancel === undefined) {

     setError(true);
     setLoad(false);
     reset();

    } else {

     let numpers = (data.cancel).match(/\d+/g);
     let numCancel = '';
     numpers.map(index => {
      numCancel += index;
     });

     if (numCancel.length != 10 || numCancel.charAt(0) != 0) {

      setError(true);
      setLoad(false);
      reset();

     } else {

      if (arrAgent.includes(numCancel)) {

       secureLocalStorage.setItem("##**$$cols#", false);
       secureLocalStorage.setItem("##**$$cols#$$", false);
       secureLocalStorage.setItem("##**", 'agent');
       secureLocalStorage.setItem("#@$$$phone##$", numCancel);
       secureLocalStorage.setItem("#@$**%%$#$", false);

       window.setTimeout(() => {
        window.location.href = '/litige/dash/cancel';
       }, 1000);

       window.console.log(numCancel);

      } else {

       setError(true);
       setLoad(false);

      }

     };

    }


   }

   else {
    window.console.log('nothing');

   }


  }

 };



 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>

    {secureLocalStorage.getItem("##$$!!#$$#") === 'two' &&
     <>
      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numéro de l'envoyer</h1></InputLabel>

       <Controller
        name="send"
        control={control}
        render={({ field }) =>

         <Input
          // autoFocus
          value={values.textmask}
          onChange={handleChange}
          inputRef={inputRef}
          onChange={(e) => setSearchTerm(e.target.value)}

          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}

          InputProps={{
           inputComponent: NumericFormatCustom,
          }}

          inputmode="tel"
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          {...field}

         />

        }
       />
      </FormControl>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numéro du bénéficiaire</h1></InputLabel>

       <Controller
        name="getted"
        control={control}
        render={({ field }) =>

         <Input
          // autoFocus
          value={values.textmask}
          onChange={handleChange}
          inputRef={inputRef}
          onChange={(e) => setSearchTerm(e.target.value)}

          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}

          InputProps={{
           inputComponent: NumericFormatCustom,
          }}

          inputmode="tel"
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          {...field}

         />

        }
       />
      </FormControl>

     </>
    }


    {secureLocalStorage.getItem("##$$!!#$$#") === 'cancel' &&
     <FormControl sx={{ width: '100%' }} variant="standard">
      <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numéro</h1></InputLabel>

      <Controller
       name="cancel"
       control={control}
       render={({ field }) =>

        <Input
         // autoFocus
         value={values.textmask}
         onChange={handleChange}
         inputRef={inputRef}
         onChange={(e) => setSearchTerm(e.target.value)}

         inputProps={{
          autoComplete: "off", inputMode: 'tel'
         }}

         InputProps={{
          inputComponent: NumericFormatCustom,
         }}

         inputmode="tel"
         name="textmask"
         id="formatted-text-mask-input"
         inputComponent={TextMaskCustom}
         {...field}

        />

       }
      />
     </FormControl>

    }


    {secureLocalStorage.getItem("##$$!!#$$#") === 'personal' &&
     <FormControl sx={{ width: '100%' }} variant="standard">
      <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numéro</h1></InputLabel>

      <Controller
       name="personal"
       control={control}
       render={({ field }) =>

        <Input
         // autoFocus
         value={values.textmask}
         onChange={handleChange}
         inputRef={inputRef}
         onChange={(e) => setSearchTerm(e.target.value)}

         inputProps={{
          autoComplete: "off", inputMode: 'tel'
         }}

         InputProps={{
          inputComponent: NumericFormatCustom,
         }}

         inputmode="tel"
         name="textmask"
         id="formatted-text-mask-input"
         inputComponent={TextMaskCustom}
         {...field}

        />

       }
      />
     </FormControl>

    }


    <button style={{ marginTop: '0.1vh' }} className='Btn'>Suivant</button>
   </form>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={error}
    onClose={handleError}
   >
    <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>
       Veuillez vérifier les numéros transmis celle-ci est invalide
      </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleError}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}
   >
    <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>
       Numero de télèphone est déja enregistre chez Muunganomoney
       </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

  </>
 );
};
