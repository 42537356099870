
import ConsumpTiOn from './Consumption/Main';
import DeTAil from './Detail/Main';


// Body wait
export default function RetuRnBoDy() {
 return (
  <div>
   <ConsumpTiOn />
   <DeTAil />
  </div>
 );
};