import './Head.css';
import IcOn from './Icon/Main';
import RetuRnlOgo from './Logo';
import ReturnIDUseR from './ID/Main';
import Media from 'react-media';
import RetuRnAvaTaR from './Avatar';



// Head InFO
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <View />
);
export const ScreenSmall = () => (
 <div>
  <div className='flex-between flex-border-bottom flex-pd-bottom wrp-head-hm-info-small'>
   <RetuRnlOgo />
   <IcOn />
  </div>

  <RetuRnAvaTaR />
 </div>
);
export const View = () => {
 return (
  <div className='flex-between flex-border-bottom flex-pd-bottom wrp-head-hm-info'>
   <RetuRnlOgo />
   <ReturnIDUseR />
   <IcOn />
  </div>
 );
};