
import RetuRnLisT from './List';

// Pret COmponent List
export default function RetuRNPReT() {
 return (
  <div className='scroll-user-list-tontine'>
   <RetuRnLisT />
  </div>
 );
}