import * as React from 'react';
import secureLocalStorage from "react-secure-storage";
import RetuRNBtnVodA from './Voda';
import RetuRNBtnOrange from './Orange';
import RetuRNBtnAirtel from './Airtel';
import RetuRNBtnAfricell from './Africell';


export default function RetuRNBtn() {
 return (
  <>
   {!!secureLocalStorage.getItem("#$$!!list%%#$$").operavoda && <RetuRNBtnVodA />}
   {!!secureLocalStorage.getItem("#$$!!list%%#$$").operaorange && <RetuRNBtnOrange />}
   {!!secureLocalStorage.getItem("#$$!!list%%#$$").operaairtel && <RetuRNBtnAirtel />}
   {!!secureLocalStorage.getItem("#$$!!list%%#$$").operaafricell && <RetuRNBtnAfricell />}
  </>
 );
};
