import React from 'react';
import './Input.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword, onAuthStateChanged
} from "firebase/auth";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { doc, updateDoc, increment, onSnapshot, arrayUnion, setDoc, collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase';
import moment from 'moment';
import Media from 'react-media';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import PropagateLoader from "react-spinners/PropagateLoader";



let expireNum = 60;
let colorspinner = '#d8e2dc';
let sizespinner = 10;


let widthSizeIconMenuSmall = 70;
let heightSizeIconMenuSmall = 70;
let textSizeIconMenuSmall = 70;


export default function ReturnInPut() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <ScreenLarge />}
					{matches.large && <ScreenLarge />}
				</>
			)}
		</Media>
	);
}

export const ScreenLarge = () => (
	<div className='input-sign-hm'>
		<ViEW />
	</div>
);
export const ScreenSmall = () => (
	<div className='input-sign-hm'>
		<ViEW />
	</div>
);

// Input form field 
export function ViEW() {

	const navigation = useNavigate();


	let pushDoc = new Array();
	let pushPret = new Array();

	const { register, handleSubmit, reset } = useForm();
	const [load, setLoad] = React.useState(false);
	const [open, setOpen] = React.useState(false);


	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');



	const [empty, setEmpty] = React.useState(false);
	const [listed, setListed] = React.useState([]);
	const [listpret, setListpret] = React.useState([]);
	const [verify, setVerify] = React.useState(null);



	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, "tontine"));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			pushDoc.push(doc.data());
		});

		setListed(pushDoc);

	}, []);
	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, "pret"));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			pushPret.push(doc.data());
		});
		setListpret(pushPret);
	}, []);
	React.useEffect(async () => {

		try {
			const unsub = onSnapshot(doc(db, "muunganomoney", "partner"), (doc) => {
				setVerify(doc.data().sms === undefined ? null : doc.data().sms);
			});

		} catch (e) {
			setVerify(null);
		}


	}, [])


	// ================================================
	// Array admin emoney and  commission


	const handleClose = () => {
		setOpen(false);
	};
	// Ref collection database!
	const onSubmit = async (data) => {

		setLoad(true);

		secureLocalStorage.setItem("@#$66ACTIVE@##*", true);
		secureLocalStorage.setItem("**wallet##&*@@!!++$$", true);
		secureLocalStorage.setItem("**icon#^menu#small#&*@@width!!++$$", widthSizeIconMenuSmall);
		secureLocalStorage.setItem("**icon#^menu#small#&*@@heigth!!++$$", heightSizeIconMenuSmall);
		secureLocalStorage.setItem("**icon#^menu#small#&*@@text!!++$$", textSizeIconMenuSmall);


		secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", true);
		secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
		secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

		secureLocalStorage.setItem("@#$$!CNA#$&", []);
		secureLocalStorage.setItem("#$$!@TYU#&#", []);



		if (moment().date() === 6) {

			if (verify === false) {
				window.console.log('nothing');
			} else {

				const washingtonRef = doc(db, "muunganomoney", "partner");
				// Set the "capital" field of the city 'DC'
				await updateDoc(washingtonRef, {
					sms: true
				});

			}

		} else {

			const washingtonRef = doc(db, "muunganomoney", "partner");
			// Set the "capital" field of the city 'DC'
			await updateDoc(washingtonRef, {
				sms: true
			});

		}



		// Create new user
		// await createUserWithEmailAndPassword(auth, data.email, data.password)
		// 	.then((userCredential) => {

		// 		// Signed in 
		// 		const user = userCredential.user;
		// 		window.setTimeout(() => {
		// 			navigation('/dash');
		// 			// navigation('/muunganomoney/overview/enroll/agent');
		// 		}, 5500);

		// 	}).catch((error) => {

		// 		const errorCode = error.code;
		// 		const errorMessage = error.message;
		// 		window.console.log(errorCode + ' ' + errorMessage);
		// 		setOpen(true);
		// 		reset();

		// 	});



		await signInWithEmailAndPassword(auth, data.email, data.password)
			.then((userCredential) => {
				// Signed in 
				const user = userCredential.user;

				if (user.uid != null) {

					window.localStorage.setItem('@expire˚˚ø', JSON.stringify(expireNum));
					secureLocalStorage.setItem("@#$66ACTIVE@##*", true);
					secureLocalStorage.setItem("@37time@**#)@color##", colorspinner);
					secureLocalStorage.setItem("@37time@**#)@size##", sizespinner);

					secureLocalStorage.setItem("@#%%*@@#!!user++", []);

					let api_id = 'API22388939215';
					let password = 'yT73OipkSK';

					secureLocalStorage.setItem("#$$@%%api$$key", api_id);
					secureLocalStorage.setItem("#$$@%%api$$pasword", password);



					if (Boolean(listpret?.length)) {

						secureLocalStorage.setItem("##id@#empty$", false);
						secureLocalStorage.setItem("##grid$$column!!$$", 'wrp-head-hm-dash-info-two');

						secureLocalStorage.setItem("##%$$sm!!$$", 'two');
						setEmpty(true);

					} else {

						secureLocalStorage.setItem("##%$$sm!!$$", 'one');
						secureLocalStorage.setItem("##grid$$column!!$$", 'wrp-head-hm-dash-info-one');
					}

					window.setTimeout(() => {
						navigation('/dash');
					}, 5500);

				}

			}).catch((error) => {
				window.setTimeout(() => {

					const errorCode = error.code;
					const errorMessage = error.message;
					window.console.log(errorCode + ' ' + errorMessage);
					setOpen(true);
					reset();
					setLoad(false);
				}, 1000)

			});


	};

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>


					<PropagateLoader
						color={colorspinner}
						loading={load}
						size={sizespinner}
						data-testid="loader"
					/>

				</Backdrop>
			</div>

			<div className='wrp-input-sign'>
				<form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
					<input autoFocus autocomplete="off" type='email' placeholder='example@email.com'  {...register('email', { required: true })} className='letterspacesign-email' />
					<input autocomplete="off" type='password' placeholder='•••••••'  {...register('password', { required: true })} className='letterspacesign-pwd' />
					<button className='Btn'>Connexion</button>
				</form>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}

					open={open}
					onClose={handleClose}
				>
					<DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								L'accès n'est pas autorisé, E-mail ou mot de passe administrateur, n'est pas valide.
      </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>

						<Button onClick={handleClose}>
							<span className='pop-up'>Fermer</span>
						</Button>

					</DialogActions>
				</Dialog>

			</div>
		</>
	)
};


