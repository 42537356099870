import React from 'react';
import { db } from '../../../../../../firebase';
import { collection, getDocs, updateDoc, doc, onSnapshot } from "firebase/firestore";
import './List.css';
import { useForm, Controller } from 'react-hook-form';
import { CiSearch } from "react-icons/ci";
import Avatar from '@mui/material/Avatar';

import currency from 'currency.js';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import RetuRnDollArd from './Dollard';
import RetuRnFrAn from './Fran';
import moment from 'moment';




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="000000"
   definitions={{
    '#': /[0-6]/,
   }}
   inputmode="tel"
   pattern="[0-6]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};


const StyledBadge = styled(Badge)(({ theme }) => ({
 '& .MuiBadge-badge': {
  right: 3,
  top: 35,
  border: `2px solid ${theme.palette.background.paper}`,
  padding: '0 4px',
 },
}));


let Empty = true;
export let phoneID = '';



// List Dash 
export default function ReturnLisTUseR() {
 return (
  <ViEw />
 );
};

export const ViEw = () => {

 const {
  register,
  watch,
  control,
  handleSubmit,
 } = useForm();

 const navigation = useNavigate();

 let pushClient = new Array();
 let pushAgent = new Array();

 const [load, setLoad] = React.useState(false);
 const [show, setShow] = React.useState(false);
 const [saving, setSaving] = React.useState(null);
 const [payusd, setPayusd] = React.useState(null);
 const [paycdf, setPaycdf] = React.useState(null);

 const [income, setIncome] = React.useState(null);
 const [listclient, setListclient] = React.useState([]);
 const [listagent, setListagent] = React.useState([]);
 const [verify, setVerify] = React.useState(null);

 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');


 const [opn, setOpn] = React.useState(false);
 const handleClickOpn = () => {
  setOpn(true);
 };
 const handlClose = () => {
  setOpn(false);
 };
 const showClose = () => {
  setShow(false);
 };


 const [openx, setOpenx] = React.useState(false);
 const handleClosex = (props) => {
  setOpenx(false);
  navigation(0);
 };

 const [opens, setOpens] = React.useState(false);
 const handleCloses = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpens(false);
 };


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());

  });

  setListclient([... new Set(pushClient)])

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushAgent.push(doc.data());

  });

  setListagent([... new Set(pushAgent)]);

 }, []);
 React.useEffect(() => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "partner"), (doc) => {
   setVerify(doc.data().sms)
  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "budget"), (doc) => {
   setPayusd(doc.data().compayusd);
   setPaycdf(doc.data().compaycdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "commissionregister"), (doc) => {
   setIncome(doc.data().income);
  });
 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
   setSaving(doc.data().saving);
  });
 }, []);


 let watchSearch = watch("search", false);
 let Income = watch("code");


 let list = [...listclient, ...listagent];

 if (Array.isArray(list) && !list.length) {
  Empty = true;
 } else {
  Empty = false;
 };


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 watchSearch = watchSearch === undefined ? '' : watchSearch;

 const handlepath = (phone, state, data) => {

  setLoad(true);

  if (data.state === 'client') {

   if (state === "client" && data.incomestatus && (moment().date() == 6 || moment().date() == 7 || moment().date() == 8 || moment().date() == 9)) {

    state === "client" && data.incomestatus && (moment().date() == 6 || moment().date() == 7 || moment().date() == 8 || moment().date() == 9) && setOpn(true)

    secureLocalStorage.setItem("@#%%!state&&*@@#!!user++", state.toLowerCase());
    secureLocalStorage.setItem("@#%%!phone&&*@@#!!user++", phone);
    secureLocalStorage.setItem("@#%%*@@#!!user++", data);
    secureLocalStorage.setItem("@#%%*#%!!&&", true);

    const unsub = onSnapshot(doc(db, "client", `${data.idphone}`), (doc) => {

     let savingusd = doc.data().thriftusd;
     let savingcdf = doc.data().thriftcdf;
     let allCommsavingusd = (Number(savingusd) * Number(income)) / 100;
     let allCommsavingcdf = (Number(savingcdf) * Number(income)) / 100;

     let somDollard = Number(allCommsavingusd) + Number(payusd);
     let somFran = Number(allCommsavingcdf) + Number(paycdf);

     secureLocalStorage.setItem("@#%!!#$$$CDF#", somFran);
     secureLocalStorage.setItem("@#%!!#$$$USD#", somDollard);


    });


   } else {

    secureLocalStorage.setItem("@#%%!state&&*@@#!!user++", state.toLowerCase());
    secureLocalStorage.setItem("@#%%!phone&&*@@#!!user++", phone);
    secureLocalStorage.setItem("@#%%*@@#!!user++", data);
    secureLocalStorage.setItem("@#%%*#%!!&&", true);
    navigation(0);

   }

  } else {


   secureLocalStorage.setItem("@#%%!state&&*@@#!!user++", state.toLowerCase());
   secureLocalStorage.setItem("@#%%!phone&&*@@#!!user++", phone);
   secureLocalStorage.setItem("@#%%*@@#!!user++", data);
   secureLocalStorage.setItem("@#%%*#%!!&&", true);
   navigation(0);




  }

 };

 return (
  <>
   <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
    <Alert
     onClose={handleCloses}
     severity="error"
     variant="filled"
     sx={{ width: '100%' }}
    >

     <p className="pop-up">
      Jeton cashbeck est incorrecte
    </p>


    </Alert>
   </Snackbar>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={openx}
    onClose={handleClosex}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className="pop-up">{"Cashbeck"}</h1>
    </DialogTitle>
    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <div>
       <Stack direction="row" spacing={2}>
        <div className="flex">
         <Avatar
          src={secureLocalStorage.getItem("@#%%*@@#!!user++").profile}
          sx={{ width: 56, height: 56 }}
         />
         <p className="pop-up" style={{ marginTop: '2vh' }}>{`${(secureLocalStorage.getItem("@#%%*@@#!!user++").firstname + ' ' + secureLocalStorage.getItem("@#%%*@@#!!user++").lastname).toUpperCase()}`}</p>
        </div>
       </Stack>

       <div style={{ marginTop: '3ch', width: '30em' }} className="flex-between">
        <RetuRnDollArd phone={secureLocalStorage.getItem("@#%%*@@#!!user++").idphone} />
        <RetuRnFrAn phone={secureLocalStorage.getItem("@#%%*@@#!!user++").idphone} />
       </div>

      </div>

     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={handleClosex}><p className="pop-up">Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={show}
    onClose={showClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className="pop-up">{"Cashbeck"}</h1>
    </DialogTitle>
    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <div>
       <p className="pop-up">Jeton Incorrect</p>
      </div>

     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={showClose}><p className="pop-up">Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <React.Fragment>
    <Dialog

     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={opn}
     onClose={handlClose}
     PaperProps={{
      component: 'form',
      onSubmit: (event) => {
       event.preventDefault();
       const formData = new FormData(event.currentTarget);
       const formJson = Object.fromEntries(formData.entries());
       const email = formJson.email;
       console.log(email);
       handlClose();
      },
     }}>

     <DialogTitle><h1 className="pop-up">Cashbeck</h1></DialogTitle>

     <DialogContent>
      <DialogContentText>
       <p className="pop-up">

        Veuillez présenter les codes jetons fournis par le client  celle-ci est utilisables qu' une fois

      </p>
      </DialogContentText>

      <FormControl sx={{ width: '100%' }} variant="standard">
       <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Confirmer jeton</h1></InputLabel>

       <Controller
        name="code"
        control={control}
        render={({ field }) =>

         <Input
          // autoFocus
          value={values.textmask}
          onChange={handleChange}
          inputRef={inputRef}
          onChange={(e) => setSearchTerm(e.target.value)}

          inputProps={{
           autoComplete: "off", inputMode: 'tel'
          }}

          sx={{ fontSize: '1.3em', fontFamily: "Alata" }}

          InputProps={{
           inputComponent: NumericFormatCustom,
          }}

          inputmode="tel"
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          {...field}

         />

        }
       />
      </FormControl>


     </DialogContent>

     <DialogActions>
      <div className="flex-between" style={{ padding: "0 15px" }}>
       <Button onClick={handlClose}><span className="pop-up" style={{ color: "#006494" }}>Annuler</span></Button>

       <Button

        onClick={async () => {

         if (secureLocalStorage.getItem("@#%%*@@#!!user++").incomestatus && secureLocalStorage.getItem("@#%%*@@#!!user++").income == Income) {

          setOpenx(true);

          const incomeRef = doc(db, "client", secureLocalStorage.getItem("@#%%*@@#!!user++").idphone);
          // Set the "capital" field of the city 'DC'
          await updateDoc(incomeRef, {
           income: "XXXXX",
           incomestatus: false
          });

          const commImoRef = doc(db, "muunganomoney", "budget");
          // Set the "capital" field of the city 'DC'
          await updateDoc(commImoRef, {
           compaycdf: Number(secureLocalStorage.getItem("@#%!!#$$$CDF#")),
           compayusd: Number(secureLocalStorage.getItem("@#%!!#$$$USD#"))
          });

          setOpenx(true);
          setOpn(false);


         } else {

          setShow(true);
          setOpn(false);

         }


        }}

       ><span className="pop-up" style={{ color: "#006494" }}>Confirmer</span></Button>
      </div>
     </DialogActions>
    </Dialog>

   </React.Fragment>

   {
    Empty === true
     ?
     <div></div>
     :
     <div className='wrp-column-hm-dash-list-user pad-left-right-3'>
      <form>
       <input type='search' {...register("search")} autocomplete="off" onFocus={true} placeholder="Recherche d'utilisateur" />
       <CiSearch size={'1.8em'} />
      </form>

      <div className='wrp-hm-dash-list-user'>
       {['Utilisateur', 'Balance', 'Status'].map((index) => {
        return (
         <h3>{index}</h3>
        )
       })}
      </div>
      <div>

       {[...list].filter((item) => {

        return watchSearch.toLowerCase() === '' ? item : item.iduser.includes(watchSearch.toLowerCase());

       }).map(index => {

        let first = index.firstname.toLowerCase() + '';
        let last = index.lastname.toLowerCase() + '';

        let nameFirst = first.charAt(0).toUpperCase() + first.slice(1);
        let nameLast = last.charAt(0).toUpperCase() + last.slice(1);
        phoneID = index.idphone;

        return (
         <>
          <div onClick={() => handlepath(index.idphone, index.state, index)} className='wrp-hm-dash-list-user'>
           <span>
            <Badge
             overlap="circular"
             anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
             badgeContent={

              (index.state === "client" && index.incomestatus) && verify === false &&
              (moment().date() === 6 || moment().date() == 7 || moment().date() == 8 || moment().date() == 9) &&

              <div className='flex-badge-ima'>
               <img src={'/img/pret-valid.png'} style={{ width: '1.8em', height: '1.8em' }} />
              </div>

             }>

             <Avatar src={index.profile} />
            </Badge>

            <div className='grid-start'>
             <p>{nameFirst} {nameLast}</p>
            </div>
           </span>

           <div className='grid-start flex-money-user-list'>
            <span>{euro(index.usd).format()} USD</span>
            <span>{euro(index.cdf).format()} CDF</span>
           </div>

           <Chip label={`${index.state.charAt(0).toUpperCase() + index.state.slice(1)}`} color={(index.state).toLowerCase() === 'client' ? 'primary' : 'success'} />
          </div>

         </>
        );
       })}
      </div>
     </div>
   }
  </>
 );
};