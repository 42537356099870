import React from 'react';
import { collection, getDocs, query, orderBy, getDoc } from "firebase/firestore";
import { db } from '../../../../firebase';
import './List.css';
import Divider from '@mui/material/Divider';
import currency from 'currency.js';
import moment from 'moment';
import RetuRnAvatars from './Avatar';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import secureLocalStorage from "react-secure-storage";


// Body Creditaire
export default function ReturnList() {

 let pushDoc = new Array();
 const [open, setOpen] = React.useState(false);
 const [arr, setArr] = React.useState([]);

 React.useEffect(async () => {

  const q = query(collection(db, 'muunganocreditaireclient'), orderBy('dateofcreate'));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
   pushDoc.push(doc.data())

  })

  setArr([...new Set(pushDoc.reverse())]);


 }, []);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const handleClose = () => {
  setOpen(false);
 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 const handleClick = (first, last, before, money, after, date, devise, ref, operator, phone) => {

  secureLocalStorage.setItem("#*&&#**name@@!", (`${first} ${last}`).toLowerCase());
  secureLocalStorage.setItem("#*&&#**before@@!", `${before}`);
  secureLocalStorage.setItem("#*&&#**money@@!", `${money}`);
  secureLocalStorage.setItem("#*&&#**after@@!", `${after}`);
  secureLocalStorage.setItem("#*&&#**date@@!", `${moment(date).format('LLL')}`);
  secureLocalStorage.setItem("#*&&#**devise@@!", `${devise}`);

  secureLocalStorage.setItem("#*&&#**ref@@!", `${ref}`);
  secureLocalStorage.setItem("#*&&#**operator@@!", `${operator}`);
  secureLocalStorage.setItem("#*&&#**phone@@!", `${phone}`);

  setOpen(true);

 }

 return (
  <div className='flex-col-list-refs'>
   {arr?.length ?
    <>
     {[...Array(arr.length).keys()].map((index) => {
      return (
       <ul style={{ backgroundColor: '#DDDEDA', cursor: 'pointer' }}>

        <li onClick={() => handleClick(arr[index].first, arr[index].last, arr[index].before, arr[index].money, arr[index].after, arr[index].date, arr[index].devise, arr[index].ref, arr[index].operator, arr[index].phone)} className='flex-between box-credit-center'>
         <div className='flex-creidit-center'>
          <RetuRnAvatars IMA={arr[index].profil} />
          <p>{(arr[index].first + '').charAt(0).toUpperCase() + (arr[index].first + '').slice(1)}</p>
         </div>

         <p>{euro(arr[index].money).format()} {arr[index].devise}</p>
        </li>

       </ul>
      )
     })}
    </>
    :
    <div></div>
   }


   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}>

    <DialogContent>

     <DialogContentText>
      <div className='flex-block-center'>

       <h1>Muungano Creditaire</h1>

       <div className='flex-between'>
        <p>Identiter</p>
        <p>{secureLocalStorage.getItem("#*&&#**name@@!")}</p>
       </div>

       <div className='flex-between'>
        <p>Soldes Avant</p>
        <p>{secureLocalStorage.getItem("#*&&#**before@@!")} {secureLocalStorage.getItem("#*&&#**devise@@!")}</p>
       </div>

       <div className='flex-between'>
        <p>Montant Reçu</p>
        <p>{secureLocalStorage.getItem("#*&&#**money@@!")} {secureLocalStorage.getItem("#*&&#**devise@@!")}</p>

       </div>

       <div className='flex-between'>
        <p>Soldes Après</p>
        <p>{secureLocalStorage.getItem("#*&&#**after@@!")} {secureLocalStorage.getItem("#*&&#**devise@@!")}</p>
       </div>

       <div className='flex-between'>
        <p>Operator</p>
        <p>{secureLocalStorage.getItem("#*&&#**operator@@!")}</p>
       </div>


       <div className='flex-between'>
        <p>Numéro</p>
        <p>{secureLocalStorage.getItem("#*&&#**phone@@!")}</p>
       </div>

       <div className='flex-between'>
        <p>Référence</p>
        <p>{secureLocalStorage.getItem("#*&&#**ref@@!")}</p>
       </div>

       <div className='flex-between'>
        <p>Date</p>
        <p>{secureLocalStorage.getItem("#*&&#**date@@!")}</p>
       </div>

      </div>

     </DialogContentText>
    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>


  </div>
 );
};