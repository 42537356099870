import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";


// Identity View
export default function ReturnEmAil() {

 let [city, setCity] = React.useState(null);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setCity(doc.data().email);
  });

 }, []);


 return (
  <div className='list-sub-exchange-info-detail color-purple-info-low'>
   <p>Email : </p>
   <p>{city}</p>
  </div>
 );
};