import './Budget.css';
import SolDE from './Solde/Main';
import OpeRatoR from './Operator/Main';
import HeAd from '../Head/Main';
import Media from 'react-media';



// Budget Creditaire Dash
export default function RetuRnBudGeT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='wrp-budget-creditaire-dash border-right'>
  <SolDE />
  <OpeRatoR />
  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-budget-creditaire-dash'>
  <HeAd />
  <SolDE />
  <OpeRatoR />
  <div></div>
 </div>
);