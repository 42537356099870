import * as React from 'react';
import { TweenMax, Power3 } from 'gsap';
import Media from 'react-media';
import './Title.css';



export default function RetuRnTitle() {

 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {
 return (
  <div className='flex-title-form-enroll'>
   <ViEw />
  </div>
 )
};
export const ScreenSmall = () => {
 return (
  <div className='flex-title-form-enroll'>
   <ViEw />
  </div>
 )
};

export const ViEw = () => {

 const itemRef = React.useRef(null);

 React.useEffect(() => {
  TweenMax.from(itemRef.current, { delay: '0.5', duration: 1, opacity: 0, x: -16, ease: Power3.easeIn })
 }, []);

 return (
  <h5 ref={itemRef}>Ajouter un nouvel agent</h5>
 );
};