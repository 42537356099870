import ReturnOver from './Over';

// Main body throw
export default function ReturnBody() {
 return (
  <div className='wrp-body-throw-stock'>
   <ReturnOver />

  </div>
 );
};