
import RetuRnAvatars from './IMA';
import RetuRnBudgetComm from './BudgetCom';

// Sms Available
export default function ReturnAvailableSMS() {
 return (
  <div style={{ margin: '1vh 0' }} className='flex-between'>
   <RetuRnBudgetComm />
   <RetuRnAvatars />
  </div>
 );
};