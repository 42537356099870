import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";


// Identity View
export default function ReturnTypeTransF() {

 let [city, setCity] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setCity(doc.data().sendtype);

  });

 }, []);


 let result = city.charAt(0).toUpperCase() + city.slice(1);

 return (
  <div className='list-sub-exchange-info-detail color-purple-info-low'>
   <p>Type du transfert : </p>
   <p>{result}</p>
  </div>
 );
};