import './Small.css';
import Media from 'react-media';
import RetuRnDraweR from './Drawer';

// Small main
export default function TRansAction() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenSmall = () => (
 <div className='wrp-small-info-pret'>
  <RetuRnDraweR />
 </div>
);
export const ScreenLarge = () => (<div></div>);