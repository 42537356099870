import './Body.css';
import RetuRnLisT from './List';

// Body Exchange
export default function ReturnBoDY() {
 return (
  <div className='wrp-body-exchange-buy-detail scroll-user-list-total'>
   <RetuRnLisT />

  </div>
 );
};