import './Title.css';
import Media from 'react-media';



// Title Price Balance 
export default function ReturnTitle(props) {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall Text={props.Text} />}
     {matches.medium && <ScreenLarge Text={props.Text} />}
     {matches.large && <ScreenLarge Text={props.Text} />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = (props) => (
 <div className='title-price-balance-actif'>
  <ViEw Text={props.Text} />
 </div>
);
export const ScreenSmall = (props) => (
 <div className='title-price-balance-actif'>
  <ViEw Text={props.Text} />
 </div>
);

export const ViEw = (props) => {
 return (
  <h3>{props.Text}</h3>
 )
}