import RetuRnTitle from './Title';
import './Head.css';
import DeVise from './Devise/Main';

// View Head 
export default function ReturnHeAd() {
 return (
  <div className='flex-between flex-border-bottom pad-left-right-3'>
   <RetuRnTitle />
   <DeVise />
  </div>
 );
};