import React from 'react';
import './Title.css';
import { TweenMax, Expo } from 'gsap';
import Media from 'react-media';



// Title View Actif
export default function ReturnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='wrp-title-actif-all'>
  <ViEw />

 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-title-actif-all-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {
 React.useEffect(() => {
  TweenMax.from('.Anima-Actif', 1, { delay: .5, opacity: 0, y: 20, ease: Expo.easeInOut })

 }, [])

 return (
  <h1 className='Anima-Actif'>Actif Global</h1>
 )
};
