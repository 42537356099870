import './Head.css';
import RetuRnTitle from './Title';
import RetuRnDrawer from './Drawer';


// Head share component 
export default function RetuRnHeAd() {
 return (
  <div className="flex-head-share flex-between p-left-right-1">
   <RetuRnTitle />
   <RetuRnDrawer />
  </div>
 );
};