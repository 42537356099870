import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import './Phone.css';



// Avatar view 
export default function RetuRnStAtus() {

 const [item, setItem] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().state);
  });
 }, []);

 let result = item.charAt(0).toUpperCase() + item.slice(1);

 return (
  <div className='flex-id-phone'>
   <h3 style={{ color: '#80deea' }}>{result}</h3>
  </div>
 );
};