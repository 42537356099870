import ReturnTitle from './Title';
import './Count.css';
import ReturnUseR from './User';


// Count Main
export default function RetuRnCoUnT() {
 return (
  <div className='flex-between flex-border-bottom flex-count-total-info pad-left-right-3'>
   <ReturnTitle />
   <ReturnUseR />
  </div>
 );
};