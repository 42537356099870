import React from 'react';
import './Btn.css';
import { HiArrowRight } from "react-icons/hi2";

import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase';

import secureLocalStorage from "react-secure-storage";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Btn Voda
export default function ReturnBtn() {

 const [load, setLoad] = React.useState(false);
 const [open, setOpen] = React.useState(false);

 // Pwd 
 const [pwdvoda, setPwdvoda] = React.useState(0);
 const [pwdorange, setPwdorange] = React.useState(0);
 const [pwdairtel, setPwdairtel] = React.useState(0);
 const [pwdafricell, setPwdafricell] = React.useState(0);


 // Prefix command
 const [prevoda, setPrevoda] = React.useState('');
 const [preorange, setPreorange] = React.useState('');
 const [preafricell, setPreafricell] = React.useState('');
 const [preairtel, setPreairtel] = React.useState('');


 // Suffix command
 const [suffixvoda, setSuffixvoda] = React.useState('');
 const [suffixorange, setSuffixorange] = React.useState('');
 const [suffixafricell, setSuffixafricell] = React.useState('');
 const [suffixairtel, setSuffixairtel] = React.useState('');


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "unitpassword"), (doc) => {
   setPwdvoda(doc.data().vodacom)
   setPwdorange(doc.data().orange);
   setPwdairtel(doc.data().airtel);
   setPwdafricell(doc.data().africell);
  });

 }, []);


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "unitcommand"), (doc) => {

   setPrevoda(doc.data().vodaprefix === undefined ? null : doc.data().vodaprefix)
   setPreorange(doc.data().orangeprefix === undefined ? null : doc.data().orangeprefix)
   setPreafricell(doc.data().africellprefix === undefined ? null : doc.data().africellprefix)
   setPreairtel(doc.data().airtelprefix === undefined ? null : doc.data().airtelprefix)

   setSuffixvoda(doc.data().vodasuffix === undefined ? null : doc.data().vodasuffix)
   setSuffixorange(doc.data().orangesuffix === undefined ? null : doc.data().orangesuffix)
   setSuffixafricell(doc.data().africellsuffix === undefined ? null : doc.data().africellsuffix)


  });

 }, []);

 const handleClose = () => {
  setOpen(false);
 };
 const handlepath = (event) => {

  // Pwd Command
  event.preventDefault();
  secureLocalStorage.setItem("%6@##(@**pwdvoda!!%%", pwdvoda);


  secureLocalStorage.setItem("%6@##(@**pwdorange!!%%", pwdorange);


  secureLocalStorage.setItem("%6@##(@**pwdairtel!!%%", pwdairtel);
  secureLocalStorage.setItem("%6@##(@**pwdafricell!!%%", pwdafricell);

  // Prefix
  secureLocalStorage.setItem("##!!%%$prefix$$voda$$", prevoda);
  secureLocalStorage.setItem("##!!%%$prefix$$orange$$", preorange);
  secureLocalStorage.setItem("##!!%%$prefix$$africell$$", preafricell);
  secureLocalStorage.setItem("##!!%%$prefix$$airtel$$", preairtel);

  // Suffixe
  secureLocalStorage.setItem("##!!%%$suffix$$voda$$", suffixvoda);
  secureLocalStorage.setItem("##!!%%$suffix$$orange$$", suffixorange);
  secureLocalStorage.setItem("##!!%%$suffix$$africell$$", suffixafricell);

  // Ornage Return
  secureLocalStorage.setItem("##!!%orange%$$$return", true);


  setLoad(true);
  window.location.href = '/unit/stock/throw/orange';
 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div onClick={handlepath} className='stock-throw-btn'>
    <HiArrowRight size='2em' color='#fff' />
   </div>
  </>
 );
};