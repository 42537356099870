import React from 'react';
import { db } from '../../../../../../firebase';
import './List.css';
import { collection, getDocs, } from "firebase/firestore";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Media from 'react-media';
import secureLocalStorage from "react-secure-storage";

import currency from 'currency.js';
import Divider from '@mui/material/Divider';
import { TweenMax, Linear } from 'gsap';


let search = '';
var now = moment();


// List Body
export default function RetuRnLisT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};



export const ScreenLarge = () => {

 const navigation = useNavigate();
 const { control, watch } = useForm({});

 const [arr, setArr] = React.useState([]);
 let pushDoc = new Array();

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, 'pret'));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   if (doc.data().pretactive) {
    pushDoc.push(doc.data());

   }

  });

  // setArr([...new Set(pushDoc)]);
  setArr(pushDoc);

 }, []);
 React.useEffect(() => {
  TweenMax.staggerFrom('.user-box-stock .Anima', .2, { delay: 2, opacity: 0, y: 20, ease: Linear.ease }, 0.08);
 }, [])

 search = watch('search');

 const handleClickpret = (item) => {

  secureLocalStorage.setItem("##phone&&#$$$[]", item.phone)
  secureLocalStorage.setItem("##phone&&#$$$[activeday]!!||", item.id);
  // navigation('/pret/dash')

 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div>
   {!arr.length
    ?
    <div></div>
    :
    <Box sx={{ width: '100%' }}>
     <div className='flex-pret-title-user'>
      <h4>Liste de Prêt</h4>
     </div>

     <List component="nav" aria-label="main mailbox folders">
      {[...Array(arr.length).keys()].map(item => {

       let str = arr[item].name;
       let yourname = str[0].toUpperCase() + str.slice(1);

       //todays date
       var end = moment([moment(arr[item].date).get('year'), moment(arr[item].date).get('month'), moment(arr[item].date).get('date')]); // another date
       var duration = moment.duration(now.diff(end));
       var daycdf = duration.asDays();
       let pretCdf = arr[item].askpret;
       let modulecdf = 0;

       for (let index = 0; index < parseInt(daycdf); index++) {
        modulecdf = (pretCdf * (arr[item].pretmodulo) / 100);
        pretCdf += modulecdf;
       };


       return (
        <>
         <div onClick={() => handleClickpret(arr[item])} className='wrp-hm-dash-list-user-pret'>
          <ListItemButton>
           <ListItemIcon>
            <Avatar sx={{ width: 50, height: 50, }} src={arr[item].profil} /></ListItemIcon>
           <ListItemText>
            <div className='grid-start'>
             <p>{yourname}</p>
             <p className='box-pop-up'>Aujourd'hui évaluer à {euro(pretCdf).format()} {arr[item].devise}</p>

            </div>
           </ListItemText>
          </ListItemButton>
         </div>

         <Divider sx={{ color: '#fff' }} />
        </>
       );
      })}
     </List>
    </Box>
   }
  </div>
 );
};
export const ScreenSmall = () => {

 const navigation = useNavigate();
 const { control, watch } = useForm({});

 const [arr, setArr] = React.useState([]);
 let pushDoc = new Array();

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, 'pret'));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   if (doc.data().pretactive) {
    pushDoc.push(doc.data());

   }

  });

  setArr(pushDoc);

 }, []);
 React.useEffect(() => {
  TweenMax.staggerFrom('.user-box-stock .Anima', .2, { delay: 2, opacity: 0, y: 20, ease: Linear.ease }, 0.08);
 }, [])

 search = watch('search');

 const handleClickpret = (item) => {

  secureLocalStorage.setItem("##phone&&#$$$[]", item.phone)
  secureLocalStorage.setItem("##phone&&#$$$[activeday]!!||", item.id);

 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div>
   {!arr.length
    ?
    <div></div>
    :
    <Box sx={{ width: '100%' }}>

     <div className='flex-pret-title-user'>
      <h4>Liste de Prêt</h4>
     </div>

     <List component="nav" aria-label="main mailbox folders">
      {[...Array(arr.length).keys()].map(item => {

       let str = arr[item].name;
       let yourname = str[0].toUpperCase() + str.slice(1);

       //todays date
       var end = moment([moment(arr[item].date).get('year'), moment(arr[item].date).get('month'), moment(arr[item].date).get('date')]); // another date
       var duration = moment.duration(now.diff(end));
       var daycdf = duration.asDays();
       let pretCdf = arr[item].askpret;
       let modulecdf = 0;

       for (let index = 0; index < parseInt(daycdf); index++) {
        modulecdf = (pretCdf * (arr[item].pretmodulo) / 100);
        pretCdf += modulecdf;
       };

       return (
        <>
         <div className='mr-top-bottom-3' onClick={() => handleClickpret(arr[item])}>

          <ListItemButton>

           <ListItemIcon>
            <Avatar sx={{ width: 50, height: 50, }} src={arr[item].profil} /></ListItemIcon>

           <ListItemText>

            <h4 className='box-pop-up'>{`${yourname}`}</h4>
            <h4 className='box-pop-up'>Aujourd'hui évaluer à {euro(pretCdf).format()} {arr[item].devise}</h4>

           </ListItemText>

          </ListItemButton>
         </div>

         <Divider sx={{ color: '#fff' }} />

        </>
       );
      })}
     </List>
    </Box>
   }
  </div>
 );
};
