import React from 'react';
import './Count.css';
import secureLocalStorage from "react-secure-storage";
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import currency from 'currency.js';


// Count group Tontine
export default function RetuRnCouNT() {


 let devise = (secureLocalStorage.getItem("##4483833@!!**$$%%").currency).includes('CDF') ? 'CDF' : 'USD';
 const [item, setItem] = React.useState(0);
 const [name, setName] = React.useState('');

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "tontine", secureLocalStorage.getItem("##id@!!group$$%%")), (doc) => {
   setItem(doc.data().rising === undefined ? 0 : doc.data().rising);
   setName(doc.data().namegroup === undefined ? 0 : doc.data().namegroup);

  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-between wrp-count-head-tontine'>
   <div>
    <h3>Nom du groupe</h3>
    <p>{name}</p>
   </div>

   <div>
    <h3>Montant de la tontine</h3>
    <p>{euro(item).format()} {devise}</p>
   </div>

  </div>
 );
};