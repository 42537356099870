import React from 'react';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import './List.css';

// Price group 
export default function RetuRnNAme(props) {

 const [item, setItem] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "client", props.docsTon), (doc) => {
   setItem(doc.data().lastname);
  });

 });

 return (
  <h3 className='pop-up flex-marge-left-3'>{item.charAt(0).toUpperCase() + item.slice(1)}</h3>
 );
};