
import BacKnaV from './Nav/Main';
import PacK from './Pack/Main';
import MonTh from './Month/Main';

// Head Component 
export default function ReturnHeAd() {
 return (
  <div className='wrp-head-throw'>
   <BacKnaV />
   <MonTh />

   <PacK />

  </div>
 );
}