import './Head.css';
import RetuRnlOgo from './Logo';
import Media from 'react-media';
import RetuRnRight from './Right';


// Head InFO
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenLarge />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <View />
);
export const ScreenSmall = () => (
 <div className='flex-between flex-border-bottom  flex-pd-bottom wrp-head-hm-info-small'>
  <RetuRnlOgo />
  <RetuRnRight />
 </div>
);
export const View = () => {
 return (
  <div className='flex-between pad-lef-righ-1em  bg-color-miletor flex-border-bottom flex-pd-bottom grid-head-dash-pret'>
   <RetuRnlOgo />
   <RetuRnRight />
  </div>
 );
};