import * as React from 'react';
import Avatar from '@mui/material/Avatar';

export default function RetuRnImAge() {
  return (
    <Avatar
      src={'/img/done-1.png'}
      sx={{ width: 35, height: 35 }}
      variant="rounded" />
  );
}
