
import HeAd from './Head/Main';

// Refs Creditaire Dash
export default function ReturnReFs() {
 return (
  <div style={{ backgroundColor: '#EEEEEA' }}>
   <HeAd />
  </div>
 );
};