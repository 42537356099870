
import RetuRnOperatoR from './Operator';

// Operaotr Budget View
export default function OpeRatoR() {
 return (
  <div>
   <RetuRnOperatoR />
  </div>
 );
};