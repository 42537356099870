
import './Title.css';

// Title Component 
export default function ReturnTitle() {
 return (
  <div className='wrp-title-repay-pret'>
   <h3>Remboursement prêt</h3>
  </div>
 );
};