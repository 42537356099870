
import RetuRnGlobAl from './Global';
import RetuRnCliEnT from './Client';
import RetuRnAgenT from './Agent';

// All actif 
export default function ReturnAllActiF() {
 return (
  <div className='flex-between'>
   <RetuRnGlobAl />
   <RetuRnCliEnT />
   <RetuRnAgenT />

  </div>
 );
};