import * as React from 'react';
import Avatar from '@mui/material/Avatar';

export default function RetuRnAvatars(props) {
 return (
  <Avatar
   src={props.IMA}
   sx={{ width: 40, height: 40 }}
  />

 );
};
