

import RetuRnIMAProFil from './IMA';


// Profil user
export default function RetuRnProFil() {
 return (
  <div className='grid-center'>
   <RetuRnIMAProFil />
  </div>
 );
};