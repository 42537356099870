import React from 'react';
import { db } from '../../../../../firebase';
import { collection, query, getDocs } from "firebase/firestore";

import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';
import './Total.css';


// Total Send
export default function RetuRnTotAl() {

 let pushDevise = new Array();

 const [sold, setSold] = React.useState(null);
 const [devise, setDevise] = React.useState([]);

 React.useEffect(async () => {

  let somme = 0;

  const q = query(collection(db, `share${secureLocalStorage.getItem("#$$!@@%DTR##")}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {

   somme += doc.data().money;
   pushDevise.push(doc.data().devise);

  });

  setSold(somme);
  setDevise([...new Set(pushDevise)]);

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className="flex-total-share">
   <p>{euro(sold).format()}  {devise[0]}</p>
  </div>
 );
};