
import './Left.css';
import RetuRnPrEt from './Pret';
import RetuRnUseR from './User';


// List left
export default function RetuRnRiGth() {
 return (
  <div className='wrp-left-nav-dash'>
   <RetuRnPrEt />
   <RetuRnUseR />

  </div>
 );
};