import LisTUseR from './List/Main';
import './User.css';
import ActIF from './Actif/Main';


// User TotAl !
export default function ReturnUseR() {
 return (
  <div className='scroll-user-list-total'>
   <ActIF />
   <LisTUseR />
  </div>
 );
};
