import RetuRnAvatars from './Avatar';
import AsKed from './Asked/Main';
import TRansAction from './Transaction/Main';


// . Profil pret 
export default function ReturnProFil() {
 return (
  <>
   <div className='flex-between'>
    <div></div>
    <div style={{ display: 'flex', marginTop: '4vh' }}>
     <AsKed />
     <div style={{ padding: '0 1.3em' }}>
      <TRansAction />
     </div>
    </div>
   </div>

   <div className='flex-between pad-3 wrp-info-dash-pret'>
    <RetuRnAvatars />
   </div>
  </>
 );
};