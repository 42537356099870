import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import secureLocalStorage from "react-secure-storage";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import { CiWifiOff } from "react-icons/ci";

import { db } from '../../../../firebase';
import { doc, onSnapshot, updateDoc, setDoc, collection, query, getDocs, arrayUnion, serverTimestamp, where, deleteDoc, deleteField } from "firebase/firestore";

import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import { v4 } from 'uuid';
import currency from 'currency.js';
import axios from 'axios';





let key = v4();




const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="00000000000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};



let checkPush = new Array();



// Btn Component 
export default function RetuRnBTn() {

 moment.locale('fr');

 const {
  register,
  watch,
  control,
  reset,
  handleSubmit,
 } = useForm();


 let iTmclpush = new Array();
 let itmshidpush = new Array();
 let pushItmshare = new Array();


 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");

 const navigation = useNavigate();


 const [sold, setSold] = React.useState(null);
 const [open, setOpen] = React.useState(false);
 const [blem, setBlem] = React.useState(false);
 const [pass, setPass] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [wifi, setWifi] = React.useState(false);
 const [next, setNext] = React.useState(false);

 const [itmIdshare, setItmIdshare] = React.useState([]);
 const [dollardA, setDollardA] = React.useState(null);
 const [franA, setFranA] = React.useState(null);
 const [codeA, setCodeA] = React.useState(null);
 const [sharedeviseA, setSharedeviseA] = React.useState(null);

 const [firstA, setFirstA] = React.useState('');
 const [lastA, setLastA] = React.useState('');
 const [etablishNumA, setEtablishNumA] = React.useState(null);
 const [clientA, setClientA] = React.useState([]);
 const [listCl, setListCl] = React.useState([]);

 const [thriftA, setThriftA] = React.useState(null);
 const [thriftfrA, setThriftfrA] = React.useState(null);

 const [signatureA, setSignatureA] = React.useState(null);
 const [signaturetypeA, setSignaturetypeA] = React.useState(null);
 const [signaturemoneyA, setSignaturemoneyA] = React.useState(null);
 const [signaturekeyA, setSignaturekeyA] = React.useState(null);

 const [signaturefrA, setSignaturefrA] = React.useState(null);
 const [signaturetypefrA, setSignaturetypefrA] = React.useState(null);
 const [signaturemoneyfrA, setSignaturemoneyfrA] = React.useState(null);
 const [signaturekeyfrA, setSignaturekeyfrA] = React.useState(null);

 const [showPassword, setShowPassword] = React.useState(false);



 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };
 const blemClose = () => {
  setBlem(false);
  // navigation(0);
 }
 const passClose = () => {
  setPass(false);
 }

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 let pushIndex = secureLocalStorage.getItem("#$$!@TYU#&#");

 const inputRef = React.useRef();
 const [searchTerm, setSearchTerm] = React.useState('');


 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const nextClose = () => {
  setNext(false);
 };


 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 React.useEffect(async () => {

  try {

   await onSnapshot(doc(db, "agent", secureLocalStorage.getItem("#$$!@@%DTR##")), (doc) => {

    setFirstA(doc.data().firstname);
    setLastA(doc.data().lastname);

    setCodeA(doc.data().code);

    setFranA(doc.data().cdf)
    setDollardA(doc.data().usd)
    setSharedeviseA(doc.data().sharedevise)
    setThriftA(doc.data().thriftusd)
    setThriftfrA(doc.data().thriftcdf)
    setEtablishNumA(doc.data().establishmentcode)
    setClientA(doc.data().sharingmpall)

    setSignatureA(doc.data().signatureusd);
    setSignaturetypeA(doc.data().signatureusdtype);
    setSignaturemoneyA(doc.data().signaturemoneyusd);
    setSignaturekeyA(doc.data().signaturekeyusd);

    setSignaturefrA(doc.data().signaturecdf);
    setSignaturetypefrA(doc.data().signaturecdftype);
    setSignaturemoneyfrA(doc.data().signaturemoneycdf);
    setSignaturekeyfrA(doc.data().signaturekeycdf);

   });

  } catch (e) {
   window.console.log(e);
  }

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
   setCodeA(doc.data().pn)
  });

 }, []);
 React.useEffect(async () => {

  let somme = 0;

  const q = query(collection(db, `share${secureLocalStorage.getItem("#$$!@@%DTR##")}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {

   somme += doc.data().money;

  });

  setSold(somme);

 }, []);
 React.useEffect(async () => {

  const q = query(collection(db, "client"), where("sharingverifier", "==", true));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   if (pushIndex.includes(doc.id)) {
    iTmclpush.push(doc.data());
   }

  });

  setListCl([...new Set(iTmclpush)]);

 }, []);
 React.useEffect(async () => {

  const shareASnapshot = await getDocs(collection(db, "share" + secureLocalStorage.getItem("#$$!@@%DTR##")));
  shareASnapshot.forEach(async (doc) => {
   itmshidpush.push(doc.id);

  });

  setItmIdshare([...new Set(itmshidpush)]);

 }, []);



 let sourceIncome = watch("count");


 let sendagentA = `${secureLocalStorage.getItem("#$$!@@%DTR##")}${parseInt(signaturemoneyA)}${parseInt(Number(dollardA) + Number(signaturemoneyA))}${parseInt((Number(dollardA) + Number(signaturemoneyA)) - Number(signaturemoneyA))}${signaturekeyA}`;
 let getagentA = `${secureLocalStorage.getItem("#$$!@@%DTR##")}${parseInt(signaturemoneyA)}${parseInt(Number(dollardA) - Number(signaturemoneyA))}${parseInt((Number(dollardA) - Number(signaturemoneyA)) + Number(signaturemoneyA))}${signaturekeyA}`;
 let checkagentA = signaturetypeA === 'envoyer' ? sendagentA === signatureA : getagentA === signatureA;

 let sendagentfrA = `${secureLocalStorage.getItem("#$$!@@%DTR##")}${parseInt(signaturemoneyfrA)}${parseInt(Number(franA) + Number(signaturemoneyfrA))}${parseInt((Number(franA) + Number(signaturemoneyfrA)) - Number(signaturemoneyfrA))}${signaturekeyfrA}`;
 let getagentfrA = `${secureLocalStorage.getItem("#$$!@@%DTR##")}${parseInt(signaturemoneyfrA)}${parseInt(Number(franA) - Number(signaturemoneyfrA))}${parseInt((Number(franA) - Number(signaturemoneyfrA)) + Number(signaturemoneyfrA))}${signaturekeyfrA}`;
 let checkagentfrA = signaturetypefrA === 'envoyer' ? sendagentfrA === signaturefrA : getagentfrA === signaturefrA;


 let nameA = firstA + ' ' + lastA + ''.toLowerCase();
 let nameAupper = nameA.toUpperCase();


 const handleOpenDialogShare = async (event) => {

  event.preventDefault();
  // setLoad(true);
  if (sharedeviseA == null || sharedeviseA == undefined || dollardA == null == undefined || franA == null || franA == undefined || codeA == null == undefined || signatureA == null || signatureA == undefined || signaturetypeA == null || signaturetypeA == undefined || signaturemoneyA == null || signaturemoneyA == undefined) {

   navigation(0);

  } else {

   if (sharedeviseA === 'USD') {

    if (checkagentA) {

     const q = query(collection(db, "client"), where("sharingverifier", "==", true));
     const querySnapshot = await getDocs(q);

     querySnapshot.forEach((doc) => {

      if ([...secureLocalStorage.getItem("#$$!@TYU#&#")].includes(doc.id)) {

       // From send CLIENT
       let sendclient = `${doc.data().idphone}${parseInt(doc.data().signaturemoneyusd)}${parseInt(Number(doc.data().usd) + Number(doc.data().signaturemoneyusd) + Number(doc.data().signaturefraisusd))}${parseInt((Number(doc.data().usd) + Number(doc.data().signaturemoneyusd)) - Number(doc.data().signaturemoneyusd))}${doc.data().signaturekeyusd}`;
       let getclient = `${doc.data().idphone}${parseInt(doc.data().signaturemoneyusd)}${parseInt(Number(doc.data().usd) - Number(doc.data().signaturemoneyusd))}${parseInt((Number(doc.data().usd) - Number(doc.data().signaturemoneyusd)) + Number(doc.data().signaturemoneyusd))}${doc.data().signaturekeyusd}`;
       let checkclient = doc.data().signatureusdtype === 'envoyer' ? sendclient === doc.data().signatureusd : getclient === doc.data().signatureusd;

       checkPush.push(checkclient);
       window.console.log(checkPush)

      }

     });

     if (checkPush.includes(false)) {

      setBlem(true);
      setOpen(false);
      window.setTimeout(() => { setLoad(false); }, 1000)

     } else {

      setOpen(true);
      window.setTimeout(() => { setLoad(false); }, 1000)

     }

    } else {

     window.console.log('blem')
     setBlem(true);

    }

   } else {

    if (checkagentfrA) {

     const q = query(collection(db, "client"), where("sharingverifier", "==", true));
     const querySnapshot = await getDocs(q);

     querySnapshot.forEach((doc) => {

      if ([...secureLocalStorage.getItem("#$$!@TYU#&#")].includes(doc.id)) {


       // From send CLIENT
       let sendclient = `${doc.data().idphone}${parseInt(doc.data().signaturemoneycdf)}${parseInt(Number(doc.data().cdf) + Number(doc.data().signaturemoneycdf) + Number(doc.data().signaturefraiscdf))}${parseInt((Number(doc.data().cdf) + Number(doc.data().signaturemoneycdf)) - Number(doc.data().signaturemoneycdf))}${doc.data().signaturekeycdf}`;
       let getclient = `${doc.data().idphone}${parseInt(doc.data().signaturemoneycdf)}${parseInt(Number(doc.data().cdf) - Number(doc.data().signaturemoneycdf))}${parseInt((Number(doc.data().cdf) - Number(doc.data().signaturemoneycdf)) + Number(doc.data().signaturemoneycdf))}${doc.data().signaturekeycdf}`;
       let checkclient = doc.data().signaturecdftype === 'envoyer' ? sendclient === doc.data().signaturecdf : getclient === doc.data().signaturecdf;


       checkPush.push(checkclient);
       window.console.log(doc.id + ' ' + checkclient)

      }

     });



     if (checkPush.includes(false)) {

      setBlem(true);
      setOpen(false);
      window.setTimeout(() => { setLoad(false); }, 1000)

     } else {

      setOpen(true);
      window.setTimeout(() => { setLoad(false); }, 1000)

     }


    } else {

     window.console.log('blem agent')
     setBlem(true);

    }

   }

  }

  delete window.handleOpenDialogShare;

 };
 const handleEtablish = async (event) => {


  event.preventDefault();
  setLoad(true);

  if (codeA === null || codeA === undefined || sold === null || sold === undefined || firstA === '' || firstA === undefined || lastA === '' || firstA === undefined || etablishNumA === null || etablishNumA === undefined || clientA === [] || clientA === undefined) {

   setLoad(false);
   setOpen(false);
   setWifi(true);

  } else {

   if (sourceIncome === codeA) {

    setLoad(true);
    setOpen(false);


    if (sharedeviseA === 'USD') {

     if (Number(sold) < Number(dollardA)) {

      if (listCl.length === 0) {

       navigation(0);

      } else {


       await deleteDoc(doc(db, "sharecashbeck", secureLocalStorage.getItem("#$$!@@%DTR##")));

       let dollardReduce = Number(dollardA) - Number(sold);
       var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
       let time = moment().format('LLL');
       let send = { date: time, solde: `${euro(sold).format()} USD`, phone: '', user: 'PN', type: 'envoyer', price: euro(dollardReduce).format(), actual: euro(dollardReduce).format() + ' USD', unite: 'USD' }

       const sendRef = doc(db, "agent", secureLocalStorage.getItem("#$$!@@%DTR##"));
       await updateDoc(sendRef, {
        usd: Number(dollardReduce),
        money: Number(sold),
        user: 'PN',
        unite: 'USD',
        phoneclient: '',
        delay: moment().format(),
        swap: arrayUnion(send),
        commission: Number(0),

        signatureusd: `${secureLocalStorage.getItem("#$$!@@%DTR##")}${parseInt(Number(sold))}${parseInt(Number(dollardA))}${parseInt(Number(dollardA) - Number(sold))}${key}`,
        signatureusdtype: 'envoyer',
        signaturemoneyusd: Number(sold),
        signaturekeyusd: key,
        signaturefraisusd: 0,

        frais: 0,
        sendtype: 'envoyer',

       });

       await setDoc(doc(db, "swap" + secureLocalStorage.getItem("#$$!@@%DTR##"), key + moment().format()), {

        action: 'paiement numérique',
        money: Number(sold),
        before: dollardA,
        after: dollardReduce,
        type: 'envoyer',
        date: moment().format(),
        delay: moment().format(),

        phone: 'service cashbeck',
        user: 'PN',

        profile: '',
        beforethrift: thriftA,
        afterthrift: thriftA,

        commission: 0,
        frais: 0,
        access: false,
        key: key + moment().format(),
        devise: 'USD',

        soldebefore: dollardA,
        soldeafter: dollardReduce,

        color: '#f4f0e9',
        dateofcreate: serverTimestamp(),

        clientshare: clientA,

        stateshare: true,
        statetrans: true,
        statepret: false,
        stateunit: false,
        statetontine: false,
        statetv: false,
        statesolaire: false,


       });
       await setDoc(doc(db, "cashbecketablishvalid", moment().format()), {

        total: Number(sold),
        date: moment().format(),
        currenttime: serverTimestamp(),
        code: etablishNumA,
        client: clientA,


       });

       const cityRef = doc(db, 'agent', secureLocalStorage.getItem("#$$!@@%DTR##"));
       // Remove the 'capital' field from the document
       await updateDoc(cityRef, {

        countsharing: 0,
        countsharingcdf: 0,

        sharedevise: deleteField(),
        sharelock: deleteField(),
        shareverify: deleteField(),
        shareverifysucces: deleteField(),
        sharingverifieraccess: deleteField(),
        sharingmpall: deleteField(),
        countsharing: deleteField(),


       });


       [...Array(listCl.length).keys()].map(async (index) => {

        let newSold = Number(listCl[index].usd) + Number(listCl[index].sharingmoney);
        let money = Number(listCl[index].sharingmoney);
        let currentSold = Number(listCl[index].usd);
        let thriftDollard = Number(listCl[index].thriftusd);
        let phOneA = secureLocalStorage.getItem("#$$!@@%DTR##");
        let phOneCl = listCl[index].idphone;


        const removeClRef = doc(db, "client", listCl[index].idphone);
        // Set the "capital" field of the city 'DC'
        await updateDoc(removeClRef, {

         sharingdevise: deleteField(),
         sharingmoney: deleteField(),
         sharingverifier: deleteField(),
         sharingverifieraccess: deleteField()

        });

        await setDoc(doc(db, "swap" + listCl[index].idphone, key + moment().format()), {

         action: `paiement numérique`,
         money: money,
         before: currentSold,
         after: newSold,
         type: 'Reçu',
         date: moment().format(),
         delay: moment().format(),

         phone: secureLocalStorage.getItem("#$$!@@%DTR##"),
         user: nameA,

         profile: '',
         beforethrift: thriftDollard,
         afterthrift: thriftDollard,

         commission: 0,
         frais: 0,
         key: key + moment().format(),
         devise: 'USD',
         access: false,

         soldebefore: currentSold,
         soldeafter: newSold,

         color: '#f4f0e9',
         dateofcreate: serverTimestamp(),


         stateshare: false,
         statetransed: true,
         statepret: false,
         stateunit: false,
         statetontine: false,
         statetv: false,
         statesolaire: false,

        });


       })
       if (true) {

        [...Array(listCl.length).keys()].map(async (index) => {


         let newSold = Number(listCl[index].usd) + Number(listCl[index].sharingmoney);
         let money = Number(listCl[index].sharingmoney);
         let currentSold = Number(listCl[index].usd);
         let thriftDollard = Number(listCl[index].thriftusd);
         let phOneA = secureLocalStorage.getItem("#$$!@@%DTR##");
         let phOneCl = listCl[index].idphone;


         var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
         let time = moment().format('LLL');
         let get = { date: time, solde: `${euro(Number(money)).format()} ${'USD'}`, phone: phOneA, user: nameA, type: 'Reçu', pricenew: euro(newSold).format(), actual: euro(newSold).format() + ' ' + 'USD', unite: 'USD' }

         const updateClRef = doc(db, "client", listCl[index].idphone);
         // Set the "capital" field of the city 'DC'
         await updateDoc(updateClRef, {

          usd: Number(newSold),
          money: Number(money),
          user: nameA,
          unite: 'USD',
          phoneclient: phOneA,

          delay: moment().format(),
          swap: arrayUnion(get),

          signatureusd: `${phOneCl}${parseInt(Number(money))}${parseInt(Number(currentSold))}${parseInt(Number(currentSold) + Number(money))}${key}`,
          signatureusdtype: 'reussi',
          signaturemoneyusd: parseInt(Number(money)),
          signaturekeyusd: key,
          signaturefraisusd: 0,

          frais: 0,
          sendtype: 'Reçu'

         });


        })

       }
       if (true) {

        [...Array(itmIdshare.length).keys()].forEach(async item => {
         await deleteDoc(doc(db, `share${secureLocalStorage.getItem("#$$!@@%DTR##")}`, itmIdshare[item]));

        })

       }

       for (let index = 0; index < listCl.length; index++) {


        let newSold = Number(listCl[index].usd) + Number(listCl[index].sharingmoney);
        let money = Number(listCl[index].sharingmoney);
        let currentSold = Number(listCl[index].usd);
        let thriftDollard = Number(listCl[index].thriftusd);
        let phOneA = secureLocalStorage.getItem("#$$!@@%DTR##");
        let phOneCl = listCl[index].idphone;


        const textmsg = `Paiement reçu montant : ${parseInt(money)} USD est effectue par le  ${phOneA} ${nameAupper}. Nouveau solde : ${parseInt(newSold)} USD`;
        const number = `${listCl[index].idphone}`;
        let phonesend = `243${number.slice(-9)}`;

        let config = {
         method: 'get',
         maxBodyLength: Infinity,
         url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${textmsg}`,

         headers: {}
        };

        axios.request(config)
         .then((response) => {
          console.log(JSON.stringify(response.data));
         })
         .catch((error) => {
          console.log(error);
         });

       }


       window.setTimeout(() => {
        navigation(0);
       }, 9000);


      }

     } else {

      setLoad(false);
      setOpen(false);
      setNext(true);

     }

    } else if (sharedeviseA === 'CDF') {

     if (Number(sold) < Number(franA)) {

      if (listCl.length === 0) {

       navigation(0);

      } else {

       await deleteDoc(doc(db, "sharecashbeck", secureLocalStorage.getItem("#$$!@@%DTR##")));

       let dollardReduce = Number(franA) - Number(sold);
       var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
       let time = moment().format('LLL');
       let send = { date: time, solde: `${euro(sold).format()} CDF`, phone: '', user: 'PN', type: 'envoyer', price: euro(dollardReduce).format(), actual: euro(dollardReduce).format() + ' CDF', unite: 'CDF' }

       const sendRef = doc(db, "agent", secureLocalStorage.getItem("#$$!@@%DTR##"));
       await updateDoc(sendRef, {
        cdf: Number(dollardReduce),
        money: Number(sold),
        user: 'PN',
        unite: 'CDF',
        phoneclient: '',
        delay: moment().format(),
        swap: arrayUnion(send),
        commission: Number(0),

        signaturecdf: `${secureLocalStorage.getItem("#$$!@@%DTR##")}${parseInt(Number(sold))}${parseInt(Number(franA))}${parseInt(Number(franA) - Number(sold))}${key}`,
        signaturecdftype: 'envoyer',
        signaturemoneycdf: Number(sold),
        signaturekeycdf: key,
        signaturefraiscdf: 0,

        frais: 0,
        sendtype: 'envoyer',

       });

       await setDoc(doc(db, "swap" + secureLocalStorage.getItem("#$$!@@%DTR##"), key + moment().format()), {

        action: 'paiement numérique',
        money: Number(sold),
        before: franA,
        after: dollardReduce,
        type: 'envoyer',
        date: moment().format(),
        delay: moment().format(),

        phone: 'service cashbeck',
        user: 'PN',

        profile: '',
        beforethrift: thriftfrA,
        afterthrift: thriftfrA,

        commission: 0,
        frais: 0,
        access: false,
        key: key + moment().format(),
        devise: 'CDF',

        soldebefore: franA,
        soldeafter: dollardReduce,

        color: '#f4f0e9',
        dateofcreate: serverTimestamp(),

        clientshare: clientA,

        stateshare: true,
        statetrans: true,
        statepret: false,
        stateunit: false,
        statetontine: false,
        statetv: false,
        statesolaire: false,


       });
       await setDoc(doc(db, "cashbecketablishvalid", moment().format()), {

        total: Number(sold),
        date: moment().format(),
        currenttime: serverTimestamp(),
        code: etablishNumA,
        client: clientA,


       });

       const cityRef = doc(db, 'agent', secureLocalStorage.getItem("#$$!@@%DTR##"));
       // Remove the 'capital' field from the document
       await updateDoc(cityRef, {

        countsharing: 0,
        countsharingcdf: 0,

        sharedevise: deleteField(),
        sharelock: deleteField(),
        shareverify: deleteField(),
        shareverifysucces: deleteField(),
        sharingverifieraccess: deleteField(),
        sharingmpall: deleteField(),
        countsharing: deleteField(),


       });

       [...Array(listCl.length).keys()].map(async (index) => {


        let newSold = Number(listCl[index].cdf) + Number(listCl[index].sharingmoney);
        let money = Number(listCl[index].sharingmoney);
        let currentSold = Number(listCl[index].cdf);
        let thriftDollard = Number(listCl[index].thriftcdf);
        let phOneA = secureLocalStorage.getItem("#$$!@@%DTR##");
        let phOneCl = listCl[index].idphone;


        const removeClRef = doc(db, "client", listCl[index].idphone);
        // Set the "capital" field of the city 'DC'
        await updateDoc(removeClRef, {

         sharingdevise: deleteField(),
         sharingmoney: deleteField(),
         sharingverifier: deleteField(),
         sharingverifieraccess: deleteField(),

        });
        await setDoc(doc(db, "swap" + listCl[index].idphone, key + moment().format()), {

         action: `paiement numérique`,
         money: money,
         before: currentSold,
         after: newSold,
         type: 'Reçu',
         date: moment().format(),
         delay: moment().format(),

         phone: secureLocalStorage.getItem("#$$!@@%DTR##"),
         user: nameA,

         profile: '',
         beforethrift: thriftDollard,
         afterthrift: thriftDollard,

         commission: 0,
         frais: 0,
         key: key + moment().format(),
         devise: 'CDF',
         access: false,

         soldebefore: currentSold,
         soldeafter: newSold,

         color: '#f4f0e9',
         dateofcreate: serverTimestamp(),


         stateshare: false,
         statetransed: true,
         statepret: false,
         stateunit: false,
         statetontine: false,
         statetv: false,
         statesolaire: false,

        });


       })
       if (true) {

        [...Array(listCl.length).keys()].map(async (index) => {


         let newSold = Number(listCl[index].cdf) + Number(listCl[index].sharingmoney);
         let money = Number(listCl[index].sharingmoney);
         let currentSold = Number(listCl[index].cdf);
         let thriftDollard = Number(listCl[index].thriftcdf);
         let phOneA = secureLocalStorage.getItem("#$$!@@%DTR##");
         let phOneCl = listCl[index].idphone;


         var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
         let time = moment().format('LLL');
         let get = { date: time, solde: `${euro(Number(money)).format()} ${'CDF'}`, phone: phOneA, user: nameA, type: 'Reçu', pricenew: euro(newSold).format(), actual: euro(newSold).format() + ' ' + 'CDF', unite: 'CDF' }

         const updateClRef = doc(db, "client", listCl[index].idphone);
         // Set the "capital" field of the city 'DC'
         await updateDoc(updateClRef, {

          cdf: Number(newSold),
          money: Number(money),
          user: nameA,
          unite: 'CDF',
          phoneclient: phOneA,

          delay: moment().format(),
          swap: arrayUnion(get),

          signaturecdf: `${phOneCl}${parseInt(Number(money))}${parseInt(Number(currentSold))}${parseInt(Number(currentSold) + Number(money))}${key}`,
          signaturecdftype: 'reussi',
          signaturemoneycdf: parseInt(Number(money)),
          signaturekeycdf: key,
          signaturefraiscdf: 0,

          frais: 0,
          sendtype: 'Reçu'

         });


        })

       }
       if (true) {

        [...Array(itmIdshare.length).keys()].forEach(async item => {
         await deleteDoc(doc(db, `share${secureLocalStorage.getItem("#$$!@@%DTR##")}`, itmIdshare[item]));

        })

       }


       for (let index = 0; index < listCl.length; index++) {


        let newSold = Number(listCl[index].cdf) + Number(listCl[index].sharingmoney);
        let money = Number(listCl[index].sharingmoney);
        let currentSold = Number(listCl[index].cdf);
        let thriftDollard = Number(listCl[index].thriftcdf);
        let phOneA = secureLocalStorage.getItem("#$$!@@%DTR##");
        let phOneCl = listCl[index].idphone;


        const textmsg = `Paiement reçu montant : ${parseInt(money)} CDF est effectue par le  ${phOneA} ${nameAupper}. Nouveau solde : ${parseInt(newSold)} CDF`;
        const number = `${listCl[index].idphone}`;
        let phonesend = `243${number.slice(-9)}`;

        let config = {
         method: 'get',
         maxBodyLength: Infinity,
         url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${textmsg}`,

         headers: {}
        };

        axios.request(config)
         .then((response) => {
          console.log(JSON.stringify(response.data));
         })
         .catch((error) => {
          console.log(error);
         });


       }

       window.setTimeout(() => {
        navigation(0);
       }, 9000);


      }

     } else {

      setLoad(false);
      setOpen(false);
      setNext(true);

     }

    } else {

     window.console.log('nothing');

    }



   } else {

    setOpen(false);
    setPass(true);
    setLoad(false);

   }

  }

  delete window.handleEtablish;

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <React.Fragment>

    <Dialog

     fullWidth={fullWidth}
     maxWidth={maxWidth}

     open={wifi}
     onClose={handlewifiClose}>

     <DialogContent>

      <DialogContentText>
       <div className='block-flex-center-wifi'>
        <CiWifiOff size={'2em'} color={'crimson'} />
        <p className='pop-up'>
         Connection internet faible
        </p>
       </div>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     open={open}
     onClose={handleClose}
     PaperProps={{
      component: 'form',
      onSubmit: (event) => {
       event.preventDefault();
       const formData = new FormData(event.currentTarget);
       const formJson = Object.fromEntries(formData.entries());
       const email = formJson.email;
       console.log(email);
       handleClose();
      },
     }}>

     <DialogTitle><h1 className="pop-up">Cashbeck</h1></DialogTitle>

     <DialogContent>
      <DialogContentText>
       <p className="pop-up">
        Veuillez confirmer le code PIN pour l'envoi du paiement numérique
      </p>
      </DialogContentText>

      <FormControl
       sx={{ width: '100%' }}

       variant="standard">
       <InputLabel htmlFor="standard-adornment-password"><div className='pop-up'>Pin actuel</div></InputLabel>

       <Controller
        name="count"
        control={control}
        render={({ field }) =>


         <Input
          autoFocus
          id="standard-adornment-password"
          {...field}
          inputProps={{
           autoComplete: "off", inputMode: 'numeric'
          }}

          sx={{ fontSize: '1.3em', fontFamily: "Alata" }}

          InputProps={{
           inputComponent: NumericFormatCustom,
          }}
          type={showPassword ? 'text' : 'password'}

          endAdornment={
           <InputAdornment position="end">

            <IconButton
             aria-label="toggle password visibility"
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>

           </InputAdornment>
          }

         />

        } />

      </FormControl>


     </DialogContent>

     <DialogActions>
      <div className="flex-between" style={{ padding: "0 15px" }}>
       <Button onClick={handleClose}><span className="pop-up">Annuler</span></Button>
       <Button onClick={handleEtablish}><span className="pop-up">confirmer</span></Button>

      </div>
     </DialogActions>

    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={blem}
     onClose={blemClose}>
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>

       <p className='pop-up'>
        Veuillez vérifier les comptes des utilisateurs établis une situation de litige et présente

								</p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={blemClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={pass}
     onClose={passClose}>
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>

       <p className='pop-up'>
        Veuillez vérifier les codes PIN administrateur

								</p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={passClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={next}
     onClose={nextClose}>
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>

       <p className='pop-up'>
        Soldes agent insuffisant pour continuer
								</p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={nextClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>


   </React.Fragment>

   <button onClick={handleOpenDialogShare} className="Btn">Valider</button>
  </>
 );
};


