import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';


// Identity View
export default function ReturnAccounTDAte() {

 let [accunt, setAccunt] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setAccunt(doc.data().accountdate);
  });

 }, []);


 return (
  <div className='list-sub-exchange-info-detail color-purple-info-low'>
   <p>Compte  creer: </p>
   <p>{moment(accunt).format('LLL')}</p>
  </div>
 );
};