import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";

import BadgeAvatars from './Avatar';
import RetuRnButtOn from './Button';
import { CiTrash } from "react-icons/ci";


export default function TemporaryDrawer() {

 const [state, setState] = React.useState({
  right: false,
 });

 const [active, setActive] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setActive(doc.data().active);
  });

 }, []);
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };


 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div
     // onClick={toggleDrawer(anchor, true)}

     >
      <Tooltip title="Supprimer">
       <IconButton>
        <CiTrash size={'0.85em'} />
       </IconButton>
      </Tooltip>
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      onClose={toggleDrawer(anchor, false)}

      PaperProps={{
       sx: { width: 450, backgroundColor: "#e5e5e5" },

      }}

     >

      <div className='flex-width-100 flex-center'>
       <BadgeAvatars />
       <RetuRnButtOn />

      </div>
     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
