import React from 'react';
import './All.css';

import { db } from '../../../../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import currency from 'currency.js';
import Media from 'react-media';




// Global all Component 
export default function RetuRnGlobAl() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {
 return (
  <div className='wrp-all-actif-user-info'>
   <h4>Clients</h4>
   <View />

  </div>
 );
};
export const ScreenSmall = () => {
 return (
  <div className='wrp-all-actif-user-info-sm'>
   <h4>Clients</h4>
   <View />
  </div>
 );
};

export const View = () => {


 const [countFran, setCountFran] = React.useState(0)
 const [countDollard, setCountDollard] = React.useState(0)

 const [thriftFran, setThriftFran] = React.useState(0)
 const [thriftDollard, setThriftDollard] = React.useState(0)


 const [countFranclient, setCountFranclient] = React.useState(0)
 const [countDollardclient, setCountDollardclient] = React.useState(0)

 const [thriftFranclient, setThriftFranclient] = React.useState(0)
 const [thriftDollardclient, setThriftDollardclient] = React.useState(0)


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   setCountDollard(prev => prev + (doc.data().usd))
   setThriftDollard(prev => prev + (doc.data().thriftusd))

   setCountFran(prev => prev + (doc.data().cdf))
   setThriftFran(prev => prev + (doc.data().thriftcdf))

  });

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   setCountDollardclient(prev => prev + (doc.data().usd))
   setThriftDollardclient(prev => prev + (doc.data().thriftusd))

   setCountFranclient(prev => prev + (doc.data().cdf))
   setThriftFranclient(prev => prev + (doc.data().thriftcdf))

  });

 }, []);


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   <p>{euro(countDollard + countDollardclient).format()} USD</p>
   <p>{euro(countFran + countFranclient).format()} CDF</p>
   <p className='actif-red'>{euro(thriftFran + thriftFranclient).format()} CDF</p>
   <p className='actif-red'>{euro(thriftDollard + thriftDollardclient).format()} USD</p>
  </>
 )
};
