import React from 'react';
import './Title.css';
import secureLocalStorage from "react-secure-storage";
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import currency from 'currency.js';
import Media from 'react-media';


// Title Contribution
export default function RetuRnRising() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='title-contri-body-user'>
  <ViEw />
 </div>

);
export const ScreenSmall = () => (
 <div className='title-contri-body-user-sm'>
  <ViEw />
 </div>

);

export const ViEw = () => {

 let devise = (secureLocalStorage.getItem("##4483833@!!**$$%%").currency).includes('CDF') ? 'CDF' : 'USD';
 const [item, setItem] = React.useState(0);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "tontine", secureLocalStorage.getItem("##id@!!group$$%%")), (doc) => {
   setItem(doc.data().asked === undefined ? 0 : doc.data().asked);

  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <h4>{euro(item).format()} {devise}</h4>
 );

};