import React from 'react';
import './Pin.css';
import { useForm, useWatch } from "react-hook-form";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import './Dat.css';
import secureLocalStorage from "react-secure-storage";
import { TweenMax, Bounce } from 'gsap';


let value = 0;

// Pin Autority
export default function ReturnPiN() {
 return (
  <div className='stock-autority-pin'>
   <CodE />
  </div>
 );
};

// Code PIN
export function CodE() {

 const navigation = useNavigate();
 const [pin, setPin] = React.useState();

 const {
  register,
  handleSubmit,
  control,
  formState: { errors },
 } = useForm();

 const code = useWatch({ control, name: 'pin' });

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "money"), (doc) => {
   setPin(doc.data().code);
  });

 }, []);
 React.useEffect(() => {

  TweenMax.to('.Anima', 1, {
   x: 0, ease: Bounce.easeIn, yoyo: true, yoyoEase: true, repeat: 1, repeatDelay: 0.1
  });


 }, []);

 if (value === 4) {
  if (pin != code) {
   secureLocalStorage.setItem("%%#ki@!!**$#@+=+", true);
   navigation(0);
  } else {

   secureLocalStorage.setItem("%%#pin@!!authority**$#@+=+", true);
   navigation('/unit/stock/throw');
  }

 }

 value = code === undefined ? 0 : code.length;

 const onSubmit = (data) => console.log(data);

 return (
  <>
   <div className={`stock-authority-dat ${secureLocalStorage.getItem("%%#ki@!!**$#@+=+") && 'Anima'}`}>
    <p style={{ backgroundColor: `${value >= 1 ? '#fff' : null}` }}></p>
    <p style={{ backgroundColor: `${value >= 2 ? '#fff' : null}` }}></p>
    <p style={{ backgroundColor: `${value >= 3 ? '#fff' : null}` }}></p>
    <p style={{ backgroundColor: `${value >= 4 ? '#fff' : null}` }}></p>

   </div>

   <form onSubmit={handleSubmit(onSubmit)}>
    {/* register your input into the hook by invoking the "register" function */}
    <input type='password' {...register("pin")} autoFocus spellcheck="false" />
   </form>
  </>
 );
};