
import ReturnTitle from './Title';
import './Head.css';
import BOdy from '../Body/Main';

// Head Dash
export default function RetuRnHeAd() {
 return (
  <div className='flex-head-refs'>
   <ReturnTitle />
   <BOdy />

  </div>
 );
};