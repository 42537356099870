
import './Title.css';
import secureLocalStorage from "react-secure-storage";

// Title Head Form
export default function RetuRnTitle() {
 return (
  <div className='title-head-acces-litige'>
   <h3 style={{ color: '#fff' }}>{secureLocalStorage.getItem("$!!title#%%$$$%")}</h3>
  </div>
 );
};