import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';
import Avatar from '@mui/material/Avatar';

import RetuRnHistoRy from './History';



export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenSmall />}
     {matches.large && <ScreenSmall />}
    </>
   )}
  </Media>
 );
};

export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>

      <Avatar src="/img/list-1.png" />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <div>
       <RetuRnHistoRy />
      </div>

     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};

