import ReturnWAiT from './Wait';
import secureLocalStorage from "react-secure-storage";


// Wait
export default function WAiT() {
 return (
  <>
   {secureLocalStorage.getItem("##**&&##$$%%@") ? <ReturnWAiT /> : <div></div>}
  </>
 );
};