import HeAd from './Head/Main';
import BudGeT from './Budget/Main';
import ReFs from './Refs/Main';
import CliEnT from './Client/Main';
import Media from 'react-media';
import './Dash.css';




// Dash Creditaire Actif
export default function RetuRnDAsh() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='grid-template-tontine'>
  <HeAd />
  <BudGeT />
  <ReFs />
  <CliEnT />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-hm-info'>
  <BudGeT />
 </div>
);