import HeAd from './Head/Main';
import BOdy from './Body/Main';
import './Transfert.css';
import ReturnBTn from './Btn';

// Transfert Main
export default function ReturnTransFeRT() {
 return (
  <div className='flex-transfert-buy-balance wrp-transfert-litige-valid'>
   <HeAd />
   <BOdy />
   <ReturnBTn />

  </div>
 );
};