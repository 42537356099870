import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';
import { CiBank } from "react-icons/ci";
import Avatar from '@mui/material/Avatar';
// import ProgrEss from '../../../Progress/Main';
import RePay from '../../../../Repay/Main';


export default function RetuRnDraweR() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <CiBank className='pointer' size={'1.5em'} color='#73818F' />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 600 }
      }}

      onClose={toggleDrawer(anchor, false)}>

     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (

    <React.Fragment key={anchor}>
     <div onClick={toggleDrawer(anchor, true)}>
      <Avatar sx={{ bgcolor: 'transparent', width: 35, height: 35 }} variant="rounded" src={'/img/money-transfer.png'} />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <RePay />

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
