import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import RetuRnNAme from './Name';
import Media from 'react-media';


const LargeAvatar = styled(Avatar)(({ theme }) => ({
 width: 50,
 height: 50,
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
 width: 35,
 height: 35,
}));



export default function BadgeAvatars() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => {


 const [item, setItem] = React.useState(null);
 const [active, setActive] = React.useState(true);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().profile);
   setActive(doc.data().active);
  });

 }, []);

 return (
  <div className='wrp-grid-center'>
   <Stack direction="row" spacing={2}>

    <Badge
     overlap="circular"
     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
     badgeContent={
      <LargeAvatar
       alt="Remy Sharp"
       src={active ? '/img/unlocked.png' : '/img/lock.png'}

      />
     }>
     <Avatar alt="Travis Howard" src={item} sx={{ width: 120, height: 120 }} />
    </Badge>

   </Stack>

   <RetuRnNAme />

   {active === true && <p>Compte utilisateur déverrouiller</p>}
   {active === false && <p>Compte utilisateur verrouillé</p>}

  </div>

 );
}
export const ScreenSmall = () => {

 const [item, setItem] = React.useState(null);
 const [active, setActive] = React.useState(true);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().profile);
   setActive(doc.data().active);
  });

 }, []);

 return (
  <div className='wrp-grid-center-sm'>
   <Stack direction="row" spacing={2}>

    <Badge
     overlap="circular"
     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
     badgeContent={
      <SmallAvatar
       alt="Remy Sharp"
       src={active ? '/img/unlocked.png' : '/img/lock.png'}
      // sx={{ bgcolor: blueGrey[400] }}
      />
     }>
     <Avatar alt="Travis Howard" src={item} sx={{ width: 80, height: 80 }} />
    </Badge>

   </Stack>

   <RetuRnNAme />

   {active === true && <p>Compte utilisateur déverrouiller</p>}
   {active === false && <p>Compte utilisateur verrouillé</p>}

  </div>


 );
}