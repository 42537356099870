import React from 'react';
import { db } from '../../../../../firebase';
import './List.css';
import { collection, getDocs } from "firebase/firestore";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";

import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';





// List Body
export default function RetuRnLisT() {

 let pushDoc = new Array();
 const navigation = useNavigate();
 const [selectedIndex, setSelectedIndex] = React.useState(0);

 const [list, setList] = React.useState([]);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "tontine"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
  });

  setList(pushDoc);

 }, []);

 const handleListItemClick = (index, item, position) => {

  setSelectedIndex(index);
  navigation(0);

  secureLocalStorage.setItem("##4483833@!!**$$%%", item);
  secureLocalStorage.setItem("$$!!&&number^**^", index);
  secureLocalStorage.setItem("$$!!&++&name^**^##", item.namegroup);
  secureLocalStorage.setItem("##id@!!group$$%%", item.idgroup);

  secureLocalStorage.setItem("#$$@!!color$$%%", item.color);
  secureLocalStorage.setItem("##id@##postion$$%%", position);

  secureLocalStorage.setItem("$#sour#ton%%", secureLocalStorage.getItem("##4483833@!!**$$%%").namegroup + secureLocalStorage.getItem("##4483833@!!**$$%%").table[0]);


 };

 return (
  <div className='list-bd-group-tontine'>
   {!list.length
    ?
    <div></div>
    :
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
     <List component="nav" aria-label="main mailbox folders">

      {[...Array(list.length).keys()].map(item => {

       let str = list[item].namegroup === undefined ? '' : list[item].namegroup;
       let result = str.charAt(0).toUpperCase() + str.slice(1);
       let yourcolor = '#' + list[item].color;

       return (
        <div>
         <ListItemButton
          onClick={() => handleListItemClick(item, list[item], item)}>

          <ListItemIcon>
           <Avatar sx={{ bgcolor: yourcolor }}>{result.slice(0, 2)}</Avatar>
          </ListItemIcon>

          <ListItemText><h3 className='pop-up'>{result}</h3></ListItemText>
         </ListItemButton>

         <div style={{ margin: '1.3vh 0' }}>
          <Divider />
         </div>


        </div>
       );
      })}

     </List>
    </Box>

   }

  </div>
 );
};