import ReturnTitle from './Title';
import Media from 'react-media';


import ReturnIncreaseCount from './Command';
import ReturnIncreaseDollard from './Dollard';
import ReturnIncreaseFran from './Fran';



// PacK Voda 
export default function ReturnPacK() {
 return (
  <div className='wrp-head-pack'>
   <ReturnTitle />

   <Media
    queries={{
     small: '(max-width: 599px)',
     medium: '(min-width: 600px) and (max-width:1199px)',
     large: '(min-width: 1200px)',
    }}>
    {matches => (
     <>
      {matches.small && <ScreenSmall />}
      {matches.medium && <ScreenLarge />}
      {matches.large && <ScreenLarge />}
     </>
    )}
   </Media>

  </div>
 );
};


export const ScreenLarge = () => (
 <div className='flex-head-pack-increase'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-head-pack-increase-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {
 return (
  <div className='flex-between flex-padding-1em'>
   <ReturnIncreaseDollard />
   <ReturnIncreaseCount />
   <ReturnIncreaseFran />
  </div>
 )
};


