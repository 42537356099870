import './Head.css';
import RetuRNTitle from './Title';
import ReturnAsKed from './Asked';

import Media from 'react-media';


// Head Component
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};



export const ScreenLarge = () => {
 return (
  <div className='pad-2em flex-grid-left flex-20vh-head-drawe'>
   <View />

  </div>
 );
};
export const ScreenSmall = () => {
 return (
  <div className='pad-1em flex-grid-left flex-20vh-head-drawe-sm'>
   <View />

  </div>
 );
};

export const View = () => {
 return (
  <>
   <RetuRNTitle />
   <ReturnAsKed />
  </>
 );
};