import './Total.css';
import CoUnT from './Count/Main';
import UseR from './User/Main';


// Total Info
export default function RetuRnTotAl() {
 return (
  <div className='pad-total-info'>
   <CoUnT />
   <UseR />
  </div>
 );
};