
import RetuRnKeyboard from './SwipeableViews';
import RetuRnKeyboardTwo from './Two';
import secureLocalStorage from "react-secure-storage";


// Swipeable Main
export default function RetuRnSwIpeAble() {

 return (
  <>
   {secureLocalStorage.getItem('##%$$sm!!$$') === 'one' && <RetuRnKeyboard />}
   {secureLocalStorage.getItem('##%$$sm!!$$') === 'two' && <RetuRnKeyboardTwo />}
  </>
 );
};