import './Logo.css';
import { useNavigate } from 'react-router-dom';

// Logo Tontine
export default function RetuRnLogo() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/dash');

 }

 return (
  <div onClick={handlepath} className='wrp-logo'>
   <img src='/img/logo.png' />
  </div>
 );
};