import ReturnTitle from './Title';
import RetuRnDrawer from './Drawer';


// Operaotr Budget View
export default function RetuRnOperatoR() {
 return (
  <div>
   <ReturnTitle />
   <RetuRnDrawer />
  </div>
 );
};