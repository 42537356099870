
import './Icon.css';
import { CgMenuGridO } from "react-icons/cg";

// Menu
export default function ReturnIcOn() {
 return (
  <div className='wrp-icon-menu-dash'>
   <CgMenuGridO size={'1.5em'} color='#6c757d' />
  </div>
 );
};