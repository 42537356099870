import React from 'react';
import { db } from '../../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import './Profil.css';


// Price group 
export default function RetuRnPrOFil() {

 const [item, setItem] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');


 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("#!!!list@#%%")), (doc) => {

   setItem(doc.data().profile);
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 });


 let firstname = first.charAt(0).toUpperCase() + first.slice(1);
 let lastname = last.charAt(0).toUpperCase() + last.slice(1);

 return (
  <div className='flex-head-profil-info-tontine'>

   <Avatar sx={{ width: 80, height: 80 }} src={item} />
   <p>{firstname} {lastname}</p>

  </div>
 );
};