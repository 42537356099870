
import RetuRnSeNd from './Send';
import NAv from './Nav/Main';
import RetuRnBTn from './Btn';
import './View.css';

// Send Main
export default function SeNd() {
 return (
  <div className="flex-view-send-share">
   <NAv />
   <RetuRnSeNd />
   <RetuRnBTn />
  </div>
 );
};