import React from 'react';
import { db } from '../../../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import './WithUs.css';
import Media from 'react-media';



// With Us 
export default function ReturnWithUs() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => {


 const [date, setDate] = React.useState(moment().format());
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setDate(doc.data().accountdate);
  });
 }, []);

 return (
  <div className='wrp-small-screen-withus-user-date'>
   <p>{moment().to(date)}, avec nous.</p>
  </div>
 );
}
export const ScreenSmall = () => {

 const [date, setDate] = React.useState(moment().format());
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setDate(doc.data().accountdate);
  });
 }, []);

 return (
  <div className='wrp-small-screen-withus-user-date-sm'>
   <p>{moment().to(date)}, avec nous.</p>
  </div>
 );
}
