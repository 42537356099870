import RetuRnTitle from './Title';
import AllActiF from './All/Main';
import './Actif.css';


// ActiF user total
export default function RetuRnActIF() {
 return (
  <div className='pad-left-right-3'>
   <RetuRnTitle />
   <AllActiF />
  </div>
 );
};
