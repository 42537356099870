
import './Stock.css';
import HOme from './Home/Main';

// Main Stock Unit
export default function ReturnUnitStocK() {
 return (
  <HOme />
 );
}
