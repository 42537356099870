import './Head.css';
import RetuRnLogo from './Logo';
import Media from 'react-media';
import VAlid from './Valid/Main';


// Head Pret
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};

export const ScreenLarge = () => (
 <div className='flex-between flex-header-6ch bg-color-hicolblue grid-head-dash-litige'>
  <div></div>
  <VAlid />
  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-between flex-header-6ch bg-color-hicolblue grid-head-dash-litige-sm'>
  <div></div>
  <VAlid />
  <div></div>
 </div>
);

