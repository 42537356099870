
import ReturnTitle from './Title';
import './Head.css';
import BOdy from '../Body/Main';
import SolDE from './Solde/Main';


// Head Dash
export default function RetuRnHeAd() {
 return (
  <div className='flex-head-refs'>
   <SolDE />
   <BOdy />

  </div>
 );
};