import * as React from 'react';
import Drawer from '@mui/material/Drawer';

import { CiLock, CiUnlock } from "react-icons/ci";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";

import BadgeAvatars from './Avatar';
import RetuRnButtOn from './Button';
import Media from 'react-media';
import './Active.css';



export default function TemporaryDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const [active, setActive] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setActive(doc.data().active);
  });

 }, []);
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <>
      {active
       ?
       <div onClick={toggleDrawer(anchor, true)}>
        <Tooltip title="Active">
         <IconButton>
          <CiUnlock size={'0.85em'} />
         </IconButton>
        </Tooltip>
       </div>
       :
       <div onClick={toggleDrawer(anchor, true)}>
        <Tooltip title="Active">
         <IconButton>
          <CiLock size={'0.85em'} color='#F68290' />
         </IconButton>
        </Tooltip>
       </div>
      }
     </>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      onClose={toggleDrawer(anchor, false)}

      PaperProps={{
       sx: { width: 500, backgroundColor: "#e5e5e5", padding: '1em' },

      }}>

      <div className='flex-grid-active'>
       <BadgeAvatars />
       <RetuRnButtOn />
      </div>


     </Drawer>
    </React.Fragment>
   ))}
  </div>

 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const [active, setActive] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setActive(doc.data().active);
  });

 }, []);
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <>
      {active
       ?
       <div onClick={toggleDrawer(anchor, true)}>
        <Tooltip title="Active">
         <IconButton>
          <CiUnlock size={'0.85em'} />
         </IconButton>
        </Tooltip>
       </div>
       :
       <div onClick={toggleDrawer(anchor, true)}>
        <Tooltip title="Active">
         <IconButton>
          <CiLock size={'0.85em'} color='#F68290' />
         </IconButton>
        </Tooltip>
       </div>
      }
     </>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      onClose={toggleDrawer(anchor, false)}

      PaperProps={{
       sx: { width: 300, backgroundColor: "#e5e5e5", padding: '1em' },

      }}>

      <div className='flex-grid-active'>
       <BadgeAvatars />
       <RetuRnButtOn />

      </div>
     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
