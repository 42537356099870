import RetuRNDollard from './Dollard';
import RetuRNFrAn from './Fran';
import Media from 'react-media';
import './Budget.css';



// Main budget Component
export default function RetuRNBudgeT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <ViEw />
);
export const ScreenSmall = () => (
 <div className='flex-budget-pret-grid-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {
 return (
  <div style={{ marginTop: '2vh' }}>
   <RetuRNDollard />
   <RetuRNFrAn />
  </div>
 );

};