import React from 'react';
import { db } from '../../../../../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import currency from 'currency.js';
import './Asked.css';



// Asked drawe
export default function ReturnAsKed() {

 let pushDollard = new Array();
 let pushFran = new Array();

 const [dollard, setDollard] = React.useState([]);
 const [fran, setFran] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "pretask"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   if (doc.data().devise === 'USD') {
    pushDollard.push(doc.data().askview)
   } else {
    pushFran.push(doc.data().askview)

   }

  });

  setDollard(pushDollard)
  setFran(pushFran)

 }, []);

 const sumDollard = dollard.reduce((accumulator, value) => {
  return accumulator + value;
 }, 0);
 const sumFran = fran.reduce((accumulator, value) => {
  return accumulator + value;
 }, 0);


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-between flex-asked-drawe'>
   <p>{euro(sumDollard).format()} USD</p>
   <p>{euro(sumFran).format()} CDF</p>
  </div>
 );
};