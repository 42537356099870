
import RetuRNUseR from './User';

// User Main
export default function UseR() {
 return (
  <div className='grid-main'>
   <RetuRNUseR />
  </div>
 );
};