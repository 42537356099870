import './Balance.css';
import AgEnT from './Agent/Main';
import CliEnT from './Client/Main';
import Media from 'react-media';



// Balance VieW
export default function ReturnBaLAnce() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='wrp-box-balance'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-box-balance-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {
 return (
  <>
   <AgEnT />
   <CliEnT />
  </>
 );
};