import './Time.css';
import ReturnTitle from './Title';
import LinearWithValueLabel from './Linear';


// Time Add for client
export default function ReturnTimeTAmp() {
 return (
  <div className='wrp-timestamp-pret'>
   <ReturnTitle />
   <LinearWithValueLabel />
  </div>
 );
};