import React from 'react';
import './Title.css';
import Media from 'react-media';
import { TweenMax, Expo } from 'gsap';


// Title Home
export default function ReturnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
}

export const ScreenLarge = () => (
 <div className='hm-title-unit-stock'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <div className='hm-title-unit-stock-sm'>
  <View />
 </div>
);
export const View = () => {

 React.useEffect(() => {

  TweenMax.from('.stock-1', 1.2, { delay: .5, y: 10, opacity: 0, ease: Expo.easeInOut });
  TweenMax.from('.stock-2', 1.2, { delay: 1, y: 10, opacity: 0, ease: Expo.easeInOut });

 }, [])

 return (
  <>
   <h1 className='stock-1'>Hi'</h1>
   <h1 className='stock-2'>Stock Unitaire</h1>
  </>
 );
};