import LisTUseR from './List/Main';
import './User.css';


// User TotAl !
export default function ReturnUseR() {
 return (
  <div className='scroll-user-list-total-dash'>
   <LisTUseR />
  </div>
 );
};
