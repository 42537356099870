
import './Title.css';

// Title Time jour pret
export default function ReturnTitle() {
 return (
  <div className='title-consumption-info flex-info-msg-pret'>
   <h3>Durée du Prêt</h3>
   <p>
    Évaluation sur la consommation du prêt
    depuis la date de son activation
   </p>
  </div>
 );
};