import HeAd from './User/Head/Main';
import Media from 'react-media';

import PrET from './Pret/Main';
import UseR from './User/Main';

import secureLocalStorage from "react-secure-storage";
import SwIpeAble from './Swipeable/Main';


// Info
export default function RetuRnInFO() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='grid-template-tontine'>
  <div className={secureLocalStorage.getItem('##grid$$column!!$$')}></div>

  <div><UseR /></div>
  <div><PrET /></div>

  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-hm-info'>
  <HeAd />
  <SwIpeAble />
 </div>
);

