import React from 'react';
import './Title.css';
import { TweenMax, Power3 } from 'gsap';
import Media from 'react-media';
import ReturnBtn from './Btn';


// Return Title Home All actif
export default function ReturnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='flex-title-view-allacitf'>
  <div className='wrp-title-hm-actifall'>
   <ViEwTitle />
  </div>

  <ReturnBtn />
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-title-view-allacitf-sm'>
  <div className='wrp-title-hm-actifall-sm'>
   <ViEwTitle />
  </div>

  <ReturnBtn />
 </div>
);
export const ViEwTitle = () => {

 React.useEffect(() => {

  TweenMax.from('.wrp-title-hm-actifall h1', 1, { delay: .5, opacity: 0, y: 20, ease: Power3.easeInOut })
  TweenMax.from('.wrp-title-hm-actifall p', 1, { delay: 1, opacity: 0, y: 10, ease: Power3.easeInOut })

 }, []);

 return (
  <>
   <h1>Tous les actifs sur MuunganoMoney</h1>
   <p>Vérification de tous les actifs MuunganoMoney. disponibles sur les agents et les clients. total général des actifs</p>
  </>

 )
};
