import './List.css';
import RetuRnNAme from './Firstname';
import RetuRnNAmeLAst from './Lastname';
import RetuRnPhone from './Phone';
import RetuRnEmAil from './Email';
import RetuRnCiTy from './City';
import RetuRnContry from './Contry';
import RetuRnFrAn from './CDF';
import RetuRnDollARd from './USD';
import RetuRnSAvingFrAn from './SavingCDF';
import RetuRnSAvingDollArd from './SavingUSD';
import RetuRnCode from './Code';
import RetuRnPin from './Pin';
import RetuRnIDForCarte from './ID';
import RetuRnBirth from './Birth';


// List Update 
export default function ReturnLisT() {
 return (
  <div className='wrp-list-body-update'>
   <RetuRnNAme />
   <RetuRnNAmeLAst />
   <RetuRnCiTy />
   <RetuRnPhone />
   <RetuRnEmAil />
   <RetuRnBirth />
   <RetuRnContry />
   <RetuRnIDForCarte />
   <RetuRnFrAn />
   <RetuRnDollARd />
   <RetuRnSAvingFrAn />
   <RetuRnSAvingDollArd />
   <RetuRnCode />
   <RetuRnPin />

  </div>
 );
};