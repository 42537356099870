import './List.css';
import ReturnIdEntity from './Identity';
import ReturnAccounTDAte from './AccountDate';
import ReturnContry from './Contry';
import RetuRnBirth from './Birth';
import RetuRnFrAn from './Fran';
import RetuRnDollArd from './Dollard';
import RetuRnLAstTransf from './LastTransf';
import RetuRnLAstTransfDate from './LastTransfDate';
import ReturnEmAil from './Email';
import ReturnTypeTransF from './TypeTransf';
import RetuRnState from './State';
import RetuRnNameTransf from './NameLastTransf';
import RetuRnThriftDollArd from './ThriftDollard';
import RetuRnThriftFrAn from './ThriftFran';
import ReturnID from './ID';

import RetuRnPhone from './Phone';
import RetuRnContAct from './Contact';


// List Info
export default function ReturnLisT() {
 return (
  <div className='wrp-list-info-user'>
   <ReturnIdEntity />
   <RetuRnBirth />
   <ReturnID />
   <RetuRnPhone />
   <ReturnContry />
   <RetuRnContAct />
   <ReturnEmAil />
   <RetuRnState />
   <ReturnAccounTDAte />
   <RetuRnDollArd />
   <RetuRnFrAn />
   <RetuRnThriftDollArd />
   <RetuRnThriftFrAn />
   <RetuRnLAstTransf />
   <ReturnTypeTransF />
   <RetuRnNameTransf />
   <RetuRnLAstTransfDate />
  </div>
 );
};