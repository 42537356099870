import './Head.css';
// import RetuRnLogo from './Logo';
import IcOnPreT from './Icon/Main';
import BudgeT from './Budget/Main';
import Media from 'react-media';



// Head Pret
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => (
 <div className='flex-between flex-header-6ch bg-platinum grid-head-dash-pret'>
  {/* <RetuRnLogo /> */}
  <BudgeT />
  <IcOnPreT />
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-between flex-header-6ch bg-platinum grid-head-dash-pret-sm'>
  {/* <RetuRnLogo /> */}
  <BudgeT />
  <IcOnPreT />
 </div>
);

