
import './Title.css';


// Title Head Component
export default function RetuRnTitle() {
 return (
  <div className="flex-title-head-share">
   <h1>Paiement Numérique</h1>
  </div>
 );
};