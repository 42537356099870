import './Progress.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';


// Main in progress
export default function RetuRnprogress() {
 return (
  <div className='flex-progress-pret rose-quartz'>
   <HeAd />
   <BOdy />
  </div>
 );
};
