import React from 'react';
import './Budget.css';
import { doc, onSnapshot } from "firebase/firestore";
import currency from 'currency.js';
import { db } from '../../../../firebase';
import Media from 'react-media';


// wallet usd available 
export default function RetuRnWAlleTFrAn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='budget-dash-screen-large'>
  <ViEw />
 </div>
)
export const ScreenSmall = () => (
 <div className='budget-dash-screen-large-sm'>
  <ViEw />
 </div>
)

export const ViEw = () => {

 const [item, setItem] = React.useState(0);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "budget"), (doc) => {
   setItem(doc.data().cdf);
  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   <p>{euro(item).format()} CDF</p>
  </>
 );
};