import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import secureLocalStorage from "react-secure-storage";
import Media from 'react-media';
import './IMA.css';



export default function RetuRnAvatars() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {
 return (
  <Stack direction="column" spacing={2}>
   <Avatar sx={{ width: 105, height: 105 }} variant="rounded" src={secureLocalStorage.getItem("#$$img@!!@98***")} />
   <div className='flex-qt-operator-budget-credit'>
    <p>{secureLocalStorage.getItem("#$$phone##$")}</p>
   </div>

  </Stack>
 );
}
export const ScreenSmall = () => {
 return (
  <Stack direction="column" spacing={2}>
   <Avatar sx={{ width: 85, height: 85 }} variant="rounded" src={secureLocalStorage.getItem("#$$img@!!@98***")} />

   <div className='flex-qt-operator-budget-credit'>
    <p>{secureLocalStorage.getItem("#$$phone##$")}</p>
   </div>

  </Stack>
 );
};