import React from 'react';
import './Price.css';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';
import currency from 'currency.js';


let pushTotal = new Array();


// Balance
export default function ReturnPriCE() {

 const [total, setTotal] = React.useState([0, 0])

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   // console.log(doc.id, " => ", doc.data().usd);
   pushTotal.push(parseInt(doc.data().usd))

  });

  setTotal([...new Set(pushTotal)]);

 }, []);

 let balanceClient = 0;
 balanceClient = total.reduce(function (a, b) {
  return a + b;
 });

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='wrp-price-actif-all'>
   <p>{euro(balanceClient).format()} USD</p>
  </div>
 );
};