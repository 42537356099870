import React from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

import { db } from '../../../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import './Avatar.css';
import Media from 'react-media';


// Avatar view 
export default function RetuRnAvaTaR() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => {

 const [profil, setProfil] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setProfil(doc.data().profile);
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);
  });

 }, []);


 let str = first.toLowerCase() + ' ' + last.toLowerCase();
 const result = str.charAt(0).toUpperCase() + str.slice(1);


 return (
  <div className='wrp-avatar-transfer-identity'>
   <Avatar
    src={profil}
    sx={{ bgcolor: deepOrange[50], width: 45, height: 45 }}
   />

   <p>{result}</p>

  </div>

 );

};
export const ScreenSmall = () => {

 const [profil, setProfil] = React.useState(null);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setProfil(doc.data().profile);
  });

 }, []);

 return (
  <div className='wrp-avatar-transfer-identity'>
   <Avatar
    src={profil}
    sx={{ bgcolor: deepOrange[50], width: 45, height: 45 }}
   />

  </div>
 );
};


