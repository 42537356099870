
import './Head.css';
import ReturnProgrEss from './Progress';
import ReturnTitle from './Title';


// Head 
export default function HeAd() {
 return (
  <div>
   <ReturnTitle />
   <ReturnProgrEss />
  </div>
 );
};