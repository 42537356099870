import React from 'react';
import 'moment/locale/fr';
import Home from './Home/Main';


// Return Home Admin 
function App() {
 return (
  <Home />
 );
};


// Export component Main 
export default App;
