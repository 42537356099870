import RetuRnAvatars from './IMA';
import ControlledSwitches from './Devise';


// Head Ref 
export default function ReturnHEAd() {
 return (
  <div className='flex-between'>
   <RetuRnAvatars />
   <ControlledSwitches />
  </div>
 );
};