import RetuRnTitle from './Title';
import './Head.css';


// View Head 
export default function ReturnHeAd() {

 return (
  <div className='flex-between flex-border-bottom pad-left-right-3'>
   <RetuRnTitle />
  </div>
 );
};