
import './Quote.css';


// Quote Component 
export default function RetuRnQouTE() {


 return (
  <div className='flex-user-quote-transfert-send-money'>
   <p>
    Une transaction a été déclenchée,
    vous voulez vous confirmer cette transactions
   </p>
  </div>
 );
};