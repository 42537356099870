import * as React from 'react';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import './Devise.css';
import secureLocalStorage from "react-secure-storage";
import Media from 'react-media';


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export default function ControlledSwitches() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => (
 <ViEW />
);
export const ScreenSmall = () => {
 return (
  <ViEW />
 );

}
export const ViEW = () => {


 const [load, setLoad] = React.useState(false);
 const [checked, setChecked] = React.useState(secureLocalStorage.getItem("###@devise^^#"));
 const handleChange = (event) => {
  setChecked(event.target.checked);
  secureLocalStorage.setItem("###@devise^^#", event.target.checked)

 };
 const handleClick = (item) => {

  setLoad(true)
  secureLocalStorage.setItem("###@devise^^#", item)

  window.setTimeout(() => { setLoad(false) }, 12500);

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <Stack direction="row" spacing={2}>
    <div className='flex-grid-center-creditaire'>
     <Avatar
      alt="Remy Sharp"
      src={checked ? '/img/dollars.png' : '/img/franc.png'}
      sx={{ width: 35, height: 35 }}
     />

     <Switch
      checked={checked}
      onClick={() => handleClick(checked)}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
     />
    </div>
   </Stack>
  </>
 )
};