
import PersonAl from './Personal/Main';
import ReturnTwO from './Two/Main';
import BuY from './Buy/Main';
import secureLocalStorage from "react-secure-storage";

// Body Form Acces
export default function RetuRnBody() {
 return (
  <>
   {secureLocalStorage.getItem("xx@@#x$$%") && <ReturnTwO />}
   {secureLocalStorage.getItem("$$personel@@#x$$%") && <PersonAl />}
   {secureLocalStorage.getItem("$$buy@@#x$$%") && <BuY />}
  </>


 );
};