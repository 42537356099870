
import './Nav.css';
import ReturnBacK from './Back';


// Navigation Main
export default function ReturnBacKNAv() {
 return (
  <div className='stock-admin-nav'>
   <ReturnBacK />
   <div></div>

  </div>
 );
}