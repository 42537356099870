import BoDY from './Body/Main';
import HeAd from './Head/Main';
import './Exchange.css';


// RetuRnEXchAnge 
export default function RetuRnEXChAnge() {
 return (
  <div className='wrp-exchange-buy-detail'>
   <HeAd />
   <BoDY />
  </div>
 );
};