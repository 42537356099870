import React from 'react';
import './WithUs.css';

import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";

import secureLocalStorage from "react-secure-storage";
import moment from 'moment';



// With Us 
export default function ReturnWithUs() {
 return (
  <ReturnDATe />
 );
};
export const ReturnDATe = () => {

 const [date, setDate] = React.useState(moment().format());
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setDate(doc.data().accountdate);
  });
 }, []);

 return (
  <div className='wrp-withus-user-info'>
   <p>{moment().to(date)}, avec nous.</p>
  </div>
 );
};