import './Ref.css';
import HEAd from './Head/Main';
import BodyREf from './Body/Main';


// Ref Operator 
export default function ReturnREf() {
 return (
  <div className='ref-operator-creditaire'>
   <HEAd />
   <div className='martop-10'></div>
   <BodyREf />
   <div></div>
  </div>
 );
};