import React from 'react';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";


// Avatar view 
export default function RetuRnNAme() {

 const [item, setItem] = React.useState('');
 const [itemlast, setItemlast] = React.useState('');

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().firstname);
   setItemlast(doc.data().lastname);
  });
 }, []);

 let str = item.toLowerCase() + ' ' + itemlast.toLowerCase();
 let result = str.charAt(0).toUpperCase() + str.slice(1);

 return (
  <div className='flex-id-phone pad-top-bottom-2'>
   <h3>{result}</h3>
  </div>
 );
};