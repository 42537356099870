import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { doc, onSnapshot, serverTimestamp, updateDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

import secureLocalStorage from "react-secure-storage";
import RetuRnProFil from './Avatar';
import RetuRnDeVise from './Devise';
import { v4 } from 'uuid';

import moment from 'moment';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import RetuRnQouTE from './Quote';
import currency from 'currency.js';


import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



export let money = 0;
export let count = 0;
export let frais = 0;
export let solde = 0;
export let Unite = null;


let key = v4();



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix=""
    />
  );
});
NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};




// Return Phone input component
export default function REturnInputPhone() {
  return (
    <>
      <Media
        queries={{
          small: '(max-width: 599px)',
          medium: '(min-width: 600px) and (max-width:1199px)',
          large: '(min-width: 1200px)',
        }}>
        {matches => (
          <>
            {matches.small && <ScreenSmall />}
            {matches.medium && <ScreenLarge />}
            {matches.large && <ScreenLarge />}
          </>
        )}
      </Media>
    </>
  );
};
export const ScreenLarge = () => {
  return (
    <div className='input-withdraw'>
      <FormInputValue />
    </div>
  );
};
export const ScreenSmall = () => {
  return (
    <div className='input-withdraw'>
      <FormInputValue />
    </div>
  )
};

export const FormInputValue = () => {

  const navigation = useNavigate();
  const [load, setLoad] = React.useState(false);
  const [password, setPassword] = React.useState(false);

  const [itembudget, setItembudget] = React.useState(null);


  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [main, setMain] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const { handleSubmit, watch, reset, control } = useForm({});

  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000',
    numberformat: '1320',
  });

  const [itemfran, setItemfran] = React.useState(0);
  const [itemdollard, setItemdollard] = React.useState(0);

  const [itemcdf, setItemcdf] = React.useState(null);
  const [itemusd, setItemusd] = React.useState(null);

  const [thriftusd, setThriftusd] = React.useState(null);
  const [thriftcdf, setThriftcdf] = React.useState(null);
  const [opened, setOpened] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpened(false);
  };
  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };


  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganomoney", "budget"), (doc) => {
      setItemfran(doc.data().cdf);
      setItemdollard(doc.data().usd);
    });

  }, []);
  React.useEffect(async () => {

    const unsubbudget = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
      setItembudget(doc.data().transfert);
    });

  }, []);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##$%%!!**++!!status@@"), secureLocalStorage.getItem("##$%%!!**++!!num@@")), (doc) => {

      setItemcdf(doc.data().cdf);
      setItemusd(doc.data().usd);

      setThriftcdf(doc.data().thriftcdf);
      setThriftusd(doc.data().thriftusd);

    });

  }, []);


  let entry = watch('count');
  let mypassword = watch('password');


  const phone = secureLocalStorage.getItem("##$%%!!**++!!num@@");
  const money = secureLocalStorage.getItem("##$%%!!!!count##");
  const devise = secureLocalStorage.getItem("##$%%!!**++!!devise@@");


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMain = async () => {

    setMain(false);
    setPassword(true);

  };
  const handleMainClose = () => {
    setMain(false);
  };

  const handlepasswordOpen = async () => {

    if (mypassword != itembudget) {
      setError(true);
      window.console.log('not egal')
    } else {

      setLoad(true);
      setOpened(true);
      setPassword(false);

      if (secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'USD') {

        const getRef = doc(db, secureLocalStorage.getItem("##$%%!!**++!!status@@"), phone);
        await updateDoc(getRef, {

          usd: Number(itemusd) + Number(money),
          money: Number(money),
          user: 'Cashbeck',
          unite: 'USD',
          phoneclient: '',
          delay: moment().format(),

          signatureusd: `${phone}${parseInt(Number(money))}${parseInt(Number(itemusd))}${parseInt(Number(itemusd) + Number(money))}${key}`,
          signatureusdtype: 'reussi',
          signaturemoneyusd: Number(money),
          signaturekeyusd: key,
          signaturefraisusd: 0,

          frais: 0,
          commission: Number(0),
          sendtype: 'Reçu'

        });


        const getadminRef = doc(db, "muunganomoney", "budget");
        await updateDoc(getadminRef, {
          usd: Number(itemdollard) - Number(money),
        });



        let after = Number(itemusd) + Number(money);
        swapGetUser(phone, `Transfért d'argent`, money, itemusd, after, 'Reçu', moment().format(), '', 'cashbeck', '', thriftusd, thriftusd, 0, 0, key, 'USD', itemusd, itemusd - money);

        window.setTimeout(() => {
          window.location.href = '/dash';
        }, 3500);


      } else if (secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'CDF') {

        const getRef = doc(db, secureLocalStorage.getItem("##$%%!!**++!!status@@"), phone);
        await updateDoc(getRef, {

          cdf: Number(itemcdf) + Number(secureLocalStorage.getItem("##$%%!!!!count##")),
          money: Number(secureLocalStorage.getItem("##$%%!!!!count##")),
          user: 'Cashbeck',
          unite: 'CDF',
          phoneclient: '',
          delay: moment().format(),

          signaturecdf: `${phone}${parseInt(Number(money))}${parseInt(Number(itemcdf))}${parseInt(Number(itemcdf) + Number(money))}${key}`,
          signaturecdftype: 'reussi',
          signaturemoneycdf: Number(money),
          signaturekeycdf: key,
          signaturefraiscdf: 0,

          frais: 0,
          commission: Number(0),
          sendtype: 'Reçu'

        });

        const getadminRef = doc(db, "muunganomoney", "budget");
        await updateDoc(getadminRef, {
          cdf: Number(itemfran) - Number(money),
        });


        let after = Number(itemcdf) + Number(money);

        swapGetUser(phone, `Transfért d'argent`, money, itemcdf, after, 'Reçu', moment().format(), '', 'cashbeck', '', thriftcdf, thriftcdf, 0, 0, key, 'CDF', itemcdf, itemcdf - money);
        window.setTimeout(() => {
          window.location.href = '/dash';
        }, 3500);

      } else {
        window.console.log('welcome!!!');
      }

    }

  };
  const handlepasswordClose = () => {
    setPassword(false);
  };

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })

  const onSubmit = async (data) => {

    if (data.count === undefined) {

      setOpen(true);
      reset();

    } else {

      if (secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'USD') {

        if (Number(itemdollard) <= Number(data.count)) {
          setOpen(true);
        } else {
          secureLocalStorage.setItem("##$%%!!!!count##", data.count);
          setMain(true);
        }

      } else if (secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'CDF') {

        if (Number(itemfran) <= Number(data.count)) {
          setOpen(true);
        } else {
          secureLocalStorage.setItem("##$%%!!!!count##", data.count);
          setMain(true);
        }

      } else {
        window.console.log(itemfran + ' ' + itemdollard)
        window.console.log(secureLocalStorage.getItem("##$%%!!**++!!devise@@"))
      }

    }

  };

  return (
    <>
      <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          <h1 className='pop-up'>Transactions de {euro(money).format()} {devise} effectuées!</h1>

        </Alert>
      </Snackbar>

      <Snackbar open={error} autoHideDuration={6000} onClose={handleError}>
        <Alert
          // onClose={handleError}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          <h1 className='pop-up'>Mot de passe administrateur incorrect</h1>
        </Alert>
      </Snackbar>


      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className='wrp-item-reduce'>
        {secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'USD' && <h1 style={{ color: `${(itemdollard - entry) <= 0 || isNaN(itemdollard - entry) ? '#fffcf7' : 'black'}` }}>{euro(itemdollard - entry).format()}$</h1>}
        {secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'CDF' && <h1 style={{ color: `${(itemfran - entry) <= 0 || isNaN(itemdollard - entry) ? '#fffcf7' : 'black'}` }}>{euro(itemfran - entry).format()}FC</h1>}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <Controller
          // autoFocus
          name="count"
          defaultValue=''
          control={control}
          render={({ field }) =>

            <TextField
              autoFocus
              label={<span className='pop-up'>Montant</span>}
              value={values.numberformat}
              onChange={handleChange}

              inputProps={{
                autoComplete: "off", inputMode: 'decimal'
              }}

              {...field}
              name="count"
              placeholder="0"
              id="formatted-numberformat-input"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}

              variant="standard"
            />

          }
        />

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>La valeur demandée n'est pas disponible, veuillez vérifier.</p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={main}
          onClose={handleMainClose}
        >
          <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
          <DialogContent>

            <DialogContentText>

              <div className='block-center'>
                <RetuRnProFil />
                <RetuRnDeVise />
                <RetuRnQouTE />

              </div>

            </DialogContentText>

          </DialogContent>
          <DialogActions>

            <div className='flex-between'>

              <Button onClick={handleMainClose}><h1 className='pop-up'>annuler</h1></Button>
              <Button onClick={handleMain} autoFocus>
                <h1 className='pop-up'>confirmer</h1></Button>
            </div>
          </DialogActions>


        </Dialog>

        <Dialog

          open={password}
          onClose={handlepasswordClose}

        >
          <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>

          <DialogContent>
            <DialogContentText>
              <p className='pop-up'>
                Veuillez indiquer le mot de passe administrateur pour confirmer le transfert
      </p>
            </DialogContentText>

            <Controller
              autoFocus
              name="password"
              defaultValue=''
              control={control}
              render={({ field }) =>

                <Input
                  fullWidth

                  style={{ fontSize: '24px', color: 'grey', letterSpacing: '5px', fontFamily: 'Quicksand' }}

                  {...field}
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

              } />

          </DialogContent>

          <DialogActions>
            <div className='flex-between'>
              <Button onClick={handlepasswordClose}><h1 className='pop-up'>Annuler</h1></Button>
              <Button onClick={handlepasswordOpen}><h1 className='pop-up'>transfert</h1></Button>
            </div>
          </DialogActions>

        </Dialog>

        <button className='Btn'>Valider</button>
      </form>
    </>
  );
};

async function swapGetUser(colSwap, action, money, before, after, type, date, phone, user, profile, beforethrift, afterthrift, commision, frais, key, devise, soldebefore, soldeafter) {

  const docRef = await addDoc(collection(db, "swap" + colSwap), {



    action: action,
    money: money,
    before: before,
    after: after,
    type: type,
    date: date,
    delay: date,

    phone: phone,
    user: user,

    profile: profile,
    beforethrift: beforethrift,
    afterthrift: afterthrift,
    commission: commision,
    frais: frais,
    key: key + date,
    devise: devise,
    access: false,

    soldebefore: soldebefore,
    soldeafter: soldeafter,

    color: '#EFF7FE',
    dateofcreate: serverTimestamp(),

    statetransed: true,
    statepret: false,
    stateunit: false,
    statetontine: false,
    statetv: false,
    statesolaire: false,

  });

};
