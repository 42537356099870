import './Logo.css';
import { useNavigate } from 'react-router-dom';

// Logo Home 
export default function RetuRnlOgo() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/dash');

 }

 return (
  <div onClick={handlepath} className='wrp-logo-creditaire'>


   {/* <Avatar
    src='/img/logo.png'
    sx={{ width: 45, height: 45 }}
   /> */}

  </div>
 );
};