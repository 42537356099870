import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { doc, getDocs, collection, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

import secureLocalStorage from "react-secure-storage";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';



export default function RetuRnAvatars() {


 const navigation = useNavigate();

 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 let pushClient = new Array();
 let pushIdClnt = new Array();


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const [listClient, setListClient] = React.useState([]);
 const [listClnt, setListClnt] = React.useState([]);

 const [load, setLoad] = React.useState(false);
 const [verify, setVerify] = React.useState(false);
 const [open, setOpen] = React.useState(false);


 React.useEffect(() => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "partner"), (doc) => {
   setVerify(doc.data().sms)
  });

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());
   pushIdClnt.push(doc.id);

  });

  setListClient([... new Set(pushClient)]);
  setListClnt([... new Set(pushIdClnt)]);

 }, []);

 const handleClose = () => {
  setOpen(false);
 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   {moment().date() === 6 && verify === true &&
    <div onClick={() => setOpen(true)}>
     <Stack direction="row" sx={{ cursor: 'pointer' }}>
      <Avatar
       src="/img/e-mail-1.png"
       sx={{ width: 40, height: 40 }}
       variant='square'
      />

     </Stack>

    </div>
   }

   <React.Fragment>
    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
    >
     <DialogTitle id="alert-dialog-title">
      <h1 className="pop-up">
       {"Cashbeck"}
      </h1>
     </DialogTitle>

     <DialogContent>
      <DialogContentText id="alert-dialog-description">

       <p className="pop-up">
        Confirmer l'envoi de jetons chez tous les clients
      </p>


      </DialogContentText>
     </DialogContent>
     <DialogActions>

      <div className="flex-between" style={{ padding: "0 15px" }}>
       <Button onClick={handleClose}><span className="pop-up" style={{ color: "#006494" }}>Annuler</span></Button>
       <Button onClick={() =>

        [...Array(listClient.length).keys()].map(item => {

         setLoad(true);
         setOpen(false);

         window.setTimeout(async () => {

          let otp = `${Math.floor(1000 + Math.random() * 900000)}`;
          const textmsgCode = `Chere client, presentez ce jeton : ${otp}, au bureau du Cashbeck pour avoir la commission de votre epargne. Ce jeton expire dans 3 jours`;
          const number = `${listClient[item].idphone}`;
          let phonesend = `243${number.slice(-9)}`;

          let config = {
           method: 'get',
           maxBodyLength: Infinity,
           url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${textmsgCode}`,

           headers: {}
          };

          axios.request(config)
           .then((response) => {
            console.log(JSON.stringify(response.data));
           })
           .catch((error) => {
            console.log(error);
           });


          const changeRef = doc(db, "client", `${listClient[item].idphone}`);
          // Set the "capital" field of the city 'DC'
          await updateDoc(changeRef, {
           income: otp,
           incomestatus: true
          });

          if (listClnt[listClnt.length - 1] == listClient[item].idphone) {

           const washingtonRef = doc(db, "muunganomoney", "partner");
           // Set the "capital" field of the city 'DC'
           await updateDoc(washingtonRef, {
            sms: false
           });


           navigation(0);


          }

         }, item * 2000);

        })

       }

        autoFocus><span className="pop-up" style={{ color: "#006494" }}>confirmer</span></Button>

      </div>

     </DialogActions>
    </Dialog>

   </React.Fragment>
  </>
 );
};
