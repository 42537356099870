import './Dash.css';
import ReturnHeAd from './Head/Main';
import AccE from './Access/Main';
import Media from 'react-media';



// Dash Prets 
export default function ReturnDasH() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenLarge />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='grid-heigth-dash-litige'>
  <ReturnHeAd />
  <AccE />
  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div>
  <ReturnHeAd />
  <AccE />
  <div></div>
 </div>
);
