import React from 'react';
import './View.css';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from '../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import Divider from '@mui/material/Divider';
import { CiEdit } from "react-icons/ci";
import IconButton from '@mui/material/IconButton';
import { useForm, Controller } from 'react-hook-form';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Media from 'react-media';



// View Name Component
export default function RetuRnNAme() {

 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {


 const [open, setOpen] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');
 const { control, watch } = useForm({});


 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 }, []);

 const handleClickOpen = () => {
  setOpen(true);
 };

 let str = first
 let result = str.charAt(0).toUpperCase() + str.slice(1)
 let view = watch('text', result);

 const handleClose = () => {
  setOpen(false);
 };
 const handleUpdate = async (value, last) => {

  setLoad(true);
  setOpen(false);

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   firstname: value,
   iduser: (value).toLowerCase() + (last).toLowerCase() + secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")
  });


  window.setTimeout(() => {
   setLoad(false);
  }, 3500)


 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-between-edit'>

    <div className='flex-block'>
     <span>Nom de famille</span>
     <p>{result}</p>
    </div>

    <React.Fragment>
     <IconButton color="primary" aria-label="edit" size="large" onClick={handleClickOpen}>
      <CiEdit size={'.8em'} />
     </IconButton>

     <Dialog open={open} onClose={handleClose}>
      <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>

      <DialogContent>
       <DialogContentText>
        <span style={{ color: 'transparent' }}>
         To subscribe to this website, please enter your email address here. We
       </span>

        <h1 className='title-pop-up'>
         {result} : {view}
        </h1>

       </DialogContentText>


       <FormControl
        sx={{ width: '100%' }}
        variant="standard">

        <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Modifier</span></InputLabel>

        <Controller
         name="text"
         control={control}
         render={({ field }) =>

          <Input
           id="standard-adornment-password"

           inputProps={{
            autoComplete: "off", inputMode: 'text'
           }}
           {...field}
           type={'text'}

          />}
        />

       </FormControl>
      </DialogContent>

      <DialogActions>
       <div className='flex-between-edit'>

        <Button onClick={handleClose}><p className='pop-up'>Annuler</p></Button>
        <Button onClick={() => { handleUpdate(view, last) }}><p className='pop-up'>Mise à jour</p></Button>
       </div>
      </DialogActions>


     </Dialog>
    </React.Fragment>
   </div>

   <Divider />
  </>
 );

};
export const ScreenSmall = () => {


 const [open, setOpen] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');
 const { control, watch } = useForm({});


 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 }, []);

 const handleClickOpen = () => {
  setOpen(true);
 };

 let str = first
 let result = str.charAt(0).toUpperCase() + str.slice(1)
 let view = watch('text', result);

 const handleClose = () => {
  setOpen(false);
 };
 const handleUpdate = async (value, last) => {

  setLoad(true);
  setOpen(false);

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   firstname: value,
   iduser: (value).toLowerCase() + (last).toLowerCase() + secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")
  });


  window.setTimeout(() => {
   setLoad(false);
  }, 3500)


 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-between-edit-sm'>

    <div className='flex-block-sm'>
     <span>Nom de famille</span>
     <p>{result}</p>
    </div>

    <React.Fragment>
     <IconButton color="primary" aria-label="edit" size="large" onClick={handleClickOpen}>
      <CiEdit size={'.8em'} />
     </IconButton>

     <Dialog open={open} onClose={handleClose}>
      <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>

      <DialogContent>
       <DialogContentText>
        <span style={{ color: 'transparent' }}>
         To subscribe to this website, please enter your email address here. We
       </span>

        <h1 className='title-pop-up-sm'>
         {result} : {view}
        </h1>

       </DialogContentText>


       <FormControl
        sx={{ width: '100%' }}
        variant="standard">

        <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Modifier</span></InputLabel>

        <Controller
         name="text"
         control={control}
         render={({ field }) =>

          <Input
           id="standard-adornment-password"
           // autocomplete="new-password"
           inputProps={{
            autoComplete: "off", inputMode: 'text'
           }}
           {...field}
           type={'text'}

          />}
        />

       </FormControl>
      </DialogContent>

      <DialogActions>
       <div className='flex-between-edit-sm'>

        <Button onClick={handleClose}><p className='pop-up'>Annuler</p></Button>
        <Button onClick={() => { handleUpdate(view, last) }}><p className='pop-up'>Mise à jour</p></Button>
       </div>
      </DialogActions>


     </Dialog>
    </React.Fragment>
   </div>

   <Divider />
  </>
 );
};