
import Media from 'react-media';
import TRAnsFeR from './Transfert';
import RetuRnClienT from './Client';
import './Right.css';



// Right 
export default function RetuRnRight() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div></div>
);
export const ScreenSmall = () => (
 <div style={{ display: 'flex', gap: '2em' }}>
  <TRAnsFeR />
  <RetuRnClienT />
 </div>
);
