
import './Title.css';


// Return title 
export default function ReturnTitle() {
 return (
  <div className='flex-between wrp-title-head-progress-global'>
   <h2>Prêt global</h2>
   <div></div>
  </div>
 );
}