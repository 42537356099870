
import ReturnActive from './Active';
import './Msg.css';
import ReturnDay from './Day';
import ReturnFind from './Find';


// Msg Component export 
export default function RetuRnMsG() {
 return (
  <div>
   <ReturnActive />
   <ReturnDay />
   <ReturnFind />
  </div>
 );
};