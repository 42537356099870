import React from 'react';
import './History.css';
import { collection, getDocs } from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import { db } from '../../../../firebase';
import currency from 'currency.js';
import Media from 'react-media';
import moment from 'moment';



export default function RetuRnHistoRy() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export function ScreenLarge() {


 let pushDoc = new Array();
 let pushItm = new Array();
 let pushElm = new Array();
 let pushDate = new Array();

 const [list, setList] = React.useState([]);
 const [item, setItem] = React.useState([]);
 const [itemElm, setItemElm] = React.useState([]);
 const [listdata, setListdata] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbecketablishvalid"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data().client);
   pushItm.push(doc.data().code);

  });

  setList([...new Set(pushDoc.reverse())]);
  setItem(pushItm);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbecketablishvalid"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushElm.push(doc.data());
  });


  setItemElm([...new Set(pushElm)]);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbecketablishvalid"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDate.push(doc.id);
  });

  setListdata([...new Set(pushDate.reverse())]);

 }, []);


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   {listdata.length == 0 ?

    <div></div>

    :
    <div className="scroll-user-list-total-dash">
     <div className="flex-history-share" style={{ gap: '2vh', marginTop: '5vh' }}>

      {[...Array(listdata.length).keys()].map((index) => {
       return (
        <div className="flex-between flex-box-history" key={index}>

         <div className="flex-between">
          <p className="pop-up">{item[index]}</p>
          <p className="pop-up">{moment(listdata[index]).format('LLL')}</p>
         </div>

         {[...Array(list[index].length).keys()].map(item => {

          return (
           <>
            <div className="flex-between" style={{ width: '60vw' }}>
             <div className="center">
              <Avatar
               src={list[index][item].profile}
               sx={{ width: 40, height: 40 }}
              />
              <p>{list[index][item].firstname + ' ' + list[index][item].lastname}</p>
             </div>

             <p>{euro(list[index][item].sharingmoney).format()}  {list[index][item].sharingdevise}</p>

            </div>

           </>

          )
         })}

        </div>
       )
      })}

     </div>
    </div>

   }
  </>
 );
};
export function ScreenSmall() {


 let pushDoc = new Array();
 let pushItm = new Array();
 let pushElm = new Array();
 let pushDate = new Array();

 const [list, setList] = React.useState([]);
 const [item, setItem] = React.useState([]);
 const [itemElm, setItemElm] = React.useState([]);
 const [listdata, setListdata] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbecketablishvalid"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data().client);
   pushItm.push(doc.data().code);

  });

  setList([...new Set(pushDoc.reverse())]);
  setItem(pushItm);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbecketablishvalid"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushElm.push(doc.data());
  });


  setItemElm([...new Set(pushElm)]);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbecketablishvalid"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDate.push(doc.id);
  });

  setListdata([...new Set(pushDate.reverse())]);

 }, []);



 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   {listdata.length == 0 ? <div></div> :

    <div>
     <div className="flex-history-share" style={{ gap: '2vh', marginTop: '5vh' }}>

      {[...Array(listdata.length).keys()].map((index) => {
       return (
        <div className="flex-between flex-box-history" key={index}>

         <div className="flex-between">
          <p className="pop-up">{item[index]}</p>
          <p className="pop-up">{moment(listdata[index]).format('LLL')}</p>
         </div>

         {[...Array(list[index].length).keys()].map(item => {

          return (
           <>
            <div className="flex-between" style={{ width: '70vw' }}>
             <div className="center">
              <Avatar
               src={list[index][item].profile}
               sx={{ width: 35, height: 35 }}
              />
              <p>{list[index][item].firstname + ' ' + list[index][item].lastname}</p>
             </div>

             <p>{euro(list[index][item].sharingmoney).format()}  {list[index][item].sharingdevise}</p>
            </div>

           </>

          )
         })}

        </div>
       )
      })}

     </div>
    </div>

   }
  </>
 );
};