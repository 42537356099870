
import HeAd from './Head/Main';

// Refs Creditaire Dash
export default function ReturnReFs() {
 return (
  <div className='border-right'>
   <HeAd />
  </div>
 );
};