
import './Repay.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';

// repay main
export default function RetuRNRepay() {
 return (
  <div className='wrp-repay-pret border-right'>
   <HeAd />
   <BOdy />
  </div>
 );
};