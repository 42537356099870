import React from 'react';
import { db } from '../../../../../firebase';
import { collection, getDocs, } from "firebase/firestore";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import secureLocalStorage from "react-secure-storage";
import Divider from '@mui/material/Divider';
import './List.css';
import ReturnPrice from './Price';
import RetuRnAvaTaR from './Avatar';
import RetuRnNAme from './Name';


// List Body
export default function RetuRnLisT() {


 let table = secureLocalStorage.getItem("##4483833@!!**$$%%").table;
 let pushItem = new Array();
 const [item, setItem] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushItem.push(doc.data());
  });

  setItem(pushItem);

 }, []);

 const handleClickpath = (item) => {

  secureLocalStorage.setItem("#!!!list@#%%", item);
  secureLocalStorage.setItem("#!&&&me@#%%", secureLocalStorage.getItem("$$!!&++&name^**^##") + item);

 };


 return (
  <div className='list-bd-group-tontine'>
   {!item.length ? <div></div> :

    <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
     <List component="nav" aria-label="main mailbox folders">

      {[...Array(table.length).keys()].map(items => {
       return (
        <div onClick={() => handleClickpath(table[items])}>
         <ListItemButton>
          <>
           <ListItemIcon>
            <RetuRnAvaTaR docsTon={table[items]} />
           </ListItemIcon>

           <ListItemText>
            <RetuRnNAme docsTon={table[items]} />
           </ListItemText>

           <ReturnPrice docsTon={table[items]} />
          </>
         </ListItemButton>

         <div style={{ margin: '1.3vh 0' }}>
          <Divider />
         </div>


        </div>
       );
      })}

     </List>
    </Box>
   }
  </div>
 );
};