
import ReturnTypE from './Type';

// Type Form 
export default function TypE() {
 return (
  <ReturnTypE />
 );
};

