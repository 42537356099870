import React from 'react';
import './Prix.css';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';



// Fran Balance Available
export default function RetuRnFRAN() {

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })

 return (
  <div className='flex-prix-balance-litige-cancel'>
   <p>{euro(secureLocalStorage.getItem("#$$!!list%%#$$").after).format()} CDF</p>

  </div>
 );
};