import './View.css';
import HeAd from './Head/Main';
import BodY from './Body/Main';


// Dash Main
export default function RetuRnViEw() {
 return (
  <div className='wrp-list-main-dash'>
   <HeAd />
   <BodY />
  </div>
 );
};