import './Info.css';
import ReturnTitle from './Title';
import LisT from './List/Main';

// InFo Component 
export default function ReturnInFO() {
 return (
  <div className='wrp-info-info-detail'>
   <ReturnTitle />
   <div className='scroll-user-list-total'>
    <LisT />
   </div>
  </div>
 );
};