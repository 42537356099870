import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';
import secureLocalStorage from "react-secure-storage";


function LinearProgressWithLabel(props) {
 return (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
   <Box sx={{ width: '100%', mr: 1 }}>
    <LinearProgress variant="determinate" {...props} />
   </Box>
   <Box sx={{ minWidth: 35 }}>
    <Typography variant="body2" color="text.secondary">{`${Math.round(
     props.value,
    )}%`}</Typography>
   </Box>
  </Box>
 );
};

LinearProgressWithLabel.propTypes = {
 /**
  * The value of the progress indicator for the determinate and buffer variants.
  * Value between 0 and 100.
  */
 value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel() {

 let start = moment(secureLocalStorage.getItem("##phone&&#$$$[activeday]!!||"));

 let a = moment();
 let b = moment(start);
 let percentage = (a.diff(b, 'days') / secureLocalStorage.getItem("##phone%%@@##[day]")) * 100;
 const [progress, setProgress] = React.useState(percentage);
 window.console.log(percentage);


 return (
  <Box sx={{ width: '100%' }}>
   <LinearProgressWithLabel value={progress} />
  </Box>
 );
};
