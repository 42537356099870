import React from 'react';
// import './Command.css';
import { db } from '../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import Media from 'react-media';


// Command Btn
export default function ReturnCommAnd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='stock-unit-hm-cmd-operator'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='stock-unit-hm-cmd-operator-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {

 const [cmd, setCmd] = React.useState(0);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "stockunitaire"), (doc) => {
   setCmd(doc.data().pack === undefined ? 0 : doc.data().pack);
  });

 }, []);

 return (
  <>
   <p>{cmd}</p>
   <img src={'/img/stock-hm-cmd.png'} />
  </>
 )
};