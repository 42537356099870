import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';

import './Prix.css';
import { db } from '../../../../firebase';

import { collection, getDocs } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './Icon.css';
import TransFeRT from './Transfert/Main';


export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {


 let pushDoc = new Array();
 let pushDocOther = new Array();


 const [item, setItem] = React.useState([]);
 const [other, setOther] = React.useState([]);

 const [open, setOpen] = React.useState(false);
 const [litige, setLitige] = React.useState(false);


 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleLitige = () => {
  setLitige(false);
 };

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "swap" + secureLocalStorage.getItem("#@$$$phone##$")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data().date);
  });

  setItem(pushDoc);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "swap" + secureLocalStorage.getItem("#@$$$phone##$%%")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDocOther.push(doc.data().date);
  });

  setOther(pushDocOther);

 }, []);


 const handleClick = (event) => {

  event.preventDefault();

  if (secureLocalStorage.getItem("###lis&&%%$$$").access || secureLocalStorage.getItem("#$$!!##!!litige$$")) {

   setLitige(true);

  } else {


   if (!!(([...item].includes(secureLocalStorage.getItem("#$$!!date%%$$$"))) && !!([...other].includes(secureLocalStorage.getItem("#$$!!date%%$$$"))))) {
    window.console.log('Hello');
   }

  }


 };



 return (
  <div>
   <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Aucune référence n'est établie sur ses transferts veuillez vérifier la valeur.
      </p>


     </DialogContentText>


    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={litige}
    onClose={handleLitige}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Cette transaction a déjà été utilisée pour
       restaurer la litige, la clé ne peut plus être utilisée
      </p>


     </DialogContentText>


    </DialogContent>
    <DialogActions>
     <Button onClick={handleLitige}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <div
      onClick={toggleDrawer(anchor, secureLocalStorage.getItem("#$$!!##!!litige$$"))}
     // onClick={toggleDrawer(anchor, (secureLocalStorage.getItem("###lis&&%%$$$").access === false && [...item].includes(secureLocalStorage.getItem("#$$!!date%%$$$")) && [...other].includes(secureLocalStorage.getItem("#$$!!date%%$$$"))))}

     >

      <div style={{ cursor: 'pointer' }} onClick={handleClick}>
       <Avatar
        src="/img/transfer-1.png"
        sx={{ width: 40, height: 40 }}
       />
      </div>

     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      PaperProps={{
       sx: { width: 600, backgroundColor: '#dfe0e2' }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <TransFeRT />

     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {


 let pushDoc = new Array();
 let pushDocOther = new Array();


 const [item, setItem] = React.useState([]);
 const [other, setOther] = React.useState([]);

 const [open, setOpen] = React.useState(false);
 const [litige, setLitige] = React.useState(false);


 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleLitige = () => {
  setLitige(false);
 };

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "swap" + secureLocalStorage.getItem("#@$$$phone##$")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data().date);
  });

  setItem(pushDoc);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "swap" + secureLocalStorage.getItem("#@$$$phone##$%%")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDocOther.push(doc.data().date);
  });

  setOther(pushDocOther);

 }, []);

 const handleClick = (event) => {

  event.preventDefault();

  if (secureLocalStorage.getItem("###lis&&%%$$$").access || secureLocalStorage.getItem("#$$!!##!!litige$$")) {

   setLitige(true);

  } else {


   if (!!(([...item].includes(secureLocalStorage.getItem("#$$!!date%%$$$"))) && !!([...other].includes(secureLocalStorage.getItem("#$$!!date%%$$$"))))) {
    window.console.log('Hello');
   }

  }


 };


 return (
  <div>
   <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Aucune référence n'est établie sur ses transferts veuillez vérifier la valeur.
      </p>


     </DialogContentText>


    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <div
      onClick={toggleDrawer(anchor, true)}
     // onClick={toggleDrawer(anchor, ([...item].includes(secureLocalStorage.getItem("#$$!!date%%$$$")) && [...other].includes(secureLocalStorage.getItem("#$$!!date%%$$$"))))}
     >
      <div style={{ cursor: 'pointer' }} onClick={handleClick}>
       <Avatar
        src="/img/transfer-1.png"
        sx={{ width: 40, height: 40 }}
       />
      </div>

     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      PaperProps={{
       sx: { width: 300, backgroundColor: '#dfe0e2' }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <TransFeRT />

     </Drawer>

    </React.Fragment>
   ))}
  </div>

 );
};

