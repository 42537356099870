
import './Form.css';
import HEAd from './Head/Main';
import BOdy from './Body/Main';


// FoRm Component
export default function RetuRNFoRm() {
 return (
  <div className='grid-form-litige'>

   <HEAd />
   <BOdy />
   <h1></h1>

  </div>
 );
};