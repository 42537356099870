import './Solde.css';
import ReturnFRan from './Fran';
import ReturnDollARd from './Dollard';
import './Solde.css';

// Solde Budget 
export default function ReturnSolde() {
 return (
  <div className='bg-blue'>
   <h1 className='flex-dash-solde-budget-creditaire'>Budget créditeur</h1>

   <div className='border-bottom-1 flex-budget-creditaire flex-between'>
    <ReturnFRan />
    <ReturnDollARd />

   </div>
  </div>
 );
};