import EdiT from './Edit/Main';
import LocK from './Lock/Main';
import RemOve from './Remove/Main';
import TransfER from './Transfer/Main';
import InFo from './Info/Main';
import './Icon.css';
import Media from 'react-media';


// Icon View
export default function ReturnIcOn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='between-no-width'>
  {/* <InFo /> */}
  <TransfER />
  <EdiT />
  <RemOve />
  <LocK />
 </div>
);
export const ScreenSmall = () => (
 <div className='between-no-width'>
  <InFo />
  <TransfER />
  <EdiT />
  <RemOve />
  <LocK />
 </div>
);