import React from 'react';
import './Btn.css';
import { TweenMax, Power3 } from 'gsap';
import { HiArrowRight } from "react-icons/hi2";
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';


// Btn View Title 
export default function ReturnBtn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};



export const ScreenLarge = () => (
 <div className='btn-hm-actif-all'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='btn-hm-actif-all-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/muungano/actif/all');
 };
 React.useEffect(() => {
  TweenMax.from('.anime-actif-btn', 1, { delay: 2, opacity: 0, x: -30, ease: Power3.easeInOut })

 }, []);

 return (
  <span onClick={handlepath} className='anime-actif-btn'>
   <HiArrowRight size='1.7em' color='#fff' />
  </span>
 );
};