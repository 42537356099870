import * as React from 'react';
import Avatar from '@mui/material/Avatar';

import { db } from '../../../../../../../firebase';
import { doc, onSnapshot, updateDoc, arrayUnion, serverTimestamp, setDoc, collection } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import currency from 'currency.js';

import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



let key = v4();


const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function RetuRNBtn() {


 const navigation = useNavigate();


 const [soldeMain, setSoldeMain] = React.useState(null);
 const [soldeDollard, setSoldeDollard] = React.useState(null);
 const [thrift, setThrift] = React.useState(null);
 const [thriftcdf, setThriftcdf] = React.useState(null);

 const [open, setOpen] = React.useState(false);
 const [error, setError] = React.useState(false);
 const [confirm, setConfirm] = React.useState(false);

 const [first, setFirst] = React.useState(null);
 const [last, setLast] = React.useState(null);

 const [profil, setProfil] = React.useState(null);


 // ======================

 const [getsoldeMain, setGetsoldeMain] = React.useState(null);
 const [getsoldeDollard, setGetsoldeDollard] = React.useState(null);

 const [getfirst, setGetfirst] = React.useState(null);
 const [getlast, setGetlast] = React.useState(null);
 const [getprofil, setGetProfil] = React.useState(null);


 const [getthrift, setGetthrift] = React.useState(null);
 const [getthriftcdf, setGetthriftcdf] = React.useState(null);

 const [opened, setOpened] = React.useState(false);


 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }
  setOpened(false);
 };


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, !!secureLocalStorage.getItem("##**$$cols#$$") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$%%")), (doc) => {
   setSoldeMain(doc.data().cdf);
   setSoldeDollard(doc.data().usd);

   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);
   setProfil(doc.data().profile);

   setThrift(doc.data().thriftusd);
   setThriftcdf(doc.data().thriftcdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$")), (doc) => {
   setGetsoldeMain(doc.data().cdf);
   setGetsoldeDollard(doc.data().usd);

   setGetfirst(doc.data().firstname);
   setGetlast(doc.data().lastname);
   setGetProfil(doc.data().profile);
   setGetthrift(doc.data().thriftusd);
   setGetthriftcdf(doc.data().thriftcdf);

  });

 }, []);



 const handleClose = () => {
  setOpen(false);
 };
 const handleError = () => {
  setError(false);
 };
 const handleConfirm = () => {
  setConfirm(true);
 };
 const handleConfirmCancel = () => {
  setConfirm(false);
 };


 const sendPhone = secureLocalStorage.getItem("#@$$$phone##$%%");
 const getPhone = secureLocalStorage.getItem("#@$$$phone##$");
 const unite = secureLocalStorage.getItem("#$$!!devise%%$$$");
 const money = secureLocalStorage.getItem("#$$!!money%%$$$");

 const getUser = (getfirst + ' ' + getlast).toLowerCase();
 const sendUser = (first + ' ' + last).toLowerCase();


 // Number(getthriftcdf) - Number(secureLocalStorage.getItem("###comms&&%%$$$"))

 window.console.log(Number(secureLocalStorage.getItem("###comms&&%%$$$")) + ' ' + getthriftcdf)


 const handleTransfer = async () => {

  if (soldeMain === null || soldeMain === undefined || soldeDollard === null || soldeDollard === undefined || first === null || first === undefined || getsoldeMain === null || getsoldeMain === undefined || getsoldeDollard === null || getsoldeDollard === undefined || getfirst === null || getfirst === undefined || getlast === null || getlast === undefined) {

   navigation(0);
   setConfirm(false);

  } else {

   if (secureLocalStorage.getItem("#$$!!devise%%$$$") === 'USD') {

    if (soldeDollard - money > 1) {

     setOpened(true);
     setConfirm(false);

     if (secureLocalStorage.getItem("##**") === 'agent') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeDollard - money).format(), actual: euro(soldeDollard - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeDollard + money).format(), actual: euro(getsoldeDollard + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "agent", sendPhone);
      await updateDoc(sendRef, {
       usd: Number(soldeDollard - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeDollard))}${parseInt(Number(soldeDollard) - Number(money))}${key}`,
       signatureusdtype: 'envoyer',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,

       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "agent", getPhone);
      await updateDoc(getRef, {
       usd: Number(getsoldeDollard) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeDollard))}${parseInt(Number(getsoldeDollard) + Number(money))}${key}`,
       signatureusdtype: 'reussi',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeDollard,
       after: soldeDollard - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,

       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       access: true,

       soldebefore: getsoldeDollard,
       soldeafter: getsoldeDollard + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeDollard,
       after: getsoldeDollard + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,
       access: true,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: getthrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: soldeDollard,
       soldeafter: soldeDollard - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });



      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     }
     else if (secureLocalStorage.getItem("##**") === 'client') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeDollard - money).format(), actual: euro(soldeDollard - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeDollard + money).format(), actual: euro(getsoldeDollard + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "client", sendPhone);
      await updateDoc(sendRef, {
       usd: Number(soldeDollard - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeDollard))}${parseInt(Number(soldeDollard) - Number(money))}${key}`,
       signatureusdtype: 'envoyer',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,

       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "client", getPhone);
      await updateDoc(getRef, {
       usd: Number(getsoldeDollard) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeDollard))}${parseInt(Number(getsoldeDollard) + Number(money))}${key}`,
       signatureusdtype: 'reussi',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeDollard,
       after: soldeDollard - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,

       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       access: true,
       devise: unite,

       soldebefore: getsoldeDollard,
       soldeafter: getsoldeDollard + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeDollard,
       after: getsoldeDollard + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,
       access: true,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: getthrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: soldeDollard,
       soldeafter: soldeDollard - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });



      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });




      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     }
     else if (secureLocalStorage.getItem("##**") === 'agentclient') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeDollard - money).format(), actual: euro(soldeDollard - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeDollard + money).format(), actual: euro(getsoldeDollard + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "client", sendPhone);
      await updateDoc(sendRef, {
       usd: Number(soldeDollard - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeDollard))}${parseInt(Number(soldeDollard) - Number(money))}${key}`,
       signatureusdtype: 'envoyer',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,

       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "agent", getPhone);
      await updateDoc(getRef, {
       usd: Number(getsoldeDollard) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeDollard))}${parseInt(Number(getsoldeDollard) + Number(money))}${key}`,
       signatureusdtype: 'reussi',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,
       swap: arrayUnion(get),
       frais: 0,
       thriftusd: Number(getthrift) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),

       commission: Number(0),
       sendtype: 'Reçu'
      });


      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeDollard,
       after: soldeDollard - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,
       accesslitige: false,
       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: getsoldeDollard,
       soldeafter: getsoldeDollard + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeDollard,
       after: getsoldeDollard + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: Number(getthrift) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,
       accesslitige: false,

       soldebefore: soldeDollard,
       soldeafter: soldeDollard - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });


      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });


      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     }
     else if (secureLocalStorage.getItem("##**") === 'clientagent') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeDollard - money).format(), actual: euro(soldeDollard - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeDollard + money).format(), actual: euro(getsoldeDollard + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "agent", sendPhone);
      await updateDoc(sendRef, {
       usd: Number(soldeDollard - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signatureusd: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeDollard))}${parseInt(Number(soldeDollard) - Number(money))}${key}`,
       signatureusdtype: 'envoyer',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,

       thriftusd: Number(getthrift) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "client", getPhone);
      await updateDoc(getRef, {
       usd: Number(getsoldeDollard) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signatureusd: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeDollard))}${parseInt(Number(getsoldeDollard) + Number(money))}${key}`,
       signatureusdtype: 'reussi',
       signaturemoneyusd: Number(money),
       signaturekeyusd: key,
       signaturefraisusd: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeDollard,
       after: soldeDollard - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,

       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       access: true,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: getsoldeDollard,
       soldeafter: getsoldeDollard + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeDollard,
       after: getsoldeDollard + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: Number(getthrift) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       commission: 0,
       access: true,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: soldeDollard,
       soldeafter: soldeDollard - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });


      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });



      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     } else {
      navigation(0);
     }


    } else {
     setError(true);
     setConfirm(false);
    }

   } else if (secureLocalStorage.getItem("#$$!!devise%%$$$") === 'CDF') {

    if (soldeMain - money > 2000) {

     setOpened(true);
     setConfirm(false);

     if (secureLocalStorage.getItem("##**") === 'agent') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeMain - money).format(), actual: euro(soldeMain - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeMain + money).format(), actual: euro(getsoldeMain + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "agent", sendPhone);
      await updateDoc(sendRef, {
       cdf: Number(soldeMain - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeMain))}${parseInt(Number(soldeMain) - Number(money))}${key}`,
       signaturecdftype: 'envoyer',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,

       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "agent", getPhone);
      await updateDoc(getRef, {
       cdf: Number(getsoldeMain) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeMain))}${parseInt(Number(getsoldeMain) + Number(money))}${key}`,
       signaturecdftype: 'reussi',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeMain,
       after: soldeMain - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       access: true,
       user: getUser,

       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: getsoldeMain,
       soldeafter: getsoldeMain + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeMain,
       after: getsoldeMain + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,
       access: true,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: getthrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: soldeMain,
       soldeafter: soldeMain - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });


      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });

      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation('/dash');

     }
     else if (secureLocalStorage.getItem("##**") === 'client') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeMain - money).format(), actual: euro(soldeMain - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeMain + money).format(), actual: euro(getsoldeMain + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "client", sendPhone);
      await updateDoc(sendRef, {
       cdf: Number(soldeMain - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeMain))}${parseInt(Number(soldeMain) - Number(money))}${key}`,
       signaturecdftype: 'envoyer',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,

       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "client", getPhone);
      await updateDoc(getRef, {
       cdf: Number(getsoldeMain) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeMain))}${parseInt(Number(getsoldeMain) + Number(money))}${key}`,
       signaturecdftype: 'reussi',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeMain,
       after: soldeMain - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,

       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,
       access: true,

       soldebefore: getsoldeMain,
       soldeafter: getsoldeMain + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeMain,
       after: getsoldeMain + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: getthrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,
       access: true,

       soldebefore: soldeMain,
       soldeafter: soldeMain - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });


      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });




      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     }
     else if (secureLocalStorage.getItem("##**") === 'agentclient') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeMain - money).format(), actual: euro(soldeMain - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeMain + money).format(), actual: euro(getsoldeMain + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "client", sendPhone);
      await updateDoc(sendRef, {
       cdf: Number(soldeMain - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeMain))}${parseInt(Number(soldeMain) - Number(money))}${key}`,
       signaturecdftype: 'envoyer',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,

       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "agent", getPhone);
      await updateDoc(getRef, {
       cdf: Number(getsoldeMain) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeMain))}${parseInt(Number(getsoldeMain) + Number(money))}${key}`,
       signaturecdftype: 'reussi',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       thriftcdf: Number(getthriftcdf) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeMain,
       after: soldeMain - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,
       access: true,

       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: getsoldeMain,
       soldeafter: getsoldeMain + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeMain,
       after: getsoldeMain + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),

       phone: sendPhone,
       user: sendUser,
       access: true,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: Number(getthriftcdf) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: soldeMain,
       soldeafter: soldeMain - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });


      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });

      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     }
     else if (secureLocalStorage.getItem("##**") === 'clientagent') {

      var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

      let time = moment().format('LLL');
      let send = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: getPhone, user: getUser, type: 'envoyer', price: euro(soldeMain - money).format(), actual: euro(soldeMain - money).format() + ' ' + unite, unite: unite }
      let get = { date: time, solde: `${euro(money).format()} ${unite} [rétablissement litige]`, phone: sendPhone, user: sendUser, type: 'Reçu', pricenew: euro(getsoldeMain + money).format(), actual: euro(getsoldeMain + money).format() + ' ' + unite, unite: unite }

      const sendRef = doc(db, "agent", sendPhone);
      await updateDoc(sendRef, {
       cdf: Number(soldeMain - money),
       money: Number(money),
       user: getUser,
       unite: unite,
       phoneclient: getPhone,
       delay: moment().format(),
       swap: arrayUnion(send),
       commission: Number(0),

       signaturecdf: `${sendPhone}${parseInt(Number(money))}${parseInt(Number(soldeMain))}${parseInt(Number(soldeMain) - Number(money))}${key}`,
       signaturecdftype: 'envoyer',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,
       thriftcdf: Number(getthriftcdf) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       frais: 0,
       sendtype: 'envoyer',

      });

      const getRef = doc(db, "client", getPhone);
      await updateDoc(getRef, {
       cdf: Number(getsoldeMain) + Number(money),
       money: Number(money),
       user: sendUser,
       unite: unite,
       phoneclient: sendPhone,
       delay: moment().format(),

       signaturecdf: `${getPhone}${parseInt(Number(money))}${parseInt(Number(getsoldeMain))}${parseInt(Number(getsoldeMain) + Number(money))}${key}`,
       signaturecdftype: 'reussi',
       signaturemoneycdf: Number(money),
       signaturekeycdf: key,
       signaturefraiscdf: 0,
       swap: arrayUnion(get),
       frais: 0,
       commission: Number(0),
       sendtype: 'Reçu'
      });

      // Add a new document with a generated id
      const newCityRef = doc(collection(db, "swap" + sendPhone));
      // later...
      await setDoc(newCityRef, {

       action: `rétablissement litige`,
       money: money,
       before: soldeMain,
       after: soldeMain - money,
       type: 'envoyer',
       date: moment().format(),
       delay: moment().format(),

       phone: getPhone,
       user: getUser,
       access: true,
       profile: profil,
       beforethrift: thrift,
       afterthrift: thrift,
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: getsoldeMain,
       soldeafter: getsoldeMain + money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetrans: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });

      // Add a new document with a generated id
      const newRef = doc(collection(db, "swap" + getPhone));
      // later...
      await setDoc(newRef, {

       action: `rétablissement litige`,
       money: money,
       before: getsoldeMain,
       after: getsoldeMain + money,
       type: 'Reçu',
       date: moment().format(),
       delay: moment().format(),
       access: true,
       phone: sendPhone,
       user: sendUser,

       profile: getprofil,
       beforethrift: getthrift,
       afterthrift: Number(getthriftcdf) - Number(secureLocalStorage.getItem("###comms&&%%$$$")),
       commission: 0,
       frais: 0,
       key: key,
       devise: unite,

       soldebefore: soldeMain,
       soldeafter: soldeMain - money,

       color: '#d7abd6',
       dateofcreate: serverTimestamp(),

       statetransed: true,
       statepret: false,
       stateunit: false,
       statetontine: false,
       statetv: false,
       statesolaire: false,

      });



      const senddocRef = doc(db, "swap" + sendPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(senddocRef, {
       access: true
      });

      const getdocRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("###lis&&%%$$$").key);
      // Set the "capital" field of the city 'DC'
      await updateDoc(getdocRef, {
       access: true
      });



      secureLocalStorage.setItem("#$$!!money%%$$$", 0);
      navigation(0);

     } else {
      navigation(0);
     }

    } else {
     setError(true);
     setConfirm(false);
    }

   } else {
    window.console.log('Hello');
   }

  }

 };

 return (
  <>
   <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
    <Alert onClose={handleClosed} severity="success" sx={{ width: '100%' }}>
     <p className='cashbeck-text'>
      Le litige vient d'être restaurée
     </p>
    </Alert>
   </Snackbar>


   <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Aucune référence n'est établie sur ses transferts veuillez vérifier la valeur.
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={error}
    onClose={handleError}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Le transfert n'est peut-être effectué car
       la balance du réducteur s'avère être insuffisante pour établir ce litige
      </p>

     </DialogContentText>


    </DialogContent>
    <DialogActions>
     <Button onClick={handleError}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={confirm}
    onClose={handleConfirmCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>

    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Voulez-vous confirmer cette transactions pour rétablir l'ordre sur la litige établi,
       cliquez sur confirmer
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>

     <div className='flex-between'>
      <Button onClick={handleConfirmCancel}><p className='cashbeck'>Annuler</p></Button>
      <Button onClick={handleTransfer}><p className='cashbeck'>Confirmer</p></Button>
     </div>

    </DialogActions>
   </Dialog>


   {parseInt(money) > 0 &&
    <div onClick={handleConfirm}>
     <Stack style={{ cursor: 'pointer' }} direction="row" spacing={2}>
      <Avatar
       src="/img/transfer-x.png"
       sx={{ width: 56, height: 56 }}
      />
     </Stack>
    </div>
   }
  </>
 );
};
