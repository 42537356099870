import * as React from 'react';
import Media from 'react-media';
// import { CiMenuFries } from "react-icons/ci";

// import { doc, onSnapshot } from "firebase/firestore";
// import { db } from '../../../../../../firebase';

// import Stack from '@mui/material/Stack';
// import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// import currency from 'currency.js';
// import moment from 'moment';


import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';


const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// Operator Head Component
export default function ReturnOperator() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='stock-nav-operator'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='stock-nav-operator-sm'>
  <ViEw />

 </div>
);

export const ViEw = () => {
 const handlepath = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("##!!%airtel%$$$return", false);
  secureLocalStorage.setItem("##!!%africell%$$$return", true);

  secureLocalStorage.setItem("#$#@path((%#@Network!!@", true);

  window.setTimeout(() => {
   window.location.href = '/unit/stock/throw/africell';
  }, 560);

 };

 return (
  <Avatar
   onClick={handlepath}
   src="/img/airtel.png"
   sx={{ width: 45, height: 45 }}
   variant="rounded"
  />



 )
};

