import './Wait.css';
import HeAd from './Head/Main';
import BoDy from './Body/Main';


// Wait
export default function ReturnWAiT() {
 return (
  <div className='scroll-user-list-tontine'>

   <div className='pading-2'>
    <HeAd />
    <BoDy />
   </div>
  </div>
 );
};