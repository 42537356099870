import './Side.css';
import RetuRnIMA from './IMA';

// Side Pin Info
export default function RetuRnSide() {
 return (
  <div className='wrp-side-info'>
   <RetuRnIMA />
  </div>
 );
};