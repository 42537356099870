import './Personal.css';
import ReturnInput from './Input';

// Personal Main
export default function ReturnTwO() {
 return (
  <div className='flex-litige-personal'>
   <ReturnInput />
  </div>
 );
};