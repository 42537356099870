import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';
import secureLocalStorage from "react-secure-storage";
import ReturnIcOn from './Icon';
import LiST from '../../../../List/Main';


export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const toggleDrawer = (anchor, open) => (event) => {

  secureLocalStorage.setItem("##$devise&&^^##", true);
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open || secureLocalStorage.getItem("#@@^^check%%%$$") });

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <ReturnIcOn />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      PaperProps={{
       sx: { width: 600 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <LiST />


     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const toggleDrawer = (anchor, open) => (event) => {

  secureLocalStorage.setItem("##$devise&&^^##", true);
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open || secureLocalStorage.getItem("#@@^^check%%%$$") });

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <ReturnIcOn />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <LiST />

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};

