import React from 'react';
import Media from 'react-media';
import EtaBlish from './Etablish/Main';
import './Body.css';
import Avatar from '@mui/material/Avatar';
import RetuRnDrawer from './Etablish/History/Drawer';



// Body Component Share
export default function RetuRnShreBody() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div>
  <div className="flex">
   <Avatar src="/img/school-2.png" variant="rounded" />
   <p className="pop-up" style={{ textTransform: 'uppercase', marginTop: '3vh' }}>établissement</p>


  </div>
  <EtaBlish />
 </div>
);
export const ScreenSmall = () => (
 <>
  <div className="flex-between">
   <div className="flex">
    <Avatar src="/img/school-2.png" variant="rounded" />
    <p className="pop-up" style={{ textTransform: 'uppercase', marginTop: '3vh' }}>établissement</p>
   </div>


   <div
    style={{ paddingRight: '1.3em' }}

   >
    <RetuRnDrawer />
   </div>

  </div>

  <EtaBlish />

 </>
);