import React from 'react';
import moment from 'moment';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../../firebase';
import secureLocalStorage from "react-secure-storage"


// Active profil
export default function ReturnActive() {

 const [item, setItem] = React.useState('');
 React.useEffect(async () => {

  try {

   const unsub = onSnapshot(doc(db, "pret", secureLocalStorage.getItem("##phone&&#$$$[]")), (doc) => {
    setItem(doc.data().id);
   });

  } catch (e) {
   window.console.log(e)
  }



 }, []);

 return (
  <div className='flex-between flex-info-msg-pret'>
   <p>Activer : </p>
   <p>{moment(item).format('LL')}</p>
  </div>
 );
};