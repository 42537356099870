import React from 'react';
import './Throw.css';
import ReturnBtn from './Btn';
import BacK from './Nav/Main';


// Throw Main
export default function ReturnThRoW() {

 return (
  <>
   <BacK />
   <div className='unit-stock-throw'>
    <ReturnBtn />
   </div>
  </>
 );
};

