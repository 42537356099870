import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { doc, onSnapshot, getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import secureLocalStorage from "react-secure-storage";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Pin.css';



const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// View Form Update view
export default function RetuRnPIn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-form-input-pin-nows'>
  <FormInputField />
 </div>
);
export const ScreenSmall = () => (
 <div className='wrp-form-input-pin-nows-sm'>
  <FormInputField />
 </div>
);

export const FormInputField = () => {


 const navigation = useNavigate();

 let pushDoc = new Array();
 let pushTon = new Array();


 const [open, setOpen] = React.useState(false);
 const [empty, setEmpty] = React.useState(false);

 const [pin, setPin] = React.useState('');
 const [opend, setOpend] = React.useState(false);
 const [loading, setLoading] = React.useState(false);
 const { handleSubmit, reset, control } = useForm();
 const [showPassword, setShowPassword] = React.useState(false);

 const [listed, setListed] = React.useState([]);
 const [ton, setTon] = React.useState([]);


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpend(false);
 };

 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleEmpty = () => {
  setEmpty(false);
 };


 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "money"), (doc) => {
   setPin(doc.data().code);

  });

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "tontine"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
  });

  setListed(pushDoc);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, secureLocalStorage.getItem("$#sour#ton%%")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushTon.push(doc.data());
  });

  setTon(pushTon);

 }, []);


 const onSubmit = async (data) => {

  secureLocalStorage.setItem("$$!!&&number^**^", 0);
  secureLocalStorage.setItem("##ton@@XX", ton);

  if (data.code === undefined) {
   setOpen(true);
  } else {

   if (pin != data.code) {
    setOpen(true);
    reset();

   } else {

    if (pin != data.code) {
     setOpen(true);
     reset();
    } else {


     if (listed?.length) {

      setLoading(true);
      secureLocalStorage.setItem("##!!@@check#&&pin@#**#", true);
      secureLocalStorage.setItem("@#%next&&*@@#!!user++", "transfer");
      secureLocalStorage.setItem("##4483833@!!**$$%%", listed[0]);
      secureLocalStorage.setItem("##id@!!group$$%%", listed[0].idgroup);
      secureLocalStorage.setItem("$#sour#ton%%", secureLocalStorage.getItem("##4483833@!!**$$%%").namegroup + secureLocalStorage.getItem("##4483833@!!**$$%%").table[0]);

      secureLocalStorage.setItem("#$$@!!color$$%%", listed[0].color);
      secureLocalStorage.setItem("##id@##postion$$%%", 0);

      window.setTimeout(() => {
       window.location.href = '/user/tontine';
      }, 4500);


     } else {

      setEmpty(true);

     }


    }

   };

  }

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={loading}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <Snackbar open={opend} autoHideDuration={6000} onClose={handleClosed}>
    <Alert onClose={handleClosed} severity="success" sx={{ width: '100%' }}>
     <p className='pop-up'>
      Utilisateur Activer
    </p>
    </Alert>
   </Snackbar>

   <form onSubmit={handleSubmit(onSubmit)}>
    <FormControl
     sx={{ width: '100%' }}

     variant="standard">
     <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin</span></InputLabel>

     <Controller
      name="code"
      control={control}
      render={({ field }) =>

       <Input
        id="standard-adornment-password"
        autocomplete="new-password"
        inputProps={{
         autoComplete: "off", inputMode: 'numeric'
        }}
        {...field}
        type={showPassword ? 'text' : 'password'}

        endAdornment={
         <InputAdornment position="end">

          <IconButton
           aria-label="toggle password visibility"
           onClick={handleClickShowPassword}
           onMouseDown={handleMouseDownPassword}
          >
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>

         </InputAdornment>
        }

       />}
     />

    </FormControl>


    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}>

     <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
        PIN, Incorrect
     </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>


    <button className='Btn'>Connexion</button>
   </form>


   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={empty}
    onClose={handleEmpty}>

    <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
    <DialogContent>

     <DialogContentText>
      <p className='pop-up'>
       Aucun groupe tontine n'est créé
     </p>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleEmpty}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>


  </>
 );
};
