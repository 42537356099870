
import './Quote.css';
import ReturnAvataR from './Avatar';
import ReturnPiN from './Pin';


// Quote Component Authority
export default function ReturnQuOTe() {
 return (
  <div className='stock-autority-quote'>
   <ReturnAvataR />

   <p>Signifie votre autorité</p>
   <ReturnPiN />

  </div>
 );
}