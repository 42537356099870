import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';
import secureLocalStorage from "react-secure-storage";
import ReFs from '../Refs/Main';
import Avatar from '@mui/material/Avatar';



export default function RetuRnTransfer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const drawerStyle = { backgroundColor: '#494' }

 const toggleDrawer = (anchor, open) => (event) => {

  secureLocalStorage.setItem("##$devise&&^^##", true);
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Avatar sx={{ width: 40, height: 40 }} variant="rounded" src="/notebook-credit.png" />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      style={drawerStyle}
      PaperProps={{
       sx: { width: 600 }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <ReFs />
     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const toggleDrawer = (anchor, open) => (event) => {

  secureLocalStorage.setItem("##$devise&&^^##", true);
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Avatar sx={{ width: 40, height: 40 }} variant="rounded" src="/img/notebook-credit.png" />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}
      PaperProps={{
       sx: { width: 300, backgroundColor: '#DDDEDA' }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <ReFs />

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};

