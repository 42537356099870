import './Update.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';


// Update Edit
export default function ReturnUpdAte() {
 return (
  <div className='wrp-update-edit-detail-user'>
   <HeAd />
   <BOdy />
   <div></div>
  </div>
 );
};