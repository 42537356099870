import './Group.css';
import HeAd from './Head/Main';
import BodY from './Body/Main';
import Media from 'react-media';


// Group Main
export default function RetuRnGROup() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div>
  <HeAd />
  <BodY />
 </div>
);
export const ScreenSmall = () => (
 <div></div>
);


