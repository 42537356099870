import React from 'react';
import './Count.css';
import { db } from '../../../../firebase';
import { collection, getDocs } from "firebase/firestore";


// Count group Tontine
export default function RetuRnCouNT() {

 let pushDoc = new Array();
 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "tontine"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);

  });

  setList(pushDoc);

 }, []);

 return (
  <div className='flex-between wrp-count-head-tontine-total'>

   <p>Total du groupe</p>
   <p>{list.length}</p>

  </div>
 );
};