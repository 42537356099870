import React from 'react';
import { db } from '../../../../../../firebase';
import { collection, getDocs, doc } from "firebase/firestore";
import moment from 'moment';


// View pack live
export default function ReturnIncreaseCount() {

 const currentDate = moment();
 let pushDoc = new Array();
 let pushCount = new Array();

 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
  });

  setList([...new Set(pushDoc.reverse())]);

 }, []);


 list.map(index => {

  if (currentDate.isSame(moment(index.date).format('YYYY-MM-DD'), 'day')) {
   pushCount.push(index);
  }

 })


 return (
  <div>
   <div className='flex-price-increase'>
    <p>{pushCount.length}</p>
   </div>
  </div>
 );
};