import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import './Phone.css';

// Avatar view 
export default function RetuRnPHone() {

 const [item, setItem] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().idphone);
  });
 }, []);

 return (
  <div className='flex-id-phone'>
   <h3>ID : {item}</h3>
  </div>
 );
};