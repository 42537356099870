
import './Title.css';

// ActiF user total
export default function RetuRnTitle() {
 return (
  <div className='title-actif-info'>
   <h1>Les Actifs</h1>
  </div>
 );
};
