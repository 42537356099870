import React from 'react';
import './Prix.css';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';


// Fran Balance Available
export default function RetuRnFrAn() {

 const [item, setItem] = React.useState(null);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$")), (doc) => {
   setItem(doc.data().cdf);
  });

 }, []);


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })

 return (
  <div className='flex-prix-balance-litige'>
   <p>{euro(item).format()} CDF</p>
  </div>
 );
};