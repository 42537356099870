import ReturnHeAd from './Head/Main';
import ProgrEss from './Progress/Main';
import RePay from './Repay/Main';
import Media from 'react-media';
import WAiT from './Wait/Main';



// Dash Prets 
export default function ReturnDasH() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='grid-template-tontine'>
  <ReturnHeAd />
  <WAiT />
  <RePay />
  <ProgrEss />
 </div>
);
export const ScreenSmall = () => (
 <div className='grid-template-tontine'>
  <ReturnHeAd />
  <WAiT />
 </div>
);