import RetuRnTitle from './Title';
import './Head.css';
import Media from 'react-media';
import DeVise from './Devise/Main';


// View Head 
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};


export const SreenLarge = () => (
 <div className='flex-between flex-border-bottom pad-left-right-3'>
  <RetuRnTitle />
  <div></div>
  {/* <DeVise /> */}
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-between flex-border-bottom pad-left-right-3'>
  <RetuRnTitle />
  <DeVise />
 </div>
);