import * as React from 'react';
import Media from 'react-media';
import secureLocalStorage from "react-secure-storage";
import MuiAlert from '@mui/material/Alert';
import './Operator.css';
import Avatar from '@mui/material/Avatar';


const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// Operator Head Component
export default function ReturnOperator() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='stock-nav-operator-africell'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='stock-nav-operator-africell-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {

 const handlepath = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("##!!%africell%$$$return", false);
  secureLocalStorage.setItem("##!!%orange%$$$return", true);

  window.setTimeout(() => {
   window.location.href = '/unit/stock/throw/orange';
  }, 560);

 };

 return (
  <Avatar
   onClick={handlepath}
   src="/img/africell.png"
   sx={{ width: 45, height: 45, cursor: 'pointer' }}
   variant="rounded"
  />


 )
};
