
import ReturnInput from './Input';

// Form Input
export default function FoRm() {
 return (
  <div className='grid-center'>
   <ReturnInput />
  </div>
 );
};