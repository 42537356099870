import React from 'react';
import './Data.css';
import { db } from '../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import moment from 'moment';


// january data component 
export default function ReturnJanuary() {

 const [cdf, setCdf] = React.useState(0);
 const [usd, setUsd] = React.useState(0);

 const [unitusd, setUnitusd] = React.useState(0);
 const [unitcdf, setUnitcdf] = React.useState(0);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganounitdatamonth", "january"), (doc) => {
   setCdf(doc.data().moneycdf);
   setUsd(doc.data().moneyusd);

   setUnitcdf(doc.data().unitcdf);
   setUnitusd(doc.data().unitusd);

  });

 }, []);



 return (
  <div className='box-unit-list-month'>
   <h3>Janvier</h3>

   <div style={{ color: `${(moment().month() + 1) != 1 && '#eeeeee'}` }} className='wrp-unit-list-month'>
    <p>{usd}$ / {unitusd}U</p>
    <p>{cdf}F / {unitcdf}U</p>
   </div>

  </div>
 );
};