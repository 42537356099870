import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import './IMA.css';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";



export default function RetuRnProFil() {

 const [item, setItem] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##$%%!!**++!!status@@"), secureLocalStorage.getItem("##$%%!!**++!!num@@")), (doc) => {
   setItem(doc.data().profile);
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);
  });

 }, []);

 return (
  <div className='block-center block-gap-1 flex-avatar-user-profil-transfert'>

   <Avatar
    src={item}
    sx={{ width: 105, height: 105 }}
   />

   <p>{first.toLowerCase()} {last.toLowerCase()}</p>
  </div>
 );
};
