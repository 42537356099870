import React from 'react';
import { db } from '../../../../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import moment from 'moment';
import currency from 'currency.js';



// View pack live
export default function ReturnIncreaseFran() {

 const currentDate = moment();
 let pushFran = new Array();
 let pushUnit = new Array();
 let pushDoc = new Array();

 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "muunganounitairtel"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());

  });

  setList([...new Set(pushDoc.reverse())]);;

 }, []);

 // found object
 list.map((index) => {
  if (index.devise === 'CDF' && currentDate.isSame(moment(index.date).format('YYYY-MM-DD'), 'day')) {
   pushFran.push(index.price)
   pushUnit.push(index.count)
  }

 })

 window.console.log(list);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div>
   <div className='flex-price-increase'>
    <p>{euro(pushFran.reduce((acc, currentValue) => acc + currentValue, 0)).format()} CDF</p>
    <span>{pushUnit.reduce((acc, currentValue) => acc + currentValue, 0)} Unité</span>
   </div>
  </div>
 );
};