import React from 'react';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { doc, onSnapshot, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import secureLocalStorage from "react-secure-storage";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Pin.css';
import moment from 'moment';



const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


let date1 = moment().format('YYYY-MM-DD');
let date2 = moment(secureLocalStorage.getItem("##$$time##$$@@")).format('YYYY-MM-DD');


// View Form Update view
export default function RetuRnPIn() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <ScreenLarge />}
					{matches.large && <ScreenLarge />}
				</>
			)}
		</Media>
	);
};

export const ScreenLarge = () => (
	<div className='wrp-form-input-pin-nows'>
		<FormInputField />
	</div>
);
export const ScreenSmall = () => (
	<div className='wrp-form-input-pin-nows-sm'>
		<FormInputField />
	</div>
);


export const FormInputField = () => {

	let pushDoc = new Array();
	const navigation = useNavigate();

	const [open, setOpen] = React.useState(false);
	const [pin, setPin] = React.useState('');
	const [opend, setOpend] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const { handleSubmit, reset, control } = useForm();
	const [list, setList] = React.useState([]);
	const [showPassword, setShowPassword] = React.useState(false);


	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');

	const handleClosed = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpend(false);
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleClose = () => {
		setOpen(false);
	};

	React.useEffect(async () => {

		const unsub = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
			setPin(doc.data().creditaire);
		});

	}, []);
	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, "agent"));
		querySnapshot.forEach((doc) => {

			pushDoc.push(doc.id);
			// doc.data() is never undefined for query doc snapshots

		});

		setList(pushDoc);

	}, []);


	window.console.log(moment(date1).isSame(date2, 'd'))


	const onSubmit = async (data) => {

		if (data.code === undefined) {
			setOpen(true);
		} else {

			if (pin != data.code) {
				setOpen(true);
				reset();

			} else {
				if (pin != data.code) {
					setOpen(true);
					reset();
				} else {

					if (moment(date1).isSame(moment(date2))) {

						window.console.log('The specific date is today !');
						setLoading(true);
						window.setTimeout(() => {
							window.location.href = '/creditaire/dash';
						}, 4500);



					} else if (moment(date1).isBefore(moment(date2))) {

						updateTochangedate((moment(date1)).toString());
						updateToMoney();

						setLoading(true);
						window.setTimeout(() => {
							window.location.href = '/creditaire/dash';
						}, 4500);

						window.console.log('The specific date is future !');
					} else {

						updateTochangedate((moment(date1)).toString());
						updateToMoney();

						setLoading(true);
						window.setTimeout(() => {
							window.location.href = '/creditaire/dash';
						}, 4500);


						window.console.log('The specific date is in the past !');

					}

				}

			};
		}

	};



	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<Snackbar open={opend} autoHideDuration={6000} onClose={handleClosed}>
				<Alert onClose={handleClosed} severity="success" sx={{ width: '100%' }}>
					<p className='pop-up'>
						Utilisateur Activer
    </p>
				</Alert>
			</Snackbar>

			<form onSubmit={handleSubmit(onSubmit)}>
				<FormControl
					sx={{ width: '100%' }}

					variant="standard">
					<InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Pin</span></InputLabel>

					<Controller
						name="code"
						control={control}
						render={({ field }) =>

							<Input
								id="standard-adornment-password"
								autocomplete="new-password"
								inputProps={{
									autoComplete: "off", inputMode: 'numeric'
								}}
								{...field}
								type={showPassword ? 'text' : 'password'}

								endAdornment={
									<InputAdornment position="end">

										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>

									</InputAdornment>
								}

							/>}
					/>

				</FormControl>

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}>

					<DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>
								PIN, Incorrect
     </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>

				<button className='Btn'>Connexion</button>
			</form>
		</>
	);
};



export async function updateTochangedate(item) {

	const dateRef = doc(db, "muunganomoney", "datecreditaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(dateRef, {
		date: item
	});

};
export async function updateToMoney() {

	const updateRef = doc(db, "muunganomoney", "budgetcreditaire");
	// Set the "capital" field of the city 'DC'
	await updateDoc(updateRef, {
		cdf: 0,
		usd: 0
	});

};
