import React from 'react';
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import './Group.css';


// Price group 
export default function RetuRnAvaTaR() {

 let strgroup = secureLocalStorage.getItem("##4483833@!!**$$%%").namegroup;
 let str = strgroup;
 let result = str.charAt(0).toUpperCase() + str.slice(1);

 return (
  <div className='class-group-css-tontine'>
   <Avatar sx={{ bgcolor: '#' + secureLocalStorage.getItem("#$$@!!color$$%%"), width: 50, height: 50 }}>{result.slice(0, 2)}</Avatar>
  </div>
 );
};