import React from 'react';
import moment from 'moment';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../../firebase';
import secureLocalStorage from "react-secure-storage"


// Active profil
export default function ReturnFind() {

 const [item, setItem] = React.useState(null);
 const [day, setDay] = React.useState(0);

 React.useEffect(async () => {

  try {
   const unsub = onSnapshot(doc(db, "pret", secureLocalStorage.getItem("##phone&&#$$$[]")), (doc) => {
    setItem(doc.data().id);
   });

  } catch (e) {
   window.console.log(e)
  }



 }, []);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "pretday"), (doc) => {

   try {
    setDay(doc.data().item);
   } catch (e) {
    window.console.log(e);
   }


  });

 }, []);


 return (
  <div className='flex-between flex-info-msg-pret'>
   <p>Date de fin de contrat : </p>
   <p>{moment(item).add(day, 'd').format('LL')}</p>
  </div>
 );
};