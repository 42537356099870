import './Drawer.css';
import Media from 'react-media';
import ProFil from './Profil/Main';


// Drawer Head
export default function ReturnDraweR() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};


export const SreenLarge = () => (
 <div></div>
)
export const ScreenSmall = () => (
 <div className='flex-between drawer-height-1eft'>
  <div></div>
  <ProFil />
 </div>
)