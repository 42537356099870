import './Info.css';
import HeAd from './Head/Main';
import BodY from './Body/Main';

// InFO 
export default function RetuRnInFo() {
 return (
  <div className='flex-grid-column'>

   <HeAd />
   <BodY />
   <div></div>

  </div>
 );
};