import './Home.css';
import ReturnTitle from './Title';
import ReturnBtn from './Btn';
import ReturnViEw from './View';
import BackNAv from './Nav/Main';


// Home Unit Stock
export default function ReturnHOme() {
 return (
  <div className='home-box-unit-stock'>
   <BackNAv />
   <div className='home-unit-stock'>
    <ReturnTitle />
    <ReturnViEw />
    <ReturnBtn />
   </div>

  </div>
 );
};