import React from 'react';
import { db } from '../../../../../../../firebase';
import './List.css';
import { collection, getDocs, doc, updateDoc, deleteDoc, setDoc, onSnapshot } from "firebase/firestore";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import { useForm, Controller } from 'react-hook-form';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import currency from 'currency.js';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, orange } from '@mui/material/colors';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Media from 'react-media';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';



const theme = createTheme({
	palette: {
		primary: lime,
	},
});
const outerTheme = createTheme({
	palette: {
		primary: {
			main: orange[600],
		},
	},
});



// List Body
export default function RetuRnLisT() {
	return (
		<Media
			queries={{
				small: '(max-width: 599px)',
				medium: '(min-width: 600px) and (max-width:1199px)',
				large: '(min-width: 1200px)',
			}}>
			{matches => (
				<>
					{matches.small && <ScreenSmall />}
					{matches.medium && <ScreenLarge />}
					{matches.large && <ScreenLarge />}
				</>
			)}
		</Media>
	);
};


export const ScreenLarge = () => {

	const navigation = useNavigate();
	let pushDoc = new Array();
	const { register, control, handleSubmit, reset, watch } = useForm({});

	const [open, setOpen] = React.useState(false);
	const [load, setLoad] = React.useState(false);
	const [list, setList] = React.useState([]);
	const [budgetfran, setBudgetfran] = React.useState(0);
	const [budgetdollard, setBudgetdollard] = React.useState(0);

	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, "pretask"));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			pushDoc.push(doc.data());
		});

		setList(pushDoc);

	}, []);
	React.useEffect(async () => {
		const unsub = onSnapshot(doc(db, "muunganomoney", "pretbudget"), (doc) => {

			setBudgetfran(doc.data().cdf);
			setBudgetdollard(doc.data().usd);

		});

	}, []);


	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');


	let str = '';
	let result = str.charAt(0).toUpperCase() + str.slice(1);
	let view = watch('percentage', result);

	const onSubmit = (data) => {

		setLoad(true);
		window.setTimeout(() => { setLoad(false) }, 5500)
		reset();
	};

	const handleClick = (item) => {

		if (item.devise === 'USD') {

			if (budgetdollard <= item.askview || item.askview >= budgetdollard) {

				setOpen(true);

			} else {

				decrimentBudgetdollard(Number(budgetdollard) - Number(item.askview));
				askedyourpretactive(item.phone);

				if (view === undefined) {
					askedTimeactivepret(item.phone, 0.6, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				} else {
					askedTimeactivepret(item.phone, view, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				}

			}

		} else {

			if (budgetfran <= item.askview || item.askview >= budgetfran) {
				setOpen(true);

			} else {

				decrimentBudgetfran(Number(budgetfran) - Number(item.askview));
				askedyourpretactive(item.phone);

				if (view === undefined) {
					askedTimeactivepret(item.phone, 0.6, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				} else {
					askedTimeactivepret(item.phone, view, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				}

			}

		}

		window.setTimeout(() => { navigation(0); }, 5640);

	};


	const handleClose = () => {
		setOpen(false);
	};
	const handleCancelClick = async (phone) => {


		const clientRef = doc(db, "client", phone);
		// Set the "capital" field of the city 'DC'
		await updateDoc(clientRef, {
			pret: false,
			pretactive: false,
			pretregister: false,
		});

		await deleteDoc(doc(db, "pretask", phone)); // remove doc client asked
		await deleteDoc(doc(db, "pret", phone)); // remove doc client asked
		window.setTimeout(() => { navigation(0) }, 850);

	};

	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<div className='list-bd-group-tontine'>
				{!list.length
					?
					<div></div>
					:
					<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<List component="nav" aria-label="main mailbox folders">

							{[...Array(list.length).keys()].map(item => {

								let str = list[item].name === undefined ? '' : list[item].name;
								let result = str.charAt(0).toUpperCase() + str.slice(1);

								return (
									<div>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header">

												<ListItemButton>
													<div className='flex-between'>

														<ListItemIcon><Avatar sx={{ bgcolor: '#f3e5f5', width: 35, height: 35 }} src={list[item].profil} /></ListItemIcon>
														<ListItemText><h3 className='pop-up'>{result}</h3></ListItemText>
														<h3>{euro(list[item].askview).format()} {list[item].devise}</h3>

													</div>

												</ListItemButton>

											</AccordionSummary>

											<AccordionDetails>

												<div className='flex-accordion-details'>

													<h1>Aperçu sur les informations du demandeur</h1>

													<div className='flex-between'>
														<p>Téléphone : </p>
														<p>{list[item].phone}</p>

													</div>

													<div className='flex-between'>
														<p>Address : </p>
														<p>{list[item].address}</p>

													</div>

													<div className='flex-between'>
														<p>Spécifications du Projet : </p>
														<p>{list[item].specify}</p>

													</div>

													<div className='flex-between'>
														<p>Coût du projet : </p>
														<p>{euro(list[item].costs).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Revenu mensuel : </p>
														<p>{euro(list[item].revenu).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Estimation sur apport : </p>
														<p>{euro(list[item].apport).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Demande en lettres : </p>
														<p>{list[item].letter}</p>

													</div>

													<div className='flex-between'>
														<p>Montant demandé en chiffres : </p>
														<p>{euro(list[item].askview).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Pourcentage sur le prêt : </p>
														<p>{list[item].pretmodulo}</p>

													</div>

													<div className='top-8vh flex-between'>

														<form onSubmit={handleSubmit(onSubmit)}>

															<div className='flex-accordion-details mr-bt-5vh'>
																<h1>Changer pourcentage</h1>
																<div className='flex-between'>

																	<FormControl
																		sx={{ width: '100%' }}
																		variant="standard">

																		<InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Changer {list[item].pretmodulo}</span></InputLabel>

																		<Controller
																			name="percentage"
																			control={control}
																			render={({ field }) =>

																				<Input
																					id="standard-adornment-password"
																					autocomplete="new-password"
																					sx={{ fontFamily: 'Alata', fontSize: '0.8em' }}

																					inputProps={{
																						autoComplete: "off", inputMode: 'numeric'
																					}}
																					{...field}
																					type={'percentage'}

																				/>}
																		/>

																	</FormControl>

																</div>
															</div>

															<div className='flex-between'>

																<button onClick={() => handleCancelClick(list[item].phone)}>
																	<ThemeProvider theme={outerTheme}>
																		<Button disableElevation variant="contained">
																			annuler
																		</Button>
																	</ThemeProvider>
																</button>

																<button
																	className='left-margin-12vw'
																	onClick={() => handleClick(list[item])}>
																	<ThemeProvider theme={theme}>
																		<Button disableElevation variant="contained">
																			accepter
																		</Button>
																	</ThemeProvider>
																</button>

															</div>

														</form>
													</div>
												</div>
											</AccordionDetails>

										</Accordion>

									</div>

								);
							})}

						</List>
					</Box>

				}

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}
				>
					<DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>

								Le budget est insuffisant pour répondre à cette demande

       </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>


			</div>
		</>
	);
};

export const ScreenSmall = () => {

	const navigation = useNavigate();
	let pushDoc = new Array();
	const { register, control, handleSubmit, reset, watch } = useForm({});

	const [open, setOpen] = React.useState(false);
	const [load, setLoad] = React.useState(false);
	const [list, setList] = React.useState([]);
	const [budgetfran, setBudgetfran] = React.useState(0);
	const [budgetdollard, setBudgetdollard] = React.useState(0);

	React.useEffect(async () => {

		const querySnapshot = await getDocs(collection(db, "pretask"));
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			pushDoc.push(doc.data());
		});

		setList(pushDoc);

	}, []);
	React.useEffect(async () => {
		const unsub = onSnapshot(doc(db, "muunganomoney", "pretbudget"), (doc) => {

			setBudgetfran(doc.data().cdf);
			setBudgetdollard(doc.data().usd);

		});

	}, []);


	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');


	let str = '';
	let result = str.charAt(0).toUpperCase() + str.slice(1);
	let view = watch('percentage', result);

	const onSubmit = (data) => {

		setLoad(true);
		window.setTimeout(() => { setLoad(false) }, 5500)
		reset();
	};

	const handleClick = (item) => {

		if (item.devise === 'USD') {

			if (budgetdollard <= item.askview || item.askview >= budgetdollard) {

				setOpen(true);

			} else {

				decrimentBudgetdollard(Number(budgetdollard) - Number(item.askview));
				askedyourpretactive(item.phone);

				if (view === undefined) {
					askedTimeactivepret(item.phone, 0.6, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				} else {
					askedTimeactivepret(item.phone, view, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				}

			}

		} else {

			if (budgetfran <= item.askview || item.askview >= budgetfran) {
				setOpen(true);

			} else {

				decrimentBudgetfran(Number(budgetfran) - Number(item.askview));
				askedyourpretactive(item.phone);

				if (view === undefined) {
					askedTimeactivepret(item.phone, 0.6, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				} else {
					askedTimeactivepret(item.phone, view, item.askview);
					addpretactive(item.phone);
					removeDocaskedpret('pretask', item.phone)
				}

			}

		}

		window.setTimeout(() => { navigation(0); }, 5640);

	};


	const handleClose = () => {
		setOpen(false);
	};
	const handleCancelClick = async (phone) => {


		const clientRef = doc(db, "client", phone);
		// Set the "capital" field of the city 'DC'
		await updateDoc(clientRef, {
			pret: false,
			pretactive: false,
			pretregister: false,
		});

		await deleteDoc(doc(db, "pretask", phone)); // remove doc client asked
		await deleteDoc(doc(db, "pret", phone)); // remove doc client asked
		window.setTimeout(() => { navigation(0) }, 850);

	};

	var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

	return (
		<>
			<div className='zindex-theme'>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={load}>

					<CircularProgress color="inherit" />
				</Backdrop>
			</div>

			<div className='list-bd-group-tontine'>
				{!list.length
					?
					<div></div>
					:
					<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<List component="nav" aria-label="main mailbox folders">

							{[...Array(list.length).keys()].map(item => {

								let str = list[item].name === undefined ? '' : list[item].name;
								let result = str.charAt(0).toUpperCase() + str.slice(1);

								return (
									<div>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header">

												<ListItemButton>
													<div>
														<ListItemIcon><Avatar sx={{ bgcolor: '#f3e5f5', width: 35, height: 35 }} src={list[item].profil} /></ListItemIcon>
														<ListItemText><h3 className='pop-up'>{result}</h3></ListItemText>
														<h3>{euro(list[item].askview).format()} {list[item].devise}</h3>

													</div>

												</ListItemButton>

											</AccordionSummary>

											<AccordionDetails>

												<div className='flex-accordion-details'>

													<h1>Aperçu sur les informations du demandeur</h1>

													<div className='flex-between'>
														<p>Téléphone : </p>
														<p>{list[item].phone}</p>

													</div>

													<div className='flex-between'>
														<p>Address : </p>
														<p>{list[item].address}</p>

													</div>

													<div className='flex-between'>
														<p>Spécifications du Projet : </p>
														<p>{list[item].specify}</p>

													</div>

													<div className='flex-between'>
														<p>Coût du projet : </p>
														<p>{euro(list[item].costs).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Revenu mensuel : </p>
														<p>{euro(list[item].revenu).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Estimation sur apport : </p>
														<p>{euro(list[item].apport).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Demande en lettres : </p>
														<p>{list[item].letter}</p>

													</div>

													<div className='flex-between'>
														<p>Montant demandé en chiffres : </p>
														<p>{euro(list[item].askview).format()} {list[item].devise}</p>

													</div>

													<div className='flex-between'>
														<p>Pourcentage sur le prêt : </p>
														<p>{list[item].pretmodulo}</p>

													</div>

													<div className='top-8vh flex-between'>

														<form onSubmit={handleSubmit(onSubmit)}>

															<div className='flex-accordion-details mr-bt-5vh'>
																<h1>Changer pourcentage</h1>
																<div className='flex-between'>

																	<FormControl
																		sx={{ width: '100%' }}
																		variant="standard">

																		<InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Changer {list[item].pretmodulo}</span></InputLabel>

																		<Controller
																			name="percentage"
																			control={control}
																			render={({ field }) =>

																				<Input
																					id="standard-adornment-password"
																					autocomplete="new-password"
																					sx={{ fontFamily: 'Alata', fontSize: '0.8em' }}

																					inputProps={{
																						autoComplete: "off", inputMode: 'numeric'
																					}}
																					{...field}
																					type={'percentage'}

																				/>}
																		/>

																	</FormControl>

																</div>
															</div>

															<div className='flex-between'>
																<button onClick={() => handleCancelClick(list[item].phone)}>
																	<ThemeProvider theme={outerTheme}>
																		<Button disableElevation variant="contained">
																			annuler
																		</Button>
																	</ThemeProvider>
																</button>

																<button className='left-margin-small-12vw' onClick={() => handleClick(list[item])}>

																	<ThemeProvider theme={theme}>
																		<Button disableElevation variant="contained">
																			accepter
																		</Button>
																	</ThemeProvider>
																</button>

															</div>

														</form>
													</div>
												</div>
											</AccordionDetails>

										</Accordion>

									</div>
								);
							})}

						</List>
					</Box>

				}

				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}
				>
					<DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
					<DialogContent>

						<DialogContentText>
							<p className='pop-up'>

								Le budget est insuffisant pour répondre à cette demande

       </p>
						</DialogContentText>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
					</DialogActions>
				</Dialog>


			</div>
		</>
	);
};






export async function decrimentBudgetfran(newbudget) {

	const washingtonRef = doc(db, "muunganomoney", "pretbudget");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		cdf: newbudget
	});

};
export async function decrimentBudgetdollard(newbudget) {

	const washingtonRef = doc(db, "muunganomoney", "pretbudget");
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		usd: newbudget
	});

};

export async function askedTimeactivepret(idpret, percentage, solde) {

	const washingtonRef = doc(db, "pret", idpret);
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		id: moment().format(),
		date: moment().format(),
		pretmodulo: percentage,
		askpret: solde
	});

};
export async function askedyourpretactive(idpret) {

	const washingtonRef = doc(db, "client", idpret);
	// Set the "capital" field of the city 'DC'
	await updateDoc(washingtonRef, {
		pretactive: true
	});

};

export async function addpretactive(idpret) {

	const cityRef = doc(db, 'pret', idpret);
	setDoc(cityRef, { pretactive: true }, { merge: true });

};
export async function removeDocaskedpret(idcolpret, idpret) {

	await deleteDoc(doc(db, idcolpret, idpret))

};



