
import './Home.css';
import BacK from './Nav/Main';
import TitleActif from './Title/Main';


// Return Home All Actif
export default function ReturnHome() {
 return (
  <div className='wrp-hm-actif-all'>
   <BacK />
   <TitleActif />
  </div>
 );
};