import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";

// Back 
export default function ReturnBacK() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/dash');
 }

 return (
  <div onClick={handlepath} className='back-nav-arrow'>
   <Tooltip title="Retour">
    <IconButton>

     <BiArrowBack size={'1.4em'} color='#fff' />
    </IconButton>
   </Tooltip>

  </div>
 );
}