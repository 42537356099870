import React from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../../firebase';


// Active profil
export default function ReturnDay() {

 const [item, setItem] = React.useState(null);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "pretday"), (doc) => {
   setItem(doc.data().item);
  });

 }, []);

 return (
  <div className='flex-between flex-info-msg-pret'>
   <p>Nombre de jours à consommer : </p>
   <p>{item}</p>
  </div>
 );
};