
import HeAd from './Head/Main';
import DrawebOdy from './Body/Main';

// Drawe Component export 
export default function RetuRnDrAwe() {
 return (
  <div>
   <HeAd />
   <DrawebOdy />

  </div>
 );
};