import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import moment from 'moment';
import "moment/locale/fr";


import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CiWifiOff } from "react-icons/ci";
import secureLocalStorage from "react-secure-storage";
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import RetuRnTitle from './Title';


import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
 DatePicker,
 MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import currency from 'currency.js';



export let nowField = moment().date();
export let pushDocs = new Array();
export let fromdate = moment().format();



let key = v4();



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[1-9]/,
   }}
   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   // thousandSeparator
   // valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};



const currencies = [
 {
  value: 'USD',
  label: 'Simple',
 },
 {
  value: 'EUR',
  label: 'Mere',
 },
];


// Input Field Component !!!
export default function ReturnInpuT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};

export const SreenLarge = () => {
 return (
  <div>
   <div className='input-signin-user'>
    <FormInput />
   </div>
  </div>
 )
}
export const ScreenSmall = () => {
 return (
  <div>
   <div className='input-signin-user-sm'>
    <FormInput />
   </div>
  </div>
 )
};




export const FormInput = () => {


 let regular = /[a-z]+/;
 const navigation = useNavigate();

 const [opened, setOpened] = React.useState(false);
 const [rate, setRate] = React.useState(null);
 const [password, setPassword] = React.useState(false);

 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpened(false);
 };


 const [client, setClient] = React.useState(0);
 const [pret, setPret] = React.useState(0);
 const [spagtversagt, setSpagtversagt] = React.useState(0);
 const [stock, setStock] = React.useState(0);
 const [versclients, setVersclients] = React.useState(0);


 const [end, setEnd] = React.useState(0);
 const [middle, setMiddle] = React.useState(0);
 const [start, setStart] = React.useState(0);


 const [wifi, setWifi] = React.useState(false);

 const [load, setLoad] = React.useState(false);
 const [problem, setProblem] = React.useState(false);

 const [open, setOpen] = React.useState(false);
 const [depot, setDepot] = React.useState(false);
 const [phone, setPhone] = React.useState(false);


 const [vodastockusd, setVodastockusd] = React.useState(0);
 const [vodastockcdf, setVodastockcdf] = React.useState(0);

 const [orangestockusd, setOrangestockusd] = React.useState(0);
 const [orangestockcdf, setOrangestockcdf] = React.useState(0);


 const [airtelstockusd, setAirtelstockusd] = React.useState(0);
 const [airtelstockcdf, setAirtelstockcdf] = React.useState(0);


 const [africellstockusd, setAfricellstockusd] = React.useState(0);
 const [africellstockcdf, setAfricellstockcdf] = React.useState(0);


 // Simple buy ================================

 const [vodasimpleusd, setVodasimpleusd] = React.useState(0);
 const [vodasimplecdf, setVodasimplecdf] = React.useState(0);

 const [orangesimpleusd, setOrangesimpleusd] = React.useState(0);
 const [orangesimplecdf, setOrangesimplecdf] = React.useState(0);


 const [airtelsimpleusd, setAirtelsimpleusd] = React.useState(0);
 const [airtelsimplecdf, setAirtelsimplecdf] = React.useState(0);


 const [africellsimpleusd, setAfricellsimpleusd] = React.useState(0);
 const [africellsimplecdf, setAfricellsimplecdf] = React.useState(0);



 // Simple dealer ================================

 const [vodadealerusd, setVodadealerusd] = React.useState(0);
 const [vodadealercdf, setVodadealercdf] = React.useState(0);

 const [orangedealerusd, setOrangedealerusd] = React.useState(0);
 const [orangedealercdf, setOrangedealercdf] = React.useState(0);

 const [airteldealerusd, setAirteldealerusd] = React.useState(0);
 const [airteldealercdf, setAirteldealercdf] = React.useState(0);

 const [africelldealerusd, setAfricelldealerusd] = React.useState(0);
 const [africelldealercdf, setAfricelldealercdf] = React.useState(0);





 // Commission SMS ================================

 const [income, setIncome] = React.useState(0);



 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const { register, formState, handleSubmit, control, watch, reset } = useForm({});
 const { isSubmitting } = formState;


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganoratesuper", "vodacom"), (doc) => {
   setVodastockusd(doc.data().usd);
   setVodastockcdf(doc.data().cdf);

  });
  const unsuborange = onSnapshot(doc(db, "muunganoratesuper", "orange"), (doc) => {
   setOrangestockusd(doc.data().usd);
   setOrangestockcdf(doc.data().cdf);

  });
  const unsubairtel = onSnapshot(doc(db, "muunganoratesuper", "airtel"), (doc) => {
   setAirtelstockusd(doc.data().usd);
   setAirtelstockcdf(doc.data().cdf);

  });
  const unsubafricell = onSnapshot(doc(db, "muunganoratesuper", "africell"), (doc) => {
   setAfricellstockusd(doc.data().usd);
   setAfricellstockcdf(doc.data().cdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganoratesimple", "vodacom"), (doc) => {
   setVodasimpleusd(doc.data().usd);
   setVodasimplecdf(doc.data().cdf);

  });
  const unsuborange = onSnapshot(doc(db, "muunganoratesimple", "orange"), (doc) => {
   setOrangesimpleusd(doc.data().usd);
   setOrangesimplecdf(doc.data().cdf);

  });
  const unsubairtel = onSnapshot(doc(db, "muunganoratesimple", "airtel"), (doc) => {
   setAirtelsimpleusd(doc.data().usd);
   setAirtelsimplecdf(doc.data().cdf);

  });
  const unsubafricell = onSnapshot(doc(db, "muunganoratesimple", "africell"), (doc) => {
   setAfricellsimpleusd(doc.data().usd);
   setAfricellsimplecdf(doc.data().cdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganoratedealer", "vodacom"), (doc) => {
   setVodadealerusd(doc.data().usd);
   setVodadealercdf(doc.data().cdf);

  });
  const unsuborange = onSnapshot(doc(db, "muunganoratedealer", "orange"), (doc) => {
   setOrangedealerusd(doc.data().usd);
   setOrangedealercdf(doc.data().cdf);

  });
  const unsubairtel = onSnapshot(doc(db, "muunganoratedealer", "airtel"), (doc) => {
   setAirteldealerusd(doc.data().usd);
   setAirteldealercdf(doc.data().cdf);

  });
  const unsubafricell = onSnapshot(doc(db, "muunganoratedealer", "africell"), (doc) => {
   setAfricelldealerusd(doc.data().usd);
   setAfricelldealercdf(doc.data().cdf);

  });

 }, []);


 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };

 const handleClose = () => {
  setOpen(false);
 };
 const phoneClose = () => {
  setPhone(false);
 };
 const depotClose = () => {
  setDepot(false);
 };
 const handlewifiClose = () => {
  setWifi(false);
 };
 const handleproblemClose = () => {
  setProblem(false);
  navigation('/dash');
 };

 const [itembudget, setItembudget] = React.useState(null);

 const [error, setError] = React.useState(false);
 const [main, setMain] = React.useState(false);

 const [showPassword, setShowPassword] = React.useState(false);

 const handleClickShowPassword = () => setShowPassword((show) => !show);

 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleError = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setError(false);
 };


 React.useEffect(async () => {

  const unsubbudget = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
   setItembudget(doc.data().taux);
  });

 }, []);
 React.useEffect(async () => {

  const unsubbudget = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
   setRate(doc.data().rate);
  });

 }, []);
 React.useEffect(async () => {

  const unsubbudget = onSnapshot(doc(db, "muunganofrais", "frais"), (doc) => {
   setEnd(doc.data().end);
   setMiddle(doc.data().middle);
   setStart(doc.data().start);
  });

 }, []);
 React.useEffect(async () => {

  const unsubbudget = onSnapshot(doc(db, "muunganofrais", "commissions"), (doc) => {

   setClient(doc.data().client);
   setPret(doc.data().pret);
   setSpagtversagt(doc.data().spagtversagt);
   setStock(doc.data().stock);
   setVersclients(doc.data().versclients);

  });

 }, []);
 React.useEffect(async () => {

  const unsubbudget = onSnapshot(doc(db, "muunganomoney", "password"), (doc) => {
   setIncome(doc.data().income);
  });

 }, []);




 let mypassword = watch('password');

 const handleMain = async () => {
  setMain(false);
  setPassword(true);

 };
 const handleMainClose = () => {
  setMain(false);
 };

 const handlepasswordOpen = async () => {

  if (mypassword != itembudget) {
   setError(true);
   window.console.log('not egal');
  } else {

   setLoad(true);
   setOpened(true);
   setPassword(false);


   const stocksupervoda = doc(db, "muunganoratesuper", "vodacom");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stocksupervoda, {
    usd: secureLocalStorage.getItem("#$$!vodasuperstockusd##"),
    cdf: secureLocalStorage.getItem("#$$!vodasuperstockcdf##")
   });

   const stocksuperorange = doc(db, "muunganoratesuper", "orange");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stocksuperorange, {
    usd: secureLocalStorage.getItem("#$$!orangesuperstockusd##"),
    cdf: secureLocalStorage.getItem("#$$!orangesuperstockcdf##")
   });

   const stocksuperairtel = doc(db, "muunganoratesuper", "airtel");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stocksuperairtel, {

    usd: secureLocalStorage.getItem("#$$!airtelsuperstockusd##"),
    cdf: secureLocalStorage.getItem("#$$!airtelsuperstockcdf##")

   });

   const stocksuperafricell = doc(db, "muunganoratesuper", "africell");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stocksuperafricell, {
    usd: secureLocalStorage.getItem("#$$!africellsuperstockusd##"),
    cdf: secureLocalStorage.getItem("#$$!africellsuperstockcdf##")
   });


   // SIMPLE =================================  

   const simplesupervoda = doc(db, "muunganoratesimple", "vodacom");
   // Set the "capital" field of the city 'DC'
   await updateDoc(simplesupervoda, {
    usd: secureLocalStorage.getItem("#$$!vodasupersimpleusd##"),
    cdf: secureLocalStorage.getItem("#$$!vodasupersimplecdf##")
   });

   const simplesuperorange = doc(db, "muunganoratesimple", "orange");
   // Set the "capital" field of the city 'DC'
   await updateDoc(simplesuperorange, {
    usd: secureLocalStorage.getItem("#$$!orangesupersimpleusd##"),
    cdf: secureLocalStorage.getItem("#$$!orangesupersimplecdf##")
   });

   const simplesuperairtel = doc(db, "muunganoratesimple", "airtel");
   // Set the "capital" field of the city 'DC'
   await updateDoc(simplesuperairtel, {
    usd: secureLocalStorage.getItem("#$$!airtelsupersimpleusd##"),
    cdf: secureLocalStorage.getItem("#$$!airtelsupersimplecdf##")
   });


   const simplesuperafricell = doc(db, "muunganoratesimple", "africell");
   // Set the "capital" field of the city 'DC'
   await updateDoc(simplesuperafricell, {
    usd: secureLocalStorage.getItem("#$$!africellsupersimpleusd##"),
    cdf: secureLocalStorage.getItem("#$$!africellsupersimplecdf##")
   });


   // DEALER ========================================

   const dealersupervoda = doc(db, "muunganoratedealer", "vodacom");
   // Set the "capital" field of the city 'DC'
   await updateDoc(dealersupervoda, {
    usd: secureLocalStorage.getItem("#$$!vodasuperdealerusd##"),
    cdf: secureLocalStorage.getItem("#$$!vodasuperdealercdf##")
   });


   const dealersuperorange = doc(db, "muunganoratedealer", "orange");
   // Set the "capital" field of the city 'DC'
   await updateDoc(dealersuperorange, {
    usd: secureLocalStorage.getItem("#$$!orangesuperdealerusd##"),
    cdf: secureLocalStorage.getItem("#$$!orangesuperdealercdf##")
   });

   const dealersuperairtel = doc(db, "muunganoratedealer", "airtel");
   // Set the "capital" field of the city 'DC'
   await updateDoc(dealersuperairtel, {
    usd: secureLocalStorage.getItem("#$$!airtelsuperdealerusd##"),
    cdf: secureLocalStorage.getItem("#$$!airtelsuperdealercdf##")
   });


   const dealersuperafricell = doc(db, "muunganoratedealer", "africell");
   // Set the "capital" field of the city 'DC'
   await updateDoc(dealersuperafricell, {
    usd: secureLocalStorage.getItem("#$$!africellsuperdealerusd##"),
    cdf: secureLocalStorage.getItem("#$$!africellsuperdealercdf##")
   });


   const stockdealerrate = doc(db, "muunganomoney", "taux");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stockdealerrate, {
    rate: secureLocalStorage.getItem("#$$!ratesuper##"),
   });


   // FRAIS&COMMISSIONS ======================================

   const stockdealerfrais = doc(db, "muunganofrais", "frais");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stockdealerfrais, {
    start: secureLocalStorage.getItem("#$$!fraisstart##"),
    middle: secureLocalStorage.getItem("#$$!fraismiddle##"),
    end: secureLocalStorage.getItem("#$$!fraisend##")
   });


   // &COMMISSIONS ======================================

   const stockdealercommissions = doc(db, "muunganofrais", "commissions");
   // Set the "capital" field of the city 'DC'
   await updateDoc(stockdealercommissions, {
    client: secureLocalStorage.getItem("#$$!client##"),
    pret: secureLocalStorage.getItem("#$$!pret##"),
    spagtversagt: secureLocalStorage.getItem("#$$!spagtversagt##"),
    versclients: secureLocalStorage.getItem("#$$!versclients##"),
   });


   // &COMMISSIONS SMS ======================================

   const updateIncome = doc(db, "muunganomoney", "password");
   // Set the "capital" field of the city 'DC'
   await updateDoc(updateIncome, {
    income: secureLocalStorage.getItem("#$$!income##"),
   });


   window.setTimeout(() => {
    navigation(0);
   }, 2500);


  }

 };
 const handlepasswordClose = () => {
  setPassword(false);
 };


 const onSubmit = async (data) => {

  setPassword(true);

  secureLocalStorage.setItem("#$$!vodasuperstockusd##", data.vodasuperstockusd === undefined ? Number(vodastockusd) : Number(data.vodasuperstockusd));
  secureLocalStorage.setItem("#$$!vodasuperstockcdf##", data.vodasuperstockcdf === undefined ? Number(vodastockcdf) : Number(data.vodasuperstockcdf));

  secureLocalStorage.setItem("#$$!orangesuperstockusd##", data.orangesuperstockusd === undefined ? Number(orangestockusd) : Number(data.orangesuperstockusd));
  secureLocalStorage.setItem("#$$!orangesuperstockcdf##", data.orangesuperstockcdf === undefined ? Number(orangestockcdf) : Number(data.orangesuperstockcdf));

  secureLocalStorage.setItem("#$$!airtelsuperstockusd##", data.airtelsuperstockusd === undefined ? Number(airtelstockusd) : Number(data.airtelsuperstockusd));
  secureLocalStorage.setItem("#$$!airtelsuperstockcdf##", data.airtelsuperstockcdf === undefined ? Number(airtelstockcdf) : Number(data.airtelsuperstockcdf));

  secureLocalStorage.setItem("#$$!africellsuperstockusd##", data.africellsuperstockusd === undefined ? Number(africellstockusd) : Number(data.africellsuperstockusd));
  secureLocalStorage.setItem("#$$!africellsuperstockcdf##", data.africellsuperstockcdf === undefined ? Number(africellstockcdf) : Number(data.africellsuperstockcdf));



  // SIMPLE AGENT

  secureLocalStorage.setItem("#$$!vodasupersimpleusd##", data.vodasupersimpleusd === undefined ? Number(vodasimpleusd) : Number(data.vodasupersimpleusd));
  secureLocalStorage.setItem("#$$!vodasupersimplecdf##", data.vodasupersimplecdf === undefined ? Number(vodasimplecdf) : Number(data.vodasupersimplecdf));


  secureLocalStorage.setItem("#$$!orangesupersimpleusd##", data.orangesupersimpleusd === undefined ? Number(orangesimpleusd) : Number(data.orangesupersimpleusd));
  secureLocalStorage.setItem("#$$!orangesupersimplecdf##", data.orangesupersimplecdf === undefined ? Number(orangesimplecdf) : Number(data.orangesupersimplecdf));

  secureLocalStorage.setItem("#$$!airtelsupersimpleusd##", data.airtelsupersimpleusd === undefined ? Number(airtelsimpleusd) : Number(data.airtelsupersimpleusd));
  secureLocalStorage.setItem("#$$!airtelsupersimplecdf##", data.airtelsupersimplecdf === undefined ? Number(airtelsimplecdf) : Number(data.airtelsupersimplecdf));

  secureLocalStorage.setItem("#$$!africellsupersimpleusd##", data.africellsupersimpleusd === undefined ? Number(africellsimpleusd) : Number(data.africellsupersimpleusd));
  secureLocalStorage.setItem("#$$!africellsupersimplecdf##", data.africellsupersimplecdf === undefined ? Number(africellsimplecdf) : Number(data.africellsupersimplecdf));



  // DEALER AGENT ======================================================

  secureLocalStorage.setItem("#$$!vodasuperdealerusd##", data.vodasuperdealerusd === undefined ? Number(vodadealerusd) : Number(data.vodasuperdealerusd));
  secureLocalStorage.setItem("#$$!vodasuperdealercdf##", data.vodasuperdealercdf === undefined ? Number(vodadealercdf) : Number(data.vodasuperdealercdf));

  secureLocalStorage.setItem("#$$!orangesuperdealerusd##", data.orangesuperdealerusd === undefined ? Number(orangedealerusd) : Number(data.orangesuperdealerusd));
  secureLocalStorage.setItem("#$$!orangesuperdealercdf##", data.orangesuperdealercdf === undefined ? Number(orangedealercdf) : Number(data.orangesuperdealercdf));


  secureLocalStorage.setItem("#$$!airtelsuperdealerusd##", data.airtelsuperdealerusd === undefined ? Number(airteldealerusd) : Number(data.airtelsuperdealerusd));
  secureLocalStorage.setItem("#$$!airtelsuperdealercdf##", data.airtelsuperdealercdf === undefined ? Number(airteldealercdf) : Number(data.airtelsuperdealercdf));

  secureLocalStorage.setItem("#$$!africellsuperdealerusd##", data.africellsuperdealerusd === undefined ? Number(africelldealerusd) : Number(data.africellsuperdealerusd));
  secureLocalStorage.setItem("#$$!africellsuperdealercdf##", data.africellsuperdealercdf === undefined ? Number(africelldealercdf) : Number(data.africellsuperdealercdf));


  // RATE
  secureLocalStorage.setItem("#$$!ratesuper##", data.ratesuperdealer === undefined ? Number(rate) : Number(data.ratesuperdealer));


  // FRAIS
  secureLocalStorage.setItem("#$$!fraisstart##", data.start === undefined ? Number(start) : Number(data.start));
  secureLocalStorage.setItem("#$$!fraismiddle##", data.middle === undefined ? Number(middle) : Number(data.middle));
  secureLocalStorage.setItem("#$$!fraisend##", data.end === undefined ? Number(end) : Number(data.end));


  // COMMISSION
  secureLocalStorage.setItem("#$$!client##", data.client === undefined ? Number(client) : Number(data.client));
  secureLocalStorage.setItem("#$$!pret##", data.pret === undefined ? Number(pret) : Number(data.pret));
  secureLocalStorage.setItem("#$$!spagtversagt##", data.spagtversagt === undefined ? Number(spagtversagt) : Number(data.spagtversagt));
  secureLocalStorage.setItem("#$$!versclients##", data.versclients === undefined ? Number(versclients) : Number(data.versclients));



  secureLocalStorage.setItem("#$$!income##", data.income === undefined ? Number(income) : Number(data.income));

  // secureLocalStorage.setItem("#$$!stock##", data.stock === undefined ? Number(stock) : Number(data.stock));



 };

 return (
  <>
   <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
    <Alert
     onClose={handleClosed}
     severity="success"
     variant="filled"
     sx={{ width: '100%' }}
    >
     <p className='pop-up'>
      La modification a été effectuée
      </p>

    </Alert>
   </Snackbar>

   <Snackbar open={error} autoHideDuration={6000} onClose={handleError}>
    <Alert
     // onClose={handleError}
     severity="error"
     variant="filled"
     sx={{ width: '100%' }}
    >
     <h1 className='pop-up'>Mot de passe administrateur incorrect</h1>
    </Alert>
   </Snackbar>


   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

    <RetuRnTitle color={'#00a1e4'} Text={'Taux sur les supers agents'} />

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="vodasuperstockusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate super-green'> ({vodastockusd}) taux sur vodacom</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={vodastockusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="vodasuperstockcdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({vodastockcdf}) taux sur vodacom</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={vodastockcdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="orangesuperstockusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({orangestockusd}) taux sur Orange</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={orangestockusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="orangesuperstockcdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({orangestockcdf}) taux sur Orange</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={orangestockcdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>
    </div>

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="airtelsuperstockusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({airtelstockusd})taux sur Airtel</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={airtelstockusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="airtelsuperstockcdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({airtelstockcdf}) taux sur Airtel</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={airtelstockcdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>
    </div>

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="africellsuperstockusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({africellstockusd}) taux sur Africell</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={africellstockusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="africellsuperstockcdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({africellstockcdf}) taux sur Africell</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={africellstockcdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>


    <RetuRnTitle color='#339989' Text={'Taux sur les agents simple'} />

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="vodasupersimpleusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate super-green'> ({vodasimpleusd}) taux sur Vodacom</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={vodasimpleusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="vodasupersimplecdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({vodasimplecdf}) taux sur Vodacom</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={vodasimplecdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="orangesupersimpleusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({orangesimpleusd}) taux sur Orange</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={orangesimpleusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="orangesupersimplecdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({orangesimplecdf}) taux sur Orange</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={orangesimplecdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="airtelsupersimpleusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({airtelsimpleusd}) taux sur Airtel</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={airtelsimpleusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="airtelsupersimplecdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({airtelsimplecdf})taux sur Airtel</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={airtelsimplecdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="africellsupersimpleusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({africellsimpleusd}) taux sur Africell</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={africellsimpleusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="africellsupersimplecdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({africellsimplecdf}) taux sur Africell</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={africellsimplecdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>


    <RetuRnTitle color='#3b38cc' Text={'Taux sur les agents dealer'} />

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="vodasuperdealerusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate super-green'> ({vodadealerusd}) taux sur Vodacom</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={vodadealerusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="vodasuperdealercdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({vodadealercdf}) taux sur Vodacom</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={vodadealercdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="orangesuperdealerusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({orangedealerusd}) taux sur Orange</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={orangedealerusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="orangesuperdealercdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({orangedealercdf}) taux sur Orange</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={orangedealercdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="airtelsuperdealerusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({airteldealerusd}) taux sur Airtel</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={airteldealerusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="airtelsuperdealercdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({airteldealercdf}) taux sur Airtel</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={airteldealercdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="africellsuperdealerusd"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({africelldealerusd}) taux sur Africell</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={africelldealerusd}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="africellsuperdealercdf"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({africelldealercdf}) taux sur Africell</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={africelldealercdf}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>


    <RetuRnTitle color='#aacb14' Text={'Frais'} />

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="start"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({start}) Frais sur silver</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={start}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }


      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="middle"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({middle}) Frais sur gold </h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={middle}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <FormControl sx={{ width: '100%' }} variant="standard">

     <Controller
      name="end"
      control={control}
      render={({ field }) =>

       <TextField
        label={<h2 className='pop-rate'> ({end}) Frais sur platine </h2>}

        value={values.numberformat}
        onChange={handleChange}
        placeholder={end}

        inputProps={{
         autoComplete: "off", inputMode: 'decimal',
        }}

        {...field}
        id="formatted-numberformat-input"
        InputProps={{
         inputComponent: NumericFormatCustom,
        }}
        variant="standard"
       />

      }
     />

    </FormControl>


    <RetuRnTitle color='#773b14' Text={'Commissions'} />

    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="client"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({client}) Commissions client</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={client}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }


      />

     </FormControl>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="pret"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({pret}) Commissions prêt</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={pret}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="spagtversagt"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({spagtversagt}) Commissions super&super</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={spagtversagt}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }


      />

     </FormControl>


     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="versclients"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate'> ({versclients}) Commissions vers clients </h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={versclients}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>


    </div>



    <RetuRnTitle color='#bba330' Text={'Taux de change'} />

    <div className='flex-between' style={{ gap: '1em' }}>

     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="ratesuperdealer"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate super-green'> Taux ({rate})</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={rate}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>


    <RetuRnTitle color='#9381ff' Text={'Commission sms  épargne'} />


    <div className='flex-between' style={{ gap: '1em' }}>
     <FormControl sx={{ width: '100%' }} variant="standard">

      <Controller
       name="income"
       control={control}
       render={({ field }) =>

        <TextField
         label={<h2 className='pop-rate super-green'> Taux ({income})</h2>}

         value={values.numberformat}
         onChange={handleChange}
         placeholder={income}

         inputProps={{
          autoComplete: "off", inputMode: 'decimal',
         }}

         {...field}
         id="formatted-numberformat-input"
         InputProps={{
          inputComponent: NumericFormatCustom,
         }}
         variant="standard"
        />

       }
      />

     </FormControl>

    </div>




    <button disabled={isSubmitting} className='Btn'>Enregistre</button>

   </form>


   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
        Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

   <Dialog

    open={password}
    onClose={handlepasswordClose}

   >
    <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>

    <DialogContent>
     <DialogContentText>
      <p className='pop-up'>
       Veuillez indiquer le mot de passe administrateur pour confirmer le transfert
      </p>
     </DialogContentText>

     <Controller
      autoFocus
      name="password"
      defaultValue=''
      control={control}
      render={({ field }) =>

       <Input
        fullWidth

        style={{ fontSize: '24px', color: 'grey', letterSpacing: '5px', fontFamily: 'Quicksand' }}

        {...field}
        id="standard-adornment-password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
         <InputAdornment position="end">
          <IconButton
           aria-label="toggle password visibility"
           onClick={handleClickShowPassword}
           onMouseDown={handleMouseDownPassword}
          >
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
         </InputAdornment>
        }
       />

      } />

    </DialogContent>

    <DialogActions>
     <div className='flex-between'>
      <Button onClick={handlepasswordClose}><h1 className='pop-up'>Annuler</h1></Button>
      <Button onClick={handlepasswordOpen}><h1 className='pop-up'>actualiser</h1></Button>
     </div>
    </DialogActions>

   </Dialog>

  </>
 )
};
