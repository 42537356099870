import React from 'react';
import './List.css';
import RetuRnIcOn from './Icon';
import { CiGrid42, CiBank, CiBarcode, CiWallet } from "react-icons/ci";
import { CiPaperplane } from "react-icons/ci";
import { useForm, Controller } from 'react-hook-form';
import { FiBatteryCharging } from "react-icons/fi";
import { IoBagAddOutline } from "react-icons/io5";

import secureLocalStorage from "react-secure-storage";
import { TfiInfoAlt } from "react-icons/tfi";
import { GiSettingsKnobs } from "react-icons/gi";
import { PiStrategyLight } from "react-icons/pi";
import { PiLinkLight } from "react-icons/pi";

import { useNavigate } from 'react-router-dom';
import { doc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import { CiUser, CiCircleList } from "react-icons/ci";

import { Expo, TweenMax } from 'gsap';
import { PiSignOutThin } from "react-icons/pi";
import { getAuth, signOut } from "firebase/auth";
import { PiShareNetworkThin } from "react-icons/pi";

import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';


// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';




let strongicon = '1.3em';
let mediumicon = '1.4em';




// List dash 
export default function RetuRnlisT() {


 let api_id = secureLocalStorage.getItem("#$$@%%api$$key");
 let password = secureLocalStorage.getItem("#$$@%%api$$pasword");


 const navigation = useNavigate();

 let pushDoc = new Array();
 let pushTon = new Array();

 let pushClient = new Array();
 let pushIdClnt = new Array();
 let pushAgent = new Array();
 let pushExist = new Array();


 const [listAgent, setListAgent] = React.useState([]);
 const [listClient, setListClient] = React.useState([]);
 const [listClnt, setListClnt] = React.useState([]);
 const [existlist, setExistlist] = React.useState([]);


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());
   pushIdClnt.push(doc.id);

  });

  setListClient([... new Set(pushClient)]);
  setListClnt([... new Set(pushIdClnt)]);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "sharecashbeck"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushExist.push(doc.id);

  });

  setExistlist([... new Set(pushExist)]);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushAgent.push(doc.data());
  });

  setListAgent([... new Set(pushAgent)]);

 }, []);



 const [opn, setOpn] = React.useState(false);

 const [load, setLoad] = React.useState(false);
 const [open, setOpen] = React.useState(false);
 const [empty, setEmpty] = React.useState(false);
 // const [sms, setSms] = React.useState(true);

 // const [pin, setPin] = React.useState('');
 const [opend, setOpend] = React.useState(false);
 const [loading, setLoading] = React.useState(false);
 const { handleSubmit, reset, control } = useForm();
 const [showPassword, setShowPassword] = React.useState(false);

 const [listed, setListed] = React.useState([]);
 const [ton, setTon] = React.useState([]);


 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');



 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpend(false);
 };

 const handleClickShowPassword = () => setShowPassword((show) => !show);
 const handleMouseDownPassword = (event) => {
  event.preventDefault();
 };

 const handleClose = () => {
  setOpen(false);
 };
 const handleEmpty = () => {
  setEmpty(false);
 };


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "tontine"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
  });

  setListed(pushDoc);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, secureLocalStorage.getItem("$#sour#ton%%")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushTon.push(doc.data());
  });

  setTon(pushTon);

 }, []);




 const dashRef = React.useRef(null);
 const enrolRef = React.useRef(null);
 const sendRef = React.useRef(null);
 const pretRef = React.useRef(null);
 const unitRef = React.useRef(null);
 const creditaireRef = React.useRef(null);
 const litigeRef = React.useRef(null);
 const litigeInfo = React.useRef(null);
 const deliveryRef = React.useRef(null);



 React.useEffect(() => {


  TweenMax.from(dashRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(enrolRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(sendRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(pretRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(unitRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(creditaireRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(litigeRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(litigeInfo.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })
  TweenMax.from(deliveryRef.current, { delay: Math.random(), duration: Math.random(), x: 0, opacity: 0, ease: Expo.easeIn })


 }, []);


 const handlClose = () => {
  setOpn(false);
 };

 const handledash = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);
  navigation(0)

 };
 const handlesend = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);
  navigation('/dash/send/money');

 };
 const handleshare = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#share#$!!@!!**&&$$", true);


  secureLocalStorage.setItem("#!!!@#@%@$$", []);
  secureLocalStorage.setItem("#!!!@#@%@$$", {

   idphone: "0",

   signaturemoneyusd: 0,
   signature: "",
   usd: 0,
   signaturefraisusd: 0,
   signaturekeyusd: "",

  });
  secureLocalStorage.setItem("#@@$^$$!#A", {

   signaturetypeA: '',

   signaturemoneyusdA: 0,
   signatureA: "",
   usdA: 0,
   signaturefraisusdA: 0,
   signaturekeyusdA: "",

  });


  secureLocalStorage.setItem("#!!!@#@%EWR##", false);
  window.location.href = "/dash/share";


 };
 const handleenroll = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);
  navigation('/enroll/identity');

 };
 const handlepret = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  navigation('/pret/pin');

 };
 const handleunit = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  navigation('/unit/stock/throw/orange');

 };
 const handlecreditaire = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);
  navigation('/creditaire/pin');

 };
 const handlelitige = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  navigation('/litige/pin');

 };
 const handleinfo = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  navigation('/user/info/pin');

 };
 const handleSignout = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#out#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  secureLocalStorage.clear();

  const auth = getAuth();
  signOut(auth).then(() => {
   // Sign-out successful
   window.console.log('sign out');
  }).catch((error) => {
   // An error happened.
   window.console.log('error sign out');
  });

  window.location.href = '/';

 };
 const handleaddBudget = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#out#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  navigation('/dash/send/transfert/budget');

 };
 const handleTontine = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("$$!!&&number^**^", 0);
  secureLocalStorage.setItem("##ton@@XX", ton);

  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#out#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);


  if (listed?.length) {

   setLoading(true);
   secureLocalStorage.setItem("##!!@@check#&&pin@#**#", true);
   secureLocalStorage.setItem("@#%next&&*@@#!!user++", "transfer");
   secureLocalStorage.setItem("##4483833@!!**$$%%", listed[0]);
   secureLocalStorage.setItem("##id@!!group$$%%", listed[0].idgroup);
   secureLocalStorage.setItem("$#sour#ton%%", secureLocalStorage.getItem("##4483833@!!**$$%%").namegroup + secureLocalStorage.getItem("##4483833@!!**$$%%").table[0]);

   secureLocalStorage.setItem("#$$@!!color$$%%", listed[0].color);
   secureLocalStorage.setItem("##id@##postion$$%%", 0);

   window.setTimeout(() => {
    window.location.href = '/user/tontine';
   }, 100);

  } else {
   setEmpty(true);
  }


 };
 const handleRate = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#out#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", true);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", false);

  navigation('/dash/rate');

 };
 const handleAddpret = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#out#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", true);

  navigation('/dash/send/transfert/budget/pret');

 };
 const handleenrollsharing = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("**#dash#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#send#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#enroll#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#pret#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#unit#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#creditaire#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#info#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#litige#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#budget#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#out#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#tontine#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#rate#$!!@!!**&&$$", false);
  secureLocalStorage.setItem("**#addpret#$!!@!!**&&$$", true);

  navigation('/share/enroll');

 };


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>



   <Dialog
    open={opn}
    onClose={handlClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className="pop-up">
      {"Cashbeck"}
     </h1>
    </DialogTitle>

    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className="pop-up">
       Veuillez confirmer les jetons de la commission épargne chez tous les clients du Cashbeck.
      </p>


     </DialogContentText>
    </DialogContent>
    <DialogActions>

     <div className="flex-between" style={{ padding: "0 15px" }}>
      <Button onClick={handlClose}><span className="pop-up" style={{ color: "#006494" }}>Annuler</span></Button>
      <Button onClick={() =>

       [...Array(listClient.length).keys()].map(item => {

        setLoad(true);
        setOpn(false);

        window.setTimeout(async () => {

         let otp = `${Math.floor(1000 + Math.random() * 900000)}`;
         const textmsgCode = `Chere client, presentez ce jeton : ${otp}, au bureau du Cashbeck pour avoir la commission de votre epargne. Ce jeton expire dans 3 jours`;
         const number = `${listClient[item].idphone}`;
         let phonesend = `243${number.slice(-9)}`;

         let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://api2.dream-digital.info/api/SendSMS?api_id=${api_id}&api_password=${password}&sms_type=T&encoding=T&sender_id=Cashbeck&phonenumber=${phonesend}&textmessage=${textmsgCode}`,

          headers: {}
         };

         axios.request(config)
          .then((response) => {
           console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
           console.log(error);
          });


         const changeRef = doc(db, "client", `${listClient[item].idphone}`);
         // Set the "capital" field of the city 'DC'
         await updateDoc(changeRef, {
          income: otp,
          incomestatus: true
         });

         if (listClnt[listClnt.length - 1] == listClient[item].idphone) {

          const washingtonRef = doc(db, "muunganomoney", "taux");
          // Set the "capital" field of the city 'DC'
          await updateDoc(washingtonRef, {
           availablesms: true
          });

          navigation(0);
         }

        }, item * 2000);

       })

      }

       autoFocus><span className="pop-up" style={{ color: "#006494" }}>confirmer</span></Button>

     </div>

    </DialogActions>
   </Dialog>

   <div className='wrp-list-menu-dash'>
    <div className='wrp-stretching'>

     <ul>
      <li ref={dashRef} onClick={handledash}><RetuRnIcOn color={secureLocalStorage.getItem("**#dash#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#dash#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Dashboard'} icon={<CiGrid42 size={strongicon} />} /></li>
      <li ref={enrolRef} onClick={handleenroll}><RetuRnIcOn color={secureLocalStorage.getItem("**#enroll#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#enroll#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Enrôler'} icon={<CiUser size={mediumicon} />} /></li>

      <li ref={sendRef} onClick={handlesend}><RetuRnIcOn color={secureLocalStorage.getItem("**#send#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#send#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Transfert'} icon={<CiPaperplane size={strongicon} />} /></li>
      <li ref={pretRef} onClick={handlepret}><RetuRnIcOn color={secureLocalStorage.getItem("**#pret#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#pret#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Prêt'} icon={<CiBank size={strongicon} />} /></li>
      <li ref={creditaireRef} onClick={handlecreditaire}><RetuRnIcOn color={secureLocalStorage.getItem("**#creditaire#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#creditaire#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Creditaire'} icon={<CiBarcode size={strongicon} />} /></li>
      <li ref={litigeRef} onClick={handlelitige}><RetuRnIcOn color={secureLocalStorage.getItem("**#litige#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#litige#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Litige'} icon={<GiSettingsKnobs size={strongicon} />} /></li>
      <li ref={unitRef} onClick={handleunit}><RetuRnIcOn color={secureLocalStorage.getItem("**#unit#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#unit#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Unité'} icon={<FiBatteryCharging size={strongicon} />} /></li>
      <li ref={litigeInfo} onClick={handleinfo}><RetuRnIcOn color={secureLocalStorage.getItem("**#info#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#info#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Info'} icon={<TfiInfoAlt size={strongicon} />} /></li>
      <li ref={litigeRef} onClick={handleaddBudget}><RetuRnIcOn color={secureLocalStorage.getItem("**#budget#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#budget#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Ajouter budget'} icon={<CiWallet size={strongicon} />} /></li>

      <li ref={creditaireRef} onClick={handleTontine}><RetuRnIcOn color={secureLocalStorage.getItem("**#tontine#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#tontine#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Tontine'} icon={<PiLinkLight size={strongicon} />} /></li>


      <li ref={creditaireRef} onClick={handleshare} className="flex-between">

       <RetuRnIcOn color={secureLocalStorage.getItem("**#tontine#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#tontine#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Etablissement'} icon={<PiShareNetworkThin size={strongicon} />} />
       <div>{!!existlist.length && <Avatar src="/img/record.png" sx={{ width: 9, height: 9 }} />}</div>

      </li>


      <li ref={creditaireRef} onClick={handleenrollsharing}><RetuRnIcOn color={secureLocalStorage.getItem("**#tontine#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#tontine#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Enrôler établissement'} icon={<CiCircleList size={strongicon} />} /></li>


      <li ref={unitRef} onClick={handleRate}><RetuRnIcOn color={secureLocalStorage.getItem("**#rate#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#rate#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Taux'} icon={<PiStrategyLight size={strongicon} />} /></li>
      <li ref={pretRef} onClick={handleAddpret}><RetuRnIcOn color={secureLocalStorage.getItem("**#addpret#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#addpret#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Ajouter budget prêt'} icon={<IoBagAddOutline size={strongicon} />} /></li>
      <li ref={pretRef} onClick={handleSignout}><RetuRnIcOn color={secureLocalStorage.getItem("**#out#$!!@!!**&&$$") ? '#fff' : '#004e98'} bgcolor={secureLocalStorage.getItem("**#out#$!!@!!**&&$$") ? '#004e98' : '#e9ecef'} Text={'Déconnexion'} icon={<PiSignOutThin size={strongicon} />} /></li>
     </ul>

     <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={empty}
      onClose={handleEmpty}>

      <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
      <DialogContent>

       <DialogContentText>
        <p className='pop-up'>
         Aucun groupe tontine n'est créé
     </p>
       </DialogContentText>

      </DialogContent>
      <DialogActions>
       <Button onClick={handleEmpty}><span className='pop-up'>Fermer</span></Button>
      </DialogActions>
     </Dialog>

    </div>
   </div>

  </>
 );
};

