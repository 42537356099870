import * as React from 'react';
import Button from '@mui/material/Button';
import { CiBellOn } from "react-icons/ci";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useForm, Controller } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from '../../../../../firebase';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import './Active.css';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function RetuRnFormDialog() {


  let pushClient = new Array();
  const { control, watch } = useForm({});

  const [item, setItem] = React.useState('');
  const [client, setClient] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [access, setAccess] = React.useState(true);


  let str = item;
  let result = str.charAt(0).toUpperCase() + str.slice(1);
  let view = watch('text', result);


  const handleClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpened(false);
  };
  React.useEffect(async () => {

    const querySnapshot = await getDocs(collection(db, "client"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushClient.push(doc.id);

    });

    setClient(pushClient);

  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickupdate = async (item) => {

    if (client.includes(view)) {
      setOpened(true);
      setAccess(true);

      const washingtonRef = doc(db, "client", item);
      // Set the "capital" field of the city 'DC'
      await updateDoc(washingtonRef, {
        pret: true
      });

    } else {

      setOpened(true);
      setAccess(false);
    }

    setOpen(false);

  }

  return (
    <React.Fragment>
      <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
        <Alert onClose={handleClosed} severity={access ? "success" : "error"} sx={{ width: '100%' }}>


          <h3 className='pop-up'>{access ? "Accès au Prêt autorisé" : "Accès au prèt non autorisé"}</h3>

        </Alert>
      </Snackbar>

      <CiBellOn size={'1.5em'} color='#fff' className='pointer' onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>

        <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>
        <DialogContent>
          <DialogContentText>

            <p className='pop-up pd-b-5vh'>
              Veuillez saisir le numéro de client pour l'activer au service prêt
              pour permettre de valider le formulaire sur la demande du prêt
      </p>

          </DialogContentText>


          <FormControl
            sx={{ width: '100%' }}
            variant="standard">

            <InputLabel htmlFor="standard-adornment-password"><span className='pop-up'>Numéro du client</span></InputLabel>

            <Controller
              name="text"
              control={control}
              render={({ field }) =>

                <Input
                  id="standard-adornment-password"
                  autocomplete="new-password"
                  sx={{ fontFamily: 'Alata', fontSize: '1.3em' }}

                  inputProps={{
                    autoComplete: "off", inputMode: 'numeric'
                  }}
                  {...field}
                  type={'text'}

                />}
            />

          </FormControl>


        </DialogContent>
        <DialogActions>

          <div className='flex-between'>
            <Button onClick={handleClose}><p className='pop-up'>Annuler</p></Button>
            <Button onClick={() => handleClickupdate(view)}><p className='pop-up'>autoriser</p></Button>
          </div>


        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
