import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Media from 'react-media';
import ReturnListEtablish from './List';
import SeNd from './Send/Main';
import secureLocalStorage from "react-secure-storage";
import { collection, getDocs } from "firebase/firestore";

import { db } from '../../../firebase';



export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {


 let iTmclpush = new Array();
 const [itmIdshare, setItmIdshare] = React.useState([]);

 React.useEffect(async () => {

  const shareASnapshot = await getDocs(collection(db, "share" + secureLocalStorage.getItem("#$$!@@%DTR##")));
  shareASnapshot.forEach(async (doc) => {
   iTmclpush.push(doc.id);
  });

  setItmIdshare([...new Set(iTmclpush)]);

 }, []);


 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };




 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <div onClick={toggleDrawer(anchor, itmIdshare.length === 0 ? false : true)}>
      <ReturnListEtablish />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 600 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <div style={{ padding: '1em' }}>
       <SeNd />
      </div>

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {


 let iTmclpush = new Array();
 const [itmIdshare, setItmIdshare] = React.useState([]);

 React.useEffect(async () => {

  const shareASnapshot = await getDocs(collection(db, "share" + secureLocalStorage.getItem("#$$!@@%DTR##")));
  shareASnapshot.forEach(async (doc) => {
   iTmclpush.push(doc.id);
  });

  setItmIdshare([...new Set(iTmclpush)]);

 }, []);




 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, itmIdshare.length === 0 ? false : true)}>
      <ReturnListEtablish />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <div style={{ padding: '1em' }}>
       <SeNd />
      </div>

     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};

