import './Nav.css';
import RetuRnAvaTaR from './Avatar';
import ReturnWithUs from './WithUs';


// Nav 
export default function RetuRnNAv() {
 return (
  <div className='wrp-nav-detail-user'>
   <RetuRnAvaTaR />
   <ReturnWithUs />
  </div>
 );
};