
import IcOn from '../../../../../../Info/Home/Head/Icon/Main';
import RetuRnAvaTaR from './Avatar';


// Head Main
export default function RetuRnHeAd() {
 return (
  <div>
   <RetuRnAvaTaR />
   <IcOn />
  </div>
 );
};