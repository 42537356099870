import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/Tabs';

// import { ScreenSmallOrange } from '../../../../Unit/Stock/Throw/Orange/Body/Over';
import { ScreenSmallOrange } from './Orange';
import { ScreenSmallVodA } from './Voda';
import { ScreenSmallAirtel } from './Airtel';
import { ScreenSmallAfricell } from './Africell';

import Media from 'react-media';


const styles = {
 tabs: {
  background: '#16425B',
  color: '#fff'
 },
 slide: {
  minHeight: '100%',
  color: '#333',
 },
 slide1: {
  padding: '1em',
  height: '65vh',
  backgroundColor: '#fff',
  overflowY: 'auto',
 },
 slide2: {
  padding: '1em',
  backgroundColor: '#fff',
 },
 slide3: {
  backgroundColor: '#6AC0FF',
 },

};


export default function RetuRnTAbs() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


class ScreenLarge extends React.Component {
 state = {
  index: 0,
 };

 handleChange = (event, value) => {
  this.setState({
   index: value,
  });
 };
 handleChangeIndex = index => {
  this.setState({
   index,
  });
 };

 render() {
  const { index } = this.state;

  return (
   <div style={{ maxWidth: '73vw' }}>
    <Tabs variant="fullWidth" value={index} onChange={this.handleChange} style={styles.tabs}>

     <Tab label="orange" />
     <Tab label="vodacom" />
     <Tab label="airtel" />
     <Tab label="africell" />
    </Tabs>

    <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>

     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallOrange /></div>
     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallVodA /></div>

     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallAirtel /></div>
     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallAfricell /></div>

    </SwipeableViews>


   </div>
  );
 }

}
class ScreenSmall extends React.Component {
 state = {
  index: 0,
 };

 handleChange = (event, value) => {
  this.setState({
   index: value,
  });
 };
 handleChangeIndex = index => {
  this.setState({
   index,
  });
 };

 render() {
  const { index } = this.state;

  return (
   <div style={{ maxWidth: '21em' }}>
    <Tabs

     // variant="fullWidth" 
     value={index} onChange={this.handleChange} style={styles.tabs}>

     <Tab label="orange" />
     <Tab label="vodacom" />
     <Tab label="airtel" />
     <Tab label="africell" />
    </Tabs>

    <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>

     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallOrange /></div>
     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallVodA /></div>


     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallAirtel /></div>
     <div style={Object.assign({}, styles.slide, styles.slide1)}><ScreenSmallAfricell /></div>

    </SwipeableViews>


   </div>
  );
 }

}

