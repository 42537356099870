import App from './App';
import { Routes, Route } from 'react-router-dom';
import FoRm from './Enrol/Form/Main';
import EnRollshaRing from './Enrolsharing/Main';


// Unit 
import HomeStOcK from './Unit/Stock/Main';
import AuthORity from './Unit/Stock/Authority/Main';
import ThRoW from './Unit/Stock/Throw/Main';
import VodA from './Unit/Stock/Throw/Voda/Main';
import ORAnge from './Unit/Stock/Throw/Orange/Main';
import AiRtel from './Unit/Stock/Throw/Airtel/Main';
import AfRiCell from './Unit/Stock/Throw/Africell/Main';

import ReturnLivE from './Live';


// Muungano ACTIF =====================
import MuunganoActiF from './AllActif/Main';
import ActIF from './AllActif/Actif/Main';
import ActIFCDF from './AllActif/ActifCDf/Main';


// UseR InFo =============================
import InFo from './Info/Main';
import InFoPin from './Info/Pin/Main';

// User TonTine ===========================
import ToNTine from './Tontine/Main';
import MainToNTine from './Tontine/Main/Main';


// User TonTine ===========================
import Pretpin from './Prets/Pin/Main';
import DasH from './Prets/Dash/Main';


// User TonTine ===========================
import LitigePin from './Litige/Pin/Main';
import LiTigeDasH from './Litige/Dash/Main';
import TwO from './Litige/Two/Main';
import PersonAl from './Litige/Personal/Main';
import CAncel from './Litige/Cancel/Main';


// Creditaire =============================
import CreditAIRe from './Creditaire/Main';
import CreditDAsh from './Creditaire/Dash/Main';


// DASH =============================
import DAsH from './Dash/Main';
import RATe from './Dash/Rate/Form/Main';
// import DevEliRy from './Dash/Delivery/Main';


// SEND MONEY =========================
import SeNdMonEy from './Dash/SendMoney/Main';
import TransfeRT from './Dash/SendMoney/Tranfert/Main';
import TRansfeRTBudgeT from './Dash/AddBudget/Tranfert/Main';
import AddbudgET from './Dash/AddBudget/Main';

import AddbudgETPret from './Dash/AddBudgetPret/Main';
import TRansfeRTBudgeTpret from './Dash/AddBudgetPret/Tranfert/Main';


// SHARE  ============================
import ShaRe from './Share/Main';


// URI viewer component 
export default function ReturRnURI() {
 return (
  <Routes>
   <Route path='/enroll/identity' element={<FoRm />} />

   {/* Unit */}
   <Route path='/unit/stock' element={<HomeStOcK />} />
   <Route path='/unit/stock/allow' element={<AuthORity />} />
   <Route path='/unit/stock/throw' element={<ThRoW />} />
   <Route path='/unit/stock/throw/voda' element={<VodA />} />
   <Route path='/unit/stock/throw/orange' element={<ORAnge />} />
   <Route path='/unit/stock/throw/airtel' element={<AiRtel />} />
   <Route path='/unit/stock/throw/africell' element={<AfRiCell />} />

   <Route path='/live' element={<ReturnLivE />} />

   {/* ACTIF  */}
   <Route path='/muungano/actif' element={<MuunganoActiF />} />
   <Route path='/muungano/actif/all' element={<ActIF />} />
   <Route path='/muungano/actif/all/cdf' element={<ActIFCDF />} />


   {/* INFO */}
   <Route path='/user/info' element={<InFo />} />
   <Route path='/user/info/pin' element={<InFoPin />} />


   {/* TONTINE */}
   <Route path='/user/tontine/pin' element={<ToNTine />} />
   <Route path='/user/tontine' element={<MainToNTine />} />

   {/* PRET NEW */}
   <Route path='/pret/pin' element={<Pretpin />} />
   <Route path='/pret/dash' element={<DasH />} />

   {/* LITIGE */}
   <Route path='/litige/pin' element={<LitigePin />} />
   <Route path='/litige/dash' element={<LiTigeDasH />} />
   <Route path='/litige/dash/two' element={<TwO />} />
   <Route path='/litige/dash/personal' element={<PersonAl />} />
   <Route path='/litige/dash/cancel' element={<CAncel />} />


   {/* LITIGE */}
   <Route path='/creditaire/pin' element={<CreditAIRe />} />
   <Route path='/creditaire/dash' element={<CreditDAsh />} />

   {/* DASH */}
   <Route path='/dash' element={<DAsH />} />
   <Route path='/dash/send/money' element={<SeNdMonEy />} />
   <Route path='/dash/send/transfert' element={<TransfeRT />} />
   <Route path='/dash/send/transfert/budget' element={<AddbudgET />} />
   <Route path='/dash/send/transfert/budget/add' element={<TRansfeRTBudgeT />} />
   <Route path='/dash/send/transfert/budget/pret' element={<AddbudgETPret />} />
   <Route path='/dash/send/transfert/budget/add/pret' element={<TRansfeRTBudgeTpret />} />
   <Route path='/dash/rate/' element={<RATe />} />
   {/* <Route path='/dash/delivery/' element={<DevEliRy />} /> */}

   {/* Share */}
   <Route path='/dash/share' element={<ShaRe />} />
   <Route path='/share/enroll' element={<EnRollshaRing />} />

   <Route path='*' element={<App />} />
  </Routes>
 );
};