
import './Unit.css';
import DemoTabs from './Tabs';

// Unit Main
export default function RetuRnUniT() {
 return (
  <div className='wrp-unit-dash'>
   <DemoTabs />
  </div>
 );
};