import React from 'react';
import './Balance.css';
import ReturnPriCE from './Price';
import ReturnTitle from './Title';

// Balance
export default function ReturnBalAnce() {
 return (
  <div className='wrp-balance-actif-all'>
   <ReturnTitle Text={'Client'} />
   <ReturnPriCE />
   <p className='price-color-word'>Lié aux clients</p>
  </div>
 );
};