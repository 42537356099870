import ReturnBacK from './Back';

import Media from 'react-media';
import ReturnOperator from './Operator';
import ReturnCommAnd from './Command';

// Navigation Main
export default function ReturnBacKNAv() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='stock-admin-nav'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='stock-admin-nav'>
  <ViEw />
 </div>
);
export const ViEw = () => (
 <>
  <ReturnBacK />
  <ReturnCommAnd />
  <ReturnOperator />

 </>
);