
import './Progress.css';
import ReturnProgressFRaNc from './CDF';
import ReturnProgressDollaRd from './USD';


// Progress 
export default function ReturnProgrEss() {
 return (
  <div className='flex-between'>

   <ReturnProgressFRaNc />
   <ReturnProgressDollaRd />

  </div>
 );
};