import React from 'react';
import ListTransFeRT from './List/Main';

// Body Transfer Component
export default function ReturnTransFeR() {

 return (
  <div>
   <ListTransFeRT />
  </div>
 );
};