import React from 'react';
import './Count.css';
import { db } from '../../../../firebase';
import { collection, getDocs } from "firebase/firestore";


// Agent Count Total
export default function ReturnUseR() {

 let pushAgent = new Array();
 let pushClient = new Array();

 let [listAgent, setListAgent] = React.useState([]);
 let [listClient, setListClient] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushAgent.push(doc.id);
  });

  setListAgent(pushAgent);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.id);
  });

  setListClient(pushClient);

 }, []);


 return (
  <div className='count-agent-total-info'>
   <p>Agents : {listAgent.length}</p>
   <p>Clients : {listClient.length}</p>

  </div>
 );
};