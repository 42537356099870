import RetuRnLogo from './Logo';
import './Head.css';
import Media from 'react-media';
import RetuRnAvaTaR from './Drawer';


// Head Main
export default function ReturnHeAd() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='flex-between border-bottom-1 wrp-head-1fr pad-right-left-3'>
  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-between border-bottom-1 wrp-head-1fr-sm pad-right-left-3'>
  <ViEw />
 </div>
);
export const ViEw = () => {
 return (
  <>
   <div></div>
   <RetuRnAvaTaR />
  </>
 );
};