import './Head.css';
import ProFil from './Profil/Main';
import BalAnce from './Balance/Main';
import DrAweR from './Drawer/Main';


// Head Main Two
export default function ReturnHeAd() {
 return (
  <>
   <DrAweR />
   <div className='flex-between flex-heath-two-litige'>
    <ProFil />
    <BalAnce />
   </div>
  </>
 );
};