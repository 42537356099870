
import './User.css';
import ProFil from './Profil/Main';


// User Transfert Component
export default function RetuRnUseR() {
 return (
  <div className='wrp-user-transfert-send'>

   <div></div>
   <ProFil />
   <div></div>

  </div>
 );
};