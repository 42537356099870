import React from 'react';
import './Body.css';
import RetuRnLisT from './List';
import RetuRnFrAnLisT from './Fran';
import secureLocalStorage from "react-secure-storage";


// Body Exchange
export default function ReturnBoDY() {

 const [checked, setChecked] = React.useState(secureLocalStorage.getItem("###@devise^^#"));

 React.useEffect(() => {

  setInterval(() => {
   setChecked(!!secureLocalStorage.getItem("###@devise^^#"))
  }, 200);

 }, [])

 return (
  <>
   <div className='wrp-body-exchange-buy-detail scroll-user-list-total'>
    {checked === true && <RetuRnLisT />}
    {checked === false && <RetuRnFrAnLisT />}
   </div>
  </>
 );
};