import './View.css';
import RetuRnTitle from './Title';
import RetuRnPIn from './Pin';


// Main InFo
export default function RetuRnViEw() {
 return (
  <div className='grid-center'>
   <RetuRnTitle />
   <RetuRnPIn />

  </div>
 );
};