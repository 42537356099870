
import RetuRnFrAn from './Fran';
import RetuRnDollard from './Dollard';
import secureLocalStorage from "react-secure-storage";

// Balance Two
export default function ReturnBAlAnce() {
 return (
  <div>

   {secureLocalStorage.getItem("#$$!!devise%%$$$") === 'USD' && <RetuRnDollard />}
   {secureLocalStorage.getItem("#$$!!devise%%$$$") === 'CDF' && <RetuRnFrAn />}

  </div>
 );
};