import ContRibutiOn from './Contribution/Main';
import LisT from './List/Main';
import './Body.css';


// Body Tontine User
export default function RetuRnBody() {
 return (
  <div className='scroll-user-list-tontine'>
   <ContRibutiOn />
   <LisT />
  </div>
 );
};