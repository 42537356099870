
import './Quote.css';

// Quote Component 
export default function RetuRnQouTE() {
 return (
  <div className='flex-admin-quote-addbudget'>
   <p>
    le budget actuel serait revérifier voulez-vous confirmer cette action
   </p>
  </div>
 );
};