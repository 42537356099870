import './Head.css';
import ProFil from './Profil/Main';
import BalAnce from './Balance/Main';


// Head Main Two
export default function ReturnHeAd() {
 return (
  <div className='flex-between flex-heath-two-litige'>
   <ProFil />
   <BalAnce />
  </div>
 );
};