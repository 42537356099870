import './View.css';
import HeAd from './Head/Main';
import GROup from './Group/Main';
import UseR from './User/Main';
import Media from 'react-media';



// View Component Tontine
export default function RetuRnMAin() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 )
};


export const ScreenLarge = () => (
 <div className='grid-template-tontine'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <div className='grid-template-tontine-sm'>
  <>
   <HeAd />
   <UseR />
   <GROup />
   <div></div>
   <div></div>
  </>
 </div>
);
export const View = () => {
 return (
  <>
   <HeAd />
   <GROup />
   <UseR />
   <div></div>
   <div></div>
  </>
 );

}
