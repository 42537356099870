import './Save.css';
import { useNavigate } from 'react-router-dom';

// Save Nav Component 
export default function RetuRnsAve() {

 const navigation = useNavigate();
 const handlepath = (event) => {

  event.preventDefault();
  navigation(0);

 };

 return (
  <div onClick={handlepath} className='wrp-save-edit-info'>
   <h2>sauvegarder</h2>
  </div>
 );
};
