
import './Title.css';
import Media from 'react-media';


// Title Home Component
export default function RetuRnTitle() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='grid-center flex-title-hm-send-money'>
  <h1>déplacer vos actifs</h1>
 </div>
);
export const ScreenSmall = () => (
 <div className='grid-center flex-title-hm-send-money-sm'>
  <h1>déplacer vos actifs</h1>
 </div>
);