import React from 'react';
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import { collection, getDocs } from "firebase/firestore";
import './List.css';
import moment from 'moment';
import currency from 'currency.js';



// List 
export default function RetuRnLisT() {

 let pushDoc = new Array();
 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "pretrefund" + secureLocalStorage.getItem("##phone&&#$$$[]")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
  });

  setList([...new Set(pushDoc.reverse())]);

 }, [])

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='list-exchange-buy-detail pad-3'>
   {[...Array(list.length).keys()].map((item) => {
    return (
     <div className='list-top-exchange-buy-detail'>
      <div className='list-sub-exchange-buy-detail'>
       <p>Mandataire  </p>
       <p>{list[item].phoneagent}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Date  </p>
       <p>{moment(list[item].date).format('LLL')}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Pourcentage  </p>
       <p>{list[item].pretmodulo}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Prêt Initial  </p>
       <p>{euro(list[item].pret).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Prêt Remboursé  </p>
       <p>{euro(list[item].reimburse).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Prêt en coure  </p>
       <p>{euro(list[item].current).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Budget au départ  </p>
       <p>{euro(list[item].lastbudget).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail'>
       <p>Budget revue à  </p>
       <p>{euro(list[item].newbudget).format()} {list[item].devise}</p>
      </div>

     </div>
    );

   })}
  </div>
 );
};