import React from 'react';
import './Live.css';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { getAuth, signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db, auth } from './firebase';

import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// View Wallet Component
export default function ReturnLivE() {

 const navigation = useNavigate();
 const [open, setOpen] = React.useState(true);
 const [date, setDate] = React.useState(null);

 React.useEffect(() => {

  secureLocalStorage.getItem("%%#pin@!!authority**$#@+=+") === false && navigation('/');
  secureLocalStorage.getItem("@#$66ACTIVE@##*") === false && navigation('/');
  window.setTimeout(() => {
   setOpen(false);
  }, 8600);

 }, []);

 let [loggedIn, setLoggedIn] = React.useState(true);

 // function to check for inativity and  log out
 const checkForInactivity = () => {

  // Get Expire  Time from local now, logout 
  const expireTime = localStorage.getItem('expireTime');
  if (expireTime < moment()) {

   window.console.log('log Out!');
   setLoggedIn(false);
   secureLocalStorage.setItem("@#$66ACTIVE@##*", false);
   signOut(auth);
   window.location.href = "/";

  }

 }
 // function to update expire time
 const updateExpireTime = () => {

  const expireTime = moment() + JSON.parse(window.localStorage.getItem('@expire˚˚ø')) * 60000;
  window.localStorage.setItem('expireTime', expireTime)

 }
 React.useEffect(() => {

  const interval = setInterval(() => { checkForInactivity(); }, 5000);
  // Clear interval on unmount
  return () => clearInterval(interval);

 }, []);
 React.useEffect(() => {

  // set Initial Expire Time
  updateExpireTime();

  // set event linestener
  window.addEventListener('click', updateExpireTime);
  window.addEventListener('keypress', updateExpireTime);
  window.addEventListener('scroll', updateExpireTime);
  window.addEventListener('mousemove', updateExpireTime);

  // clean up
  return () => {
   window.addEventListener('click', updateExpireTime);
   window.addEventListener('keypress', updateExpireTime);
   window.addEventListener('scroll', updateExpireTime);
   window.addEventListener('mousemove', updateExpireTime);
  }


 }, []);
 React.useEffect(async () => {

  try {
   const unsub = onSnapshot(doc(db, "muunganomoney", "datecreditaire"), (doc) => {
    setDate(doc.data().date);
   });

  } catch (e) {
   window.console.log(e);
  }

 }, []);


 const handleLogout = (event) => {
  event.preventDefault();

  secureLocalStorage.clear();

  const auth = getAuth();
  signOut(auth).then(() => {
   // Sign-out successful
   window.console.log('sign out');
  }).catch((error) => {
   // An error happened.
   window.console.log('error sign out');
  });

  navigation('/');



 };
 const handleEnrol = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("##@@!!check%%$$", true);
  navigation('/enroll/phone');
 };
 const handlecreditaire = (item) => {

  if (item === null) {
   navigation(0);

  } else {

   secureLocalStorage.setItem("##$$time##$$@@", item);
   window.location.href = '/creditaire/pin'

  }

 };

 return (
  <>
   <div className='wrp-live'>
    <div>
     <p onClick={() => navigation('/unit/stock')}>Unité</p>
     <p onClick={() => navigation('/muungano/actif')}>Actif Muungano</p>
     <p onClick={() => navigation('/user/info/pin')}>Info</p>
     <p onClick={() => handlecreditaire(date)}>Creditaire</p>

     <p onClick={() => navigation('/user/tontine/pin')}>Tontine</p>
     <p onClick={() => navigation('/pret/pin')}>Prêt</p>
     <p onClick={() => navigation('/litige/pin')}>Litige</p>
     <p onClick={handleEnrol}>Enregistrement Agent</p>
     <p onClick={handleLogout}>Se déconnecter</p>
    </div>
   </div>

   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={open}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

  </>
 );
};