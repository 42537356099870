import './Title.css';

// Title Count 
export default function ReturnTitle() {
 return (
  <div className='title-count-total-info'>
   <h2>
    Les utilisateurs
   </h2>
  </div>
 );
};