import React from 'react';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import RetuRnIMA from './IMA';


// Price Group 
export default function ReturnPrice(props) {

 const [item, setItem] = React.useState(0);
 const [asked, setAsked] = React.useState(false);


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("$#sour#ton%%"), props.docsTon), (doc) => {
   setItem(doc.data().solde);
   setAsked(doc.data().askedactive);
   window.console.log(doc.data().soldeactive)
  });

 });

 return (
  <div className='flex-row'>
   {asked === true && <RetuRnIMA IMA={'/img/money-bag.png'} />}
   {item !== 0 && <RetuRnIMA IMA={'/img/money-stack.png'} />}
  </div>
 );
};