import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";


// Identity View
export default function RetuRnBirth() {

 let [accunt, setAccunt] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setAccunt(doc.data().birth);
  });

 }, []);

 return (
  <div className='list-sub-exchange-info-detail color-purple-info'>
   <p>Date de naissance : </p>
   <p>{accunt}</p>
  </div>
 );
};