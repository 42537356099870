import './Dash.css';
import LiST from './List/Main';
import MAin from './Main/Main';
import Media from 'react-media';



// Dash 
export default function RetuRnDAsH() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='flex-heigth'>
  <LiST />
  <MAin />
 </div>
);
export const ScreenSmall = () => (
 <MAin />
);