
import './List.css';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';


// List Buy Component 
export default function ReturnLisT() {

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='wrp-list-buy-transfert-cancel'>
   <ul>

    <li><p>Action</p><p>{secureLocalStorage.getItem("#$$!!list%%#$$").action}</p></li>


    <li><p>Somme envoyée</p><p>{euro(secureLocalStorage.getItem("#$$!!list%%#$$").money).format()} {secureLocalStorage.getItem("#$$!!list%%#$$").devise}</p></li>
    <li><p>Avant transfert</p><p>{euro(secureLocalStorage.getItem("#$$!!list%%#$$").before).format()} {secureLocalStorage.getItem("#$$!!list%%#$$").devise}</p></li>
    <li><p>Après le transfert</p><p>{euro(secureLocalStorage.getItem("#$$!!list%%#$$").after).format()} {secureLocalStorage.getItem("#$$!!list%%#$$").devise}</p></li>
    <li><p>Numéro du bénéficiaire</p><p>{secureLocalStorage.getItem("#$$!!list%%#$$").phone}</p></li>
    <li><p>Nom du bénéficiaire</p><p>{secureLocalStorage.getItem("#$$!!list%%#$$").user}</p></li>


   </ul>
  </div>
 );
};