
import './Title.css';

// Title Head Component
export default function ReturnTitle() {
 return (
  <div className='title-head-refs-creditaire'>
   <h1>
    transaction créditeur

   </h1>
  </div>
 );
};