import './Nav.css';
import RiGth from './Rigth/Main'
import LEft from './Left/Main'
import Media from 'react-media';
import MeNu from './Menu/Main';
// import RetuRnCounT from './Count';


// Nav Main
export default function RetuRnNAv() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   { matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className='wrp-nav-head-dash flex-between'>
  <LEft />
  <div></div>
  <RiGth />

 </div>
);
export const ScreenSmall = () => (
 <>
  <MeNu />
  <div className='wrp-nav-head-dash flex-between'>
   <LEft />
   <div></div>
   <RiGth />
  </div>
 </>
);

