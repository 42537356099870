

import './Title.css';

// Title Consumption
export default function ReturnTitle() {
 return (
  <div className='title-consumption-info'>
   <h3>Consommation</h3>
  </div>
 );
};