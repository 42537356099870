import React from 'react';

import { db } from '../../../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import './Avatar.css';
import Media from 'react-media';
import currency from 'currency.js';


// Avatar view 
export default function RetuRnBalAnce() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => {

 const [cdf, setCdf] = React.useState(0);
 const [usd, setUsd] = React.useState(0);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setCdf(doc.data().cdf);
   setUsd(doc.data().usd);
  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='wrp-avatar-transfer-identity'>
   <p>{euro(cdf).format()} CDF</p>
   <p>{euro(usd).format()} USD</p>

  </div>

 );

};

export const ScreenSmall = () => {

 const [cdf, setCdf] = React.useState(0);
 const [usd, setUsd] = React.useState(0);

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setCdf(doc.data().cdf);
   setUsd(doc.data().usd);
  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });


 return (
  <div className='wrp-avatar-transfer-identity'>
   <p>{euro(cdf).format()} CDF</p>
   <p>{euro(usd).format()} USD</p>

  </div>
 );
};


