
import RetuRnLisT from './List';

// Body
export default function RetuRNBody() {
 return (
  <div className='scroll-user-list-tontine'>
   <RetuRnLisT />
  </div>
 );
};