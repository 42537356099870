import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import './List.css';
import secureLocalStorage from "react-secure-storage";
import { doc, onSnapshot, } from "firebase/firestore";
import { db } from '../../../../firebase';



export default function ReturnList() {


 const [voda, setVoda] = React.useState(null)
 const [airtel, setAirtel] = React.useState(null)
 const [orange, setOrange] = React.useState(null)
 const [africel, setAfricel] = React.useState(null)


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "creditairephone"), (doc) => {

   setVoda(doc.data().vodacom);
   setOrange(doc.data().orange);
   setAirtel(doc.data().airtel);
   setAfricel(doc.data().africell);

  });

 }, []);
 const handlenetwork = (item, network, phone, prefix, img, opeartor) => {

  secureLocalStorage.setItem("#$$img@!!@98***", item);
  secureLocalStorage.setItem("#$$network##$", network);
  secureLocalStorage.setItem("#$$phone##$", phone);

  secureLocalStorage.setItem("#@@^^check%%%$$", false)
  secureLocalStorage.setItem("#prefix%%%$$", prefix);
  secureLocalStorage.setItem("#img&&##%$$", img);

  secureLocalStorage.setItem("#opeartor##&&##%$$", opeartor);

 };


 return (
  <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
   <nav aria-label="main mailbox folders">
    <List>

     <ListItem onClick={() => handlenetwork('/img/orange-money.png', 'orangemoney', orange, ['089', '085', '084'], '/img/orange-money.png', 'orange')} style={{ marginTop: '0.5ch', marginBottom: '0.5ch' }} disablePadding>
      <ListItemButton>
       <ListItemIcon>
        <Avatar sx={{ bgcolor: '#923002', width: 45, height: 45, marginRight: '1em' }} src={'/img/orange-money.png'} variant="rounded" />
       </ListItemIcon>
       <ListItemText >

        <h1 className='flex-uppercase-1'>Orange</h1>

       </ListItemText>
      </ListItemButton>
     </ListItem>

     <Divider />

     <ListItem onClick={() => handlenetwork('/img/mpesa.png', 'mpesa', voda, ['083', '082', '081'], '/img/mpesa.png', 'vodacom')} style={{ marginTop: '0.5ch', marginBottom: '0.5ch' }} disablePadding>
      <ListItemButton>
       <ListItemIcon>

        <Avatar sx={{ bgcolor: '#923002', width: 45, height: 45, marginRight: '1em' }} src={'/img/mpesa.png'} variant="rounded" />

       </ListItemIcon>
       <ListItemText >

        <h1 className='flex-uppercase-1'>vodacom</h1>

       </ListItemText>
      </ListItemButton>
     </ListItem>

     <Divider />

     <ListItem onClick={() => handlenetwork('/img/afrimoney.png', 'afrimoney', africel, ['090', '091'], '/img/afrimoney.png', 'africell')} style={{ marginTop: '0.5ch', marginBottom: '0.5ch' }} disablePadding>
      <ListItemButton>
       <ListItemIcon>

        <Avatar sx={{ bgcolor: '#923002', width: 45, height: 45, marginRight: '1em' }} src={'/img/afrimoney.png'} variant="rounded" />

       </ListItemIcon>
       <ListItemText >

        <h1 className='flex-uppercase-1'>africell</h1>

       </ListItemText>
      </ListItemButton>
     </ListItem>

     <Divider />

     <ListItem onClick={() => handlenetwork('/img/airtelmoney.png', 'airtelmoney', airtel, ['099', '098', '097'], '/img/airtelmoney.png', 'airtel')} style={{ marginTop: '0.5ch', marginBottom: '0.5ch' }} disablePadding>
      <ListItemButton>
       <ListItemIcon>

        <Avatar sx={{ bgcolor: '#923002', width: 45, height: 45, marginRight: '1em' }} src={'/img/airtelmoney.png'} variant="rounded" />

       </ListItemIcon>
       <ListItemText >

        <h1 className='flex-uppercase-1'>Airtel</h1>

       </ListItemText>
      </ListItemButton>
     </ListItem>


    </List>
   </nav>
  </Box>
 );
};
