import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import { db } from '../../../firebase';
import { collection, query, getDocs } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';


export default function ReturnListEtablish() {


 let pushDoc = new Array();
 let pushEtablCode = new Array();
 let pushlit = new Array();

 const [listEtablish, setListEtablish] = React.useState([]);
 const [listEtablCode, setListEtablCode] = React.useState([]);


 React.useEffect(async () => {

  const q = query(collection(db, "sharecashbeck"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots;
   pushDoc.push(doc.id)
   pushEtablCode.push(doc.data().establishmentcode);

  });

  setListEtablish([...new Set(pushDoc)]);
  setListEtablCode([...new Set(pushEtablCode)]);

 }, []);

 return (
  <div>
   {listEtablCode.length === 0 ?
    <div className="flex-etablish-list" style={{ display: 'grid', placeItems: 'center', height: '80vh' }}>
     <Avatar
      src="/img/empty-folder.png"
      sx={{ width: 65, height: 65 }}
     />
    </div>

    :

    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
     <nav aria-label="secondary mailbox folders">

      {[...Array(listEtablish.length).keys()].map((item) => {

       return (
        <div
         onClick={async () => {

          secureLocalStorage.setItem("#$$!@@%DTR##", listEtablish[item]);
          secureLocalStorage.setItem("#$$!@@%ERR##", listEtablCode[item]);

          const querySnapshot = await getDocs(collection(db, `share${listEtablish[item]}`));
          querySnapshot.forEach((doc) => {
           // doc.data() is never undefined for query doc snapshots
           pushlit.push(doc.id);

          });

          secureLocalStorage.setItem("#$$!@TYU#&#", pushlit);
          secureLocalStorage.setItem("#$$!@TRH##", pushlit.length === 0 ? false : true);

         }}>

         <List>
          <ListItem disablePadding>
           <ListItemButton>

            <div>
             <p style={{ paddingTop: '1em' }} className="pop-up">
              {listEtablCode[item]}
             </p>
            </div>

           </ListItemButton>
          </ListItem>

          <Divider />
         </List>

        </div>
       )

      })}
     </nav>
    </Box>

   }
  </div>
 );
};
