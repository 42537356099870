import React from 'react';
import './View.css';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from '../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useForm, Controller } from 'react-hook-form';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import MomentUtils from "@date-io/moment";
import moment from 'moment';
import "moment/locale/fr";


// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
 DatePicker,
 MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { FiCheck } from "react-icons/fi";
import Media from 'react-media';



// View Name Component
export default function RetuRnBirth() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [locale, setLocale] = React.useState("fr");
 const [load, setLoad] = React.useState(false);
 const [item, setItem] = React.useState('');
 const [selectedDate, handleDateChange] = React.useState(new Date());
 const { formState, handleSubmit, control, watch } = useForm({});


 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().birth);
  });

 }, []);


 let result = item;
 let view = watch('birth', result);

 const handleUpdate = async () => {
  window.console.log(moment(view).format('LL'));
 };

 const onSubmit = async (data) => {

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   birth: moment(data.birth).format('LL'),
  });

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-between-edit'>
    <div className='flex-block'>
     <span>Date de naissance</span>
     <p>{result}</p>
    </div>

    <React.Fragment>
     <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

      <Controller
       name="birth"
       control={control}
       render={({ field }) =>

        <MuiPickersUtilsProvider
         libInstance={moment} utils={MomentUtils} locale={locale}>


         <DatePicker
          disableFuture
          openTo="year"
          format="dd/MM/yyyy"
          label="Date de naissance"
          views={["year", "month", "date"]}
          value={selectedDate}
          onChange={handleUpdate}
          {...field}
         />

        </MuiPickersUtilsProvider>}
      />


      <button>

       <IconButton color="primary" aria-label="edit" size="large">
        <FiCheck size={'.8em'} />
       </IconButton>

      </button>
     </form>

    </React.Fragment>
   </div>

   <Divider />
  </>
 );
}
export const ScreenSmall = () => {


 const [locale, setLocale] = React.useState("fr");
 const [load, setLoad] = React.useState(false);
 const [item, setItem] = React.useState('');
 const [selectedDate, handleDateChange] = React.useState(new Date());
 const { formState, handleSubmit, control, watch } = useForm({});


 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().birth);
  });

 }, []);


 let result = item;
 let view = watch('birth', result);

 const handleUpdate = async () => {
  window.console.log(moment(view).format('LL'));
 };
 const onSubmit = async (data) => {

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   birth: moment(data.birth).format('LL'),
  });

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-between-edit-sm'>
    <div className='flex-block-sm'>
     <span>Date de naissance</span>
     <p>{result}</p>
    </div>

    <React.Fragment>
     <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

      <Controller
       name="birth"
       control={control}
       render={({ field }) =>

        <MuiPickersUtilsProvider
         libInstance={moment} utils={MomentUtils} locale={locale}>


         <DatePicker
          disableFuture
          openTo="year"
          format="dd/MM/yyyy"
          label="Date de naissance"
          views={["year", "month", "date"]}
          value={selectedDate}
          onChange={handleUpdate}
          {...field}
         />

        </MuiPickersUtilsProvider>}
      />

      <button>
       <IconButton color="primary" aria-label="edit" size="large">
        <FiCheck size={'.5em'} />
       </IconButton>

      </button>
     </form>

    </React.Fragment>
   </div>

   <Divider />
  </>
 );
}
