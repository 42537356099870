import React from 'react';
import { db } from '../../../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import { collection, getDocs } from "firebase/firestore";
import './List.css';
import moment from 'moment';
import currency from 'currency.js';
import Media from 'react-media';


// List 
export default function RetuRnLisT() {

 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenLarge />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {


 let pushDoc = new Array();
 const [list, setList] = React.useState([]);

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "tontine" + secureLocalStorage.getItem("#!!!list@#%%")));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data());
  });

  setList([...new Set(pushDoc.reverse())]);

 }, [])

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 return (
  <div className='list-exchange-buy-detail-view'>

   {[...Array(list.length).keys()].map((item) => {
    return (
     <div className='list-top-exchange-buy-detail-view'>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Transfert  : </p>
       <p>{euro(list[item].transfer).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Solde avant :  </p>
       <p>{euro(list[item].before).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Solde après :  </p>
       <p>{euro(list[item].after).format()} {list[item].devise}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Date :  </p>
       <p>{moment(list[item].date).format('LLL')}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Utiliser :  </p>
       <p>{(list[item].use).toLowerCase()}</p>
      </div>

      <div className='list-sub-exchange-buy-detail-view'>
       <p>Nom du groupe :  </p>
       <p>{list[item].namegroup}</p>
      </div>


     </div>
    );

   })}
  </div>

 );
};
