import * as React from 'react';
import './IMA.css';
import FormInputValue from './Input';


export default function RetuRnIMAProFil() {

 return (
  <div className='block-center block-gap-1 flex-avatar-user-profil-transfert'>
   <FormInputValue />
  </div>
 );
};
