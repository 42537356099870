import './Nav.css';
import ReturnBacK from './Back';

// Navigation Main
export default function ReturnBacKNAv() {
 return (
  <div className='admin-nav'>
   <ReturnBacK />
   <div></div>

  </div>
 );
}