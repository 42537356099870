import './User.css';
import HeAd from './Head/Main';
import BOdy from './Body/Main';


// User Main
export default function RetuRNUseR() {
 return (
  <div className='grid-user-tontine border-left-1'>
   <HeAd />
   <BOdy />
  </div>
 );
};