import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { doc, setDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import "moment/locale/fr";

import Select from "react-select"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CiWifiOff } from "react-icons/ci";
import secureLocalStorage from "react-secure-storage";
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import currency from 'currency.js';



export let nowField = moment().date();
export let pushDocs = new Array();
export let fromdate = moment().format();


let key = v4();



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputmode="tel"
      pattern="[0-9]*"

      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      inputmode="tel"
      valueIsNumericString
      prefix=""
    />
  );
});
NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


const currencies = [
  {
    value: 'USD',
    label: 'Simple',
  },
  {
    value: 'EUR',
    label: 'Mere',
  },
];


// Input Field Component !!!
export default function ReturnInpuT() {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width:1199px)',
        large: '(min-width: 1200px)',
      }}>
      {matches => (
        <>
          {matches.small && <ScreenSmall />}
          {matches.medium && <SreenLarge />}
          {matches.large && <SreenLarge />}
        </>
      )}
    </Media>
  );
};

export const SreenLarge = () => {
  return (
    <div className='input-signin-user'>
      <FormInput />
    </div>
  )
}
export const ScreenSmall = () => {
  return (
    <div className='input-signin-user-sm'>
      <FormInput />
    </div>
  )
};


export const FormInput = () => {


  let regular = /[a-z]+/;
  const navigation = useNavigate();


 const [opened, setOpened] = React.useState(false);
 const [view,setView] = React.useState(true);

 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpened(false);
 };



  const [locale, setLocale] = React.useState("fr");
  const [wifi, setWifi] = React.useState(false);


 const [selectedOption, setSelectedOption] = React.useState('agent mere');
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [load, setLoad] = React.useState(false);
  const [problem, setProblem] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [depot, setDepot] = React.useState(false);
  const [phone, setPhone] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');


  const { register, formState, handleSubmit, control } = useForm({});
  const { isSubmitting } = formState;


  let profildefault = 'https://firebasestorage.googleapis.com/v0/b/ungamamoney-wallet.appspot.com/o/image%2Flogo.png?alt=media&token=a1b84070-76b5-43c7-a3e1-199e353c0711'


  const existing = {
    active: true, state: "agent", cdf: 0, usd: 0, thriftcdf: 0, thriftusd: 0, pretexten: '', sendtype: '', accountdate: moment().format(), pret: false, pretregister: false, pretactive: false, code: '000000', pin: 'ungano', solde: 0, frais: 0, extension: 'extension', profile: profildefault
  };


  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const phoneClose = () => {
    setPhone(false);
  };
  const depotClose = () => {
    setDepot(false);
  };
  const handlewifiClose = () => {
    setWifi(false);
  };
  const handleproblemClose = () => {
    setProblem(false);
    navigation('/dash');
  };

  const onSubmit = async (data) => {

    var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
    let time = moment().format('LLL');
    let get = { date: time, solde: `${euro(0).format()} CDF [INSCRIPTION]`, user: 'Cashbeck', type: 'Reçu', price: euro(0).format(), actual: euro(0).format() + ' CDF', unite: 'CDF' }

    if (data.phone === undefined || data.phoneaccount === undefined) {

      setPhone(true);
      setLoad(false);

    } else {

      setLoad(true);

      let num = (data.phone).match(/\d+/g);
      let numPhone = '';
      num.map(index => {
        numPhone += index;

      })


      let numed = (data.phoneaccount).match(/\d+/g);
      let accountphone = '';
      numed.map(index => {
        accountphone += index;
      })

      if (numPhone.length != 10 || accountphone.length != 10) {

        setPhone(true);
        setLoad(false);

      } else {
      
        let dateBirth = data.birth;

        if (dateBirth) {

          Object.defineProperty(data, 'birth', {
            value: dateBirth.format('LL'),
            writable: true,
            configuration: true,
            enumerable: true

          });

          let mail = '';

          if (data.email === undefined || data.email === '') {
            mail = '';
          } else {
            mail = data.email;
          }

          let objInput = {

            addresse: data.addresse,
            birth: data.birth,
            email: mail,
            firstname: data.firstname,
            lastname: data.lastname,
            phone: accountphone,
            ville: data.ville,

            countsharing : 0,
            countsharingcdf : 0,

            commistockcdf: 0,
            commistockusd: 0,
            commistockedate: (moment().month()) + 1,

            commistockunitecdf: 0,
            commistockunite: 0,

            iduser: (data.firstname + data.lastname + accountphone).toLowerCase(),
            idphone: accountphone,
            idcarte: data.id,

            signatureusd: `${accountphone}${0}${0}${0}${key}`,
            signatureusdtype: 'reussi',
            signaturemoneyusd: 0,
            signaturekeyusd: key,
            signaturefraisusd: 0,
            
            signaturecdf: `${accountphone}${0}${0}${0}${key}`,
            signaturecdftype: 'reussi',
            signaturemoneycdf: 0,
            signaturekeycdf: key,
            signaturefraiscdf: 0,

            team: selectedOption.value,
            money: 0,
            user: "Cashbeck",
            unite: 'CDF',
            phoneclient: 777,
            delay: moment().format(),
            sendtype: 'Reçu'

          }

          setOpened(true);
          setView(false)
          const clone = Object.assign(objInput, existing);          
          await setDoc(doc(db, "agent", `${accountphone}`), clone);

          window.setTimeout(async () => {
              window.location.href = "/dash";
          }, 7500);


        } else {

          setLoad(false);
          setOpen(true);

        }

      }
    }

  };

  return (
  <>
    <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
     <Alert
      onClose={handleClosed}
      severity="success"
      variant="filled"
      sx={{ width: '100%' }}
     >
      <p className='pop-up'>
       l'agent a été enregistré avec succès
      </p>

     </Alert>
    </Snackbar>

   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>


<div style={{paddingTop :'5vh'}}></div>



    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numéro du compte</h1></InputLabel>

         <Controller
      name="phoneaccount"
           required
           inputProps={{ autoComplete: "off" }}
           control={control}
           render={({ field }) =>

             <Input
               autoFocus
               required
               inputProps={{ autoComplete: "off" }}
               value={values.textmask}
               onChange={handleChange}
               InputLabelProps={{ required: true }}
               inputProps={{
                 autoComplete: "off", inputMode: 'tel'
               }}
               name="textmask"
               id="formatted-text-mask-input"
               inputComponent={TextMaskCustom}
               {...field}
             />

           }
         />
       </FormControl>



    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel
    
      htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Nom</h1></InputLabel>

     <Controller
      name="firstname"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        name="firstname"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Prènom</h1></InputLabel>

     <Controller
      name="lastname"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        name="lastname"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Email</h1></InputLabel>

     <Controller
      name="email"

      control={control}
      render={({ field }) =>

       <Input
        inputProps={{ autoComplete: "off" }}
        name="email"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero de contact</h1></InputLabel>

     <Controller
      name="phone"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input

        required
        inputProps={{ autoComplete: "off" }}
        value={values.textmask}
        onChange={handleChange}
        InputLabelProps={{ required: true }}
        inputProps={{
         autoComplete: "off", inputMode: 'tel'
        }}
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>ID Carte d'identité</h1></InputLabel>

     <Controller
      name="id"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        inputProps={{ autoComplete: "off" }}
        name="id"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Ville</h1></InputLabel>

     <Controller
      name="ville"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        inputProps={{ autoComplete: "off" }}
        name="ville"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Addresse</h1></InputLabel>

     <Controller
      name="addresse"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        inputProps={{ autoComplete: "off" }}
        name="addresse"
        {...field}
       />

      }
     />
    </FormControl>


<div style={{ margin : '3vh 0'}}>

        <Controller
          name="select"
          required
          control={control}
          render={({ field }) => (
            <Select
              {...field}

            defaultValue={selectedOption}
            onChange={setSelectedOption}

              options={[
                { value: "mere", label: "agent mere" },
                { value: "simple", label: "agent simple" },
              ]}
            />
          )}
        />

</div>

    <Controller
     name="birth"
     control={control}
     render={({ field }) =>

      <MuiPickersUtilsProvider
       libInstance={moment} utils={MomentUtils} locale={locale}>

       <DatePicker
        disableFuture
        openTo="year"
        format="dd/MM/yyyy"
        label="Date de naissance"
        views={["year", "month", "date"]}
        value={selectedDate}
        onChange={handleDateChange}
        {...field}
       />

      </MuiPickersUtilsProvider>}
    />


{view &&<button disabled={isSubmitting} className='Btn'>Enregistre</button>}

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}
    >
     <DialogTitle><span className='pop-up'>MuunganoMoney</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
              Veuillez confirmer la date de naissance
            </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={phone}
     onClose={phoneClose}
    >
     <DialogTitle><span className='pop-up'>MuunganoMoney</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
    Veuillez vérifier les numéros que vous avez établis, celle-ci est incorrecte
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={phoneClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={depot}
     onClose={depotClose}
    >
     <DialogTitle><span className='pop-up'>MuunganoMoney</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>
              Le solde actuel n'est pas suffisant pour finaliser cette inscription
      </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={depotClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

   </form>


   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={wifi}
    onClose={handlewifiClose}>

    <DialogContent>

     <DialogContentText>
      <div className='block-flex-center-wifi'>
       <CiWifiOff size={'2em'} color={'crimson'} />
       <p className='pop-up'>
              Connection internet faible

        </p>
      </div>
     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handlewifiClose}><span className='pop-up'>Fermer</span></Button>
    </DialogActions>
   </Dialog>

	 <Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={problem}
				onClose={handleproblemClose}>
				<DialogTitle><span className='pop-up'>MuunganoMoney</span></DialogTitle>
				<DialogContent>

					<DialogContentText>

						<p className='info-detect'>

            Votre compte est temporairement suspendu,
            après vérification 
            	
            sur une situation
            incorrect des vos comptes, de vous, soit de votre destinataire, veuillez contacter MuunganoMoney

								</p>
					</DialogContentText>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleproblemClose}><span className='pop-up'>Fermer</span></Button>
				</DialogActions>
			</Dialog>

  </>
 )
};
