
import './Icon.css';
import Media from 'react-media';


// icon view
export default function RetuRnIcOn(props) {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall bgcolor={props.bgcolor} color={props.color} icon={props.icon} Text={props.Text} />}
     {matches.medium && <ScreenLarge bgcolor={props.bgcolor} color={props.color} icon={props.icon} Text={props.Text} />}
     {matches.large && <ScreenLarge bgcolor={props.bgcolor} color={props.color} icon={props.icon} Text={props.Text} />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = (props) => (
 <div className='flex-list-grid-center'>
  <ViEw bgcolor={props.bgcolor} color={props.color} icon={props.icon} Text={props.Text} />
 </div>
);
export const ScreenSmall = (props) => (
 <div className='flex-list-grid-center-sm'>
  <ViEw bgcolor={props.bgcolor} color={props.color} icon={props.icon} Text={props.Text} />
 </div>
);
export const ViEw = (props) => (
 <>
  <div style={{ backgroundColor: props.bgcolor, color: props.color }} className='icon-dash'>{props.icon}</div>
  <span>{props.Text}</span>
 </>
);