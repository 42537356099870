import './Left.css';
import HeAd from './Head/Main';
import RiGHtBodY from './Body/Main';


// Left Main
export default function RetuRnLEFt() {
 return (
  <div className='flex-litige-left'>
   <HeAd />
   <RiGHtBodY />
  </div>
 );
};