
import './Devise.css';
import { useNavigate } from 'react-router-dom';

// Devise Back
export default function ReturnDevise() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  window.location.href = '/muungano/actif/all';
  // navigation('/muungano/actif/all');

 }

 return (
  <div className='wrp-currency-acitf-all'>
   <img onClick={handlepath} src={'/img/dollars.png'} />
  </div>
 );
}