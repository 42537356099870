
import ReturnTitle from './Title';
import './Head.css';


// Head Repay 
export default function ReturnHeAd() {
 return (
  <div className='border-bottom-1'>
   <ReturnTitle />
  </div>
 );
};