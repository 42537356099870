import React from 'react';
import SwipeableViews from 'react-swipeable-views';
// import secureLocalStorage from "react-secure-storage";
import PrET from '../Pret/Main';
import UseR from '../User/Main';
// import MAin from '../../../../../Tontine/Main/Main'

import { bindKeyboard } from 'react-swipeable-views-utils';
const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);


function RetuRnKeyboardTwo() {
 return (
  <BindKeyboardSwipeableViews>
   <UseR />
   <PrET />
  </BindKeyboardSwipeableViews>
 );
};

export default RetuRnKeyboardTwo;
