
import RetuRnPrET from './Pret';
import ProFil from './Profil/Main';


// Main Head wait
export default function RetuRnHeAd() {
 return (
  <>
   <ProFil />
   <RetuRnPrET />
  </>
 );
}