
import './IMA.css';
import Media from 'react-media';

// View Home Return page
export default function ReturnIMA() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
}

export const ScreenLarge = () => (
 <div className='hm-IMA'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <div className='hm-IMA-sm'>
  <View />
 </div>);

export const View = () => (
 <img src={'/img/hm-admin.jpg'} alt='muungano admin' />
)