import React from 'react';
import { db } from '../../../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import './List.css';
import { CiSearch } from "react-icons/ci";
import { useForm } from "react-hook-form";
import Avatar from '@mui/material/Avatar';

import currency from 'currency.js';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



let Empty = true;
export let phoneID = '';


// List Dash 
export default function ReturnLisTUseR() {
 return (
  <ViEw />
 );
};

export const ViEw = () => {

 const {
  register,
  watch,
  handleSubmit,
 } = useForm();

 const navigation = useNavigate();

 let pushClient = new Array();
 let pushAgent = new Array();

 const [load, setLoad] = React.useState(false);
 const [listclient, setListclient] = React.useState([]);
 const [listagent, setListagent] = React.useState([]);


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "client"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushClient.push(doc.data());

  });

  setListclient([... new Set(pushClient)])

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushAgent.push(doc.data());

  });

  setListagent([... new Set(pushAgent)]);

 }, []);


 let watchSearch = watch("search", false);
 let list = [...listclient, ...listagent];

 // list = list.sort(() => Math.random() - 0.2);
 if (Array.isArray(list) && !list.length) {
  Empty = true;
 } else {
  Empty = false;
 };


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 watchSearch = watchSearch === undefined ? '' : watchSearch;

 const handlepath = (phone, state) => {

  setLoad(true);
  secureLocalStorage.setItem("@#%%!state&&*@@#!!user++", state.toLowerCase());
  secureLocalStorage.setItem("@#%%!phone&&*@@#!!user++", phone);

  window.setTimeout(() => {
   setLoad(false);
   navigation(0)
  }, 3500);
 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>


   {
    Empty === true
     ?
     <div></div>
     :
     <div className='wrp-column-hm-dash-list-user pad-left-right-3'>
      <form>
       <input type='search' {...register("search")} autocomplete="off" onFocus={true} placeholder="Recherche d'utilisateur" />
       <CiSearch size={'1.8em'} />
      </form>

      <div className='wrp-hm-dash-list-user'>
       {['Utilisateur', 'Balance', 'Status'].map((index) => {
        return (
         <h3>{index}</h3>
        )
       })}
      </div>
      <div>

       {[...list].filter((item) => {

        return watchSearch.toLowerCase() === '' ? item : item.iduser.includes(watchSearch.toLowerCase());

       }).map(index => {

        let first = index.firstname.toLowerCase() + '';
        let last = index.lastname.toLowerCase() + '';

        let nameFirst = first.charAt(0).toUpperCase() + first.slice(1);
        let nameLast = last.charAt(0).toUpperCase() + last.slice(1);

        phoneID = index.idphone;

        return (
         <div onClick={() => handlepath(index.idphone, index.state)} className='wrp-hm-dash-list-user'>
          <span>

           <Avatar src={index.profile} />
           <div className='grid-start'>
            <p>{nameFirst} {nameLast}</p>
           </div>
          </span>

          <div className='grid-start flex-money-user-list'>
           <span>{euro(index.usd).format()} USD</span>
           <span>{euro(index.cdf).format()} CDF</span>
          </div>

          <Chip label={`${index.state.charAt(0).toUpperCase() + index.state.slice(1)}`} color={(index.state).toLowerCase() === 'client' ? 'primary' : 'success'} />

         </div>
        );
       })}
      </div>
     </div>
   }
  </>
 );
};