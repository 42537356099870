

import RetuRnWAlleTdollard from './Walletusd';
import RetuRnWAlleTFrAn from './Walletcdf';

// Budget Main
export default function RetuRnBudGeT() {
 return (
  <div className='flex-between wrp-budget-head-dash'>
   <RetuRnWAlleTdollard />
   <RetuRnWAlleTFrAn />
  </div>
 );
};