import * as React from 'react';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import './Devise.css';
import secureLocalStorage from "react-secure-storage";
import Media from 'react-media';




export default function ControlledSwitches() {

 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>

 );
};


export const ScreenLarge = () => (
 <ViEW />
);
export const ScreenSmall = () => {

 const [checked, setChecked] = React.useState(true);
 const handleChange = (event) => {
  setChecked(event.target.checked);
  secureLocalStorage.setItem("##$devise&&^^##", event.target.checked)

 };
 const handleClick = (item) => {
  secureLocalStorage.setItem("##$devise&&^^##", item)
 };


 return (
  <Stack direction="row" spacing={2}>
   <div className='flex-grid-center-creditaire'>
    <Avatar
     alt="Remy Sharp"
     src={checked ? '/img/dollars.png' : '/img/franc.png'}
     sx={{ width: 40, height: 40 }}
    />

    <Switch
     checked={checked}
     onClick={() => handleClick(checked)}
     onChange={handleChange}
     inputProps={{ 'aria-label': 'controlled' }}
    />
   </div>
  </Stack>
 );

}
export const ViEW = () => {

 const [checked, setChecked] = React.useState(true);
 const handleChange = (event) => {
  setChecked(event.target.checked);
  secureLocalStorage.setItem("##$devise&&^^##", event.target.checked)

 };
 const handleClick = (item) => {
  secureLocalStorage.setItem("##$devise&&^^##", item)
 };

 return (
  <Stack direction="row" spacing={2}>
   <div className='flex-grid-center-creditaire'>
    <Avatar
     alt="Remy Sharp"
     src={checked ? '/img/dollars.png' : '/img/franc.png'}
     sx={{ width: 45, height: 45 }}
    />

    <Switch
     checked={checked}
     onClick={() => handleClick(checked)}
     onChange={handleChange}
     inputProps={{ 'aria-label': 'controlled' }}
    />
   </div>
  </Stack>

 )
};