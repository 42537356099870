import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';
import Media from 'react-media';



// Identity View
export default function RetuRnFrAn() {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width:1199px)',
        large: '(min-width: 1200px)',
      }}>
      {matches => (
        <>
          {matches.small && <ScreenSmall />}
          {matches.medium && <ScreenLarge />}
          {matches.large && <ScreenLarge />}
        </>
      )}
    </Media>
  );
};


export const ScreenLarge = () => {

  let [price, setPrice] = React.useState(0);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
      setPrice(doc.data().cdf);

    });

  }, []);

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

  return (
    <div className='list-sub-exchange-buy-detail color-purple-info-low'>
      <p>Solde en franc : </p>
      <p>{euro(price).format()} CDF</p>
    </div>
  );
}

export const ScreenSmall = () => {

  let [price, setPrice] = React.useState(0);
  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
      setPrice(doc.data().cdf);

    });

  }, []);

  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

  return (
    <div className='list-sub-exchange-info-detail color-purple-info-low'>
      <p>Solde en franc : </p>
      <p>{euro(price).format()} CDF</p>
    </div>);
}