
import NAv from './Nav/Main';
import BudGeT from './Budget/Main';
import InFO from './Info/Main';
import AvailAbleSMS from './SMS/Main';



// Head Main
export default function ReturnHeAd() {
 return (
  <>
   <AvailAbleSMS />
   <NAv />
   <BudGeT />
   <InFO />
  </>
 );
};