
import './Actif.css';
import HOme from './Home/Main';


// Actif Muungano 
export default function ReturnActiF() {
 return (
  <div className='wrp-actif-all'>
   <HOme />
  </div>
 );
}