import React from 'react';
import { db } from '../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import './List.css';
import currency from 'currency.js';
import { AiOutlinePercentage } from "react-icons/ai";


// Dollard List User
export default function RetuRnDollArd(props) {

 const [item, setItem] = React.useState(null);
 const [income, setIncome] = React.useState(null);

 React.useEffect(async () => {


  const unsub = onSnapshot(doc(db, "client", `${props.phone}`), (doc) => {
   setItem(doc.data().thriftusd);
  });


 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "commissionregister"), (doc) => {
   setIncome(doc.data().income);
  });
 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className="flex-thrift">
   <p>{euro(item).format()} USD</p>
   <p style={{ paddingLeft: '1.5em' }}>{income} <AiOutlinePercentage size={'1em'} /> </p>
   <p style={{ color: "#f35b04" }}>{euro(item * income / 100).format()} USD</p>
  </div>
 );
};
