
import RetuRnCouNT from './Count';
import './Head.css';


// Head Main
export default function ReturnHeAd() {
 return (
  <div className='flex-head-group-tontine border-bottom-1'>
   <RetuRnCouNT />
  </div>
 );
};
