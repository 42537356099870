import React from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";


// Avatar view 
export default function RetuRnAvaTaR() {

 const [profil, setProfil] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setProfil(doc.data().profile);
  });
 }, []);

 return (
  <Avatar
   src={profil}
   sx={{ bgcolor: deepOrange[50], width: 44, height: 44 }}
  />
 );
};