

// Title Progress Component 
export default function ReturnTitle() {
 return (
  <div className='title-consumption-info'>
   <h3>Aperçu sur les informations du demandeur</h3>

  </div>
 );
}