import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import './IMA.css';
import { db } from '../../../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { v4 } from 'uuid';
import currency from 'currency.js';



let key = v4();


export default function RetuRnAvatars() {


 const [profil, setProfil] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 const [soldeMain, setSoldeMain] = React.useState(null);
 const [soldeDollard, setSoldeDollard] = React.useState(null);


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, !!secureLocalStorage.getItem("##**$$cols#$$") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$%%")), (doc) => {

   setSoldeMain(doc.data().cdf);
   setSoldeDollard(doc.data().usd);

   setProfil(doc.data().profile);
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);

  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-profil-left-litige-transf'>
   <div>
    <Avatar
     src={profil}
     sx={{ width: 55, height: 55 }}
    />
   </div>

   <h4>{first} {last}</h4>
   <div className='grid-center'>
    <h5 style={{ color: 'grey' }}>la balance serait révisé à</h5>
    <h5
     style={{ color: soldeMain - secureLocalStorage.getItem("#$$!!money%%$$$") <= 2000 && '#c1121f' }}

    >{secureLocalStorage.getItem("#$$!!devise%%$$$") === 'USD' ? euro(soldeDollard).add(-secureLocalStorage.getItem("#$$!!money%%$$$")).format() : euro(soldeMain).add(-secureLocalStorage.getItem("#$$!!money%%$$$")).format()} {secureLocalStorage.getItem("#$$!!devise%%$$$")}</h5>
   </div>
  </div>
 );
};
