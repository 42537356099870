import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { doc, onSnapshot, serverTimestamp, updateDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

import secureLocalStorage from "react-secure-storage";
// import RetuRnProFil from './Avatar';
// import RetuRnDeVise from './Devise';


import { v4 } from 'uuid';

import moment from 'moment';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import RetuRnQouTE from './Quote';
import currency from 'currency.js';



export let money = 0;
export let count = 0;
export let frais = 0;
export let solde = 0;
export let Unite = null;


let key = v4();



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[1-9]/,
   }}
   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   valueIsNumericString
   prefix=""
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};




// Return Phone input component
export default function REturnInputPhone() {
 return (
  <>
   <Media
    queries={{
     small: '(max-width: 599px)',
     medium: '(min-width: 600px) and (max-width:1199px)',
     large: '(min-width: 1200px)',
    }}>
    {matches => (
     <>
      {matches.small && <ScreenSmall />}
      {matches.medium && <ScreenLarge />}
      {matches.large && <ScreenLarge />}
     </>
    )}
   </Media>
  </>
 );
};
export const ScreenLarge = () => {
 return (
  <div className='input-withdraw-drawer'>
   <FormInputValue />
  </div>
 );
};
export const ScreenSmall = () => {
 return (
  <div className='input-withdraw-drawer'>
   <FormInputValue />
  </div>
 )
};

export const FormInputValue = () => {

 const navigation = useNavigate();
 const [load, setLoad] = React.useState(false);
 const { handleSubmit, watch, reset, control } = useForm({});

 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });

 const [itemfran, setItemfran] = React.useState(0);
 const [itemdollard, setItemdollard] = React.useState(0);

 const [itemcdf, setItemcdf] = React.useState(null);
 const [itemusd, setItemusd] = React.useState(null);

 const [thriftusd, setThriftusd] = React.useState(null);
 const [thriftcdf, setThriftcdf] = React.useState(null);


 const [opened, setOpened] = React.useState(false);

 const handleClicked = () => {
  setOpen(true);
 };
 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpened(false);
 };


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "budget"), (doc) => {
   setItemfran(doc.data().cdf);
   setItemdollard(doc.data().usd);
  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##$%%!!**++!!status@@"), secureLocalStorage.getItem("##$%%!!**++!!num@@")), (doc) => {

   setItemcdf(doc.data().cdf);
   setItemusd(doc.data().usd);

   setThriftcdf(doc.data().thriftcdf);
   setThriftusd(doc.data().thriftusd);

  });

 }, []);


 let entry = watch('count');


 const money = secureLocalStorage.getItem("##$%%!!!!count##");


 const [open, setOpen] = React.useState(false);
 const [main, setMain] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handleMain = async () => {

  setLoad(true);
  setOpened(true);
  setMain(false);


  if (secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'USD') {

   const getRef = doc(db, "muunganomoney", "budget");
   await updateDoc(getRef, {
    usd: Number(itemdollard) + Number(money),
   });

   window.setTimeout(() => {
    window.location.href = '/dash';
   }, 3500);

  } else if (secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'CDF') {

   const getRef = doc(db, "muunganomoney", "budget");
   await updateDoc(getRef, {
    cdf: Number(itemfran) + Number(money),
   });

   window.setTimeout(() => {
    window.location.href = '/dash';
   }, 3500);

  } else {
   window.console.log('welcome!!!');
  }



 };
 const handleMainClose = () => {
  setMain(false);
 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })

 const onSubmit = async (data) => {

  if (data.count === undefined) {

   setOpen(true);
   reset();

  } else {

   secureLocalStorage.setItem("##$%%!!!!count##", data.count);
   setMain(true);

  }

 };

 return (
  <>
   <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
    <Alert
     onClose={handleClosed}
     severity="success"
     variant="filled"
     sx={{ width: '100%' }}
    >
     Les budgets viennent d'être augmentés
        </Alert>
   </Snackbar>

   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='wrp-item-reduce'>
    {secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'USD' && <h1 style={{ color: `${(itemdollard + entry) <= 0 ? '#fffcf7' : 'black'}` }}>{euro(Number(itemdollard) + Number(entry)).format()}$</h1>}
    {secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'CDF' && <h1 style={{ color: `${(itemfran + entry) <= 0 ? '#fffcf7' : 'black'}` }}>{euro(Number(itemfran) + Number(entry)).format()}FC</h1>}
   </div>

   <form onSubmit={handleSubmit(onSubmit)}>

    <Controller
     autoFocus
     name="count"
     defaultValue=''
     control={control}
     render={({ field }) =>

      <TextField
       autoFocus
       label={<span className='pop-up'>Montant</span>}
       value={values.numberformat}
       onChange={handleChange}

       inputProps={{
        autoComplete: "off", inputMode: 'decimal'
       }}

       {...field}
       name="count"
       placeholder="0"
       id="formatted-numberformat-input"
       InputProps={{
        inputComponent: NumericFormatCustom,
       }}

       variant="standard"
      />

     }
    />

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}
    >
     <DialogTitle><p className='pop-up'>MuunganoMoney</p></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>La valeur demandée n'est pas disponible, veuillez vérifier.</p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

    <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={main}
     onClose={handleMainClose}
    >
     <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
     <DialogContent>

      <DialogContentText>

       <div className='block-center'>
        <RetuRnQouTE />
       </div>

      </DialogContentText>

     </DialogContent>
     <DialogActions>

      <div className='flex-between'>

       <Button onClick={handleMainClose}><h1 className='pop-up'>annuler</h1></Button>
       <Button onClick={handleMain} autoFocus>
        <h1 className='pop-up'>confirmer</h1></Button>
      </div>
     </DialogActions>


    </Dialog>


    <button className='Btn'>Valider</button>
   </form>
  </>
 );
};


