
import RetuRnLisT from './List';
import './Body.css';


// body view
export default function RetuRnBody() {
 return (
  <div className='scroll-user-list-tontine flex-mrtop-5'>
   <RetuRnLisT />
  </div>
 );
};