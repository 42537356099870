
import './Avatar.css';
import Media from 'react-media';

// Avatar Box
export default function ReturnAvataR() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
}


export const ScreenLarge = () => (
 <div className='allow-avatar'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='allow-avatar-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {
 return (
  <img src={'/img/admin.png'} />
 );
};