
import './Title.css';

// Title Form
export default function RetuRnTitle(props) {
 return (
  <div className='flex-title-rate-form'>
   <h3 style={{ color: props.color }}>{props.Text}</h3>
  </div>
 );
};