
import './Body.css';
import RestORE from './Restore/Main';

// Transfert Body
export default function ReturnBody() {
 return (
  <div className='wrp-body-transfert-valid'>
   <RestORE />
  </div>
 );
};