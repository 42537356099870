import React from 'react';
import HeAd from './Head/Main';
import ShreBody from './Body/Main';
import Media from 'react-media';



// Share Main
export default function RetuRnShaRe() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => (
 <div className="grid-template-tontine">
  <HeAd />
  <ShreBody />
  <div></div>
 </div>
)
export const ScreenSmall = () => (
 <div style={{ height: '100%', minHeight: '90vh' }} className="grid">
  <HeAd />
  <ShreBody />
 </div>
)
