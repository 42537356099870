
import './Consumption.css';
import ReturnTitle from './Title';
import MsG from './Msg/Main';
import TimesPrET from './Time/Main';



// consumption main
export default function RetuRnConsumption() {
 return (
  <>

   <div className='flex-consumption-info-dash-pret'>
    <ReturnTitle />
    <MsG />
    <TimesPrET />
   </div>
  </>
 );
};