import './Actif.css';
import BacK from './Nav/Main';
import TitlE from './Title/Main';
import ReturnBaLAnce from './Balance';

// Actif 
export default function ReturnActiF() {
 return (
  <div className='wrp-actif-body-all'>
   <BacK />
   <TitlE />
   <ReturnBaLAnce />
  </div>
 );
};