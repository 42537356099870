import React from 'react';
import InFO from './Info/Main';
import UniT from './Unit/Main';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";


// view body component 
export default function RetuRnBodY() {

 const [load, setLoad] = React.useState(secureLocalStorage.getItem("@#%%*#%!!&&"));

 React.useEffect(() => {

  window.setTimeout(() => {
   setLoad(false)
  }, 2000)

 }, [])


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <InFO />
   <UniT />
  </>
 );
};