import React from 'react';
import './View.css';
import Skeleton from '@mui/material/Skeleton';
import Media from 'react-media';
import ReturnCommAnd from './Command';
import Avatar from '@mui/material/Avatar';



// Cmd js
export default function ReturnViEw() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {

 const [item, setItem] = React.useState(false);
 React.useEffect(() => {
  window.setTimeout(() => {
   setItem(true);

  }, 3599);

 }, []);

 return (
  <div className='cmd-hm-stock-unit'>

   <Avatar src="/img/vodafone.png" sx={{ width: 45, height: 45 }} variant="rounded" />
   <Avatar src="/img/airtel.png" sx={{ width: 45, height: 45 }} variant="rounded" />
   <Avatar src="/img/orange.png" sx={{ width: 45, height: 45 }} variant="rounded" />
   <Avatar src="/img/africell.png" sx={{ width: 45, height: 45 }} variant="rounded" />

   <ReturnCommAnd />
  </div>
 );

};
export const ScreenSmall = () => {

 const [item, setItem] = React.useState(false);
 React.useEffect(() => {
  window.setTimeout(() => {
   setItem(true);

  }, 3599);

 }, []);

 return (
  <div className='cmd-hm-stock-unit-sm'>

   <Avatar src="/img/vodafone.png" sx={{ width: 45, height: 45 }} variant="rounded" />
   <Avatar src="/img/airtel.png" sx={{ width: 45, height: 45 }} variant="rounded" />
   <Avatar src="/img/orange.png" sx={{ width: 45, height: 45 }} variant="rounded" />
   <Avatar src="/img/africell.png" sx={{ width: 45, height: 45 }} variant="rounded" />

   <ReturnCommAnd />
  </div>
 );

};
// NetworK Main Cmd
export const NetworK = (props) => {

 const [item, setItem] = React.useState(false);
 React.useEffect(() => {
  window.setTimeout(() => {
   setItem(true);

  }, 3599);

 }, []);

 return (
  <>
   {
    item ? (
     <div className='stock-network-img'>
      <img src={props.IMA} />

     </div>

    ) : (
     <Skeleton variant="rounded" width={50} height={50} />
    )
   }

  </>
 );
};
// NetworK Main Cmd
export const NetworKSmAll = (props) => {

 const [item, setItem] = React.useState(false);
 React.useEffect(() => {
  window.setTimeout(() => {
   setItem(true);

  }, 3599);

 }, []);

 return (
  <>
   {
    item ? (
     <div className='stock-network-img-sm'>
      <img src={props.IMA} />

     </div>

    ) : (
     <Skeleton variant="rounded" width={50} height={50} />
    )
   }

  </>
 );
};

