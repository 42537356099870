import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Media from 'react-media';
import RetuRnImAge from './Icon';
import ReturnListEtablish from './List';


export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Tooltip title="List">
       <IconButton>

        <RetuRnImAge />

       </IconButton>
      </Tooltip>

     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 600 }
      }}
      onClose={toggleDrawer(anchor, false)}>

      <div style={{ padding: '1em' }}>
       <ReturnListEtablish />
      </div>

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Tooltip title="Modifier">
       <IconButton>
        <RetuRnImAge />
       </IconButton>
      </Tooltip>
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <div style={{ padding: '1em' }}>
       <ReturnListEtablish />
      </div>

     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};

