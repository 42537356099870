
import './Profil.css';
import RetuRnAvatars from './IMA';


// Profil Head
export default function ReturnProFil() {
 return (
  <div className='wrp-profil-litige-two'>
   <RetuRnAvatars />

  </div>
 );
};