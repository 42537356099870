import RetuRnTitle from './Title';
import RetuRnRising from './Rising';


// Main daily contribution
export default function ReturnContRibutiOn() {
 return (
  <div className='flex-between border-bottom-1'>
   <RetuRnTitle />
   <RetuRnRising />

  </div>
 );
};