
import RetuRnTitle from './Title';
import Media from 'react-media';
import './Type.css';



// Type Form 
export default function ReturnTypE() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => {
 return (
  <div className='grid-center bg-color-green-type'>
   <RetuRnTitle />
  </div>
 );
};
export const ScreenSmall = () => {
 return (
  <div className='flex-between bg-color-green-type-sm'>
   <RetuRnTitle />
   <div></div>
  </div>
 );
};


