import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';

import secureLocalStorage from "react-secure-storage";
import './Avatar.css';


export default function RetuRnAvatars() {

 const [item, setItem] = React.useState('');
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');

 React.useEffect(async () => {

  try {

   const unsub = onSnapshot(doc(db, "client", secureLocalStorage.getItem("##phone&&#$$$[]")), (doc) => {
    setItem(doc.data().profile);
    setFirst(doc.data().firstname);
    setLast(doc.data().lastname);
   });


  } catch (e) {

   window.console.log(e)
  }

 }, []);

 let str = first.toLowerCase() + ' ' + last.toLowerCase();
 let result = str.charAt(0).toUpperCase() + str.slice(1);

 return (
  <div className='flex-avatar-profil-info-pret'>
   <Avatar alt="Remy Sharp" src={item} sx={{ width: 120, height: 120 }} />
   <p>{result}</p>
  </div>
 );
};