import React from 'react';
import './Progress.css';
import { db } from '../../../../firebase';
import { collection, getDocs } from "firebase/firestore";
import moment from 'moment';
import currency from 'currency.js';


var now = moment();

// Ask Target DollaRd
export default function ReturnProgressFRaNc() {
 return (
  <Price />
 );
};

export const Price = () => {

 let pushDoc = new Array();
 const [list, setList] = React.useState([]);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "pret"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.data())
  });

  setList([...new Set(pushDoc)]);

 }, []);

 let asKpret = 0;
 window.console.log(list.length);


 [...Array(list.length).keys()].map(item => {

  if (list[item].devise === 'CDF') {

   //todays date
   var end = moment([moment(list[item].date).get('year'), moment(list[item].date).get('month'), moment(list[item].date).get('date')]); // another date
   var duration = moment.duration(now.diff(end));
   var daycdf = duration.asDays();

   let pretCdf = list[item].askpret;
   let modulecdf = 0;

   for (let index = 0; index < parseInt(daycdf); index++) {
    modulecdf = (pretCdf * list[item].pretmodulo / 100);
    pretCdf += modulecdf;

   };

   window.console.log(asKpret += pretCdf);
  }

 });

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-global-pret-progres'>
   <h3>{euro(asKpret).format()} CDF</h3>
  </div>
 );
};