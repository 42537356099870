import React from 'react';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import './List.css';


// Price group 
export default function RetuRnAvaTaR(props) {

 const [item, setItem] = React.useState(0);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "client", props.docsTon), (doc) => {
   setItem(doc.data().profile);
  });

 });

 return (
  <div>
   <Avatar sx={{ width: 40, height: 40 }} src={item} />
  </div>
 );
};