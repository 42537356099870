
import './Title.css';


// Title Contribution
export default function RetuRnTitle() {
 return (
  <div className='title-contri-body-user'>
   <h4>Contribution quotidienne</h4>
  </div>
 );
};