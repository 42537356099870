import React from 'react';
import './Btn.css';
import Media from 'react-media';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import { db } from '../../../firebase';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Btn Home Stock
export default function ReturnBtn() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='flex-btn-stock'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <div className='flex-btn-stock-sm'>
  <View />
 </div>
);
export const View = () => {

 const navigation = useNavigate();
 const [load, setLoad] = React.useState(false);
 const [tomorrow, setTomorrow] = React.useState('');

 const today = moment();
 const livetomorrow = moment().add(1, 'days');

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "stockunitaire"), (doc) => {
   setTomorrow(doc.data().tomorrow === undefined ? '' : doc.data().tomorrow);

  });

 }, []);

 window.console.log(today.format('YYYY-MM-DD') === tomorrow);
 window.console.log(tomorrow);

 const handlepath = (event) => {

  event.preventDefault();
  setLoad(true);
  secureLocalStorage.setItem("%%#ki@!!**$#@+=+", false);

  if (tomorrow === undefined || tomorrow === '') {
   navigation(0);

  } else if (today.format('YYYY-MM-DD') == tomorrow) {
   updateDocTodayTomorrow(livetomorrow.format('YYYY-MM-DD'));
   window.setTimeout(() => {
    navigation('allow');
   }, 2500);
  } else {
   window.setTimeout(() => {
    navigation('allow');
   }, 2500);
  }


 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <button onClick={handlepath}>Continue</button>
  </>
 )
};

// Tomorrow to Today
export async function updateDocTodayTomorrow(tomorrow) {

 const washingtonRef = doc(db, "muunganomoney", "stockunitaire");
 // Set the "capital" field of the city 'DC'
 await updateDoc(washingtonRef, {

  africellincreasemoneycdf: 0,
  africellincreasemoneyusd: 0,
  africellincreaseunitusd: 0,
  africellincreaseunitcdf: 0,

  airtelincreasemoneycdf: 0,
  airtelincreasemoneyusd: 0,
  airtelincreaseunitusd: 0,
  airtelincreaseunitcdf: 0,

  orangeincreasemoneycdf: 0,
  orangeincreasemoneyusd: 0,
  orangeincreaseunitusd: 0,
  orangeincreaseunitcdf: 0,

  vodaincreasemoneycdf: 0,
  vodaincreasemoneyusd: 0,
  vodaincreaseunitcdf: 0,
  vodaincreaseunitusd: 0,

  tomorrow: tomorrow

 });

};