import './Title.css';
import ReturnWithUs from './WithUs';
import RetuRnAvaTaR from './Avatar';

// Title Component 
export default function ReturnTitle() {
 return (
  <div className='flex-between flex-border-bottom pad-left-right-3'>
   <RetuRnAvaTaR />
   <ReturnWithUs />

  </div>
 );
};