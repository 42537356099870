import * as React from 'react';
import './Operator.css';
import Media from 'react-media';

import MuiAlert from '@mui/material/Alert';
import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';



const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// Operator Head Component
export default function ReturnOperator() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='stock-nav-operator'>
  <ViEw />
 </div>
);
export const ScreenSmall = () => (
 <div className='stock-nav-operator-sm'>
  <ViEw />
 </div>
);
export const ViEw = () => {

 const handlepath = (event) => {

  event.preventDefault();
  secureLocalStorage.setItem("#$#@path((%#@Network!!@", true);
  secureLocalStorage.setItem("##!!%vodacom%$$$return", true);
  secureLocalStorage.setItem("##!!%orange%$$$return", false);

  window.setTimeout(() => {
   window.location.href = '/unit/stock/throw/voda';
  }, 560);

 };

 return (
  <Avatar
   onClick={handlepath}
   src="/img/orange.png"
   sx={{ width: 45, height: 45 }}
   variant="rounded"
  />
 )
};
