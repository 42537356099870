import './Authority.css';
import BacK from './Nav/Main';
import ReturnQuOTe from './Quote';


// Allow Stock
export default function ReturnAuthORity() {
 return (
  <div className='allow-stock-unit'>
   <BacK />
   <div className='allow-stock-sub-unit'>
    <ReturnQuOTe />
   </div>

  </div>
 );
};