
import RetuRnDrawer from './Drawer';


// Edit Info Head
export default function RetuRnEdiT() {
 return (
  <RetuRnDrawer />
 );
};
