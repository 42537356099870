import HeAd from './Head/Main';
import BoDY from './Body/Main';
import './Tontine.css';


// RetuRnEXchAnge 
export default function RetuRnTonTine() {
 return (
  <div className='wrp-exchange-buy-detail flex-border-rigth'>
   <HeAd />
   <BoDY />
  </div>
 );
};