
import './Title.css';

// Return Title
export default function ReturnTitle() {
 return (
  <div className='wrp-title-sign-hm'>
   <h1>Cashbeck Service</h1>
   <p>Gestion des actif de vos E - money au - delà des frontières, si simple que de les envoyer a côté de la rue.</p>
  </div>
 );
};