import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import './Etablish.css';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";


export default function RetuRnEtablish() {

 const [confirm, setConfirm] = React.useState(null);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "cashbeckestablishment", secureLocalStorage.getItem("#$$!@@%DTR##")), (doc) => {
   setConfirm(doc.data().name);

  });

 }, []);

 return (
  <div>
   <div className="flex">
    <Avatar
     src="/img/school-2.png"
     variant="rounded"
     sx={{ width: 30, height: 30 }}
    />
    <p style={{ marginTop: '3vh' }} className="pop-up">{secureLocalStorage.getItem("#$$!@@%ERR##")}</p>
   </div>

   <div>
    <p className="flex-etablish">{confirm}</p>
   </div>
  </div>
 );
};
