import React from 'react';
import './View.css';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from '../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { IoFingerPrintOutline } from "react-icons/io5";
import Media from 'react-media';



// View Name Component
export default function RetuRnPin() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [open, setOpen] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [item, setItem] = React.useState('');

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().code);
  });

 }, []);

 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handleUpdate = async () => {

  setLoad(true);
  setOpen(false);

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   code: '000000'
  });

  window.setTimeout(() => {
   setLoad(false);
  }, 3500)


 };



 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-between-edit'>

    <div className='flex-block'>
     <span>Code pin</span>
     <p className='letter-space'>••••••••</p>

    </div>
    <React.Fragment>
     <IconButton aria-label="edit" color='primary' size="large" onClick={handleClickOpen}>
      <IoFingerPrintOutline size={'.8em'} />
     </IconButton>

     <Dialog open={open} onClose={handleClose}>
      <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>


      <DialogContent>
       <DialogContentText>
        <p style={{ color: 'grey' }} className='pop-up-low'>
         Une demande de réinitialisation du mot de passe est prise
         en compte, qui donne une valeur par défaut du mot de passe
        </p>

       </DialogContentText>

      </DialogContent>



      <DialogActions>
       <div className='flex-between-edit'>

        <Button onClick={handleClose}><p className='pop-up'>Annuler</p></Button>
        <Button onClick={() => handleUpdate()}><p className='pop-up'>Réinitialiser</p></Button>
       </div>
      </DialogActions>


     </Dialog>
    </React.Fragment>
   </div>

   <Divider />
  </>

 );
}
export const ScreenSmall = () => {


 const [open, setOpen] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [item, setItem] = React.useState('');

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setItem(doc.data().code);
  });

 }, []);

 const handleClickOpen = () => {
  setOpen(true);
 };
 const handleClose = () => {
  setOpen(false);
 };
 const handleUpdate = async () => {

  setLoad(true);
  setOpen(false);

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   code: '000000'
  });

  window.setTimeout(() => {
   setLoad(false);
  }, 3500)


 };


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='flex-between-edit'>

    <div className='flex-block'>
     <span>Code d'accès</span>
     <p className='letter-space'>••••••••</p>

    </div>

    <React.Fragment>
     <IconButton aria-label="edit" color='primary' size="large" onClick={handleClickOpen}>
      <IoFingerPrintOutline size={'.8em'} />
     </IconButton>

     <Dialog open={open} onClose={handleClose}>
      <DialogTitle><h1 className='pop-up'>Cashbeck</h1></DialogTitle>


      <DialogContent>
       <DialogContentText>
        <p style={{ color: 'grey' }} className='pop-up-low'>
         Une demande de réinitialisation du  Code d'accès est prise
         en compte, qui donne une valeur par défaut sur le Code d'accès
        </p>

       </DialogContentText>

      </DialogContent>



      <DialogActions>
       <div className='flex-between'>

        <Button onClick={handleClose}><p className='pop-up'>Annuler</p></Button>
        <Button onClick={() => handleUpdate()}><p className='pop-up'>Réinitialiser</p></Button>
       </div>
      </DialogActions>


     </Dialog>
    </React.Fragment>

   </div>

   <Divider />
  </>

 );
}

