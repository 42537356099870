import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { PiUsersThreeThin } from "react-icons/pi";
import { db } from '../../../../../firebase';
import { getDocs, collection } from "firebase/firestore";
import { TweenMax, Power3 } from 'gsap';



const StyledBadge = styled(Badge)(({ theme }) => ({
 '& .MuiBadge-badge': {
  right: 1,
  top: 15,
  border: `2px solid ${theme.palette.background.paper}`,
  padding: '0 4px',
  color: '#fff',
  backgroundColor: '#004e98'
 },
}));



export default function RetuRnUseR() {

 const elemntRef = React.useRef(null)
 const [item, setItem] = React.useState(0);
 const [items, setItems] = React.useState(0);

 React.useEffect(async () => {

  const fetchTotalDocument = async () => {

   try {

    const collectionRef = collection(db, 'agent');
    const querySnapshot = await getDocs(collectionRef);
    setItem(querySnapshot.size);


   } catch (error) {
    console.error('Error')

   }

  }

  TweenMax.from(elemntRef.current, { delay: Math.random(), duration: Math.random(), x: -8, opacity: 0, ease: Power3.easeInOut })
  fetchTotalDocument();

 }, []);
 React.useEffect(async () => {

  const fetchTotalDocument = async () => {

   try {

    const collectionRef = collection(db, 'client');
    const querySnapshot = await getDocs(collectionRef);
    setItems(querySnapshot.size);


   } catch (error) {
    console.error('Error')

   }

  }

  fetchTotalDocument();

 }, []);


 return (
  <div ref={elemntRef}>
   <IconButton aria-label="cart">

    <StyledBadge badgeContent={item + items}>
     <PiUsersThreeThin size={'1.2em'} />
    </StyledBadge>

   </IconButton>

  </div>
 );
};