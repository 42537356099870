
import RetuRnLisT from './List';

// view drawe body
export default function RetuRNDrawebOdy() {
 return (
  <div className='scroll-user-list-tontine'>
   <RetuRnLisT />
  </div>
 );
};