import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import './IMA.css';
import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { v4 } from 'uuid';



let key = v4();



export default function RetuRnAvatars() {

 const [profil, setProfil] = React.useState(null);
 const [first, setFirst] = React.useState('');
 const [last, setLast] = React.useState('');
 const [frais, setFrais] = React.useState(null);


 const [soldeMain, setSoldeMain] = React.useState(null);
 const [soldeDollard, setSoldeDollard] = React.useState(null);

 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);

 const [signaturedollard, setSignaturedollard] = React.useState(null);
 const [signaturetypedollard, setSignaturetypedollard] = React.useState(null);
 const [signaturemoneydollard, setSignaturemoneydollard] = React.useState(null);
 const [signaturekeydollard, setSignaturekeydollard] = React.useState(null);


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$")), (doc) => {

   setSoldeMain(doc.data().cdf);
   setSoldeDollard(doc.data().usd);

   setProfil(doc.data().profile);
   setFirst(doc.data().firstname);
   setLast(doc.data().lastname);
   setFrais(doc.data().frais);

   setSignature(doc.data().signaturecdf);
   setSignaturetype(doc.data().signaturecdftype);
   setSignaturemoney(doc.data().signaturemoneycdf);
   setSignaturekey(doc.data().signaturekeycdf);

   setSignaturedollard(doc.data().signatureusd);
   setSignaturetypedollard(doc.data().signatureusdtype);
   setSignaturemoneydollard(doc.data().signaturemoneyusd);
   setSignaturekeydollard(doc.data().signaturekeyusd);

  });

 }, []);


 let sendagent = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getagent = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkagent = signaturetype === 'envoyer' ? sendagent === signature : getagent === signature;


 let sendagentdollard = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoneydollard)}${parseInt(Number(soldeDollard) + Number(signaturemoneydollard))}${parseInt((Number(soldeDollard) + Number(signaturemoneydollard)) - Number(signaturemoneydollard))}${signaturekeydollard}`;
 let getagentdollard = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoneydollard)}${parseInt(Number(soldeDollard) - Number(signaturemoneydollard))}${parseInt((Number(soldeDollard) - Number(signaturemoneydollard)) + Number(signaturemoneydollard))}${signaturekeydollard}`;
 let checkagentdollard = signaturetypedollard === 'envoyer' ? sendagentdollard === signaturedollard : getagentdollard === signaturedollard;


 // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) + Number(signaturemoney) + Number(frais))}${parseInt((Number(soldeMain) + Number(signaturemoney)) - Number(signaturemoney))}${signaturekey}`;
 let getclient = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoney)}${parseInt(Number(soldeMain) - Number(signaturemoney))}${parseInt((Number(soldeMain) - Number(signaturemoney)) + Number(signaturemoney))}${signaturekey}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;

 // From send CLIENT
 let sendclientdollard = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoneydollard)}${parseInt(Number(soldeDollard) + Number(signaturemoneydollard) + Number(frais))}${parseInt((Number(soldeDollard) + Number(signaturemoneydollard)) - Number(signaturemoneydollard))}${signaturekeydollard}`;
 let getclientdollard = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoneydollard)}${parseInt(Number(soldeDollard) - Number(signaturemoneydollard))}${parseInt((Number(soldeDollard) - Number(signaturemoneydollard)) + Number(signaturemoneydollard))}${signaturekeydollard}`;
 let checkclientdollard = signaturetypedollard === 'envoyer' ? sendclientdollard === signaturedollard : getclientdollard === signaturedollard;


 return (
  <div className='flex-profil-left-litige'>
   <div

   // className={(checkclient && checkclientdollard) || (checkagent && checkagentdollard) ? 'border-succes' : 'border-error'}

   >
    <Avatar
     src={profil}
     sx={{ width: 65, height: 65 }}
    />
   </div>

   <h4>{first} {last}</h4>
  </div>
 );
};
