import './Head.css';
import RetuRnPrOFil from './Profil';


// Head Info
export default function RetuRnHeAd() {
 return (
  <div className='flex-between flex-height-25-vh flex-pad-left-right-3'>
   <RetuRnPrOFil />

  </div>
 );
};