
import './IMA.css';

// image 
export default function RetuRnIMA(props) {
 return (
  <div className='img-list-user-tontine'>
   <img src={props.IMA} />
  </div>
 );
};