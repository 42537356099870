import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import { db } from '../../../../firebase';
import { doc, collection, query, getDocs, onSnapshot } from "firebase/firestore";


import Avatar from '@mui/material/Avatar';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';


export default function RetuRnSeNd() {

 let pushDoc = new Array();
 const [listEtablish, setListEtablish] = React.useState([]);

 React.useEffect(async () => {

  const q = query(collection(db, `share${secureLocalStorage.getItem("#$$!@@%DTR##")}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots;
   pushDoc.push(doc.data());

  });

  setListEtablish([...new Set(pushDoc)]);

 }, []);


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
    <nav aria-label="secondary mailbox folders">

     {[...Array(listEtablish.length).keys()].map((item) => {

      return (
       <div>
        <List>
         <ListItem disablePadding>
          <ListItemButton>

           <div className="flex-between">
            <div className="grid">
             <Avatar src={listEtablish[item].profile} sx={{ width: 45, height: 45 }} />
             <p
              style={{ marginTop: '0.5vh' }}

              className="pop-up">
              {`${(listEtablish[item].firstname + '  ' + listEtablish[item].lastname).toLowerCase()}`}
             </p>
            </div>

            <p style={{ marginTop: '3vh' }} className="pop-up">{euro(listEtablish[item].money).format()}  {listEtablish[item].devise}</p>

           </div>
          </ListItemButton>
         </ListItem>

         <Divider />
        </List>

       </div>
      )
     })}
    </nav>
   </Box>

  </>
 );
};
