
import './Logo.css';
import { useNavigate } from 'react-router-dom';

// Logo Home 
export default function RetuRnlOgo() {

 const navigation = useNavigate();
 const handlepath = (event) => {
  event.preventDefault();
  navigation('/dash');

 }

 return (
  <div onClick={handlepath} className='wrp-logo-info'>
   <h1>Muungano</h1><h1 className='color-info-logo'>Money</h1>
  </div>
 );
};