
import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import currency from 'currency.js';
import moment from 'moment';


// Budget Comm
export default function RetuRnBudgetComm() {

 const [payusd, setPayusd] = React.useState(null);
 const [paycdf, setPaycdf] = React.useState(null);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, "muunganomoney", "budget"), (doc) => {
   setPayusd(doc.data().compayusd);
   setPaycdf(doc.data().compaycdf);

  });
 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   {
    (moment().date() === 6 || moment().date() === 7 || moment().date() === 8 || moment().date() === 9) &&
    <div className="flex">
     <p style={{ color: '#f55738' }} className="pop-up">{euro(payusd).format()} USD</p>
     <p style={{ color: '#f55738' }} className="pop-up">{euro(paycdf).format()} CDF</p>
    </div>

   }
  </>
 );
};