import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import secureLocalStorage from "react-secure-storage";
import TextField from '@mui/material/TextField';
import './Input.css';

import { collection, getDocs, doc, onSnapshot, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../../../../../../../firebase';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import moment from 'moment';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import currency from 'currency.js';




const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputmode="tel"
      pattern="[0-9]*"

      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      inputmode="tel"
      valueIsNumericString
      prefix={!!secureLocalStorage.getItem("##$devise&&^^##") ? 'USD ' : 'CDF '}
    />
  );
});
NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};



// Input Field Component 
export default function ReturnFoRM() {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width:1199px)',
        large: '(min-width: 1200px)',
      }}>
      {matches => (
        <>
          {matches.small && <ScreenSmall />}
          {matches.medium && <SreenLarge />}
          {matches.large && <SreenLarge />}
        </>
      )}
    </Media>
  );
};

export const SreenLarge = () => {
  return (
    <div className='wrp-input-ref-signin-user'>
      <FormInput />
    </div>
  )
}
export const ScreenSmall = () => {
  return (
    <div className='wrp-input-ref-signin-user'>
      <FormInput />
    </div>
  )
};
export const FormInput = () => {


  const navigation = useNavigate();
  let pushDoc = new Array();


  const [list, setList] = React.useState([]);
  const [prix, setPrix] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [prefix, setPrefix] = React.useState(false);

  const [nothing, setNothing] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [itemusd, setItemusd] = React.useState(null);
  const [itemcdf, setItemcdf] = React.useState(null);

  const [itemFran, setItemFran] = React.useState(null);
  const [itemDollard, setItemDollard] = React.useState(null);



  const [opend, setOpend] = React.useState(false);
  const [refed, setRefed] = React.useState(false);

  const handleClick = () => {
    setOpend(true);
  };
  const handleRefed = () => {
    setRefed(false);
  };
  const handlePrefix = () => {
    setPrefix(false);
  };
  const handleError = () => {
    setError(false);
  };

  const handleClosed = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpend(false);
  };

  React.useEffect(async () => {

    const querySnapshot = await getDocs(collection(db, "muunganocreditaire"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushDoc.push(doc.id);

    });

    setList([...new Set(pushDoc)])

  }, []);


  const { handleSubmit, control, reset } = useForm({});


  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleNothing = () => {
    setNothing(false);
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  React.useEffect(async () => {

    const unsub = onSnapshot(doc(db, "muunganomoney", "budgetcreditaire"), (doc) => {

      setItemusd(doc.data().usd);
      setItemcdf(doc.data().cdf);
      setItemDollard(doc.data().dollard);
      setItemFran(doc.data().fran);

    });

  }, []);



  var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
  // Ref collection database!
  const onSubmit = async (data) => {

    setLoad(true);
    if (data.phone === undefined || data.phone === '') {

      setPrefix(true);
      setLoad(false);

    } else {

      let num = (data.phone === undefined ? '' : data.phone).match(/\d+/g);
      let numPhone = '';
      num.map(index => {
        numPhone += index;
      });


      if (itemusd === null || itemusd === undefined || itemcdf === null || itemcdf === undefined || secureLocalStorage.getItem("#$$phone##$") === null) {

        setOpen(true);
        setLoad(false);
        window.setTimeout(() => {
          secureLocalStorage.setItem("#@@^^check%%%$$", false)
        }, 3000);

      } else {

        if (numPhone === '' || numPhone === undefined || data.count === undefined || data.count === '' || data.reference === undefined || data.reference === '') {

          setError(true);
          setLoad(false);

        } else {

          if (list.includes(data.reference)) {

            setRefed(true);
            setLoad(false);
            window.setTimeout(() => { secureLocalStorage.setItem("#@@^^check%%%$$", false) }, 2000)

          } else {

            if ([...secureLocalStorage.getItem("#prefix%%%$$")].includes(numPhone.substring(0, 3))) {

              setOpend(true);


              if (!!secureLocalStorage.getItem("##$devise&&^^##")) {

                setPrix(Number(data.count) + Number(itemusd));

                const washingtonRef = doc(db, "muunganomoney", "budgetcreditaire");
                // Set the "capital" field of the city 'DC'
                await updateDoc(washingtonRef, {
                  usd: Number(data.count) + Number(itemusd),
                  dollard: Number(data.count) + Number(itemDollard),
                });
                await setDoc(doc(db, "muunganocreditaire", ((data.reference).toString()).trim()), {

                  bank: secureLocalStorage.getItem("#$$network##$"),
                  banknumber: (secureLocalStorage.getItem("#$$phone##$")).toString(),
                  initiator: numPhone,
                  reference: ((data.reference).toString()).trim(),
                  count: Number(data.count),
                  status: false,
                  date: moment().format(),
                  dateactive: moment().format(),
                  devise: 'USD',
                  image: secureLocalStorage.getItem("#img&&##%$$"),
                  operator: secureLocalStorage.getItem("#opeartor##&&##%$$"),
                  dateofcreate: serverTimestamp(),
                });

                window.setTimeout(() => {
                  setLoad(false);
                  reset();
                  secureLocalStorage.setItem("#@@^^check%%%$$", true)
                  navigation(0);
                }, 3000);

              } else {

                setPrix(Number(data.count) + Number(itemcdf));
                const washingtonRef = doc(db, "muunganomoney", "budgetcreditaire");
                // Set the "capital" field of the city 'DC'
                await updateDoc(washingtonRef, {
                  cdf: Number(data.count) + Number(itemcdf),
                  fran: Number(data.count) + Number(itemFran)
                });

                await setDoc(doc(db, "muunganocreditaire", ((data.reference).toString()).trim()), {

                  bank: secureLocalStorage.getItem("#$$network##$"),
                  banknumber: (secureLocalStorage.getItem("#$$phone##$")).toString(),
                  initiator: numPhone,
                  reference: ((data.reference).toString()).trim(),
                  count: Number(data.count),
                  status: false,
                  date: moment().format(),
                  dateactive: moment().format(),
                  devise: 'CDF',
                  image: secureLocalStorage.getItem("#img&&##%$$"),
                  operator: secureLocalStorage.getItem("#opeartor##&&##%$$"),
                  dateofcreate: serverTimestamp()
                });

                window.setTimeout(() => {
                  setLoad(false);
                  reset();
                  secureLocalStorage.setItem("#@@^^check%%%$$", true)
                  navigation(0);
                }, 3000);
              }

            } else {
              setLoad(false);
              setPrefix(true);

            }


          }
        }


      }

    }

  };

  return (
    <>
      <div className='zindex-theme'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}>

          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Snackbar open={opend} autoHideDuration={6000} onClose={handleClosed}>
        <Alert onClose={handleClosed} severity="success" sx={{ width: '100%' }}>
          <p className='pop-up'>
            Nouveau soldes {euro(prix).format()}
          </p>

        </Alert>
      </Snackbar>

      <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">

        <FormControl sx={{ width: '100%' }} variant="standard">

          <Controller
            name="count"
            control={control}
            render={({ field }) =>

              <TextField
                label={<p className='pop-up'>Montant</p>}
                value={values.numberformat}
                onChange={handleChange}
                name="numberformat"
                {...field}
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                variant="standard"

              />
            }

          />

        </FormControl>

        <FormControl sx={{ width: '100%' }} variant="standard">
          <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numero</h1></InputLabel>

          <Controller
            name="phone"
            control={control}
            render={({ field }) =>

              <Input
                value={values.textmask}
                onChange={handleChange}
                InputLabelProps={{ required: true }}
                inputProps={{
                  autoComplete: "off", inputMode: 'tel'
                }}
                name="textmask"
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
                {...field}
              />

            }
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }} variant="standard">

          <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Nº Reference</h1></InputLabel>

          <Controller
            name="reference"
            control={control}
            render={({ field }) =>

              <Input
                inputProps={{ autoComplete: "off" }}
                InputLabelProps={{ required: true }}
                {...field}
              />

            }
          />
        </FormControl>



        <button className='Btn'>Valider</button>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>

                Connexion instable

       </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={nothing}
          onClose={handleNothing}
        >
          <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>
                Budget est insufisant

      </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleNothing}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={refed}
          onClose={handleRefed}
        >
          <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>
                Désolé, cette référence a été déjà utilisée

      </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleRefed}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={prefix}
          onClose={handlePrefix}
        >
          <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>

                Désolé, veuillez vérifier le numéro

                </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handlePrefix}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={error}
          onClose={handleError}
        >
          <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
          <DialogContent>

            <DialogContentText>
              <p className='pop-up'>
                Vérifiez vos informations
        </p>
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleError}><span className='pop-up'>Fermer</span></Button>
          </DialogActions>
        </Dialog>


      </form>

    </>
  )
};
