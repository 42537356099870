import React from 'react';
import './Prix.css';
import { db } from '../../../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';
import { BsArrowUp } from "react-icons/bs";


// Fran Balance Available
export default function RetuRnDollard() {

 const [item, setItem] = React.useState(null);
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, !!secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$")), (doc) => {
   setItem(doc.data().usd);
  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' })

 return (
  <div className='flex-prix-balance-litige-cancel'>
   <p>{euro(item).add(secureLocalStorage.getItem("#$$!!money%%$$$")).format()} USD</p>
   <BsArrowUp size={'1.05em'} color='#679436' />
   <span>{euro(secureLocalStorage.getItem("#$$!!money%%$$$")).format()}</span>
  </div>
 );
};