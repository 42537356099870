import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { CiRead } from "react-icons/ci";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UseRUpdAte from './Update/Main';



export default function RetuRnDrawer() {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Tooltip title="Modifier">
       <IconButton>
        <CiRead size={'0.85em'} />
       </IconButton>
      </Tooltip>
     </div>



     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }

      }}

      onClose={toggleDrawer(anchor, false)}>
      <UseRUpdAte />
     </Drawer>

    </React.Fragment>
   ))}
  </div>
 );
};
