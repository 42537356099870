import * as React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";


function MyApp() {

 const { enqueueSnackbar } = useSnackbar();
 const [active, setActive] = React.useState(null);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setActive(doc.data().active);
  });

 }, []);

 const handleClickenable = (variant) => async () => {
  // variant could be success, error, warning, info, or default
  enqueueSnackbar(`Son compte vient d'être déverrouillé`, { variant });

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   active: true
  });


 };
 const handleClickdisable = (variant) => async () => {
  // variant could be success, error, warning, info, or default
  enqueueSnackbar(`Son compte vient d'être verrouillé`, { variant });

  const washingtonRef = doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++"));
  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
   active: false
  });
 };


 return (
  <React.Fragment>
   {active === true && <button onClick={handleClickdisable('error')} className='Btn'>Verrouillé</button>}
   {active === false && <button onClick={handleClickenable('success')} className='Btn'>Déverrouiller</button>}

  </React.Fragment>
 );
};

export default function IntegrationNotistack() {
 return (
  <SnackbarProvider maxSnack={3}>
   <MyApp />
  </SnackbarProvider>
 );
};
