import React from 'react';
import { db } from '../../../../firebase';
import { collection, getDocs, doc, updateDoc, increment } from "firebase/firestore";
import './Over.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import moment from 'moment';
import { MdOutlineFilterNone, MdVisibility, MdLightbulb } from "react-icons/md";
import secureLocalStorage from "react-secure-storage";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useNavigate, Navigate } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Media from 'react-media';
import currency from 'currency.js';



let pushDoc = new Array();
let pushDocs = new Array();


let highColor = '#7f7f7f';
let lowColor = '#EDEDED';
let colorCopy = '#04e762';



let numPhone = 0;
let unit = 0;
let price = 0;
let date = 0;
let operator = 0;
let status = 0;
let devise = 0;
let name = '';
let leader = '';



let unir = 0;
let iconsizesmall = '17px';
let iconsize = '16.8px';



const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ReturnOver() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmallVodA />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 )

};

export function ScreenLarge() {


 const [list, setList] = React.useState([]);
 const [arr, setArr] = React.useState([]);
 const [opened, setOpened] = React.useState(false);
 const [open, setOpen] = React.useState(false);
 const [nan, setNan] = React.useState(false);

 const [notif, setNotif] = React.useState(false);
 const [valid, setValid] = React.useState(false);
 const [copy, setCopy] = React.useState(false);
 const [load, setLoad] = React.useState(false);

 const [reload, setReload] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');


//  let aujourdhui = moment();
// aujourdhui.isSame(moment(doc.data().date).format('YYYY-MM-DD'), 'day')


 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
       pushDoc.push(doc.id);
       pushDocs.push(doc.data());
  });

  setList([...new Set(pushDoc.reverse())]);
  setArr([...new Set(pushDocs.reverse())]);

 }, []);


 const handleClosed = () => {
  setOpened(false);
 };
 const handleCancel = () => {
  setCopy(false);
 };
 const handleValidCancel = () => {
  setValid(false);
 };
 const handlenotifClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }
  setNotif(false);
 };
 const handleNanClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setNan(false);
 };

 const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpen(false);
 };
 const handleClick = async (col, value, price, unit, phone) => {

  if (isNaN(price) || isNaN(unit)) {

   setNan(true);

   const washingtonRef = doc(db, "agent", phone);
   // Set the "capital" field of the city 'DC'
   await updateDoc(washingtonRef, {
    active: false
   });

  } else {

   setOpen(true);
   setLoad(false);
   setReload(!reload);

   const muunganoRef = doc(db, "muunganounitvoda", value);
   // Set the "capital" field of the city 'DC'
   await updateDoc(muunganoRef, {
    copy: true
   })

   const clientRef = doc(db, col, value);
   // Set the "capital" field of the city 'DC'
   await updateDoc(clientRef, {
    copy: true
   })

   window.setTimeout(async () => {

    const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
    querySnapshot.forEach((doc) => {
     // doc.data() is never undefined for query doc snapshots
     pushDoc.push(doc.id);
     pushDocs.push(doc.data());

    });

    setList([...new Set(pushDoc.reverse())]);
    setArr([...new Set(pushDocs.reverse())]);

   }, 10);

  }

 };
 const handleState = async (col, value, copy, valid, unit, money, devise, leader, dockey) => {

  if (valid === true) {
   setValid(true);
  } else {

   if (copy === false) {

    setCopy(true);

   } else {

    setLoad(true);
    setNotif(true);

    const muunganoRef = doc(db, "muunganounitvoda", value);
    // Set the "capital" field of the city 'DC'
    await updateDoc(muunganoRef, {
     status: true
    })

    const clientRef = doc(db, col, value);
    // Set the "capital" field of the city 'DC'
    await updateDoc(clientRef, {
     status: true
    })

    const fiatRef = doc(db, 'unit' + leader, value);
    // Set the "capital" field of the city 'DC'
    await updateDoc(fiatRef, {
     status: true
    })


    const swapRef = doc(db, 'swap' + leader, dockey);
    // Set the "capital" field of the city 'DC'
    await updateDoc(swapRef, {
     vodastatus: false
    })

    const unitRef = doc(db, 'muunganomoney', 'stockunitaire');
    // Set the "capital" field of the city 'DC'
    await updateDoc(unitRef, {
     pack: increment(-1),
     packvoda: increment(-1),
     unit: increment(-unit),
     unitvoda: increment(-unit),
    })

    if (devise == 'USD') {

     const unitRef = doc(db, 'muunganomoney', 'stockunitaire');
     // Set the "capital" field of the city 'DC'
     await updateDoc(unitRef, {
      vodaincreaseunitusd: increment(unit),
      vodaincreasemoneyusd: increment(money)
     })


    } else {

     const unitRef = doc(db, 'muunganomoney', 'stockunitaire');
     // Set the "capital" field of the city 'DC'
     await updateDoc(unitRef, {
      vodaincreaseunitcdf: increment(unit),
      vodaincreasemoneycdf: increment(money)
     })

    }

    window.setTimeout(async () => {

     const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
     querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushDoc.push(doc.id);
      pushDocs.push(doc.data());

     });

     setList([...new Set(pushDoc.reverse())]);
     setArr([...new Set(pushDocs.reverse())]);

    }, 10);

    setLoad(false);

   }

  }

 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='wrp-body-throw-over'>

    {list.length === 0 || arr.length === 0
     ?
     <div></div>
     :
     <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
       <Alert severity="success"><p className='pop-up'>La commande a été bien copiée</p></Alert>

      </Snackbar>

      <Snackbar open={notif} autoHideDuration={6000} onClose={handlenotifClose}>
       <Alert severity="warning"><p className='pop-up'>La commande a été bien valider</p></Alert>

      </Snackbar>

      <Snackbar open={nan} autoHideDuration={6000} onClose={handleNanClose}>
       <Alert severity="error">
       <p className='pop-up'>
          La commande invalidée
        
        </p>
       </Alert>
      </Snackbar>


      <div className='wrp-body-throw-content'>

       <h3>Téléphone</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}

          >{arr[item].phone === undefined ? null : arr[item].phone}</p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>

       <h3>Unité</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}
          >{arr[item].count === undefined ? null : arr[item].count}</p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>

       <h3>Prix</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}
          >{arr[item].price === undefined ? null : euro(arr[item].price).format()} {arr[item].devise === undefined ? null : arr[item].devise === 'USD' ? 'USD' : 'CDF'} </p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>

       <h3>Date</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}
          >{moment(arr[item].date).format('LLL') === undefined ? null : moment(arr[item].date).format('LLL')}</p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>

       <h3>Fiat</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}

          >{arr[item].fiatname.toLowerCase() === undefined ? null : arr[item].fiatname.toLowerCase()}</p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>
       <h3>Vue</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div
          onClick={() => {

           numPhone = arr[item].phone;
           unit = arr[item].count;
           price = arr[item].price;
           date = arr[item].date;
           operator = arr[item].operator;
           status = arr[item].status === false ? 'No Valider' : 'Valider';
           devise = arr[item].devise === 'USD' ? 'USD' : 'CDF';
           name = arr[item].fiatname;
           leader = arr[item].leader;
           setOpened(true);

          }}

          className='wrp-body-throw-content-sub class-cursor'
         >
          <p>
           <MdVisibility color={arr[item].status === false ? highColor : lowColor}  />
          </p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>
       <h3>Copié</h3>

       {[...Array(list.length).keys()].map(item => {

        unir += arr[item].count;

        return (
         <div>
          <CopyToClipboard
           text={`${secureLocalStorage.getItem("##!!%%$prefix$$voda$$")}${secureLocalStorage.getItem("%6@##(@**pwdvoda!!%%")}*${arr[item].phone}*${arr[item].count}${secureLocalStorage.getItem("##!!%%$suffix$$voda$$")}`}
           onCopy={() => handleClick(arr[item].fiat, arr[item].fiatdocument, arr[item].price, arr[item].count, arr[item].leader)}
           >

           <p style={{ color: arr[item].copy === false ? highColor : lowColor, cursor: 'pointer' }}>
            <MdOutlineFilterNone color={arr[item].copy === false ? highColor : colorCopy} />
           </p>
          </CopyToClipboard>
         </div>
        )
       })}


      </div>

      <div className='wrp-body-throw-content'>
       <h3>Valider</h3>

       {[...Array(list.length).keys()].map(item => {

        return (
         <div
          onClick={() => handleState(arr[item].fiat, arr[item].fiatdocument, arr[item].copy, arr[item].status, arr[item].count, arr[item].price, arr[item].devise, arr[item].leader, arr[item].dockey)}

         >
          <p className='throw-content' style={{ color: arr[item].status === false ? highColor : lowColor, cursor: 'pointer' }}>
           <MdLightbulb color={arr[item].status === false ? highColor : lowColor} />
          </p>

         </div>
        )
       })}

      </div>

      <Dialog
       fullWidth={fullWidth}
       maxWidth={maxWidth}
       open={opened}
       onClose={handleClosed}>

       <DialogTitle><p className='pop-up'>Détail dela Commande</p></DialogTitle>
       <DialogContent>

        <DialogContentText>
         <div className='flex-stock-detail'>

          <p>Téléphone : {numPhone}</p>
          <p>Unité : {unit}</p>
          <p>Montant : {euro(price).format()} {devise === 'USD' ? 'USD' : 'CDF'}</p>
          <p>Date : {moment(date).format('LLL')}</p>
          <p>Operateur : {operator}</p>
          <p>Status : {status}</p>
          <p>Fiat : {name}</p>
          <p>Leader : {leader}</p>

         </div>

        </DialogContentText>

       </DialogContent>
       <DialogActions>
        <Button onClick={handleClosed}><span className='pop-up'>Fermer</span></Button>
       </DialogActions>
      </Dialog>

      <Dialog
       fullWidth={fullWidth}
       maxWidth={maxWidth}
       open={copy}
       onClose={handleCancel}>

       <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
       <DialogContent>

        <DialogContentText>
         <div className='pop-up'>
          <p>Veuillez copier la commande s'il vous plaît</p>
         </div>

        </DialogContentText>

       </DialogContent>
       <DialogActions>
        <Button onClick={handleCancel}><span className='pop-up'>Fermer</span></Button>
       </DialogActions>
      </Dialog>

      <Dialog
       fullWidth={fullWidth}
       maxWidth={maxWidth}
       open={valid}
       onClose={handleValidCancel}>

       <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
       <DialogContent>

        <DialogContentText>
         <div className='pop-up'>
          <p>La commande a été déjà validée.</p>
         </div>

        </DialogContentText>

       </DialogContent>
       <DialogActions>
        <Button onClick={handleValidCancel}><span className='pop-up'>Fermer</span></Button>
       </DialogActions>
      </Dialog>


     </>
    }

   </div>

  </>
 );
};
export function ScreenSmallVodA() {


 const [list, setList] = React.useState([]);
 const [arr, setArr] = React.useState([]);
 const [opened, setOpened] = React.useState(false);
 const [valid, setValid] = React.useState(false);
 const [nan, setNan] = React.useState(false);

 const [open, setOpen] = React.useState(false);
 const [notif, setNotif] = React.useState(false);


 const [copy, setCopy] = React.useState(false);
 const [load, setLoad] = React.useState(false);
 const [reload, setReload] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 React.useEffect(async () => {
  const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots

   pushDoc.push(doc.id);
   pushDocs.push(doc.data());

  });

  setList([...new Set(pushDoc.reverse())]);
  setArr([...new Set(pushDocs.reverse())]);

 }, []);

 const handleClosed = () => {
  setOpened(false);
 };
 const handleCancel = () => {
  setCopy(false);
 };
 const handleValidCancel = () => {
  setValid(false);
 };
 const handlenotifClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }
  setNotif(false);
 };
 const handleNanClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setNan(false);
 };

 const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpen(false);
 };
 const handleClick = async (col, value, price, unit, phone) => {

  if (isNaN(price) || isNaN(unit)) {

   setNan(true);

   const washingtonRef = doc(db, "agent", phone);

   // Set the "capital" field of the city 'DC'
   await updateDoc(washingtonRef, {
    active: false
   });

  } else {

   setOpen(true);
   setLoad(false);
   setReload(!reload);

   const muunganoRef = doc(db, "muunganounitvoda", value);
   // Set the "capital" field of the city 'DC'
   await updateDoc(muunganoRef, {
    copy: true
   })

   const clientRef = doc(db, col, value);
   // Set the "capital" field of the city 'DC'
   await updateDoc(clientRef, {
    copy: true
   })

   

   window.setTimeout(async () => {

    const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
    querySnapshot.forEach((doc) => {
     // doc.data() is never undefined for query doc snapshots
     pushDoc.push(doc.id);
     pushDocs.push(doc.data());

    });

    setList([...new Set(pushDoc.reverse())]);
    setArr([...new Set(pushDocs.reverse())]);

   }, 10);

  }

 };
 const handleState = async (col, value, copy, valid, unit, money, devise, leader, dockey) => {

  if (valid === true) {
   setValid(true);
  } else {

   if (copy === false) {

    setCopy(true);

   } else {

    setLoad(true);
    setNotif(true);

    const muunganoRef = doc(db, "muunganounitvoda", value);
    // Set the "capital" field of the city 'DC'
    await updateDoc(muunganoRef, {
     status: true
    })

    const clientRef = doc(db, col, value);
    // Set the "capital" field of the city 'DC'
    await updateDoc(clientRef, {
     status: true
    })

    const fiatRef = doc(db, 'unit' + leader, value);
    // Set the "capital" field of the city 'DC'
    await updateDoc(fiatRef, {
     status: true
    })


    const swapRef = doc(db, 'swap' + leader, dockey);
    // Set the "capital" field of the city 'DC'
    await updateDoc(swapRef, {
     vodastatus: false
    })

    const unitRef = doc(db, 'muunganomoney', 'stockunitaire');
    // Set the "capital" field of the city 'DC'
    await updateDoc(unitRef, {
     pack: increment(-1),
     packvoda: increment(-1),
     unit: increment(-unit),
     unitvoda: increment(-unit),
    })

    if (devise == 'USD') {

     const unitRef = doc(db, 'muunganomoney', 'stockunitaire');
     // Set the "capital" field of the city 'DC'
     await updateDoc(unitRef, {
      vodaincreaseunitusd: increment(unit),
      vodaincreasemoneyusd: increment(money)
     })


    } else {

     const unitRef = doc(db, 'muunganomoney', 'stockunitaire');
     // Set the "capital" field of the city 'DC'
     await updateDoc(unitRef, {
      vodaincreaseunitcdf: increment(unit),
      vodaincreasemoneycdf: increment(money)
     })

    }

    window.setTimeout(async () => {

     const querySnapshot = await getDocs(collection(db, "muunganounitvoda"));
     querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      pushDoc.push(doc.id);
      pushDocs.push(doc.data());

     });

     setList([...new Set(pushDoc.reverse())]);
     setArr([...new Set(pushDocs.reverse())]);

    }, 10);

    setLoad(false);

   }

  }

 };


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <div className='wrp-body-throw-over-dash'>

    {list.length === 0 || arr.length === 0
     ?
     <div></div>
     :
     <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
       <Alert severity="success"><p className='pop-up'>La commande a été bien copiée</p></Alert>

      </Snackbar>

      <Snackbar open={notif} autoHideDuration={6000} onClose={handlenotifClose}>
       <Alert severity="warning"><p className='pop-up'>La commande a été bien valider</p></Alert>

      </Snackbar>

      <Snackbar open={nan} autoHideDuration={6000} onClose={handleNanClose}>
       <Alert severity="error">
       <p className='pop-up'>
         La commande invalidée
        
        </p>
       </Alert>
      </Snackbar>


      <div className='wrp-body-throw-content'>

       <h3>Téléphone</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}
          >{arr[item].phone === undefined ? null : arr[item].phone}</p>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>
       <h3>Unité</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div className='wrp-body-throw-content-sub'>
          <p
           style={{ color: arr[item].status === false ? highColor : lowColor }}
          >{arr[item].count === undefined ? null : arr[item].count}</p>
         </div>
        )

       })}

      </div>




      <div className='wrp-body-throw-content'>
       <h3>Vue</h3>
       {[...Array(list.length).keys()].map(item => {
        return (
         <div
          onClick={() => {

           numPhone = arr[item].phone;
           unit = arr[item].count;
           price = arr[item].price;
           date = arr[item].date;
           operator = arr[item].operator;
           status = arr[item].status === false ? 'No Valider' : 'Valider';
           devise = arr[item].devise === 'USD' ? 'USD' : 'CDF';
           name = arr[item].fiatname;
           leader = arr[item].leader;
           setOpened(true);

          }}
          className='wrp-body-throw-content-sub class-cursor'
         >
          <span>
           <MdVisibility color={arr[item].status === false ? highColor : lowColor} />
          </span>
         </div>
        )

       })}

      </div>

      <div className='wrp-body-throw-content'>
       <h3>Copié</h3>
       {[...Array(list.length).keys()].map(item => {

        window.console.log(arr[item].count)
        return (
         <div>
          <CopyToClipboard
           text={`${secureLocalStorage.getItem("##!!%%$prefix$$voda$$")}${secureLocalStorage.getItem("%6@##(@**pwdvoda!!%%")}*${arr[item].phone}*${arr[item].count}${secureLocalStorage.getItem("##!!%%$suffix$$voda$$")}`}
           
           // onCopy={() => handleClick(arr[item].fiat, arr[item].fiatdocument)}
           onCopy={() => handleClick(arr[item].fiat, arr[item].fiatdocument, arr[item].price, arr[item].count, arr[item].leader)}
           
           >

           <span style={{ color: arr[item].copy === false ? highColor : lowColor, cursor: 'pointer' }}>
            <MdOutlineFilterNone color={arr[item].copy === false ? highColor : colorCopy} />
           </span>
          </CopyToClipboard>
         </div>
        )
       })}

      </div>

      <div className='wrp-body-throw-content'>
       <h3>Valider</h3>

       {[...Array(list.length).keys()].map(item => {

        return (
         <div
          onClick={() => handleState(arr[item].fiat, arr[item].fiatdocument, arr[item].copy, arr[item].status, arr[item].count, arr[item].price, arr[item].devise, arr[item].leader, arr[item].dockey)}
         >
          <span style={{ color: arr[item].status === false ? highColor : lowColor, cursor: 'pointer' }}>
           <MdLightbulb color={arr[item].status === false ? highColor : lowColor}  />
          </span>

         </div>
        )
       })}

      </div>




      <Dialog
       fullWidth={fullWidth}
       maxWidth={maxWidth}
       open={opened}
       onClose={handleClosed}>

       <DialogTitle><p className='pop-up'>Détail dela Commande</p></DialogTitle>
       <DialogContent>

        <DialogContentText>
         <div className='flex-stock-detail'>

          <p>Téléphone : {numPhone}</p>
          <p>Unité : {unit}</p>
          <p>Montant : {euro(price).format()} {devise === 'USD' ? 'USD' : 'CDF'}</p>
          
          <p>Date : {moment(date).format('LLL')}</p>
          <p>Operateur : {operator}</p>
          <p>Status : {status}</p>
          <p>Fiat : {name}</p>
          <p>Leader : {leader}</p>

         </div>

        </DialogContentText>

       </DialogContent>
       <DialogActions>
        <Button onClick={handleClosed}><span className='pop-up'>Fermer</span></Button>
       </DialogActions>
      </Dialog>

      <Dialog
       fullWidth={fullWidth}
       maxWidth={maxWidth}
       open={copy}
       onClose={handleCancel}>

       <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
       <DialogContent>

        <DialogContentText>
         <div className='pop-up'>
          <p>Veuillez copier la commande s'il vous plaît</p>
         </div>

        </DialogContentText>

       </DialogContent>
       <DialogActions>
        <Button onClick={handleCancel}><span className='pop-up'>Fermer</span></Button>
       </DialogActions>
      </Dialog>

      <Dialog
       fullWidth={fullWidth}
       maxWidth={maxWidth}
       open={valid}
       onClose={handleValidCancel}>

       <DialogTitle><p className='pop-up'>Cashbeck</p></DialogTitle>
       <DialogContent>

        <DialogContentText>
         <div className='pop-up'>
          <p>La commande a été déjà validée.</p>
         </div>

        </DialogContentText>

       </DialogContent>
       <DialogActions>
        <Button onClick={handleValidCancel}><span className='pop-up'>Fermer</span></Button>
       </DialogActions>
      </Dialog>


     </>
    }

   </div>

  </>
 );
};




