import * as React from 'react';

import { db } from '../../../../../firebase';
import { doc, onSnapshot, updateDoc, arrayUnion, serverTimestamp, setDoc, collection, deleteDoc, deleteField, increment } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import currency from 'currency.js';

import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { format } from "date-fns";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Avatar from '@mui/material/Avatar';
import './Btn.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



let key = v4();


const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default function RetuRNBtnRecoveRy() {

 let pushDoc = new Array();

 const navigation = useNavigate();

 const [load, setLoad] = React.useState(false);
 const [open, setOpen] = React.useState(false);
 const [error, setError] = React.useState(false);
 const [confirm, setConfirm] = React.useState(false);
 const [valid, setValid] = React.useState(false);


 const [signature, setSignature] = React.useState(null);
 const [signaturetype, setSignaturetype] = React.useState(null);
 const [signaturemoney, setSignaturemoney] = React.useState(null);
 const [signaturekey, setSignaturekey] = React.useState(null);
 const [signaturefrais, setSignaturefrais] = React.useState(null);


 const [signaturecdf, setSignaturecdf] = React.useState(null);
 const [signaturetypecdf, setSignaturetypecdf] = React.useState(null);
 const [signaturemoneycdf, setSignaturemoneycdf] = React.useState(null);
 const [signaturekeycdf, setSignaturekeycdf] = React.useState(null);
 const [signaturefraiscdf, setSignaturefraiscdf] = React.useState(null);




 // ======================

 const [opened, setOpened] = React.useState(false);

 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }
  setOpened(false);
 };


 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", secureLocalStorage.getItem("#@$$$phone##$")), (doc) => {

   setSignature(doc.data().signatureusd);
   setSignaturetype(doc.data().signatureusdtype);
   setSignaturemoney(doc.data().signaturemoneyusd);
   setSignaturekey(doc.data().signaturekeyusd);
   setSignaturefrais(doc.data().signaturefraisusd);

   setSignaturecdf(doc.data().signaturecdf);
   setSignaturetypecdf(doc.data().signaturecdftype);
   setSignaturemoneycdf(doc.data().signaturemoneycdf);
   setSignaturekeycdf(doc.data().signaturekeycdf);
   setSignaturefraiscdf(doc.data().signaturefraiscdf);

  });

 }, []);


 const handleClose = () => {
  setOpen(false);
 };
 const handleClosevalid = () => {
  setValid(false);
 };




 const handleError = () => {
  setError(false);
 };
 const handleConfirmCancel = () => {
  setConfirm(false);
 };
 const handleConfirm = () => {
  setConfirm(true);
 };


 const getPhone = secureLocalStorage.getItem("#@$$$phone##$");

 // From send CLIENT
 let sendclient = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoney)}${parseInt(Number(secureLocalStorage.getItem("#$$!!list%%#$$").before))}${parseInt((Number(secureLocalStorage.getItem("#$$!!list%%#$$").after)))}${secureLocalStorage.getItem("#$$!!list%%#$$").key}`;
 let getclient = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoney)}${parseInt(Number(secureLocalStorage.getItem("#$$!!list%%#$$").before))}${parseInt((Number(secureLocalStorage.getItem("#$$!!list%%#$$").after)))}${secureLocalStorage.getItem("#$$!!list%%#$$").key}`;
 let checkclient = signaturetype === 'envoyer' ? sendclient === signature : getclient === signature;

 let sendclientfran = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoneycdf)}${parseInt(Number(secureLocalStorage.getItem("#$$!!list%%#$$").before))}${parseInt((Number(secureLocalStorage.getItem("#$$!!list%%#$$").after)))}${secureLocalStorage.getItem("#$$!!list%%#$$").key}`;
 let getclientfran = `${secureLocalStorage.getItem("#@$$$phone##$")}${parseInt(signaturemoneycdf)}${parseInt(Number(secureLocalStorage.getItem("#$$!!list%%#$$").before))}${parseInt((Number(secureLocalStorage.getItem("#$$!!list%%#$$").after)))}${secureLocalStorage.getItem("#$$!!list%%#$$").key}`;
 let checkclientfran = signaturetypecdf === 'envoyer' ? sendclientfran === signaturecdf : getclientfran === signaturecdf;


 let toKeySignature = signaturetype === 'envoyer' ? sendclient : getclient;
 let toKeySignaturecdf = signaturetypecdf === 'envoyer' ? sendclientfran : getclientfran;


 // window.console.log(getPhone + ' ' + signaturemoney + ' ' + secureLocalStorage.getItem("#$$!!list%%#$$").before + ' ' + secureLocalStorage.getItem("#$$!!list%%#$$").after);
 // window.console.log(sendclient)


 const handleValidRecovery = async () => {

  if (secureLocalStorage.getItem("#$$!!list%%#$$").devise === 'USD') {

   secureLocalStorage.setItem("##**$$&&#", false);

   setOpened(true);
   setConfirm(false);
   setLoad(true);

   const getRef = doc(db, secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", getPhone);
   await updateDoc(getRef, {

    usd: secureLocalStorage.getItem("#$$!!list%%#$$").after,
    money: secureLocalStorage.getItem("#$$!!list%%#$$").money,
    user: 'Service Cashbeck',
    unite: secureLocalStorage.getItem("#$$!!list%%#$$").devise,
    phoneclient: 'Service Cashbeck',
    delay: moment().format(),


    signatureusd: toKeySignature,

    signatureusdtype: signaturetype,
    signaturemoneyusd: Number(secureLocalStorage.getItem("#$$!!list%%#$$").money),
    signaturekeyusd: secureLocalStorage.getItem("#$$!!list%%#$$").key,
    signaturefraisusd: signaturefrais,
    frais: 0,

    commission: Number(0),
    sendtype: 'Reçu'

   });

   // Add a new document with a generated id
   const newRef = doc(collection(db, "swap" + getPhone));
   // later...
   await setDoc(newRef, {

    action: `restauration`,
    money: Number(secureLocalStorage.getItem("#$$!!list%%#$$").money),
    before: secureLocalStorage.getItem("#$$!!list%%#$$").before,
    after: secureLocalStorage.getItem("#$$!!list%%#$$").after,

    type: 'Reçu',
    date: moment().format(),
    delay: moment().format(),

    phone: '',
    user: 'Service Cashbeck',

    beforethrift: secureLocalStorage.getItem("#$$!!list%%#$$").beforethrift,
    afterthrift: secureLocalStorage.getItem("#$$!!list%%#$$").afterthrift,

    commission: secureLocalStorage.getItem("#$$!!list%%#$$").commission,
    frais: 0,
    key: secureLocalStorage.getItem("#$$!!list%%#$$").key,
    devise: secureLocalStorage.getItem("#$$!!list%%#$$").devise,

    color: '#cdb4db',
    dateofcreate: serverTimestamp(),

    color: '#FFDBEA',

    statetrans: false,
    statepret: false,
    stateunit: true,
    statetontine: false,
    statetv: false,
    statesolaire: false,

   });


   const washingtonRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("##$$%##$**&&#"));
   // Set the "capital" field of the city 'DC'
   await updateDoc(washingtonRef, {
    recovery: true
   });


   secureLocalStorage.setItem("#$$!!money%%$$$", 0);
   navigation(0);

  } else if (secureLocalStorage.getItem("#$$!!list%%#$$").devise === 'CDF') {


   secureLocalStorage.setItem("##**$$&&#", false);

   setOpened(true);
   setConfirm(false);
   setLoad(true);

   const getRef = doc(db, secureLocalStorage.getItem("##**$$cols#") ? "client" : "agent", getPhone);
   await updateDoc(getRef, {
    cdf: secureLocalStorage.getItem("#$$!!list%%#$$").after,
    money: secureLocalStorage.getItem("#$$!!list%%#$$").money,
    user: 'Service Cashbeck',
    unite: secureLocalStorage.getItem("#$$!!list%%#$$").devise,

    phoneclient: 'Service Cashbeck',
    delay: moment().format(),

    signaturecdf: toKeySignaturecdf,
    signaturecdftype: signaturetypecdf,

    signaturemoneycdf: Number(secureLocalStorage.getItem("#$$!!list%%#$$").money),
    signaturekeycdf: secureLocalStorage.getItem("#$$!!list%%#$$").key,
    signaturefraiscdf: signaturefraiscdf,
    frais: 0,

    commission: Number(0),
    sendtype: 'Reçu'

   });

   // Add a new document with a generated id
   const newRef = doc(collection(db, "swap" + getPhone));
   // later...
   await setDoc(newRef, {

    action: `restauration`,
    money: Number(secureLocalStorage.getItem("#$$!!list%%#$$").money),
    before: secureLocalStorage.getItem("#$$!!list%%#$$").before,
    after: secureLocalStorage.getItem("#$$!!list%%#$$").after,

    type: 'Reçu',
    date: moment().format(),
    delay: moment().format(),

    phone: '',
    user: 'Service Cashbeck',

    beforethrift: secureLocalStorage.getItem("#$$!!list%%#$$").beforethrift,
    afterthrift: secureLocalStorage.getItem("#$$!!list%%#$$").afterthrift,

    commission: secureLocalStorage.getItem("#$$!!list%%#$$").commission,
    frais: 0,
    key: secureLocalStorage.getItem("#$$!!list%%#$$").key,
    devise: secureLocalStorage.getItem("#$$!!list%%#$$").devise,

    color: '#cdb4db',
    dateofcreate: serverTimestamp(),

    color: '#FFDBEA',

    statetrans: false,
    statepret: false,
    stateunit: true,
    statetontine: false,
    statetv: false,
    statesolaire: false,


   });


   const washingtonRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("##$$%##$**&&#"));
   // Set the "capital" field of the city 'DC'
   await updateDoc(washingtonRef, {
    recovery: true
   });


   secureLocalStorage.setItem("#$$!!money%%$$$", 0);
   navigation(0);



  } else {
   window.console.log('nothing');
  }

 };

 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>
     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
    <Alert onClose={handleClosed} severity="success" sx={{ width: '100%' }}>
     <p className='cashbeck-text'>
      le client vient d'être restauré
     </p>
    </Alert>
   </Snackbar>

   <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Aucune référence n'est établie sur ses transferts veuillez vérifier la valeur.
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={error}
    onClose={handleError}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Le transfert n'est peut-être effectué car
       la balance du réducteur s'avère être insuffisante pour établir ce litige
      </p>

     </DialogContentText>


    </DialogContent>
    <DialogActions>
     <Button onClick={handleError}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={valid}
    onClose={handleClosevalid}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>

    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Cette commande n'est peut-être annuler, car elle est déjà validée
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>

     <Button onClick={handleClosevalid}><p className='cashbeck'>Annuler</p></Button>

    </DialogActions>
   </Dialog>

   <Dialog
    open={confirm}
    onClose={handleConfirmCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>

    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Confirmer la  restauration du client.
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>

     <div className='flex-between'>
      <Button onClick={handleConfirmCancel}><p className='cashbeck'>Annuler</p></Button>
      <Button onClick={handleValidRecovery}><p className='cashbeck'>Confirmer</p></Button>
     </div>

    </DialogActions>
   </Dialog>


   <button onClick={handleConfirm} className='BTn'>
    <div className='flex-between'>
     <div></div>
     <p className='pop-up mrtop-2'>
      RESTAURER
     </p>
     <div></div>
    </div>
   </button>

  </>
 );
};

