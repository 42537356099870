import React from 'react';
import ReturnTitle from './Title';
import './Detail.css';
import RetuRnList from './List';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';


// Detail Main
export default function ReturnDeTAil() {

 const [phone, setPhone] = React.useState(null);
 const [addres, setAddres] = React.useState(null);
 const [specify, setSpecify] = React.useState(null);
 const [devise, setDevise] = React.useState(null);
 const [costs, setCosts] = React.useState(null);
 const [revenu, setRevenu] = React.useState(null);
 const [apport, setApport] = React.useState(null);
 const [letter, setLetter] = React.useState(null);
 const [askview, setAskview] = React.useState(null);
 const [pretmodulo, setPretmodulo] = React.useState(null);


 React.useEffect(async () => {

  try {
   const unsub = onSnapshot(doc(db, "pret", secureLocalStorage.getItem("##phone&&#$$$[]")), (doc) => {


    setPhone(doc.data().phone);
    setAddres(doc.data().address);
    setSpecify(doc.data().specify);
    setDevise(doc.data().devise);
    setCosts(doc.data().costs);
    setRevenu(doc.data().revenu);
    setApport(doc.data().apport);
    setLetter(doc.data().letter);
    setAskview(doc.data().askview);
    setPretmodulo(doc.data().pretmodulo);

   });

  } catch (e) {

   window.console.log(e)

  }


 }, [])


 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='detail-info-pret'>
   <ReturnTitle />
   <RetuRnList left='Téléphone' right={phone} />
   <RetuRnList left='Address' right={addres} />
   <RetuRnList left='Spécifications du Projet' right={specify} />
   <RetuRnList left='Coût du projet' right={`${euro(costs).format()} ${devise}`} />
   <RetuRnList left='Revenu mensuel' right={`${euro(revenu).format()} ${devise}`} />
   <RetuRnList left='Estimation sur apport' right={`${euro(apport).format()} ${devise}`} />
   <RetuRnList left='Demande en lettres' right={`${letter}`} />
   <RetuRnList left='Montant demandé en chiffres' right={`${euro(askview).format()} ${devise}`} />
   <RetuRnList left='Pourcentage sur le prêt' right={`${pretmodulo}`} />
  </div>
 );
};