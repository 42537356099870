import React from 'react';

// List Component 
export default function RetuRnList(props) {
 return (
  <div className='flex-between'>
   <p>{props.left} : </p>
   <p>{props.right}</p>
  </div>
 );
};