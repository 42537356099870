
import './Body.css';
import BalAnce from './Balance/Main';
import BuY from './Buy/Main';
import './Body.css';



// Transfert Body
export default function ReturnBody() {
 return (
  <div className='wrp-body-transfert-valid'>
   <BalAnce />
   <BuY />
  </div>
 );
};