import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import { db } from '../../firebase';
import { collection, query, getDocs, where } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './List.css';
import currency from 'currency.js';
import moment from 'moment';



export default function ReturnListEtablish() {

 let pushDoc = new Array();

 const [listEtablish, setListEtablish] = React.useState([]);
 const [open, setOpen] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');


 const handleClose = () => {
  setOpen(false);
 };

 React.useEffect(async () => {

  const q = query(collection(db, "cashbeckestablishment"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots;
   pushDoc.push(doc.data().establishmentcode)

  });

  setListEtablish([...new Set(pushDoc)]);

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div>
   <div className="flex">
    <Avatar src="/img/school-2.png" variant="rounded" />
    <p className="pop-up" style={{ textTransform: 'uppercase', marginTop: '3vh' }}>établissement</p>
   </div>

   <Box sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '3vh' }}>
    <nav aria-label="secondary mailbox folders">

     {[...Array(listEtablish.length).keys()].map((item) => {

      return (
       <div style={{ cursor: 'pointer' }} onClick={async () => {

        let code = listEtablish[item];

        const q = query(collection(db, "cashbeckestablishment"), where("establishmentcode", "==", code));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
         // doc.data() is never undefined for query doc snapshots
         secureLocalStorage.setItem("@#$$!CNA#$&", doc.data());
         secureLocalStorage.setItem("@#!!$$TRW!#$&", moment(doc.data().dateofcreate).format('LLL'));

        });

        setOpen(true);

       }}>
        <List>
         <ListItem>
          <div>
           <p className="pop-up">
            {listEtablish[item]}
           </p>
          </div>
         </ListItem>

         <Divider />
        </List>
       </div>
      )

     })}
    </nav>

   </Box>

   <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}

    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">

     <h1 className="pop-up">
      {"ÉTABLISSEMENT"}
     </h1>

    </DialogTitle>

    <DialogContent>

     <DialogContentText id="alert-dialog-description">

      <div className="grid flex-list-share">

       <div className="flex-between">
        <p>Établissement : </p>  <p>{secureLocalStorage.getItem("@#$$!CNA#$&").establishmentcode}</p>
       </div>

       <div className="flex-between">
        <p>Nom de l'établissement : </p>  <p>{secureLocalStorage.getItem("@#$$!CNA#$&").name}</p>
       </div>

       <div className="flex-between">
        <p>Address : </p>  <p>{secureLocalStorage.getItem("@#$$!CNA#$&").address}</p>
       </div>

       <div className="flex-between">
        <p>Date create : </p>  <p>{secureLocalStorage.getItem("@#!!$$TRW!#$&")}</p>
       </div>

       <div className="flex-between">
        <p>Budget : </p>  <p>{euro((secureLocalStorage.getItem("@#$$!CNA#$&").budget)).format()} USD</p>
       </div>

       <div className="flex-between">
        <p>Dépense mensuelle : </p>  <p>{euro((secureLocalStorage.getItem("@#$$!CNA#$&").spent)).format()} USD</p>
       </div>

       <div className="flex-between">
        <p>Salaire : </p>  <p>{euro((secureLocalStorage.getItem("@#$$!CNA#$&").salary)).format()} USD</p>
       </div>

      </div>


     </DialogContentText>

    </DialogContent>
    <DialogActions>

     <Button onClick={handleClose}><p className="pop-up">fermer</p></Button>
    </DialogActions>
   </Dialog>

  </div>
 );
};
