import React from 'react';
import './Input.css';
import Media from 'react-media';
import { useForm, Controller } from 'react-hook-form';
import { doc, setDoc, arrayUnion, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import "moment/locale/fr";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import secureLocalStorage from "react-secure-storage";
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
 DatePicker,
 MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import currency from 'currency.js';


import RetuRnDevIse from './Devise';




export let nowField = moment().date();
export let pushDocs = new Array();
export let fromdate = moment().format();





let key = v4();






const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
 const { onChange, ...other } = props;
 return (
  <IMaskInput
   {...other}
   mask="(#00) 000-0000"
   definitions={{
    '#': /[0-9]/,
   }}
   inputmode="tel"
   pattern="[0-9]*"

   inputRef={ref}
   onAccept={(value) => onChange({ target: { name: props.name, value } })}
   overwrite
  />
 );
});
TextMaskCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
 props,
 ref,
) {
 const { onChange, ...other } = props;

 return (
  <NumericFormat
   {...other}
   getInputRef={ref}
   onValueChange={(values) => {
    onChange({
     target: {
      name: props.name,
      value: values.value,
     },
    });
   }}
   thousandSeparator
   inputmode="tel"
   valueIsNumericString
   prefix="$ "
  />
 );
});
NumericFormatCustom.propTypes = {
 name: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
};

const currencies = [
 {
  value: 'USD',
  label: 'Simple',
 },
 {
  value: 'EUR',
  label: 'Mere',
 },
];

// Input Field Component !!!
export default function ReturnInpuT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};

export const SreenLarge = () => {
 return (
  <div className='input-signin-sharing'>
   <FormInput />
  </div>
 )
}
export const ScreenSmall = () => {
 return (
  <div className='input-signin-sharing-sm'>
   <FormInput />
  </div>
 )
};

export const FormInput = () => {


 let regular = /[a-z]+/;
 const navigation = useNavigate();

 let pushDoc = new Array();
 let pushDocEtablish = new Array();


 const [opened, setOpened] = React.useState(false);
 const [click, setClick] = React.useState(true);

 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpened(false);
 };

 const [locale, setLocale] = React.useState("fr");

 const [list, setList] = React.useState([]);
 const [listetablish, setListetablish] = React.useState([]);
 
 
 const [open, setOpen] = React.useState(false);
 const [selectedDate, handleDateChange] = React.useState(new Date());
 const [load, setLoad] = React.useState(false);

 const [fullWidth, setFullWidth] = React.useState(true);
 const [maxWidth, setMaxWidth] = React.useState('sm');

 const { register, formState, handleSubmit, control } = useForm({});
 const { isSubmitting } = formState;


 let profildefault = 'https://firebasestorage.googleapis.com/v0/b/ungamamoney-wallet.appspot.com/o/image%2Flogo.png?alt=media&token=a1b84070-76b5-43c7-a3e1-199e353c0711'


 const existing = {
  active: true, state: "agent", cdf: 0, usd: 0, thriftcdf: 0, thriftusd: 0, pretexten: '', sendtype: '', accountdate: moment().format(), pret: false, pretregister: false, pretactive: false, code: '000000', pin: 'ungano', solde: 0, frais: 0, extension: 'extension', profile: profildefault
 };

 const [values, setValues] = React.useState({
  textmask: '(100) 000-0000',
  numberformat: '1320',
 });
 const handleChange = (event) => {
  setValues({
   ...values,
   [event.target.name]: event.target.value,
  });
 };
 const handleClose = () => {
  setOpen(false);
 };

 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "agent"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDoc.push(doc.id);

  });

  setList([...new Set(pushDoc)]);

 }, []);
 React.useEffect(async () => {

  const querySnapshot = await getDocs(collection(db, "cashbeckestablishment"));
  querySnapshot.forEach((doc) => {
   // doc.data() is never undefined for query doc snapshots
   pushDocEtablish.push(doc.id);

  });

  setListetablish([...new Set(pushDocEtablish)]);

 }, []);


 const otp = `${Math.floor(100000 + Math.random() * 90000)}`;

 const onSubmit = async (data) => {


  // let dateBirth = data.birth;
  // if (dateBirth) {

  //  Object.defineProperty(data, 'birth', {
  //   value: dateBirth.format('LL'),
  //   writable: true,
  //   configuration: true,
  //   enumerable: true

  //  });


  // } else {

  //  setLoad(false);
  //  setOpen(true);

  // }


  let num = (data.phone).match(/\d+/g);
  let numPhone = '';
  num.map(index => {
   numPhone += index;
  })

  if (listetablish.includes(numPhone) || numPhone.length != 10 || list.includes(numPhone) === false) {
   
   setOpen(true);
   setOpened(false)

  } else {

     setClick(false);
     setLoad(true);

     const updateDocAgent = doc(db, "agent", numPhone);
     // Set the "capital" field of the city 'DC'
     await updateDoc(updateDocAgent, {
       establishmentcode: otp,
       countsharingcdf : 0,
       countsharing : 0,
     });

     const source = {

       phone: numPhone,
       address: data.address,
       dateofcreate: moment().format(),
       budget: data.budget,
       name: data.name,
       salary: data.salary,
       spent: data.spent,
       establishmentcode: otp

     };

     await setDoc(doc(db, "cashbeckestablishment", `${numPhone}`), source);
     window.setTimeout(() => {
       navigation(0);
     }, 2000);

  
  }


  delete window.onSubmit;

 };

 return (
  <>
   <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
    <Alert
     onClose={handleClosed}
     severity="success"
     variant="filled"
     sx={{ width: '100%' }}
    >
     <p className='pop-up'>
       L'établissement a été créé
     
      </p>

    </Alert>
   </Snackbar>

   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
     open={load}>

     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>

    <div style={{ paddingTop: '5vh' }}></div>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Numéro du compte</h1></InputLabel>

     <Controller
      name="phone"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        autoFocus
        required
        inputProps={{ autoComplete: "off" }}
        value={values.textmask}
        onChange={handleChange}
        InputLabelProps={{ required: true }}
        inputProps={{
         autoComplete: "off", inputMode: 'tel'
        }}
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel

      htmlFor="formatted-text-mask-input"><h1 className='pop-up'>Nom de l'établissement</h1></InputLabel>

     <Controller
      name="name"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        name="firstname"
        {...field}
       />

      }
     />
    </FormControl>

    <FormControl sx={{ width: '100%' }} variant="standard">
     <InputLabel

      htmlFor="formatted-text-mask-input"><h1 className='pop-up'>L'adresse de l'établissement</h1></InputLabel>

     <Controller
      name="address"
      required
      inputProps={{ autoComplete: "off" }}
      control={control}
      render={({ field }) =>

       <Input
        required
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ required: true }}
        name="firstname"
        {...field}
       />

      }
     />
    </FormControl>


    <Controller
     name="budget"
     defaultValue=''
     control={control}
     render={({ field }) =>

      <TextField
       label={<h2 className='pop-up'>Le budget mensuel</h2>}
       value={values.numberformat}
       onChange={handleChange}
       required

       inputProps={{
        autoComplete: "off", inputMode: 'decimal'
       }}

       {...field}
       name="apport"
       placeholder="0"
       id="formatted-numberformat-input"
       InputProps={{
        inputComponent: NumericFormatCustom,
       }}

       variant="standard"
      />

     }
    />

    <Controller
     name="spent"
     defaultValue=''
     control={control}
     render={({ field }) =>

      <TextField
       label={<h2 className='pop-up'>Dépense mensuelle</h2>}
       value={values.numberformat}
       onChange={handleChange}
       required

       inputProps={{
        autoComplete: "off", inputMode: 'decimal'
       }}

       {...field}
       name="apport"
       placeholder="0"
       id="formatted-numberformat-input"
       InputProps={{
        inputComponent: NumericFormatCustom,
       }}

       variant="standard"
      />

     }
    />
    <Controller
     name="people"
     defaultValue=''
     control={control}
     render={({ field }) =>

      <TextField
       label={<h2 className='pop-up'>Nombre de personnes</h2>}
       value={values.numberformat}
       onChange={handleChange}
       required

       inputProps={{
        autoComplete: "off", inputMode: 'decimal'
       }}

       {...field}
       name="apport"
       placeholder="0"
       id="formatted-numberformat-input"
       
       // InputProps={{
       //  inputComponent: NumericFormatCustom,
       // }}



       variant="standard"
      />

     }
    />

    <Controller
     name="salary"
     defaultValue=''
     control={control}
     render={({ field }) =>

      <TextField
       label={<h2 className='pop-up'>Salaire mensuel moyen</h2>}
       value={values.numberformat}
       onChange={handleChange}
       required

       inputProps={{
        autoComplete: "off", inputMode: 'decimal'
       }}

       {...field}
       name="apport"
       placeholder="0"
       id="formatted-numberformat-input"
       InputProps={{
        inputComponent: NumericFormatCustom,
       }}

       variant="standard"
      />

     }
    />

    
    
    {/* <Controller
     name="birth"
     control={control}
     render={({ field }) =>

      <MuiPickersUtilsProvider
       libInstance={moment} utils={MomentUtils} locale={locale}>

       <DatePicker
        disableFuture
        openTo="year"
        format="dd/MM/yyyy"
        label="La date de la création"
        views={["year", "month", "date"]}
        value={selectedDate}
        onChange={handleDateChange}
        {...field}
       />

      </MuiPickersUtilsProvider>}
    />
 */}


    {click && <button disabled={isSubmitting} className='Btn'>Enregistre</button>}

   </form>

      <Dialog
     fullWidth={fullWidth}
     maxWidth={maxWidth}
     open={open}
     onClose={handleClose}
    >
     <DialogTitle><span className='pop-up'>Cashbeck</span></DialogTitle>
     <DialogContent>

      <DialogContentText>
       <p className='pop-up'>

Ce numéro ne peut pas utiliser le service paiement numérique
             
        </p>
      </DialogContentText>

     </DialogContent>
     <DialogActions>
      <Button onClick={handleClose}><span className='pop-up'>Fermer</span></Button>
     </DialogActions>
    </Dialog>

  </>
 )
};
