
import './Title.css';

// Title view
export default function RetuRNTitle() {
 return (
  <div className='wrp-title-head-drawe-asked'>
   <h2>Total sur la demande</h2>

  </div>
 );
};