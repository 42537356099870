import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import './IMA.css';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';



export default function RetuRnDeVise() {

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='block-center wrp-count-send-transfert' style={{ gap: '2vh', marginTop: '5vh' }}>
   <Avatar
    src={secureLocalStorage.getItem("##$%%!!**++!!devise@@") === 'USD' ? '/img/dollars.png' : '/img/franc.png'}
    sx={{ width: 45, height: 45 }}
   />

   <p>{euro(secureLocalStorage.getItem("##$%%!!!!count##")).format()}</p>
  </div>
 );
};
