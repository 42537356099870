import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { TweenMax, Power3 } from 'gsap';
import { GoPackageDependencies } from "react-icons/go";

import { db } from '../../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";


const StyledBadge = styled(Badge)(({ theme }) => ({
 '& .MuiBadge-badge': {
  right: 1,
  top: 13,
  border: `2px solid ${theme.palette.background.paper}`,
  padding: '0 4px',
  color: '#fff',
  backgroundColor: '#004e98'
 },
}));

export default function RetuRnUnit() {

 const elemntRef = React.useRef(null)
 const [item, setItem] = React.useState(0);

 React.useEffect(() => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "stockunitaire"), (doc) => {
   setItem(doc.data().pack);
  });

  TweenMax.from(elemntRef.current, { delay: Math.random(), duration: Math.random(), x: -8, opacity: 0, ease: Power3.easeInOut })

 }, []);

 return (
  <div ref={elemntRef}>
   <IconButton aria-label="cart">

    <StyledBadge badgeContent={item}>
     <GoPackageDependencies size={'1.2em'} />
    </StyledBadge>

   </IconButton>
  </div>
 );
};