import ReturnBacK from './Back';
import ReturnDevise from './Devise';

// Navigation Main
export default function ReturnBacKNAv() {
 return (
  <div className='admin-nav'>
   <ReturnBacK />
   <ReturnDevise />

  </div>
 );
}