
import './Title.css';

// Title Operator Budget 
export default function ReturnTitle() {
 return (
  <div className='title-operator-budget'>
   <h1>Choix d'operateur</h1>
  </div>
 );
};