
import './List.css';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';
import moment from 'moment';


// List Buy Component 
export default function ReturnLisT() {

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='wrp-list-buy-transfert-cancel'>
   <ul>
    <li><p>Action</p><p>achat crédit</p></li>
    <li><p>Montant</p><p>{euro(secureLocalStorage.getItem("#$$!!list%%#$$").money).format()} {secureLocalStorage.getItem("#$$!!list%%#$$").devise}</p></li>
    <li><p>Nombre des unités</p><p>{secureLocalStorage.getItem("#$$!!list%%#$$").unit} U</p></li>
    <li><p>Date</p><p>{moment(secureLocalStorage.getItem("#$$!!list%%#$$").date).format('LL')}</p></li>
   </ul>
  </div>
 );
};