

import RetuRnEtablish from './Etablish';
import RetuRnTotAl from './Total';

// Navigation 
export default function RetuRnNAv() {
 return (
  <div>
   <div className="flex-between">
    <RetuRnEtablish />
    <div></div>
   </div>

   <div className="flex-between">
    <div></div>
    <RetuRnTotAl />
   </div>

  </div>
 );
};