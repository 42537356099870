import * as React from 'react';
import Drawer from '@mui/material/Drawer';

import Media from 'react-media';
import FoRm from './Form/Main';
import secureLocalStorage from "react-secure-storage";

import { CiShare2, CiShop } from "react-icons/ci";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Avatar from '@mui/material/Avatar';
import { TweenMax, Expo } from 'gsap';



export default function RetuRnDrawer() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };


 React.useEffect(() => {
  TweenMax.from('.anime', 1, { delay: .8, opacity: 0, x: -10, ease: Expo.easeIn })

 }, []);
 React.useEffect(() => {
  TweenMax.from('.anime-cancel', 1, { delay: 1.3, opacity: 0, x: -10, ease: Expo.easeIn })

 }, []);


 const handleagenttoagent = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("##@@agent$$agent#", true);
  secureLocalStorage.setItem("##@@client$$client#", false);
  secureLocalStorage.setItem("##@@client$$agent#", false);

  secureLocalStorage.setItem("##**$$cols#", false);
  secureLocalStorage.setItem("##$$!!#$$#", 'two');
  secureLocalStorage.setItem("##$$!!color#$$#", '#293241');
  secureLocalStorage.setItem("$!!title#%%$$$%", 'litige pour deux');

  secureLocalStorage.setItem("xx@@#x$$%", true);
  secureLocalStorage.setItem("$$personel@@#x$$%", false);
  secureLocalStorage.setItem("$$buy@@#x$$%", false);


 };
 const handlecancelaccess = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("##@@agent$$agent#", true);
  secureLocalStorage.setItem("##@@client$$client#", false);
  secureLocalStorage.setItem("##@@client$$agent#", false);
  secureLocalStorage.setItem("##@@drawer$!!#", true);

  secureLocalStorage.setItem("##**$$cols#", false);
  secureLocalStorage.setItem("##**$$&&#", false);
  secureLocalStorage.setItem("$!!title#%%$$$%", 'annuller achat');

  secureLocalStorage.setItem("##$$!!#$$#", 'cancel');
  secureLocalStorage.setItem("##$$!!color#$$#", '#293241');

  secureLocalStorage.setItem("xx@@#x$$%", false);
  secureLocalStorage.setItem("$$personel@@#x$$%", false);
  secureLocalStorage.setItem("$$buy@@#x$$%", true);

 };
 const handlepersonal = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("##@@agent$$agent#", true);
  secureLocalStorage.setItem("##@@client$$client#", false);
  secureLocalStorage.setItem("##@@client$$agent#", false);
  secureLocalStorage.setItem("##@@drawer$!!#", true);

  secureLocalStorage.setItem("##**$$cols#", false);
  secureLocalStorage.setItem("##**$$&&#", false);
  secureLocalStorage.setItem("$!!title#%%$$$%", 'Litige personel');

  secureLocalStorage.setItem("##$$!!#$$#", 'cancel');
  secureLocalStorage.setItem("##$$!!color#$$#", '#293241');

  secureLocalStorage.setItem("xx@@#x$$%", false);
  secureLocalStorage.setItem("$$personel@@#x$$%", true);
  secureLocalStorage.setItem("$$buy@@#x$$%", false);

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>
     <div onClick={toggleDrawer(anchor, true)}>

      <Tooltip>
       <div onClick={handleagenttoagent} className='grid-flex-center flex-flag-litge-box'>
        <IconButton>
         <Avatar sx={{ bgcolor: '#15616d', height: '2.8em', width: '2.8em' }}>
          <div>
           <CiShare2 size={'1.5em'} color='#fff' />
          </div>
         </Avatar>

        </IconButton>
        <p className='anime' style={{ color: '#15616d' }}>Litige pour deux</p>
       </div>
      </Tooltip>

      <Tooltip >
       <div onClick={handlecancelaccess} className='grid-flex-center flex-flag-litge-box'>
        <IconButton>
         <Avatar sx={{ bgcolor: '#260701', height: '2.8em', width: '2.8em' }}>
          <div>
           <CiShop size={'1.5em'} color='#fff' />

          </div>
         </Avatar>

        </IconButton>
        <p className='anime-cancel' style={{ color: '#260701' }}>Annulation achat</p>
       </div>
      </Tooltip>

      <Tooltip>
       <div onClick={handlepersonal} className='grid-flex-center flex-flag-litge-box'>
        <IconButton>
         <Avatar sx={{ bgcolor: '#2f3e46', height: '2.8em', width: '2.8em' }}>
          <div>
           <CiShare2 size={'1.5em'} color='#fff' />
          </div>
         </Avatar>

        </IconButton>
        <p className='anime' style={{ color: '#2f3e46' }}>Litige personel</p>
       </div>
      </Tooltip>

     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 600 }

      }}
      onClose={toggleDrawer(anchor, false)}>

      <FoRm />

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });
 const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });
 };


 React.useEffect(() => {
  TweenMax.from('.anime', 1, { delay: .8, opacity: 0, x: -10, ease: Expo.easeIn })

 }, []);
 React.useEffect(() => {
  TweenMax.from('.anime-cancel', 1, { delay: 1.3, opacity: 0, x: -10, ease: Expo.easeIn })

 }, []);


 const handleagenttoagent = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("##@@agent$$agent#", true);
  secureLocalStorage.setItem("##@@client$$client#", false);
  secureLocalStorage.setItem("##@@client$$agent#", false);

  secureLocalStorage.setItem("##**$$cols#", false);
  secureLocalStorage.setItem("##$$!!#$$#", 'two');
  secureLocalStorage.setItem("##$$!!color#$$#", '#293241');
  secureLocalStorage.setItem("$!!title#%%$$$%", 'litige pour deux');

  secureLocalStorage.setItem("xx@@#x$$%", true);
  secureLocalStorage.setItem("$$personel@@#x$$%", false);
  secureLocalStorage.setItem("$$buy@@#x$$%", false);


 };
 const handlecancelaccess = (event) => {
  event.preventDefault();
  secureLocalStorage.setItem("##@@agent$$agent#", true);
  secureLocalStorage.setItem("##@@client$$client#", false);
  secureLocalStorage.setItem("##@@client$$agent#", false);
  secureLocalStorage.setItem("##@@drawer$!!#", true);

  secureLocalStorage.setItem("##**$$cols#", false);
  secureLocalStorage.setItem("##**$$&&#", false);
  secureLocalStorage.setItem("$!!title#%%$$$%", 'annuller achat');

  secureLocalStorage.setItem("##$$!!#$$#", 'cancel');
  secureLocalStorage.setItem("##$$!!color#$$#", '#293241');

  secureLocalStorage.setItem("xx@@#x$$%", false);
  secureLocalStorage.setItem("$$personel@@#x$$%", false);
  secureLocalStorage.setItem("$$buy@@#x$$%", true);

 };
 const handlepersonal = (event) => {

  event.preventDefault();

  secureLocalStorage.setItem("##@@agent$$agent#", true);
  secureLocalStorage.setItem("##@@client$$client#", false);
  secureLocalStorage.setItem("##@@client$$agent#", false);
  secureLocalStorage.setItem("##@@drawer$!!#", true);

  secureLocalStorage.setItem("##**$$cols#", false);
  secureLocalStorage.setItem("##**$$&&#", false);
  secureLocalStorage.setItem("$!!title#%%$$$%", 'Litige personel');

  secureLocalStorage.setItem("##$$!!#$$#", 'cancel');
  secureLocalStorage.setItem("##$$!!color#$$#", '#293241');

  secureLocalStorage.setItem("xx@@#x$$%", false);
  secureLocalStorage.setItem("$$personel@@#x$$%", true);
  secureLocalStorage.setItem("$$buy@@#x$$%", false);

 };

 return (
  <div>
   {['right'].map((anchor) => (

    <React.Fragment key={anchor}>
     <div onClick={toggleDrawer(anchor, true)}>

      <Tooltip>
       <div onClick={handleagenttoagent} className='grid-flex-center flex-flag-litge-box'>
        <IconButton>
         <Avatar sx={{ bgcolor: '#15616d', height: '2.8em', width: '2.8em' }}>
          <div>
           <CiShare2 size={'1.5em'} color='#fff' />
          </div>
         </Avatar>

        </IconButton>
        <p className='anime' style={{ color: '#15616d' }}>Litige pour deux</p>
       </div>
      </Tooltip>

      <Tooltip >
       <div onClick={handlecancelaccess} className='grid-flex-center flex-flag-litge-box'>
        <IconButton>
         <Avatar sx={{ bgcolor: '#260701', height: '2.8em', width: '2.8em' }}>
          <div>
           <CiShop size={'1.5em'} color='#fff' />

          </div>
         </Avatar>

        </IconButton>
        <p className='anime-cancel' style={{ color: '#260701' }}>Annulation achat</p>
       </div>
      </Tooltip>

      <Tooltip>
       <div onClick={handlepersonal} className='grid-flex-center flex-flag-litge-box'>
        <IconButton>
         <Avatar sx={{ bgcolor: '#2f3e46', height: '2.8em', width: '2.8em' }}>
          <div>
           <CiShare2 size={'1.5em'} color='#fff' />
          </div>
         </Avatar>

        </IconButton>
        <p className='anime' style={{ color: '#2f3e46' }}>Litige personel</p>
       </div>
      </Tooltip>


     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300 }

      }}
      onClose={toggleDrawer(anchor, false)}>

      <FoRm />

     </Drawer>
    </React.Fragment>
   ))}
  </div>

 );
};
