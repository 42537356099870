import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import Media from 'react-media';


// Identity View
export default function ReturnTypeTransF() {

 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 let [city, setCity] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setCity(doc.data().sendtype);

  });

 }, []);

 let result = city.charAt(0).toUpperCase() + city.slice(1);

 return (
  <div className='list-sub-exchange-buy-detail color-purple-info-low'>
   <p>Type du transfert : </p>
   <p>{result}</p>
  </div>
 );

};
export const ScreenSmall = () => {

 let [city, setCity] = React.useState('');
 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setCity(doc.data().sendtype);

  });

 }, []);

 let result = city.charAt(0).toUpperCase() + city.slice(1);

 return (
  <div className='list-sub-exchange-info-detail color-purple-info-low'>
   <p>Type du transfert : </p>
   <p>{result}</p>
  </div>
 );
};
