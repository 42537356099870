import React from 'react';
import './Balance.css';
import Media from 'react-media';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import moment from 'moment';
import currency from 'currency.js';
import secureLocalStorage from "react-secure-storage";


// Prix HeAd 
export default function ReturnBalance() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

export const ScreenLarge = () => (
 <div className='wrp-main-prix-pret'>
  <View />
 </div>
);
export const ScreenSmall = () => (
 <View />
);
export const View = () => {

 const [item, setItem] = React.useState(0.00);
 const [time, setTime] = React.useState(new Date());
 const [modulo, setModulo] = React.useState(0);
 const [devise, setDevise] = React.useState('');


 React.useEffect(async () => {

  try {
   await onSnapshot(doc(db, "pret", secureLocalStorage.getItem("##phone&&#$$$[]")), (doc) => {

    setItem(doc.data().askpret);
    setTime(doc.data().date);
    setModulo(doc.data().pretmodulo);
    setDevise(doc.data().devise);

   });
  } catch (e) {
   window.console.log(e);
  }

 }, []);


 var now = moment(); //todays date
 let year = moment(time).get('year');
 let months = moment(time).get('month');
 let days = moment(time).get('date');
 var end = moment([year, months, days]); // another date

 var duration = moment.duration(now.diff(end));
 var day = duration.asDays();

 let livePret = item;
 let moduleusd = 0;

 for (let index = 0; index <= parseInt(day); index++) {

  if (index === 0) {
   continue;
  } else {
   moduleusd = (livePret * modulo / 100);
   livePret += moduleusd;
  }

 };

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <p className='wrp-main-prix-pret-sm'>{euro(livePret).format()} {devise}</p>
 )
};
