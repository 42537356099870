import * as React from 'react';

import { db } from '../../../../../firebase';
import { doc, onSnapshot, updateDoc, arrayUnion, serverTimestamp, setDoc, collection, deleteDoc, deleteField, increment } from "firebase/firestore";
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
import currency from 'currency.js';

import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { format } from "date-fns";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Avatar from '@mui/material/Avatar';
import './Btn.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



let key = v4();

const xmas = new Date();
const timemonth = format(xmas, "LLLL").toLowerCase();

const Alert = React.forwardRef(function Alert(props, ref) {
 return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function RetuRNBtnVodA() {

 let pushDoc = new Array();

 const navigation = useNavigate();

 const [load, setLoad] = React.useState(false);
 const [open, setOpen] = React.useState(false);
 const [error, setError] = React.useState(false);
 const [confirm, setConfirm] = React.useState(false);
 const [valid, setValid] = React.useState(false);

 const [stock, setStock] = React.useState(0);
 const [list, setList] = React.useState([]);

 // ======================

 const [getsoldeMain, setGetsoldeMain] = React.useState(null);
 const [getsoldeDollard, setGetsoldeDollard] = React.useState(null);


 const [monthprix, setMonthprix] = React.useState(null);
 const [monthunit, setMonthunit] = React.useState(null);

 const [monthprixcdf, setMonthprixcdf] = React.useState(null);
 const [monthunitcdf, setMonthunitcdf] = React.useState(null);

 const [commprix, setCommprix] = React.useState(null);
 const [communit, setCommunit] = React.useState(null);

 const [commprixcdf, setCommprixcdf] = React.useState(null);
 const [communitcdf, setCommunitcdf] = React.useState(null);


 const [getfirst, setGetfirst] = React.useState(null);
 const [getlast, setGetlast] = React.useState(null);
 const [getprofil, setGetProfil] = React.useState(null);

 const [getthrift, setGetthrift] = React.useState(null);
 const [getthriftcdf, setGetthriftcdf] = React.useState(null);
 const [opened, setOpened] = React.useState(false);

 const [status, setStatus] = React.useState(null);


 const handleClosed = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }
  setOpened(false);
 };

 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "agent", secureLocalStorage.getItem("#@$$$phone##$")), (doc) => {

   setGetsoldeMain(doc.data().cdf);
   setGetsoldeDollard(doc.data().usd);

   setGetfirst(doc.data().firstname);
   setGetlast(doc.data().lastname);
   setGetProfil(doc.data().profile);
   setGetthrift(doc.data().thriftusd);
   setGetthriftcdf(doc.data().thriftcdf);

   setCommprix(doc.data().commistockusd);
   setCommunit(doc.data().commistockunite);

   setCommprixcdf(doc.data().commistockcdf);
   setCommunitcdf(doc.data().commistockunitecdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganounitdatamonth", timemonth), (doc) => {
   setMonthprix(doc.data().moneyusd);
   setMonthunit(doc.data().unitusd);

   setMonthprixcdf(doc.data().moneycdf);
   setMonthunitcdf(doc.data().unitcdf);

  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "taux"), (doc) => {
   setStock(doc.data().stock);
  });

 }, []);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("#$$!!list%%#$$").fiatid, secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda), (doc) => {
   setStatus(doc.data().status);
  });

 }, []);


 const handleClose = () => {
  setOpen(false);
 };
 const handleClosevalid = () => {
  setValid(false);
 };


 const handleError = () => {
  setError(false);
 };
 const handleConfirmCancel = () => {
  setConfirm(false);
 };


 const getPhone = secureLocalStorage.getItem("#@$$$phone##$");
 const unite = secureLocalStorage.getItem("#$$!!devise%%$$$");
 const getUser = (getfirst + ' ' + getlast).toLowerCase();

 let modulovoda = secureLocalStorage.getItem("#$$!!list%%#$$").team === 'next' ? secureLocalStorage.getItem("#$$!!list%%#$$").countvoda * stock / 100 : 0;

 // window.console.log(secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda)
 // window.console.log(secureLocalStorage.getItem("#$$!!list%%#$$"))

 const handleValidVodacom = async () => {

  if (secureLocalStorage.getItem("#$$!!devise%%$$$") === 'USD') {

   secureLocalStorage.setItem("##**$$&&#", false);

   const thriftusd = getthrift - modulovoda;
   let commlive = secureLocalStorage.getItem("#$$!!list%%#$$").team === 'next' ? commprix - Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda) : commprix;
   let communitlive = secureLocalStorage.getItem("#$$!!list%%#$$").team === 'next' ? communit - Number(secureLocalStorage.getItem("#$$!!list%%#$$").unitvoda) : communit;


   setOpened(true);
   setConfirm(false);
   setLoad(true);


   var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

   let time = moment().format('LLL');
   let get = { date: time, solde: `${euro(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda).format()} ${unite} [achat annulée]`, phone: '', user: 'Service Cashbeck', type: 'reçu', pricenew: euro(getsoldeDollard + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda)).format(), actual: euro(getsoldeDollard + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda)).format() + ' ' + unite, unite: unite }

   const getRef = doc(db, "agent", getPhone);
   await updateDoc(getRef, {
    usd: Number(getsoldeDollard) + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    money: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    user: 'achat annulée',
    unite: unite,
    phoneclient: getPhone,
    delay: moment().format(),

    signatureusd: `${getPhone}${parseInt(Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda))}${parseInt(Number(getsoldeDollard))}${parseInt(Number(getsoldeDollard) + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda))}${key}`,
    signatureusdtype: 'reussi',
    signaturemoneyusd: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    signaturekeyusd: key,
    signaturefraisusd: 0,
    swap: arrayUnion(get),
    frais: 0,

    commistockusd: Number(commlive),
    commistockunite: Number(communitlive),

    thriftusd: thriftusd,
    commission: Number(0),
    sendtype: 'reçu'

   });

   const adminRef = doc(db, "muunganounitdatamonth", timemonth);
   await updateDoc(adminRef, {
    moneyusd: Number(monthprix) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    unitusd: Number(monthunit) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").unitvoda),
   });

   const packRef = doc(db, "muunganomoney", "stockunitaire");
   await updateDoc(packRef, {
    pack: increment(-1)
   });


   // Add a new document with a generated id
   const newRef = doc(collection(db, "swap" + getPhone));
   // later...
   await setDoc(newRef, {

    action: `achat annulée`,

    canceloperator: true,
    canceloperatorname: 'vodacom',

    money: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    before: getsoldeDollard,
    after: getsoldeDollard + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),

    type: 'reçu',
    date: moment().format(),
    delay: moment().format(),

    phone: getPhone,
    user: getUser,

    profile: getprofil,
    beforethrift: getthrift,
    afterthrift: thriftusd,
    commission: 0,
    frais: 0,
    key: key,
    devise: unite,

    soldebefore: getsoldeDollard,
    soldeafter: getsoldeDollard,

    color: '#F8F2EF',
    dateofcreate: serverTimestamp(),

    unit: Number(secureLocalStorage.getItem("#$$!!list%%#$$").unit) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").unitvoda),
    unitvoda: 0,
    unitairtel: secureLocalStorage.getItem("#$$!!list%%#$$").unitairtel,
    unitafricell: secureLocalStorage.getItem("#$$!!list%%#$$").unitafricell,
    unitorange: secureLocalStorage.getItem("#$$!!list%%#$$").unitorange,

    count: Number(secureLocalStorage.getItem("#$$!!list%%#$$").count) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    countvoda: 0,
    countairtel: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countairtel),
    countafricell: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countafricell),
    countorange: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countorange),

    // numvoda: secureLocalStorage.getItem("#$$!!list%%#$$").numvoda,
    // numairtel: secureLocalStorage.getItem("#$$!!list%%#$$").numairtel,
    // numafricell: secureLocalStorage.getItem("#$$!!list%%#$$").numafricell,
    // numorange: secureLocalStorage.getItem("#$$!!list%%#$$").numorange,

    // operavoda: false,
    // operaairtel: secureLocalStorage.getItem("#$$!!list%%#$$").operaairtel === undefined ? false : true,
    // operaorange: secureLocalStorage.getItem("#$$!!list%%#$$").operaorange === undefined ? false : true,
    // operaafricell: secureLocalStorage.getItem("#$$!!list%%#$$").operaafricell === undefined ? false : true,

    fiat: secureLocalStorage.getItem("#$$!!list%%#$$").fiat,

    fiatvoda: false,
    fiatairtel: secureLocalStorage.getItem("#$$!!list%%#$$").fiatairtel,
    fiatorange: secureLocalStorage.getItem("#$$!!list%%#$$").fiatorange,
    fiatafricell: secureLocalStorage.getItem("#$$!!list%%#$$").operaafricell,

    typefiat: secureLocalStorage.getItem("#$$!!list%%#$$").typefiat,

    color: '#FFDBEA',
    dateofcreate: serverTimestamp(),

    statetrans: false,
    statepret: false,
    stateunit: true,
    statetontine: false,
    statetv: false,
    statesolaire: false,


   });


   const cityRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("#$$!!list%%#$$").dockey);
   // Remove the 'capital' field from the document
   await updateDoc(cityRef, {
    operavoda: false
   });


   if (secureLocalStorage.getItem("#$$!!list%%#$$").typefiat === 'simple') {

    await deleteDoc(doc(db, secureLocalStorage.getItem("#$$!!list%%#$$").fiat, secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda));

   } else {
    await deleteDoc(doc(db, secureLocalStorage.getItem("#$$!!list%%#$$").fiatmere, secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda));
   }

   await deleteDoc(doc(db, "muunganounitvoda", secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda));

   secureLocalStorage.setItem("#$$!!money%%$$$", 0);
   navigation(0);


  } else if (secureLocalStorage.getItem("#$$!!devise%%$$$") === 'CDF') {

   secureLocalStorage.setItem("##**$$&&#", false);

   setOpened(true);
   setConfirm(false);
   setLoad(true);

   const thriftcdf = getthriftcdf - modulovoda;
   const commlivecdf = secureLocalStorage.getItem("#$$!!list%%#$$").team === 'next' ? commprixcdf - Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda) : commprixcdf;
   const communitlivecdf = secureLocalStorage.getItem("#$$!!list%%#$$").team === 'next' ? communitcdf - Number(secureLocalStorage.getItem("#$$!!list%%#$$").unitvoda) : communitcdf;



   var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

   let time = moment().format('LLL');
   let get = { date: time, solde: `${euro(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda).format()} ${unite} [achat annulée]`, phone: '', user: 'Service Cashbeck', type: 'reçu', pricenew: euro(getsoldeMain + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda)).format(), actual: euro(getsoldeMain + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda)).format() + ' ' + unite, unite: unite }

   const getRef = doc(db, "agent", getPhone);
   await updateDoc(getRef, {
    cdf: Number(getsoldeMain) + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    money: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    user: 'achat annulée',
    unite: unite,
    phoneclient: getPhone,
    delay: moment().format(),

    signaturecdf: `${getPhone}${parseInt(Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda))}${parseInt(Number(getsoldeMain))}${parseInt(Number(getsoldeMain) + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda))}${key}`,
    signaturecdftype: 'reussi',
    signaturemoneycdf: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    signaturekeycdf: key,
    signaturefraiscdf: 0,
    swap: arrayUnion(get),
    frais: 0,

    commistockcdf: commlivecdf,
    commistockunitecdf: communitlivecdf,

    thriftcdf: thriftcdf,
    commission: Number(0),
    sendtype: 'reçu'

   });

   const adminRef = doc(db, "muunganounitdatamonth", timemonth);
   await updateDoc(adminRef, {
    moneycdf: Number(monthprixcdf) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    unitcdf: Number(monthunitcdf) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").unitvoda),
   });

   const packRef = doc(db, "muunganomoney", "stockunitaire");
   await updateDoc(packRef, {
    pack: increment(-1)
   });


   // Add a new document with a generated id
   const newRef = doc(collection(db, "swap" + getPhone));
   // later...
   await setDoc(newRef, {

    action: `achat annulée`,
    money: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    before: getsoldeMain,
    after: getsoldeMain + Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),

    canceloperator: true,
    canceloperatorname: 'vodacom',


    type: 'reçu',
    date: moment().format(),
    delay: moment().format(),

    phone: getPhone,
    user: getUser,

    profile: getprofil,
    beforethrift: getthriftcdf,
    afterthrift: thriftcdf,
    commission: 0,
    frais: 0,
    key: key,
    devise: unite,

    soldebefore: getsoldeMain,
    soldeafter: getsoldeMain,

    color: '#F8F2EF',
    dateofcreate: serverTimestamp(),

    unit: Number(secureLocalStorage.getItem("#$$!!list%%#$$").unit) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").unitvoda),
    unitvoda: 0,
    unitairtel: secureLocalStorage.getItem("#$$!!list%%#$$").unitairtel,
    unitafricell: secureLocalStorage.getItem("#$$!!list%%#$$").unitafricell,
    unitorange: secureLocalStorage.getItem("#$$!!list%%#$$").unitorange,

    count: Number(secureLocalStorage.getItem("#$$!!list%%#$$").count) - Number(secureLocalStorage.getItem("#$$!!list%%#$$").countvoda),
    countvoda: 0,
    countairtel: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countairtel),
    countafricell: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countafricell),
    countorange: Number(secureLocalStorage.getItem("#$$!!list%%#$$").countorange),

    // numvoda: secureLocalStorage.getItem("#$$!!list%%#$$").numvoda,
    // numairtel: secureLocalStorage.getItem("#$$!!list%%#$$").numairtel,
    // numafricell: secureLocalStorage.getItem("#$$!!list%%#$$").numafricell,
    // numorange: secureLocalStorage.getItem("#$$!!list%%#$$").numorange,

    operavoda: false,
    operaairtel: secureLocalStorage.getItem("#$$!!list%%#$$").operaairtel === undefined ? false : true,
    operaorange: secureLocalStorage.getItem("#$$!!list%%#$$").operaorange === undefined ? false : true,
    operaafricell: secureLocalStorage.getItem("#$$!!list%%#$$").operaafricell === undefined ? false : true,

    fiat: secureLocalStorage.getItem("#$$!!list%%#$$").fiat,

    fiatvoda: false,
    fiatairtel: secureLocalStorage.getItem("#$$!!list%%#$$").fiatairtel,
    fiatorange: secureLocalStorage.getItem("#$$!!list%%#$$").fiatorange,
    fiatafricell: secureLocalStorage.getItem("#$$!!list%%#$$").operaafricell,

    typefiat: secureLocalStorage.getItem("#$$!!list%%#$$").typefiat,

    color: '#FFDBEA',
    dateofcreate: serverTimestamp(),

    statetrans: false,
    statepret: false,
    stateunit: true,
    statetontine: false,
    statetv: false,
    statesolaire: false,


   });

   const cityRef = doc(db, "swap" + getPhone, secureLocalStorage.getItem("#$$!!list%%#$$").dockey);
   // Remove the 'capital' field from the document
   await updateDoc(cityRef, {
    operavoda: false
   });


   if (secureLocalStorage.getItem("#$$!!list%%#$$").typefiat === 'simple') {

    await deleteDoc(doc(db, secureLocalStorage.getItem("#$$!!list%%#$$").fiat, secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda));

   } else {
    await deleteDoc(doc(db, secureLocalStorage.getItem("#$$!!list%%#$$").fiatmere, secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda));
   }

   await deleteDoc(doc(db, "muunganounitvoda", secureLocalStorage.getItem("#$$!!list%%#$$").fiatvoda));

   secureLocalStorage.setItem("#$$!!money%%$$$", 0);
   navigation(0);

  } else {
   window.console.log('nothing');
  }

 };
 const handleTransferVoda = async () => {

  if (getsoldeMain === null || getsoldeMain === undefined || getsoldeDollard === null || getsoldeDollard === undefined || getfirst === null || getfirst === undefined || getlast === null || getlast === undefined) {

   navigation(0);
   setConfirm(false);

  } else {

   if (secureLocalStorage.getItem("#$$!!devise%%$$$") === 'USD') {

    if (status) {
     setValid(true);
     setConfirm(false);
    } else {
     setConfirm(true);

    }

   } else if (secureLocalStorage.getItem("#$$!!devise%%$$$") === 'CDF') {

    if (status) {
     setValid(true);
     setConfirm(false);
    } else {
     setConfirm(true);

    }

   } else {
    window.console.log('hello');
   }

  }

 };


 return (
  <>
   <div className='zindex-theme'>
    <Backdrop
     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
     open={load}>
     <CircularProgress color="inherit" />
    </Backdrop>
   </div>

   <Snackbar open={opened} autoHideDuration={6000} onClose={handleClosed}>
    <Alert onClose={handleClosed} severity="success" sx={{ width: '100%' }}>
     <p className='cashbeck-text'>
      C'est achats vient d'être annulé
     </p>
    </Alert>
   </Snackbar>

   <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Aucune référence n'est établie sur ses transferts veuillez vérifier la valeur.
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>
     <Button onClick={handleClose}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={error}
    onClose={handleError}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>


    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Le transfert n'est peut-être effectué car
       la balance du réducteur s'avère être insuffisante pour établir ce litige
      </p>

     </DialogContentText>


    </DialogContent>
    <DialogActions>
     <Button onClick={handleError}><p className='cashbeck'>Fermer</p></Button>
    </DialogActions>
   </Dialog>

   <Dialog
    open={valid}
    onClose={handleClosevalid}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>

    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Cette commande n'est peut-être annuler, car elle est déjà validée
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>

     <Button onClick={handleClosevalid}><p className='cashbeck'>Annuler</p></Button>

    </DialogActions>
   </Dialog>

   <Dialog
    open={confirm}
    onClose={handleConfirmCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   >
    <DialogTitle id="alert-dialog-title">
     <h1 className='pop-up'>Cashbeck</h1>
    </DialogTitle>

    <DialogContent>
     <DialogContentText id="alert-dialog-description">

      <p className='cashbeck-text'>
       Cliquez sur confirmer pour valider l'annulation de cette commande d'achat
      </p>

     </DialogContentText>

    </DialogContent>
    <DialogActions>

     <div className='flex-between'>
      <Button onClick={handleConfirmCancel}><p className='cashbeck'>Annuler</p></Button>
      <Button onClick={handleValidVodacom}><p className='cashbeck'>Confirmer</p></Button>
     </div>

    </DialogActions>
   </Dialog>


   <button onClick={handleTransferVoda} className='BTn'>
    <div className='flex-between'>
     <Avatar
      variant="rounded"
      src="/img/vodafone.png"
      sx={{ width: 30, height: 30 }}
     />

     <p style={{ marginTop: '2vh' }} className='pop-up'>
      Annuler
     </p>

    </div>
   </button>

  </>
 );
};

