import * as React from 'react';
import Drawer from '@mui/material/Drawer';
// import { CiEdit } from "react-icons/ci";
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
import Media from 'react-media';
// import ReturnList from './List';
// import REf from './Ref/Main';
import secureLocalStorage from "react-secure-storage";
import Avatar from '@mui/material/Avatar';
import CliEnT from '../Client/Main';


export default function RetuRnClienT() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};


export const ScreenLarge = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const toggleDrawer = (anchor, open) => (event) => {

  secureLocalStorage.setItem("##$devise&&^^##", true);
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Avatar sx={{ width: 40, height: 40 }} variant="rounded" src="/notebook-credit.png" />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 600 }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <CliEnT />
     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};
export const ScreenSmall = () => {

 const [state, setState] = React.useState({
  right: false,
 });

 const toggleDrawer = (anchor, open) => (event) => {

  secureLocalStorage.setItem("##$devise&&^^##", true);
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
   return;
  }

  setState({ ...state, [anchor]: open });

 };

 return (
  <div>
   {['right'].map((anchor) => (
    <React.Fragment key={anchor}>

     <div onClick={toggleDrawer(anchor, true)}>
      <Avatar sx={{ width: 40, height: 40 }} variant="rounded" src="/img/book.png" />
     </div>

     <Drawer
      anchor={anchor}
      open={state[anchor]}

      PaperProps={{
       sx: { width: 300, backgroundColor: '#DDDEDA' }
      }}

      onClose={toggleDrawer(anchor, false)}>

      <CliEnT />

     </Drawer>
    </React.Fragment>
   ))}
  </div>
 );
};

