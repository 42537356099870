
import './Budget.css';


// budget transfert
export default function RetuRnBudgET() {
 return (
  <div className='flex-budget-transfert'>
  </div>
 );
};