import * as React from 'react';
// import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Panel } from 'office-ui-fabric-react/lib/Panel';
import { useConstCallback } from '@uifabric/react-hooks';
import { FiMenu, FiX } from "react-icons/fi";
import './Month.css';

import ReturnJanuary from './January';
import ReturnFebruary from './February';
import ReturnMarch from './March';
import ReturnApriL from './April';
import ReturnMay from './May';
import ReturnJune from './June';
import ReturnJuly from './July';
import ReturnAugust from './August';
import ReturnSeptember from './September';
import ReturnOctober from './October';
import ReturnNovember from './November';
import ReturnDecember from './December';


export const PanelBasicExample = () => {

 const [isOpen, setIsOpen] = React.useState(false);
 const openPanel = useConstCallback(() => setIsOpen(true));
 const dismissPanel = useConstCallback(() => setIsOpen(false));

 return (
  <div className='wrp-month-throw'>
   <div></div>
   <div>
    <FiMenu size={'2em'} onClick={openPanel} style={{ cursor: 'pointer' }} />

    <Panel
     isOpen={isOpen}
     onDismiss={dismissPanel}
     // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
     closeButtonAriaLabel="Close"
    >
     <div className='flex-month-throw-content'>
      <div></div>
      <div className='throw-content-icon'>
       <FiX size={'2em'} onClick={dismissPanel} style={{ cursor: 'pointer' }} />
      </div>

     </div>


     <div className='wrp-unit-list-month'>
      <h2>Accumulation mensuel des unité actif</h2>
     </div>

     <ReturnJanuary />
     <ReturnFebruary />
     <ReturnMarch />
     <ReturnApriL />
     <ReturnMay />
     <ReturnJune />
     <ReturnJuly />
     <ReturnAugust />
     <ReturnSeptember />
     <ReturnOctober />
     <ReturnNovember />
     <ReturnDecember />

    </Panel>

   </div>
  </div>
 );
};

export default PanelBasicExample;