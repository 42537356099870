
import RetuRnFrAn from './Fran';
import RetuRnDollard from './Dollard';

// Balance Two
export default function ReturnBAlAnce() {
 return (
  <div>
   <RetuRnDollard />
   <RetuRnFrAn />
  </div>
 );
};