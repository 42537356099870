import React from 'react';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from '../../../../firebase';
import './List.css';
import Divider from '@mui/material/Divider';
import currency from 'currency.js';
import moment from 'moment';


// Body Creditaire
export default function ReturnList() {

 let pushDoc = new Array();
 const [arr, setArr] = React.useState([]);

 React.useEffect(async () => {

  const q = query(collection(db, 'muunganocreditaire'), orderBy('dateofcreate'));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
   pushDoc.push(doc.data())

  })

  setArr([...new Set(pushDoc.reverse())]);

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });

 return (
  <div className='flex-col-list-refs'>
   {arr?.length ?
    <>
     {[...Array(arr.length).keys()].map((index) => {
      return (
       <ul style={{ backgroundColor: arr[index].status === true ? '#a0a499' : '#edede9' }}>

        <li className='flex-between'>
         <p>Banque : </p>
         <p>{arr[index].bank}</p>
        </li>

        <li className='flex-between'>
         <p>Nº Bancaire : </p>
         <p>{arr[index].banknumber}</p>
        </li>

        <li className='flex-between'>
         <p>Nº Initiateur : </p>
         <p>{arr[index].initiator}</p>
        </li>

        <li className='flex-between'>
         <p>Nº référence : </p>
         <p>{arr[index].reference}</p>
        </li>

        <li className='flex-between'>
         <p>Montant : </p>
         <p>{euro(arr[index].count).format()} {arr[index].devise}</p>
        </li>

        <li className='flex-between'>
         <p>Status : </p>
         <p>{arr[index].status === true ? "Valider" : "Non valide"}</p>
        </li>

        <li className='flex-between'>
         <p>Date : </p>
         <p>{moment(arr[index].date).format('LLL')}</p>
        </li>

        <Divider />
       </ul>
      )
     })}
    </>
    :
    <div></div>
   }
  </div >
 );
};