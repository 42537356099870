
import RetuRnBudGeT from './Budget';

// Budget Creditaire Dash
export default function BudGeT() {
 return (
  <div style={{ backgroundColor: '#EEEEEA' }}>
   <RetuRnBudGeT />
  </div>
 );
};