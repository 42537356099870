import React from 'react';
import './List.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../../../../firebase';
import secureLocalStorage from "react-secure-storage";
import currency from 'currency.js';


// Identity View
export default function RetuRnThriftFrAn() {

 let [price, setPrice] = React.useState(0);

 React.useEffect(async () => {
  const unsub = onSnapshot(doc(db, secureLocalStorage.getItem("@#%%!state&&*@@#!!user++"), secureLocalStorage.getItem("@#%%!phone&&*@@#!!user++")), (doc) => {
   setPrice(doc.data().thriftcdf);

  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 return (
  <div className='list-sub-exchange-info-detail color-purple-info-low'>
   <p>Épargne en franc : </p>
   <p>{euro(price).format()} USD</p>
  </div>
 );
};