import './Group.css';
import HeAd from './Head/Main';
import BodY from './Body/Main';

// Group Main
export default function RetuRnGROup() {
 return (
  <div>
   <HeAd />
   <BodY />
  </div>
 );
};