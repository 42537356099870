import './Nav.css';
import RetuRnsAve from './Save';

// Nav 
export default function RetuRnNAv() {
 return (
  <div className='wrp-nav-detail-user'>
   <div></div>
   <RetuRnsAve />
  </div>
 );
};