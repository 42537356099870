
import ReturnEtaBlish from './Etablish';
import HistoRy from './History/Main';
import Media from 'react-media';


// Etablish 
export default function EtaBlish() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <ScreenLarge />}
     {matches.large && <ScreenLarge />}
    </>
   )}
  </Media>
 );
};

const ScreenLarge = () => (
 <div className="grid-share-col">
  <ReturnEtaBlish />
  <HistoRy />
 </div>
);
const ScreenSmall = () => (
 <div>
  <ReturnEtaBlish />
 </div>
);


