
import AsKed from './Asked/Main';
import AcTive from './Active/Main';
import './Icon.css';


// Icon Main
export default function RetuRnIcOnPreT() {
 return (
  <div className='flex-between flex-width-10em'>
   <AsKed />
   <AcTive />
  </div>

 );
};