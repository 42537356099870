import React from 'react';
import { db } from '../../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";
import './Fran.css';
import currency from 'currency.js';
import RetuRNUnit from './Unit';


// Fran Solde Creditaire
export default function ReturnDollARd() {

 const [item, setItem] = React.useState(null);
 React.useEffect(async () => {

  const unsub = onSnapshot(doc(db, "muunganomoney", "budgetcreditaire"), (doc) => {
   setItem(doc.data().usd);
  });

 }, []);

 var euro = value => currency(value, { separator: ' ', decimal: ',', symbol: '' });
 return (
  <p>{euro(item).format()} USD</p>
 );
};
