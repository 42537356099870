import './Two.css';
import HeAd from './Head/Main';
import LEFt from './Left/Main';
// import RIgHT from './Right/Main';
import Media from 'react-media';



// Two Litige 
export default function TwO() {
 return (
  <Media
   queries={{
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width:1199px)',
    large: '(min-width: 1200px)',
   }}>
   {matches => (
    <>
     {matches.small && <ScreenSmall />}
     {matches.medium && <SreenLarge />}
     {matches.large && <SreenLarge />}
    </>
   )}
  </Media>
 );
};

export const SreenLarge = () => (
 <div className='grid-template-tontine-sm'>
  <HeAd />
  <LEFt />
  <div></div>
 </div>
);
export const ScreenSmall = () => (
 <div className='grid-template-tontine-sm'>
  <HeAd />
  <LEFt />
  <div></div>
 </div>
);