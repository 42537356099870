

import RetuRnGlobAl from './All/Global';
import RetuRnCliEnT from './All/Client';
import RetuRnAgenT from './All/Agent';

// Info Head Component
export default function RetuRnInFO() {
 return (
  <div className='flex-between'>
   <RetuRnGlobAl />
   <RetuRnCliEnT />
   <RetuRnAgenT />
  </div>
 );
};