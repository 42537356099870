
import './IMA.css';

// View IMAGE
export default function RetuRnIMA() {
 return (
  <div className='flex-img-side-info'>
   <img src="/img/2928727.png" />
  </div>
 );
}